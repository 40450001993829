import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { Card, Table } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import UheHelper from 'util/UheHelper';
import { shouldShowCustomerActionsCell } from '@util/UheRoleChecker';
import { injectIntl } from 'react-intl';

import ListingsTopFilter from '@filters/ListingsTopFilter';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import ActionsCell from '@components/tables/cells/ActionsCell';
import ExportCsv from '@components/ExportCsv/ExportCsv';

import {
  customersOnFetchData,
  deleteCustomer,
  fetchInterpreterOptions,
} from '@uhe_actions/configuration/customers/CustomersActions';
import {
  LISTING_TABLES_PAGE_SIZE,
  TOP_FILTER_PREFIX,
  TABLE_FILTER_PREFIX,
  APP_PAGES_CONTEXT,
} from '@constants/UHESettings';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { setSubtitle } from '@uhe_actions/SubtitleActions';

/**
 * @description Renders customers page
 */
class Customers extends React.Component {
  /**
   * @description Adapts the data from the API
   * @param {Array} data
   * @returns {Array}
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      if (value.organization) {
        adaptedData.push({
          key: index,
          organization: {
            id: value.organization.id,
            name: value.organization.name,
          },
          customer: {
            id: value.id,
            organizationId: value.organization.id,
            name: value.name,
          },
          clinicians: value.no_clinicians,
          interpreter: value.interpreter.name,
          numberOfBedsCarts: value.total_devices_active,
          advancedReports:
            value.advanced_reports.active === 1 ? (
              <IntlMessages id="common.on" />
            ) : (
                <IntlMessages id="common.off" />
              ),
          actions: {
            id: value.id,
          },
        });
      }
    });

    return adaptedData;
  }

  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);
    const {
      history, subtitle, setSubtitle, intl,
    } = this.props;

    this.data = [];
    this.columns = [];
    this.filterTypes = {
      advancedReports: {
        type: 'dropdown',
        options: [
          {
            value: '1',
            label: intl.formatMessage({ id: 'common.on' }),
          },
          {
            value: '0',
            label: intl.formatMessage({ id: 'common.off' }),
          },
        ],
      },
      interpreter: {
        type: 'dropdown',
        options: [],
      },
    };

    this.topFilterMap = {
      [`${TOP_FILTER_PREFIX}organization`]: 'organization.id',
      [`${TOP_FILTER_PREFIX}customer`]: 'id',
    };
    this.tableFilterMap = {
      organization: 'organization.name',
      customer: 'name',
      clinicians: 'no_clinicians',
      unit: 'unit.name',
      numberOfBedsCarts: 'total_devices_active',
      interpreter: 'interpreter.name',
      advancedReports: 'advanced_reports.active',
      branding: 'branding',
    };
    this.tableKeys = [
      'organization',
      'customer',
      'clinicians',
      'numberOfBedsCarts',
      'interpreter',
    ];

    this.tableKeys.forEach((value, index) => {
      const filter = this.filterTypes[value] || {};
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            filterType={filter.type}
            filterOptions={filter.options}
            showFilter={
              !(
                value === 'actions'
                || value === 'numberOfBedsCarts'
                || value === 'clinicians'
              )
            }
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            triggerCharsNum={
              value === 'clinicians' || value === 'numberOfBedsCarts'
                ? 0
                : undefined
            }
          />
        ),
        sorter: (value === 'actions') ? false : { multiple: index },
        defaultSortOrder: (value === 'organization') ? this.checkSortTableOrder() : false,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.topFilters = [
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byOrganization',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'organization',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byCustomer',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'customer',
      },
    ];

    if (
      subtitle && subtitle.langId !== 'customers.title'
    ) {
      setSubtitle('customers.title');
    }

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);

    this.onPageChange(1);
  }

  componentDidMount() {
    this.props.fetchInterpreterOptions();
  }

  /**
   * @description Updates table on location change
   * @param {Object} prevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { location, loggedUser } = this.props;
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }
    const { interpreterOptions } = this.props;
    if (prevProps.interpreterOptions !== interpreterOptions) {
      this.filterTypes.interpreter.options = interpreterOptions.list.map((option) => ({
        value: option.name,
        label: option.name,
      }));
      this.forceUpdate();
    }
  }

  /**
   * @param  {number} page
   * @return {void}
   */
  onPageChange(page) {
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const sort = [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);

      if (filterParam) {
        if (
          this.filterTypes[key]
          && this.filterTypes[key].type === 'dropdown'
        ) {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }
    });

    currSort.forEach((sortItem) => {
      const key = sortItem.split(',')[0];
      const value = this.tableFilterMap[key];

      if (value) {
        sort.push(sortItem.replace(key, value));
      }
    });

    this.filter = filter;
    this.sort = sort;

    this.props.customersOnFetchData(this.currentPage, sort, filter);
  }

  /**
   * @description Check the default sort order for table
   * @returns {string}
   */
  checkSortTableOrder() {
    const { location } = this.props;
    let checkOrder = location.search.split('%2C')[1];

    if (checkOrder === 'asc') {
      checkOrder = 'ascend';
    }
    if (checkOrder === 'desc') {
      checkOrder = 'descend';
    }
    if (!checkOrder) {
      checkOrder = false;
    }

    return checkOrder;
  }

  /**
   * @description Renders cell
   * @param {string} content
   * @param {string} key
   * @return {JSX}
   */
  cellRenderer(content, key) {
    let cell;
    const { intl, loggedUser } = this.props;

    switch (key) {
      case 'organization':
        cell = <OrganizationCell content={content} />;
        break;
      case 'customer':
        cell = <CustomerCell content={content} showIco={false} />;
        break;
      case 'actions':
        cell = (
          <ActionsCell
            page={APP_PAGES_CONTEXT.customers}
            loggedUser={loggedUser}
            intl={intl}
            content={content}
            editLink={`/configuration/customers/edit/${content.id}`}
            deleteAction={() => {
              this.props.deleteCustomer(
                content.id,
                this.currentPage,
                this.sort,
                this.filter,
              );
            }}
            bulkLink={`/configuration/customers/edit/${content.id}/bulk-actions`}
          />
        );
        break;
      default:
        cell = content;
    }

    return cell;
  }

  /**
  * @description Render Add Button
  * @returns {JSX}
  */
  renderAddButton = () => {
    const { isCaregilitySystemAdmin, isOrganizationAdmin } = this.props.loggedUser;

    if (isCaregilitySystemAdmin || isOrganizationAdmin) {
      return (
        <Link
          to="/configuration/customers/new"
          shape="circle"
          icon="+"
          className="page-icons page-icon-plus"
        >
          <i className="icon icon-add" />
          <span>
            <IntlMessages id="common.addText" />
          </span>
        </Link>
      );
    }

    return null;
  }

  /**
  * @description Render Export Csv Button
  * @returns {JSX}
  */
  renderExportCsvButton = () => {
    const { isCaregilitySystemAdmin, isCaregilityAdmin, isOrganizationAdmin } = this.props.loggedUser;

    if (isCaregilitySystemAdmin || isCaregilityAdmin || isOrganizationAdmin) {
      return (
        <ExportCsv
          getCsvUrl={UheHelper.getCsvUrl(
            this.filter,
            this.sorting,
            ENDPOINTS.ExportCsv.customers,
          )}
        />
      );
    }
  }

  /**
  * @description Render table
  * @returns {JSX}
  */
  renderTable = () => {
    const { pagination, data, loading, loggedUser } = this.props;
    pagination.onChange = this.onPageChange;

    if (loggedUser.roles) {
      const hasAdminPermission = shouldShowCustomerActionsCell(loggedUser);

      if (hasAdminPermission) {
        if (this.columns.length <= 5) {
          const foundActions = this.tableKeys.filter(
            (key) => key === 'actions');

          if (foundActions.length === 0) {
            this.tableKeys.push('actions');
          }

          this.tableKeys.filter((key) => key === 'actions').forEach((value, index) => {
            const filter = this.filterTypes[value] || {};
            this.columns.push({
              title: (cellData) => (
                <ListingsTableInputFilter
                  filterType={filter.type}
                  filterOptions={filter.options}
                  showFilter={
                    !(
                      value === 'actions'
                      || value === 'numberOfBedsCarts'
                      || value === 'clinicians'
                    )
                  }
                  cellData={cellData}
                  title={`uhe.table.${value}`}
                  dataKey={value}
                  triggerCharsNum={
                    value === 'clinicians' || value === 'numberOfBedsCarts'
                      ? 0
                      : undefined
                  }
                />
              ),
              sorter: (value === 'actions') ? false : { multiple: index },
              defaultSortOrder: (value === 'organization') ? this.checkSortTableOrder() : false,
              align: index > 3 ? 'center' : 'left',
              minWidth: 200,
              dataIndex: value,
              render: (content) => this.cellRenderer(content, value),
            });
          });
        }
      }

      return (
        <Table
          bordered
          className="gx-table-responsive"
          columns={this.columns}
          dataSource={Customers.dataAdapter(data)}
          pagination={pagination}
          loading={loading}
        />
      );
    }
  }

  render() {
    const { pagination, data, loading } = this.props;
    pagination.onChange = this.onPageChange;

    return (
      <div className="dashboard configuration">
        <div className="customers-filter-box">
          <Card
            className="filter-boxes gx-card"
            title={<IntlMessages id="uhe.title.filters" />}
          >
            <ListingsTopFilter filters={this.topFilters} />
          </Card>
        </div>
        <div>
          <div className="uhe-table-header">
            <div className="buttons-container">
              {this.renderAddButton()}
              {this.renderExportCsvButton()}
            </div>
            <p>
              <IntlMessages id="uhe.table.matchingResults" />
              <span>{pagination.total}</span>
            </p>
          </div>
          <div className="uhe-table">
            {this.renderTable()}
          </div>
        </div>
      </div>
    );
  }
}

Customers.defaultProps = {
  data: [],
  loading: true,
  pagination: {
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
  showIco: false,
};

Customers.propTypes = {
  data: PropTypes.array,
  location: PropTypes.object,
  history: PropTypes.object,
  subtitle: PropTypes.object,
  pagination: PropTypes.object,
  intl: PropTypes.object,
  loading: PropTypes.bool,
  setSubtitle: PropTypes.func,
  customersOnFetchData: PropTypes.func,
  setSubtitle: PropTypes.func,
  interpreterOptions: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ConfigurationCustomers, subtitle, ConfigurationUsers }) => {
  const { list, page } = ConfigurationCustomers.table || {};
  const { loading, interpreterOptions } = ConfigurationCustomers;
  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  return {
    data: list,
    pagination,
    loading,
    subtitle,
    loggedUser: ConfigurationUsers.ownUser,
    interpreterOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteCustomer: (id, page, sorting, filter) => dispatch(deleteCustomer({
    id, page, sorting, filter,
  })),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  customersOnFetchData: (page, sort, filter) => dispatch(customersOnFetchData(page, sort, filter)),
  fetchInterpreterOptions: () => dispatch(fetchInterpreterOptions()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(Customers)));
