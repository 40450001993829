import React, { useEffect } from 'react';
import {
  Card, Select, Col, Row, Input, Form, Checkbox, Popover,
} from 'antd';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
  UHE_BEDCART_AUTH,
  UHE_BEDCART_INNER_AUTH,
  CONNECTION_TYPES,
  PROTOCOL_TYPES,
} from '@constants/UHESettings';
import { shouldShowEditPasswordIpConfiguration } from '@util/UheRoleChecker';

const { Option } = Select;

const connections = Object.values(PROTOCOL_TYPES).map((type) => (
  <Option key={type} title={CONNECTION_TYPES[type]} value={type}>
    {CONNECTION_TYPES[type]}
  </Option>
));

const authTypes = UHE_BEDCART_AUTH.map((auth, index) => (
  <Option key={index} title={auth}>
    {auth}
  </Option>
));

const innerAuthTypes = UHE_BEDCART_INNER_AUTH.map((innerAuth, index) => (
  <Option key={index} title={innerAuth}>
    {innerAuth}
  </Option>
));

/**
 * Renders IP Configuration Card in ManageUheBedCart file
 * @param {Object} bedCart bedCart
 * @param {Object} intl intl
 * @param {function} onChangeInputHandler on change input headler
 * @param {function} onChangeAuth on change Auth
 * @param {boolean} loading loading
 * @param {boolean} loggedUser logged User
 * @returns {JSX} return
 */
const IpConfigurationCard = ({
  bedCart,
  intl,
  onChangeInputHandler,
  onChangeAuth,
  loading,
  loggedUser,
}) => {
  /**
   * @description Handle Changes in the Connection Input
   * @param {string} protocolType
   */
  const setProtocol = (protocolType) => {
    onChangeInputHandler({
      target: {
        value: protocolType,
      },
    }, 'protocol');
  };

  useEffect(() => {
    if (!bedCart.protocol) {
      setProtocol(PROTOCOL_TYPES.dhcp);
    }
  }, [bedCart]);

  /**
   * @description Depending on Connection Type Renders it's Input Fields
   * @returns {JSX.Element}
   */
  const renderConnectionType = () => {
    switch (bedCart.protocol) {
      case PROTOCOL_TYPES.staticIp:
        return (
          <div>
            <Form.Item
              className="gx-align-items-center"
              name="ip4v"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.ipAddress" />
              }
              initialValue={bedCart.ip4v}
            >
              <Input
                onChange={(e) => onChangeInputHandler(e, 'ip4v')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.ipAddress',
                })}
              />
            </Form.Item>
            <Form.Item
              className="gx-align-items-center"
              name="netmask"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.subMask" />
              }
              initialValue={bedCart.netmask}
            >
              <Input
                onChange={(e) => onChangeInputHandler(e, 'netmask')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.subMask',
                })}
              />
            </Form.Item>
            <Form.Item
              className="gx-align-items-center"
              name="gateway"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.gateway" />
              }
              initialValue={bedCart.gateway}
            >
              <Input
                onChange={(e) => onChangeInputHandler(e, 'gateway')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.gateway',
                })}
              />
            </Form.Item>
            <Form.Item
              className="gx-align-items-center"
              name="preferred_dns"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.prefDns" />
              }
              initialValue={bedCart.preferred_dns}
            >
              <Input
                onChange={(e) => onChangeInputHandler(e, 'preferred_dns')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.prefDns',
                })}
              />
            </Form.Item>
            <Form.Item
              className="gx-align-items-center"
              name="alternate_dns"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.altDns" />
              }
              initialValue={bedCart.alternate_dns}
            >
              <Input
                onChange={(e) => onChangeInputHandler(e, 'alternate_dns')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.altDns',
                })}
              />
            </Form.Item>
          </div>
        );

      case PROTOCOL_TYPES.wifi:
        return (
          <div>
            <Form.Item
              className="gx-align-items-center"
              name="ssid"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.ssid" />
              }
              initialValue={bedCart.ssid}
            >
              <Input
                onChange={(e) => onChangeInputHandler(e, 'ssid')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.ssid',
                })}
              />
            </Form.Item>
            <Form.Item
              hidden={!shouldShowEditPasswordIpConfiguration(loggedUser)}
              className="gx-align-items-center"
              name="ssid_password"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.ssidPass" />
              }
              initialValue={bedCart.ssid_password}
            >
              <Input
                onChange={(e) => onChangeInputHandler(e, 'ssid_password')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.ssidPass',
                })}
              />
            </Form.Item>
          </div>
        );

      case PROTOCOL_TYPES.enterpriseWifi:
        return (
          <div>
            <Form.Item
              className="gx-align-items-center"
              name="enterprise_network_name"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.netName" />
              }
              initialValue={bedCart.enterprise_network_name}
            >
              <Input
                onChange={(e) => onChangeInputHandler(e, 'enterprise_network_name')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.netName',
                })}
              />
            </Form.Item>
            <Form.Item
              className="gx-align-items-center"
              name="enterprise_username"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.user" />
              }
              initialValue={bedCart.enterprise_username}
            >
              <Input
                onChange={(e) => onChangeInputHandler(e, 'enterprise_username')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.user',
                })}
              />
            </Form.Item>
            <Form.Item
              hidden={!shouldShowEditPasswordIpConfiguration(loggedUser)}
              className="gx-align-items-center"
              name="enterprise_password"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.pass" />
              }
              initialValue={bedCart.enterprise_password}
            >
              <Input.Password
                onChange={(e) => onChangeInputHandler(e, 'enterprise_password')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.pass',
                })}
              />
            </Form.Item>
            <Form.Item
              className="gx-align-items-center"
              name="enterprise_auth"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.auth" />
              }
              initialValue={bedCart.enterprise_auth}
            >
              <Select
                onChange={(e, auth) => onChangeAuth(e, auth, 'enterprise_auth')}
              >
                {authTypes}
              </Select>
            </Form.Item>
            <Form.Item
              className="gx-align-items-center"
              name="enterprise_inner_auth"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.innerAuth" />
              }
              initialValue={bedCart.enterprise_inner_auth}
            >
              <Select
                onChange={(e, auth) => onChangeAuth(e, auth, 'enterprise_inner_auth')}
              >
                {innerAuthTypes}
              </Select>
            </Form.Item>
            <Form.Item
              hidden={bedCart.enterprise_auth !== 'PEAP'}
              className="gx-align-items-center"
              name="enterprise_DomainName"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.domainName" />
              }
              initialValue={bedCart.enterprise_do}
            >
              <Popover content={<IntlMessages id="configuration.bedsCarts.ipConfig.damainmessage" />}>
                <Input
                  onChange={(e) => onChangeInputHandler(e, 'enterprise_domain_name')}
                  longdesc={intl.formatMessage({
                    id: 'configuration.bedsCarts.descriptions.domainName',
                  })}
                />
              </Popover>
            </Form.Item>
            <Form.Item
              hidden={bedCart.enterprise_auth !== 'PEAP'}
              className="gx-align-items-center"
              name="enterprise_ca"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.cacertificaterequired" />
              }
              initialValue={bedCart.enterprise_ca}
            >
              <Popover
                overlayStyle={{ width: '20vw' }}
                content={<IntlMessages id="configuration.bedsCarts.ipConfig.camessage" />}>
                <Checkbox
                  onChange={(e) => onChangeInputHandler(e, 'enterprise_ca')}
                  longdesc={intl.formatMessage({
                    id: 'configuration.bedsCarts.descriptions.cacertificaterequired',
                  })}
                />
              </Popover>
            </Form.Item>
            <Form.Item
              hidden={bedCart.enterprise_auth !== 'PEAP'}
              className="gx-align-items-center"
              name="enterprise_anonymous_Identity"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.anonymousIdentity" />
              }
              initialValue={bedCart.enterprise_ai}
            >
              <Popover
                overlayStyle={{ width: '20vw' }}
                content={<IntlMessages id="configuration.bedsCarts.ipConfig.anonymousmessage" />}>
                <Input
                  onChange={(e) => onChangeInputHandler(e, 'enterprise_ai')}
                  longdesc={intl.formatMessage({
                    id: 'configuration.bedsCarts.descriptions.anonymousIdentity',
                  })}
                />
              </Popover>
            </Form.Item>
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <div>
      <Card
        className="gx-card"
        title={<IntlMessages id="configuration.bedsCarts.ipConfig.title" />}
        loading={loading}
      >
        <Row className="gx-align-items-center care-device-row">
          <div>
            <p><IntlMessages id="configuration.bedsCarts.actions.description1" /></p>
            <p><IntlMessages id="configuration.bedsCarts.actions.description2" /></p>
            <p><IntlMessages id="configuration.bedsCarts.actions.description3" /></p>
            <p><IntlMessages id="configuration.bedsCarts.actions.description4" /></p>
          </div>
        </Row>
        <Row className="care-device-row">
          <Col lg={10}>
            <Form.Item
              className="gx-align-items-center"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.conn" />
              }
            >
              <Select value={bedCart.protocol} onChange={setProtocol}>
                {connections}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={14}>
            {renderConnectionType()}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

IpConfigurationCard.defaultProps = {
  loading: true,
};

IpConfigurationCard.propTypes = {
  bedCart: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
  onChangeInputHandler: PropTypes.func.isRequired,
  onChangeAuth: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loggedUser: PropTypes.isRequired,
};

export default injectIntl(IpConfigurationCard);
