import React, { Component, useRef } from 'react';
import { GoogleMap, withGoogleMap } from 'react-google-maps';
import _ from 'lodash';
import { Input, Card, Form } from 'antd';
import { SearchBox } from 'react-google-maps/lib/components/places/SearchBox';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import IntlMessages from '@util/IntlMessages';

import {
  ADDRESS_POSTAL_CODE,
  ADDRESS_COUNTRY,
  ADDRESS_CITY,
  ADDRESS_STATE,
} from '@constants/UHESettings';

const FacilityMap = withGoogleMap((props) => {
  const { google } = window;
  const marker = useRef(null);
  const searchBox = useRef(null);
  const map = useRef(null);

  /**
   * @description Changes marker on location change and updates state with the proper data
   * @returns {void}
   */
  const onPlacesChanged = () => {
    const places = searchBox.current.getPlaces();
    let lat;
    let lng;
    let country = '';
    let adrState = '';
    let city = '';
    let street = '';
    let zip = '';

    places.forEach((place) => {
      lat = place.geometry.location.lat();
      lng = place.geometry.location.lng();
      street = place.formatted_address;

      if (place.address_components) {
        place.address_components.forEach((adr_component) => {
          adr_component.types.forEach((type) => {
            switch (type) {
              case ADDRESS_POSTAL_CODE:
                zip = adr_component.short_name;
                break;

              case ADDRESS_COUNTRY:
                country = adr_component.long_name;
                break;

              case ADDRESS_CITY:
                city = adr_component.short_name;
                break;

              case ADDRESS_STATE:
                adrState = adr_component.short_name;
                break;
            }
          });
        });
      }

      props.handlePlaceChange(
        { lat, lon: lng },
        zip,
        country,
        city,
        adrState,
        street,
      );
    });

    map.current.panTo({ lat, lng });
  };

  const markerPos = {
    lat: props.facility.geo.lat,
    lng: props.facility.geo.lon,
  };

  const { disabled } = props;

  return (
    <GoogleMap
      ref={map}
      defaultZoom={15}
      options={{ streetViewControl: false, fullscreenControl: false }}
      defaultCenter={{
        lat: props.facility.geo.lat,
        lng: props.facility.geo.lon,
      }}
    >
      <SearchBox
        ref={searchBox}
        onPlacesChanged={onPlacesChanged}
        controlPosition={google.maps.ControlPosition.TOP_CENTER}
      >
        <Form.Item
          className="search-box"
          name="searchBox"
          rules={[
            {
              required: props.isNew,
              message: <IntlMessages id="common.error_address" />,
            },
          ]}
        >
          <Input
            style={{ width: '300px' }}
            type="text"
            placeholder="Enter address"
            onChange={props.onChangeHandlerAddress}
            value={props.facility.address1}
            disabled={disabled}
          />
        </Form.Item>
      </SearchBox>
      {props.facility.city && <MarkerWithLabel
        ref={marker}
        position={markerPos}
        labelAnchor={new google.maps.Point(0, 190)}
      >
        <Card className="gx-card">
          <div>
            <p>Street: {props.facility.address1}</p>
            <p>City: {props.facility.city}</p>
            <p>State: {props.facility.state}</p>
            <p>ZIP Code: {props.facility.zip}</p>
          </div>
        </Card>
      </MarkerWithLabel>}
    </GoogleMap>
  );
});
class SimpleMap extends Component {
  render() {
    return (
      <Card className="gx-card facility-map">
        <FacilityMap
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `550px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          {...this.props}
        />
      </Card>
    );
  }
}

export default SimpleMap;
