import React, { useState, useEffect } from 'react';
import { Menu, Button } from 'antd';
import { Link } from 'react-router-dom';

import CustomScrollbars from 'util/CustomScrollbars';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from '@constants/ThemeSetting';
import IntlMessages from '@util/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import {
  ExceptionOutlined, DesktopOutlined, ExclamationCircleOutlined, FundViewOutlined,
} from '@ant-design/icons';
import {
  onNavStyleChange,
  toggleCollapsedSideNav,
} from 'appRedux/actions/Setting';
import {
  shouldRenderNotificationsLink,
  shouldRenderMonitoringLink,
  shouldRenderRoomBedLink,
  shouldRenderUnitLink,
  shouldRenderFacilityLink,
  shouldRenderCustomerLink,
  shouldRenderOrganizationLink,
  shouldRenderUsersLink,
  shouldRenderSSOProvidersLink,
  shouldRenderReportingLink,
  shouldRenderSystemLink,
  shouldRenderIObserverLink,
  shouldRenderTechnicianLink,
  shouldRenderDeveloperLink,
  shouldRenderSettingsLink,
  isTechnicianAccess,
} from '@util/UheRoleChecker';
import UserProfile from './UserProfile';
import SidebarLogo from './SidebarLogo';
import { useIntl } from 'react-intl';

const { SubMenu } = Menu;

/**
 * @description Handles sidebar menu
 * @returns {JSX}
 */
const SidebarContent = () => {
  const dispatch = useDispatch();

  const {
    width, navCollapsed, themeType, pathname,
  } = useSelector(
    ({ settings }) => settings,
  );

  const [closedBedsCartsSubmenu, setClosedBedsCartsSubmenu] = useState(true);
  const [closedSettingsSubmenu, setClosedSettingsSubmenu] = useState(true);
  const [closedReportingSubmenu, setClosedReportingSubmenu] = useState(true);
  const loggedUser = useSelector(({ ConfigurationUsers }) => ConfigurationUsers.ownUser);

  const [isCaregilitySystemAdmin, setCaregilitySystemAdmin] = useState(false);
  const [isUserAdmin, setUserAdmin] = useState(false);
  const [isCaregilityAdmin, setCaregilityAdmin] = useState(false);
  const [isOrganizationAdmin, setOrganizationAdmin] = useState(false);
  const [isCustomerAdmin, setCustomerAdmin] = useState(false);
  const [isFacilityAdmin, setFacilityAdmin] = useState(false);
  const [isUnitAdmin, setUnitAdmin] = useState(false);
  const [isTechnicianAdmin, setTechnicianAdmin] = useState(false);
  const [isTechnician, setTechnician] = useState(false);

  useEffect(() => {
    if (loggedUser.roles) {
      const caregilitySystemAdmin = loggedUser.isCaregilitySystemAdmin;
      const userAdmin = loggedUser.isUserAdmin;
      const caregilityAdmin = loggedUser.isCaregilityAdmin;
      const customerAdmin = loggedUser.isCustomerAdmin;
      const organizationAdmin = loggedUser.isOrganizationAdmin;
      const facilityAdmin = loggedUser.isFacilityAdmin;
      const unitAdmin = loggedUser.isUnitAdmin;
      const technicianAdmin = loggedUser.isTechnicianAdmin;
      const technician = loggedUser.isTechnician;

      if (caregilitySystemAdmin && !isCaregilitySystemAdmin) {
        setCaregilitySystemAdmin(true);
      }

      if (userAdmin && !isUserAdmin) {
        setUserAdmin(true);
      }

      if (caregilityAdmin && !isCaregilityAdmin) {
        setCaregilityAdmin(true);
      }

      if (customerAdmin && !isCustomerAdmin) {
        setCustomerAdmin(true);
      }

      if (organizationAdmin && !isOrganizationAdmin) {
        setOrganizationAdmin(true);
      }

      if (facilityAdmin && !isFacilityAdmin) {
        setFacilityAdmin(true);
      }

      if (unitAdmin && !isUnitAdmin) {
        setUnitAdmin(true);
      }

      if (technicianAdmin && !isTechnicianAdmin) {
        setTechnicianAdmin(true);
      }

      if (technician && !isTechnician) {
        setTechnician(true);
      }
    }
  });

  /**
  * @description Render Developer Link
  * @returns {JSX}
  */
  const renderDeveloperLink = () => {
    if (loggedUser.roles) {
      if (shouldRenderDeveloperLink(loggedUser)) {
        return (
          <Menu.Item key="system/developer" title={<IntlMessages id="sidebar.developer" />}>
            <Link to={`/system/developer?sort=${encodeURIComponent('organization,asc')}`}>
              <DesktopOutlined />
              <span className={navStyle === NAV_STYLE_FIXED ? 'menu-msg' : ''}>
                <IntlMessages id="sidebar.developer" />
              </span>
            </Link>
          </Menu.Item>
        );
      }
    }

    return null;
  };

  /**
  * @description Render Developer Link
  * @returns {JSX}
  */
  const renderTechnicianLink = () => {
    if (loggedUser.roles) {
      if (shouldRenderTechnicianLink(loggedUser)) {
        return (
          <Menu.Item key="technician" title={<IntlMessages id="sidebar.technician" />}>
            <Link to="/technician">
              <i className="icon icon-social support-icon" />
              <IntlMessages id="sidebar.technician" />
            </Link>
          </Menu.Item>
        );
      }
    }

    return null;
  };

  /**
  * Renders iObserver MenuItem
  * @returns {JSX.Element} IObserver Menu Link
  */
  const renderiObserverMenuItem = () => {
    if (loggedUser.roles && shouldRenderIObserverLink(loggedUser)) {
      return (
        <Menu.Item key="system/settings/iobserver">
          <Link to="/system/settings/iobserver">
            <FundViewOutlined className="iobserver-icon" />
            <IntlMessages id="sidebar.iobserver" />
          </Link>
        </Menu.Item>
      );
    }

    return null;
  };

  /**
  * Renders Settings System MenuItem
  * @returns {JSX.Element} System Menu Link
  */
  const renderSettingsSystemMenuItem = () => {
    if (loggedUser.roles && shouldRenderSystemLink(loggedUser)) {
      return (
        <Menu.Item key="system/settings/system">
          <Link to="/system/settings/system">
            <i className="icon icon-components" />
            <IntlMessages id="sidebar.system" />
          </Link>
        </Menu.Item>
      );
    }

    return null;
  };

  /**
  * @description Render Reporting Submenu
  * @returns {JSX}
  */
  const renderReportingSubmenu = () => {
    if (loggedUser.roles) {
      if (shouldRenderReportingLink(loggedUser)) {
        return (
          <SubMenu
            className="reporting-submenu"
            key="reporting"
            onTitleClick={() => setClosedReportingSubmenu(!closedReportingSubmenu)}
            title={(
              <span className="gx-d-flex group-item">
                <i className="icon icon-copy" />
                <IntlMessages id="sidebar.reporting" />
                <span className={navStyle === NAV_STYLE_FIXED ? 'menu-msg' : 'menu-msg-collapsed'}>
                  <i className={closedReportingSubmenu ? 'icon icon-chevron-right gx-ml-5' : 'icon icon-chevron-down gx-ml-5'} />
                </span>
              </span>
            )}
          >
            <Menu.Item key="reporting/sign-in-summary">
              <Link to="/reporting/sign-in-summary">
                <i className="icon icon-signin" />
                <IntlMessages id="sidebar.signIn" />
              </Link>
            </Menu.Item>
            <Menu.Item key="reporting/vmr-availability">
              <Link to="/reporting/vmr-availability">
                <i className="icon icon-chart" />
                <IntlMessages id="sidebar.vmr" />
              </Link>
            </Menu.Item>
          </SubMenu>
        );
      }
    }

    return null;
  };

  /**
  * @description Render SSO Providers Link
  * @returns {JSX}
  */
  const renderSSOProvidersLink = () => {
    if (loggedUser.roles) {
      if (shouldRenderSSOProvidersLink(loggedUser)) {}
    }

    return null;
  };

  /**
  * @description Render Users MenuItem
  * @returns {JSX}
  */
  const renderUsersMenuItem = () => {
    if (loggedUser.roles) {
      if (shouldRenderUsersLink(loggedUser)) {
        return (
          <Menu.Item key="configuration/users">
            <Link to={`/configuration/users?sort=${encodeURIComponent('organization,asc')}`}>
              <i className="icon icon-avatar" />
              <IntlMessages id="sidebar.users" />
            </Link>
          </Menu.Item>
        );
      }
    }

    return null;
  };

  /**
  * @description Render Organizations MenuItem
  * @returns {JSX}
  */
  const renderOrganizationsMenuItem = () => {
    if (loggedUser.roles) {
      if (shouldRenderOrganizationLink(loggedUser)) {
        return (
          <Menu.Item key="configuration/organizations">
            <Link to={`/configuration/organizations?sort=${encodeURIComponent('organization,asc')}`}>
              <i className="icon icon-radiobutton" />
              <IntlMessages id="sidebar.organizations" />
            </Link>
          </Menu.Item>
        );
      }
    }

    return null;
  };

  /**
  * @description Render Customers MenuItem
  * @returns {JSX}
  */
  const renderCustomersMenuItem = () => {
    if (loggedUser.roles) {
      if (shouldRenderCustomerLink(loggedUser)) {
        return (
          <Menu.Item key="configuration/customers">
            <Link to={`/configuration/customers?sort=${encodeURIComponent('organization,asc')}`}>
              <i className="icon icon-auth-screen" />
              <IntlMessages id="sidebar.customers" />
            </Link>
          </Menu.Item>
        );
      }
    }

    return null;
  };

  /**
  * @description Render Facilities MenuItem
  * @returns {JSX}
  */
  const renderFacilitiesMenuItem = () => {
    if (loggedUser.roles) {
      if (shouldRenderFacilityLink(loggedUser)) {
        return (
          <Menu.Item key="configuration/facilities">
            <Link to={`/configuration/facilities?sort=${encodeURIComponent('organization,asc')}`}>
              <i className="icon icon-location" />
              <IntlMessages id="sidebar.facilities" />
            </Link>
          </Menu.Item>
        );
      }
    }

    return null;
  };

  /**
  * @description Render Practice MenuItem
  * @returns {JSX}
  */
  const renderUnitPracticeMenuItem = () => {
    if (loggedUser.roles) {
      if (shouldRenderUnitLink(loggedUser)) {
        return (
          <Menu.Item key="configuration/units">
            <Link to={`/configuration/units?sort=${encodeURIComponent('organization,asc')}`}>
              <i className="icon icon-company" />
              <IntlMessages id="sidebar.units" />
            </Link>
          </Menu.Item>
        );
      }
    }

    return null;
  };

  /**
  * @description Render Room Bed Submenu
  * @returns {JSX}
  */
  const renderRoomBedSubmenu = () => {
    if (loggedUser.roles) {
      if (shouldRenderRoomBedLink(loggedUser)) {
        return (
          <SubMenu
            key="bedsCarts"
            onTitleClick={() => setClosedBedsCartsSubmenu(!closedBedsCartsSubmenu)}
            title={(
              <span className="gx-d-flex">
                <i className="icon icon-map-traffic-layer gx-d-flex gx-align-items-center" />
                <span className="bedcarts">
                  <IntlMessages id="sidebar.bedsCarts" />
                </span>
                <i className={!closedBedsCartsSubmenu ? 'icon icon-chevron-right gx-ml-5' : 'icon icon-chevron-down gx-ml-5'} />
              </span>
            )}
          >
            <Menu.Item key="configuration/beds-carts/uhe-units">
              <Link to={`/configuration/beds-carts/uhe-units?sort=${encodeURIComponent('organization,asc')}`}>
                <i className="icon icon-radiobutton" />
                <IntlMessages id="sidebar.bedsCarts.uhe" />
              </Link>
            </Menu.Item>
            <Menu.Item key="configuration/beds-carts/non-uhe-units">
              <Link to={`/configuration/beds-carts/non-uhe-units?sort=${encodeURIComponent('organization,asc')}`}>
                <i className="icon icon-auth-screen" />
                <IntlMessages id="sidebar.bedsCarts.non_uhe" />
              </Link>
            </Menu.Item>
            <Menu.Item key="configuration/mobile-patients">
              <Link to={`/configuration/mobile-patients?sort=${encodeURIComponent('firstName,asc')}`}>
                <i className="icon icon-avatar" />
                <IntlMessages id="sidebar.mobilePatients" />
              </Link>
            </Menu.Item>
          </SubMenu>
        );
      }
    }

    return null;
  };

  /**
  * @description Render Monitoring MenuItem
  * @returns {JSX}
  */
  const renderMonitoringMenuItem = () => {
    if (loggedUser.roles) {
      if (shouldRenderMonitoringLink(loggedUser)) {
        return (
          <Menu.ItemGroup
            key="monitoring"
            title={(
              <span className="gx-d-flex group-item">
                <i className="icon icon-data-display" />
                <IntlMessages id="sidebar.monitoring" />
              </span>
            )}
          >
            <Menu.Item key="uhe-units" multiple>
              <Link to="/uhe-units">
                <i className="icon icon-radiobutton" />
                <IntlMessages id="sidebar.uhe" />
              </Link>
            </Menu.Item>
          </Menu.ItemGroup>
        );
      }
    }

    return null;
  };

  /**
  * @description Renders Notifications Link
  * @returns {JSX.Element}
  */
  const renderNotificationsLink = () => {
    if (loggedUser.roles) {
      if (shouldRenderNotificationsLink(loggedUser)) {
        return (
          <Menu.Item key="system/notifications" title={<IntlMessages id="sidebar.notifications" />}>
            <Link to="/system/notifications">
              <ExclamationCircleOutlined />
              <span>
                <IntlMessages id="sidebar.notifications" />
              </span>
            </Link>
          </Menu.Item>
        );
      }
    }

    return null;
  };

  let navStyle = useSelector(({ settings }) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  /**
   * @description Gets no headers class
   * @param {string} navStyle
   * @returns {string}
   */
  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR
      || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return 'gx-no-header-notifications';
    }
    return '';
  };

  let selectedKeys = pathname.substr(1);
  const tokens = selectedKeys.split('/');
  const defaultOpenKeys = tokens[1];
  const intl = useIntl();

  selectedKeys = selectedKeys.replace('/new', '');
  selectedKeys = selectedKeys.replace('/edit', '');
  selectedKeys = selectedKeys.replace('/bulk/upload', '');
  selectedKeys = selectedKeys.replace(/\/[0-9]+/g, '');

  if (selectedKeys[selectedKeys.length - 1] === '/') {
    selectedKeys = selectedKeys.substring(0, selectedKeys.length - 1);
  }

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          <Button
            shape="circle"
            className={`config-icon prev-icon ${navStyle === 'NAV_STYLE_FIXED' ? 'nav-slider' : 'nav-slider-collapsed'}`}
            onClick={() => {
              if (navStyle === NAV_STYLE_DRAWER) {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              } else if (navStyle === NAV_STYLE_FIXED) {
                dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
              } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              } else {
                dispatch(onNavStyleChange(NAV_STYLE_FIXED));
              }
            }}
          >
            <i className={`icon ${navStyle === 'NAV_STYLE_FIXED' ? 'icon-charvlet-left' : 'icon-charvlet-right'}`} />
          </Button>
        </div>

        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys, 'bedsCarts']}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline"
          >
            {renderMonitoringMenuItem()}
            {isTechnicianAccess(loggedUser) && (
              <Menu.ItemGroup
                key="configuration"
                title={(
                  <span className="gx-d-flex group-item">
                    <i className="icon icon-setting" />
                    <IntlMessages id="sidebar.configuration" />
                  </span>
                )}
              >
                {renderOrganizationsMenuItem()}
                {renderCustomersMenuItem()}
                {renderFacilitiesMenuItem()}
                {renderUnitPracticeMenuItem()}
                {renderRoomBedSubmenu()}
                {renderUsersMenuItem()}
              </Menu.ItemGroup>
            )}
            <Menu.ItemGroup
              key="system"
              title={(
                <span className="gx-d-flex group-item">
                  <i className="icon icon-calendar-culture" />
                  <IntlMessages id="sidebar.system" />
                </span>
              )}
            >
              {shouldRenderSettingsLink(loggedUser)
              && (
              <SubMenu
                className="submenu-item"
                onTitleClick={() => setClosedSettingsSubmenu(!closedSettingsSubmenu)}
                key="system/settings"
                title={(
                  <span className="gx-d-flex group-item">
                    <i className="icon icon-extra-components" />
                    <IntlMessages id="sidebar.settings" />
                    <i className={closedSettingsSubmenu ? 'icon icon-chevron-right gx-ml-5' : 'icon icon-chevron-down gx-ml-5'} />
                  </span>
                )}
              >
                {renderSettingsSystemMenuItem()}
                {renderiObserverMenuItem()}
                {renderSSOProvidersLink()}
                {renderReportingSubmenu()}
              </SubMenu>
              )}
              {isTechnicianAccess(loggedUser) && (
                <Menu.Item className="product-documentation" key="system/support" title={intl.formatMessage({ id: 'sidebar.support' })}>
                  <Link to="/system/support">
                    <ExceptionOutlined />
                    <IntlMessages id="sidebar.support" />
                  </Link>
                </Menu.Item>
              )}
              {renderDeveloperLink()}
              {renderNotificationsLink()}
              {renderTechnicianLink()}
            </Menu.ItemGroup>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
