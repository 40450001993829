import {
  SYSTEM_SETTINGS_SYSTEM_FETCH_SUCCESS,
  SYSTEM_SETTINGS_SYSTEM_FETCH_DATA,
  SAVE_SYSTEM_SETTINGS_SYSTEM_REQUEST,
  SAVE_SYSTEM_SETTINGS_SYSTEM_SUCCESS,
  CLEAR_SYSTEM_SETTINGS,
  FETCH_LICENSING_SUCCESS,
  FETCH_APPLICATION_VERSION_SUCCESS,
} from '@constants/UHEActionTypes';

const INIT_STATE = {
  loading: true,
  data: {},
  licensing: {},
  version: {},
};

/**
 * @description System reducer
 * @param  {Object} state
 * @param  {string} action
 * @return {Object}
 */
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SYSTEM_SETTINGS_SYSTEM_FETCH_SUCCESS:
      const newState = { data: action.data, loading: false };
      return Object.assign({}, state, newState);

    case SYSTEM_SETTINGS_SYSTEM_FETCH_DATA:
      return Object.assign({}, state, { loading: true });
    case SAVE_SYSTEM_SETTINGS_SYSTEM_REQUEST: {
      return { ...state, loading: true }
    }
    case SAVE_SYSTEM_SETTINGS_SYSTEM_SUCCESS: {
      const data = { ...action.payload };
      const newState = { data };
      return { ...state, ...newState, loading: false };
    }

    case CLEAR_SYSTEM_SETTINGS: {
      return { ...state, data: {} };
    }

    case FETCH_LICENSING_SUCCESS: {
      const licensing = { ...INIT_STATE.licensing, ...action.data };
      const newState = { licensing, loading: false };

      return { ...state, ...newState };
    }

    case FETCH_APPLICATION_VERSION_SUCCESS: {
      const version = { ...INIT_STATE.version, ...action.data };
      const newState = { version, loading: false };

      return { ...state, ...newState };
    }

    default:
      return state;
  }
};
