import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  deleteUheBedCartSuccess,
  deleteNonUheBedCartSuccess,
  fetchBedCartSuccess,
  fetchDeviceTypesSuccess,
  fetchDeviceQrCodeSuccess,
  fetchDeviceAdditionalQrCodeSuccess,
  saveUheBedCartSuccess,
  fetchAcknowledgedSuccess,
  fetchAcknowledgedFalseSuccess,
  fetchAcknowledgedNotesSuccess,
  uploadBedCartPictureSuccess,
  fetchBedCartImageSuccess,
  fetchCareDeviceModesSuccess,
  fetchCallActionSuccess,
  deleteRoomPictureSuccess,
  fetchBedCart,
  saveBedCartFail,
} from '@actions/uhe/configuration/bedsCarts/bedsCartsActions';
import {
  DELETE_UHE_BED_CART_REQUEST,
  DELETE_NON_UHE_BED_CART_REQUEST,
  FETCH_BED_CART_REQUEST,
  FETCH_DEVICE_TYPES_REQUEST,
  FETCH_DEVICE_QR_CODE_REQUEST,
  FETCH_DEVICE_ADDITIONAL_QR_CODE_REQUEST,
  SAVE_UHE_BEDCART_REQUEST,
  ADD_BEDCART_REQUEST,
  FETCH_ACKNOWLEDGED_REQUEST,
  FETCH_ACKNOWLEDGED_FALSE_REQUEST,
  FETCH_ACKNOWLEDGED_NOTE_RREQUEST,
  FETCH_DEVICE_ACTION,
  FETCH_CALL_ACTION,
  UPLOAD_BEDCART_PICTURE_REQUEST,
  FETCH_BEDCART_IMAGE_REQUEST,
  FETCH_CARE_DEVICE_MODES,
  DELETE_ROOM_PICTURE,
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';

/**
 * @description Sends GET Request
 * @param {string} device_id
 * @returns {Object}
 */
const fetchBedCartById = async (device_id) => {
  return await RestManager.request(
    `${ENDPOINTS.monitoring.UHETable}/${device_id}`
  );
};

/**
 * @description Sends POST Request
 * @param {Object} data
 * @returns {string}
 */
const fetchDeviceAction = async (data, id) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.monitoring.UHETable}/${id}${ENDPOINTS.command}${data}`, 'POST', data,
  );
};

/**
 * @description Sends GET Request
 * @returns {Array}
 */
const getDeviceTypes = async () => {
  return await RestManager.request(`${ENDPOINTS.bedsCarts.deviceTypes}?size=1000&sort=list_priority,desc&sort=device_common_name,asc`);
};

/**
 * @description Sends GET Request
 * @returns {Object}
 */
const getDeviceQrCode = async (data) => {
  return await RestManager.request(`${ENDPOINTS.bedsCarts.technician}${data.payload}${ENDPOINTS.bedsCarts.qrCodeMain}/one`);
};

/**
 * @description Sends GET Request
 * @returns {Object}
 */
const getDeviceAdditionalQrCode = async (data) => {
  return await RestManager.request(`${ENDPOINTS.bedsCarts.technician}${data.payload}${ENDPOINTS.bedsCarts.qrCodeMain}/two`);
};

/**
 * @description Sends GET Request
 * @returns {Object}
 */
const getDeviceComponentImage = async (data) => {
  return await RestManager.request(`${ENDPOINTS.monitoring.UHETable}/${data.payload.device_id}/components/${data.payload.component_id}/image`);
};

/**
 * @description Sends DELETE Request
 * @async
 * @param {String} device_id
 * @returns {Object}
 */
const doDeleteUheBedCart = async (device_id) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.monitoring.UHETable}/${device_id}`,
    'DELETE'
  );
};

/**
 * @description Sends POST Requst to /endpoints/:id
 * @param {Object} bedCart
 * @param {string} id
 * @returns {Object}
 */
const saveBedCartById = async (bedCart, id) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.monitoring.UHETable}/${id}`,
    'POST',
    bedCart
  );
};

/**
 * @description Sends POST Requst to /endpoints
 * @param {Object} body
 * @returns {Object}
 */
const addNewBedCart = async (body) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.monitoring.UHETable}`,
    'POST',
    body
  );
};


/**
 * @description Sends POST Requst to /endpoints/:id
 * @param {Object} bedCart
 * @param {string} id
 * @returns {Object}
 */
const saveAcknowledgedById = async (bedCart, id) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.monitoring.UHETable}/${id}/update_acknowledged?acknowledged=true`,
    'POST',
    bedCart
  );
};

/**
 * @description Sends POST Requst to /endpoints/:id
 * @param {Object} bedCart
 * @param {string} id
 * @returns {Object}
 */
const saveAcknowledgedByIdFalse = async (bedCart, id) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.monitoring.UHETable}/${id}/update_acknowledged?acknowledged=false`,
    'POST',
    bedCart
  );
};

/**
 * @description Sends POST Requst to /endpoints/:id
 * @param {Object} bedCart
 * @param {string} id
 * @returns {Object}
 */
const saveNotes = async (bedCart, id) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.monitoring.UHETable}/${id}/update_notes`,
    'POST',
    bedCart
  );
};

/**
 * @description Upload bedCart picture request
 * @param {Object} file
 * @param {string} id
 * @returns {Object}
 */
const uploadBedCartPictureRequest = async (file, id) => {
  return await RestManager.formDataRequest(
    `${ENDPOINTS.monitoring.UHETable}/${id}/components/images`,
    file,
  );
};

/**
 * Sends DELETE Request
 * @async
 * @param {String} id for bedCart
 * @param {Number} imgId for img delete
 * @returns {Object} to delete
 */
const doDeletePictrure = async (imgId, id) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.monitoring.UHETable}/${id}/components/${imgId}/image`,
    'DELETE'
  );
};

/**
 * @description Handles Response and Request for uploading Image file
 * @param {Object} payload
 * @returns {void}
 */
function* onUploadBedCartPicture(payload) {
  try {
    const uploadBedCartPicture = yield call(
      uploadBedCartPictureRequest,
      payload.file,
      payload.id,
    );

    if (uploadBedCartPicture) {
      yield put(uploadBedCartPictureSuccess());
      yield put(showMessage('saved_images_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Fetches Bed/Cart by ID
 * @param {Object} payload
 * @returns {void}
 */
function* doFetchBedCartById({ payload }) {
  try {
    const fetchedBedCart = yield call(fetchBedCartById, payload.id);
    if (fetchedBedCart) {
      yield put(fetchBedCartSuccess({ ...fetchedBedCart }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Fetches Device Action
 * @param {Object} payload data for actions
 * @returns {void}
 */
function* doFetchDeviceAction({ payload }) {
  try {
    const fetchAction = yield call(fetchDeviceAction, payload.command, payload.id);
    if (fetchAction) {
      if (payload.command === 'selftest') {
        yield put(showMessage('selfTestStart'));
      } else if (payload.command === 'endselftest') {
        yield put(showMessage('selfTestEnd'));
      } else if (payload.command === 'play_sound') {
        yield put(showMessage('sound'));
      } else if (payload.command === 'end_call') {
        yield put(showMessage('hangUpCall'));
      } else if (payload.command === 'alert') {
        yield put(showMessage('alertFromRoom'));
      } else if (payload.command === 'factory_reset') {
        yield put(showMessage('factory_reset'));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(saveBedCartFail());
  }
}

/**
 * @description Sends POST Request
 * @param {Object} data
 * @returns {string}
 */
const fetchCallAction = async (id) => {
  return await RestManager.request(
    `${ENDPOINTS.monitoring.UHETable}/${id}/call-room`
  );
};

/**
 * @description Fetches Call Room Action
 * @param {Object} payload
 * @returns {void}
 */
function* doFetchCallAction(data) {
  try {
    const response = yield call(fetchCallAction, data.payload.id, data.payload.machine_name);
    if (response) {
      const callData = response;
      const host = window.env_overrides.REACT_APP_CALL_ROOM_URL
        || process.env.REACT_APP_CALL_ROOM_URL;
      window.open(
        `${host}?hl=true&api_username=${encodeURIComponent(`${callData.api_username}`)}&data=${encodeURIComponent(`${callData.data}`)}`,
        '_blank',
      );
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Fetches UHE/NON UHE Device Types
 * @param {Object} data
 * @returns {void}
 */
function* doFetchDeviceTypes({ data }) {
  try {
    const fetchedDeviceTypes = yield call(getDeviceTypes, data);
    if (fetchedDeviceTypes) {
      yield put(fetchDeviceTypesSuccess({ types: fetchedDeviceTypes }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Fetches UHE QR Code
 * @param {Object} data
 * @returns {void}
 */
function* doFetchDeviceQrCode(data) {
  try {
    const fetchedQrCode = yield call(getDeviceQrCode, data);
    if (fetchedQrCode) {
      yield put(fetchDeviceQrCodeSuccess(fetchedQrCode));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Fetches Additional UHE QR Code
 * @param {Object} data
 * @returns {void}
 */
function* doFetchDeviceAdditionalQrCode(data) {
  try {
    const fetchedAdditionalQrCode = yield call(getDeviceAdditionalQrCode, data);
    if (fetchedAdditionalQrCode) {
      yield put(fetchDeviceAdditionalQrCodeSuccess(fetchedAdditionalQrCode));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Fetches Device Image
 * @param {Object} data
 * @returns {void}
 */
function* doFetchDeviceImage(data) {
  try {
    const fetchedDeviceImage = yield call(getDeviceComponentImage, data);
    if (fetchedDeviceImage) {
      yield put(fetchBedCartImageSuccess(fetchedDeviceImage));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Handles Delete UHE Bed/Cart by device_id
 * @param {string} data
 * @returns {void}
 */
function* deleteUheBedCartById(data) {
  try {
    const deletedUheBedCart = yield call(
      doDeleteUheBedCart,
      data.payload.device_id,
    );
    if (deletedUheBedCart) {
      yield put(deleteUheBedCartSuccess());
      yield put(showMessage('delete_success'));
      data.payload.history.push('/configuration/beds-carts/uhe-units');
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 *
 * @description Handles Delete NON UHE Bed/Cart by device_id
 * @param {string} data
 * @returns {void}
 */
function* deleteNonUheBedCartById(data) {
  try {
    const deletedUheBedCart = yield call(
      doDeleteUheBedCart,
      data.payload.device_id,
    );
    if (deletedUheBedCart) {
      yield put(deleteNonUheBedCartSuccess());
      yield put(showMessage('delete_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Handles Edit for the Bed/Cart
 * @param {Object} data
 * @returns {void}
 */
function* saveBedCartEdit(data) {
  try {
    if (data.payload) {
      const bedCart = yield call(
        saveBedCartById,
        data.payload.body,
        data.payload.id,
      );

      if (bedCart) {
        yield put(saveUheBedCartSuccess(bedCart));
        yield put(showMessage('save_success'));
        const fetchedBedCart = yield call(fetchBedCartById, data.payload.id);
        if (fetchedBedCart) {
          yield put(fetchBedCartSuccess({ ...fetchedBedCart }));
        }
      }
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(saveBedCartFail());
  }
}

/**
 * @description Handles Create functionality for Bed/Cart
 * @param {Oject} data
 * @returns {void}
 */
function* createBedCart(data) {
  try {
    if (data.payload) {
      const newBedCart = yield call(addNewBedCart, data.payload.body);

      if (newBedCart) {
        yield put(showMessage('save_success'));
        data.payload.redirectOnNewBedCart(
          newBedCart.id,
          data.payload.body.endpoint_type === 'Standards Based'
        );
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Handles Create functionality for Bed/Cart
 * @param {Oject} data
 * @returns {void}
 */
function* changeSwitchforAcknowledged(data) {
  try {
    if (data.id.body) {
      const newBedCart = yield call(saveAcknowledgedById, data.id.body, data.id.id);

      if (newBedCart) {
        yield put(fetchAcknowledgedSuccess(newBedCart));
        yield put(showMessage('save_success'));
        data.payload.redirectOnNewBedCart(
          newBedCart.id,
          data.id.body.endpoint_type === 'Standards Based'
        );
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Handles Create functionality for Bed/Cart
 * @param {Oject} data
 * @returns {void}
 */
function* changeSwitchforAcknowledgedFalse(data) {
  try {
    if (data.id.body) {
      const newBedCart = yield call(saveAcknowledgedByIdFalse, data.id.body, data.id.id);

      if (newBedCart) {
        yield put(fetchAcknowledgedFalseSuccess(newBedCart));
        yield put(showMessage('save_success'));
        data.payload.redirectOnNewBedCart(
          newBedCart.id,
          data.id.body.endpoint_type === 'Standards Based',
        );
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Handles Create functionality for Bed/Cart
 * @param {Oject} data
 * @returns {void}
 */
function* changeNotesAcknowledged(data) {
  try {
    if (data.id.body) {
      const newBedCart = yield call(saveNotes, data.id.body, data.id.id);

      if (newBedCart) {
        yield put(fetchAcknowledgedNotesSuccess(newBedCart));
        yield put(showMessage('save_success'));
        data.payload.redirectOnNewBedCart(
          newBedCart.id,
          data.id.body.endpoint_type === 'Standards Based'
        );
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Sends GET Request
 * @returns {Object}
 */
const fetchModesRequest = async () => {
  return await RestManager.request(
    `${ENDPOINTS.monitoring.UHETable}/device_config_modes`
  );
};

/**
 * @description Fetches Bed/Cart modes
 * @param {Object} payload
 * @returns {void}
 */
function* doFetchModesRequest() {
  try {
    const fetchedModes = yield call(fetchModesRequest);
    if (fetchedModes) {
      yield put(fetchCareDeviceModesSuccess({ ...fetchedModes }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Handles Delete Room Picture
 * @param {string} data to delete
 * @returns {void}
 */
function* deleteRoomPicture(data) {
  try {
    const deletedUheBedCart = yield call(
      doDeletePictrure,
      data.payload.imgId,
      data.payload.id,
    );
    if (deletedUheBedCart) {
      yield put(deleteRoomPictureSuccess());
      yield put(showMessage('delete_success'));
      const fetchedBedCart = yield call(fetchBedCartById, data.payload.id);
      if (fetchedBedCart) {
        yield put(fetchBedCartSuccess({ ...fetchedBedCart }));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Generator function which handles FETCH_BED_CART_REQUEST action
 * @returns {void}
 */
export function* fetchBedCartWatcher() {
  yield takeEvery(FETCH_BED_CART_REQUEST, doFetchBedCartById);
}

/**
 * @description Generator function which handles FETCH_DEVICE_ACTION action
 * @returns {void}
 */
export function* fetchDeviceActionWatcher() {
  yield takeEvery(FETCH_DEVICE_ACTION, doFetchDeviceAction);
}

/**
 * @description Generator function which handles FETCH_DEVICE_ACTION action
 * @returns {void}
 */
export function* fetchCallActionWatcher() {
  yield takeEvery(FETCH_CALL_ACTION, doFetchCallAction);
}

/**
 * @description Generator function which handles FETCH_DEVICE_TYPES_REQUEST action
 * @returns {void}
 */
export function* fetchDeviceTypesWatcher() {
  yield takeEvery(FETCH_DEVICE_TYPES_REQUEST, doFetchDeviceTypes);
}

/**
 * @description Generator function which handles FETCH_DEVICE_QR_CODE_REQUEST action
 * @returns {void}
 */
export function* fetchDeviceQrCodeWatcher() {
  yield takeEvery(FETCH_DEVICE_QR_CODE_REQUEST, doFetchDeviceQrCode);
}

/**
 * @description Generator function which handles FETCH_DEVICE_ADDITIONAL_QR_CODE_REQUEST action
 * @returns {void}
 */
export function* fetchDeviceAdditionalQrCodeWatcher() {
  yield takeEvery(FETCH_DEVICE_ADDITIONAL_QR_CODE_REQUEST, doFetchDeviceAdditionalQrCode);
}

/**
 * @description Generator function which handles FETCH_BEDCART_IMAGE_REQUEST action
 * @returns {void}
 */
export function* fetchBedCartImageWatcher() {
  yield takeEvery(FETCH_BEDCART_IMAGE_REQUEST, doFetchDeviceImage);
}

/**
 * @description Generator function which handles FETCH_DEVICE_TYPES_REQUEST action
 * @returns {void}
 */
export function* fetchAcknowledgedBY() {
  yield takeEvery(FETCH_ACKNOWLEDGED_REQUEST, changeSwitchforAcknowledged);
}

/**
 * @description Generator function which handles FETCH_DEVICE_TYPES_REQUEST action
 * @returns {void}
 */
export function* fetchAcknowledgedBYFalse() {
  yield takeEvery(FETCH_ACKNOWLEDGED_FALSE_REQUEST, changeSwitchforAcknowledgedFalse);
}

/**
 * @description Generator function which handles FETCH_DEVICE_TYPES_REQUEST action
 * @returns {void}
 */
export function* fetchAcknowledgedNotes() {
  yield takeEvery(FETCH_ACKNOWLEDGED_NOTE_RREQUEST, changeNotesAcknowledged);
}
/**
 * @description Generator function which handles DELETE_UHE_UNIT_REQUEST action
 * @returns {void}
 */
export function* deleteUheBedCart() {
  yield takeEvery(DELETE_UHE_BED_CART_REQUEST, deleteUheBedCartById);
}

/**
 * @description Generator function which handles DELETE_NON_UHE_BED_CART_REQUEST action
 * @returns {void}
 */
export function* deleteNonUheBedCart() {
  yield takeEvery(DELETE_NON_UHE_BED_CART_REQUEST, deleteNonUheBedCartById);
}

/**
 * @description Generator function which handles SAVE_UHE_BEDCART_REQUEST action
 * @returns {void}
 */
export function* saveBedCartWatcher() {
  yield takeEvery(SAVE_UHE_BEDCART_REQUEST, saveBedCartEdit);
}

/**
 * @description Generator function which handles ADD_BEDCART_REQUEST action
 * @returns {void}
 */
export function* createBedCartWatcher() {
  yield takeEvery(ADD_BEDCART_REQUEST, createBedCart);
}

export function* uploadBedCartPictureWatcher() {
  yield takeEvery(UPLOAD_BEDCART_PICTURE_REQUEST, onUploadBedCartPicture);
}

/**
 * @description Generator function which handles FETCH_BED_CART_REQUEST action
 * @returns {void}
 */
export function* fetchModesWatcher() {
  yield takeEvery(FETCH_CARE_DEVICE_MODES, doFetchModesRequest);
}

/**
 * Generator function which handles DELETE_ROOM_PICTURE action
 * @returns {void}
 */
export function* deleteRoomPictureWatcher() {
  yield takeEvery(DELETE_ROOM_PICTURE, deleteRoomPicture);
}

export default function* rootSaga() {
  yield all([
    fork(deleteUheBedCart),
    fork(deleteNonUheBedCart),
    fork(fetchBedCartWatcher),
    fork(fetchDeviceActionWatcher),
    fork(fetchDeviceTypesWatcher),
    fork(fetchDeviceQrCodeWatcher),
    fork(fetchDeviceAdditionalQrCodeWatcher),
    fork(saveBedCartWatcher),
    fork(createBedCartWatcher),
    fork(fetchAcknowledgedBY),
    fork(fetchAcknowledgedBYFalse),
    fork(fetchAcknowledgedNotes),
    fork(fetchBedCartImageWatcher),
    fork(fetchModesWatcher),
    fork(fetchCallActionWatcher),
    fork(deleteRoomPictureWatcher),
  ]);
}
