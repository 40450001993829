//ListingsTopFilterSaga
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  customersOnFetchData,
  customersFetchDataSuccess,
  fetchCustomerSuccess,
  saveCustomerSuccess,
  setRedirectUrl,
  clearLoading,
  fetchIndemandInterpreterSuccess,
  fetchStratusInterpreterSuccess,
  deleteStratusInterpreterSuccess,
  uploadInterpreterSuccess,
  saveStratusInterpreterSuccess,
  customersOnFetchDevicesDataSuccess,
  addBulkActionSuccess,
  pendingActionsDataSuccess,
  customersOnFetchDevicesData,
  pendingActionsData,
  deletePendingAction,
  deletePendingActionSuccess,
  fetchInterpreterOptionsSuccess,
  fetchInterpreterSuccess,
  uploadJamfDataSuccess,
} from '@actions/uhe/configuration/customers/CustomersActions';
import {
  CONFIGURATION_CUSTOMERS_FETCH_DATA,
  FETCH_CUSTOMER_REQUEST,
  SAVE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_REQUEST,
  FETCH_INDEMAND_INTERPRETER,
  FETCH_STRATUS_INTERPRETER,
  DELETE_STRATUS_INTEPRETER_REQUEST,
  UPLOAD_INTERPRETER_REQUEST,
  SAVE_STRATUS_INTERPRETER_REQUEST,
  CONFIGURATION_CUSTOMERS_FETCH_DEVICES,
  BULK_ACTION_REQUEST,
  PENDING_ACTION_DATA,
  DELETE_PENDING_ACTION_REQUEST,
  FETCH_INTERPRETER_OPTIONS_REQUEST,
  FETCH_INTERPRETER_REQUEST,
  UPLOAD_JAMF_DATA_REQUEST
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';

/**
 *
 * @param {number} page
 * @param {Array} sorting
 */
const doFetchData = async (page, sorting, filter) => {
  const filterQueryString =
    filter && filter.length ? `&${filter.join('&')}` : '';
  const sortingQueryString =
    sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : '';
  return await RestManager.request(
    `${ENDPOINTS.configuration.CustomersTable}?page=${
    page || 0
    }${sortingQueryString}${filterQueryString}`
  );
};

/**
 * @description Delete request
 * @param {string} id
 * @return  {Promise}
 */
const deleteCustomerAction = async (id) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.configuration.DeleteCustomer}/${id}`,
    'DELETE'
  );
};

/**
 * @param  {string} id
 * @return {Object}
 */
const doFetchCustomerById = async (id) => {
  return await RestManager.request(`${ENDPOINTS.customer.fetchById}/${id}`);
};

/**
 * Build FormData object
 *
 * @param  {object} bodyData
 * @return {FormData}
 */
const prepareSaveEditFormData = (bodyData) => {
  const data = new FormData();
  data.append('icon', bodyData.branding.icon);
  const jsonToSend = { ...bodyData };
  delete jsonToSend.branding.icon;
  delete jsonToSend.branding.attachment;
  delete jsonToSend.id;
  data.append('data', JSON.stringify(jsonToSend));
  return data;
};

/**
 * @param  {string} id
 * @return {Object}
 */
const save = async (bodyData) => {
  const { branding = {} } = bodyData;
  let data;

  if (branding.icon && branding.icon instanceof File) {
    data = prepareSaveEditFormData(bodyData);
    return await RestManager.formDataRequest(
      `${ENDPOINTS.customer.saveCustomer}/${bodyData.id}`,
      data
    );
  }

  data = { ...bodyData };
  delete data.id;
  delete data.branding.attachment;
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.customer.saveCustomer}/${bodyData.id}`,
    'POST',
    data
  );
};
/**
 * @param  {Object} bodyData
 * @return {Object}
 */
const addNew = async (bodyData) => {
  const { branding = {} } = bodyData;
  let data;

  if (branding.icon && branding.icon instanceof File) {
    data = prepareSaveEditFormData(bodyData);
    return await RestManager.formDataRequest(
      `${ENDPOINTS.customer.saveCustomer}`,
      data
    );
  }

  data = { ...bodyData };
  delete data.id;
  delete data.branding.attachment;
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.customer.saveCustomer}`,
    'POST',
    bodyData
  );
};

/**
 * @description Sends GET Request to /interpreter/:id/codes
 * @param {number} id
 * @returns {Object}
 */
const fetchIndemand = async (id, page) => {
  return await RestManager.request(
    `${ENDPOINTS.customer.interpreter}/${id}/codes?page=${page || 0}`
  );
};

/**
 * @description Sends GET Request to /interpreter/:id/customer/:id/mappings
 * @param {number} id
 * @returns {Object}
 */
const fetchStratus = async (interpreterId, customerId, page) => {
  return await RestManager.request(
    `${
    ENDPOINTS.customer.interpreter
    }/${interpreterId}/customer/${customerId}/mappings?page=${page || 0}`
  );
};

/**
 * @description Fetches Stratus Interpreter
 * @param {Object} payload
 * @returns {void}
 */
function* doFetchStratus({ payload, page }) {
  try {
    // TODO Make Action -> Clear Stratus/InDemand // Reducers {...state, {}}
    const fetchedStratus = yield call(
      fetchStratus,
      payload.interpreterId,
      payload.customerId,
      payload.page
    );

    if (fetchedStratus) {
      yield put(fetchStratusInterpreterSuccess({ ...fetchedStratus }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Fetches In Demand Intepreter
 * @param {Object} payload
 * @returns {void}
 */
function* doFetchIndemand(payload) {
  try {
    const fetchedIndemand = yield call(fetchIndemand, payload.id);

    if (fetchedIndemand) {
      yield put(fetchIndemandInterpreterSuccess({ ...fetchedIndemand }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 *
 * @param {number} page
 * @param {Array} sorting
 * @param filter {Array}
 */
function* fetchTableData({ page, sorting, filter }) {
  try {
    const fetchedData = yield call(doFetchData, page, sorting, filter);
    yield put(customersFetchDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
    yield put(clearLoading());
  }
}

/**
 * @description Delete customer by given id
 * @param {string} data
 * @return {void}
 */
function* delCustomer({ id, page, sorting, filter }) {
  try {
    const deletedCustomer = yield call(deleteCustomerAction, id);
    if (deletedCustomer?.status >= 200 && deletedCustomer?.status < 300) {
      yield put(showMessage('delete_success'));
      const fetchedData = yield call(doFetchData, page, sorting, filter);
      yield put(customersFetchDataSuccess(fetchedData));
    } else {
      console.error('DELETE ERROR: ', deletedCustomer);
      yield put(fetchError(deletedCustomer));
    }
  } catch (error) {
    console.error('DELETE ERROR: ', error);
    yield put(fetchError(error));
  }
}

/**
 * @param {object} - includes id
 */
function* fetchCustomerById({ id }) {
  try {
    const fetchedCustomer = yield call(doFetchCustomerById, id);
    if (fetchedCustomer) {
      yield put(fetchCustomerSuccess(fetchedCustomer));
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(clearLoading());
  }
}

/**
 * @description Save customer in case of update or create
 * @param {string} data - Data for saving
 */
function* saveCustomer(data) {
  try {
    const { customer } = data.payload;
    let savedCustomer = null;

    if (customer.id) {
      // Update existing
      savedCustomer = yield call(save, customer);
      if (savedCustomer) {
        yield put(saveCustomerSuccess(customer));
        yield put(showMessage('save_success'));
      }
    } else {
      // Create new
      savedCustomer = yield call(addNew, customer);
      if (savedCustomer) {
        yield put(saveCustomerSuccess(customer));
        yield put(
          setRedirectUrl({
            url: `/configuration/customers/edit/${savedCustomer.id}`,
          })
        );
        yield put(showMessage('save_success'));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(clearLoading());
  }
}

/**
 * @description Sends DELETE Request to /interpreter/:id/customer/:id/mappings/:id
 * @param {number} interpreterId
 * @param {number} customerId
 * @param {number} mappingId
 * @returns {Object}
 */
const deleteStratusInterpreterRequest = async (
  interpreterId,
  customerId,
  mappingId
) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.customer.interpreter}/${interpreterId}/customer/${customerId}/mappings/${mappingId}`,
    'DELETE'
  );
};

/**
 * @description Handles Request and Request for Deleting inDemand/Stratus interpreters
 * @param {Object} payload
 * @returns {void}
 */
function* deleteStratusInterpreter({ payload }) {
  try {
    const deleteStratusMapping = yield call(
      deleteStratusInterpreterRequest,
      payload.interpreterId,
      payload.customerId,
      payload.mappingId
    );

    if (deleteStratusMapping) {
      yield put(deleteStratusInterpreterSuccess());
      yield put(showMessage('delete_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Sends POST Request to /interpreter/:id/customer/:id/mappings
 * @param {number} interpreterId
 * @param {number} customerId
 * @param {Object} file
 * @returns {Object}
 */
const uploadInterpreterRequest = async (interpreterId, customerId, file) => {
  return await RestManager.formDataRequest(
    `${ENDPOINTS.customer.interpreter}/${interpreterId}/customer/${customerId}/mappings`,
    file
  );
};

/**
 * @description Handles Response and Request for Uploading CSV File
 * @param {Object} payload
 * @returns {void}
 */
function* uploadIntepreterMapping({ payload }) {
  try {
    const uploadInterpreter = yield call(
      uploadInterpreterRequest,
      payload.interpreterId,
      payload.customerId,
      payload.formData,
    );

    if (uploadInterpreter) {
      yield put(uploadInterpreterSuccess());
      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Sends POST Request to /interpreter/:id/customer/:id/mappings/:id
 * @param {number} interpreterId
 * @param {number} customerId
 * @param {number} mappingId
 * @param {Object} data
 * @returns {Object}
 */
const saveStratusInterpreterRequest = async (
  interpreterId,
  customerId,
  mappingId,
  data
) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.customer.interpreter}/${interpreterId}/customer/${customerId}/mappings/${mappingId}`,
    'POST',
    data
  );
};

/**
 * @description Handles Request and Response for the Stratus Interpreter Save
 * @param {Object} payload
 * @returns {void}
 */
export function* doSaveStratusInterpreter({ payload }) {
  try {
    const savedStratusInterpreter = yield call(
      saveStratusInterpreterRequest,
      payload.interpreterId,
      payload.customerId,
      payload.mappingId,
      payload.data
    );

    if (savedStratusInterpreter) {
      yield put(saveStratusInterpreterSuccess());
      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 *
 * @param {number} page
 * @param {Array} sorting
 */
const doFetchDevices = async (page, sorting, filter, id) => {
  const filterQueryString =
  filter && filter.length ? `&${filter.join('&')}` : '';
  const sortingQueryString =
  sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : '';
  return await RestManager.request(
    `${ENDPOINTS.monitoring.UHETable}?customer_id=${id}&page=${
      page || 0
      }${sortingQueryString}${filterQueryString}`
  );
};

/**
 *
 * @param {number} page
 * @param {Array} sorting
 * @param filter {Array}
 */
function* fetchTableDevices({id, page, sorting, filter}) {
  try {
    const fetchedData = yield call(doFetchDevices, page, sorting, filter, id);
    yield put(customersOnFetchDevicesDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
    yield put(clearLoading());
  }
}

/**
 * @description Sends POST Request
 * @param {Object} user
 * @param {Number} id
 * @returns {Array}
 */
const addAction = async (body, id, isUpdateNetworkCert) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.customer.fetchById}/${id}/bulk-commands${isUpdateNetworkCert ? '/update_wifi_cert' : ''}`,
    'POST',
    body
  );
};

/**
 * @description Add bulk action commands
 * @param {Object} data
 * @returns {void}
 */
function* addBulkAction(data) {
  try {
    if (data.payload.id) {
      const toSave = {
        command: data.payload.command,
        [data.payload.key]: data.payload.value,
        cert: data.payload.cert,
        key: data.payload.pem,
      };
      const command = yield call(addAction, toSave, data.payload.id, data.payload.isUpdateNetworkCert);

      if (command) {
        yield put(addBulkActionSuccess(command));
        yield put(pendingActionsData(0, null, null, data.payload.id));
        const fetchedCustomer = yield call(doFetchCustomerById, data.payload.id);
        if (fetchedCustomer) {
          yield put(fetchCustomerSuccess(fetchedCustomer));
        }
        yield put(showMessage('save_success'));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(clearLoading());
  }
}

/**
 *
 * @param {number} page
 * @param {Array} sorting
 */
const doFetchPendingActions = async (page, sorting, filter, id ) => {
  return await RestManager.request(`${ENDPOINTS.customer.fetchById}/${id}/bulk-commands`);
};

/**
 *
 * @param {number} page
 * @param {Array} sorting
 * @param {Array} filter
 * @param {number} id
 */
function* fetchTablePendingActions({page, sorting, filter, id}) {
  try {
    const fetchedData = yield call(doFetchPendingActions, page, sorting, filter, id);
    yield put(pendingActionsDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
    yield put(clearLoading());
  }
}

/**
 * @description Delete request
 * @param {string} id
 * @return  {Promise}
 */
const deleteAction = async (id, commandId) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.configuration.DeleteCustomer}/${id}/bulk-commands/${commandId}`,
    'DELETE'
  );
};

/**
 * @description Delete command by id
 * @param {string} data
 * @return {void}
 */
function* deleteData(data) {
  try {
    const deletedCommand = yield call(deleteAction, data.payload.orgID, data.payload.id);
    if(deletedCommand && deletedCommand.status === 404) {
      yield put(deletePendingActionSuccess(deletedCommand));
      yield put(showMessage('commandExist'));
      yield put(pendingActionsData(0, null, null, data.payload.orgID));
    } else if (deletedCommand && (deletedCommand.status >= 200 && deletedCommand.status < 300)) {
      yield put(deletePendingActionSuccess(deletedCommand));
      yield put(showMessage('delete_success'));
      yield put(pendingActionsData(0, null, null, data.payload.orgID));
    } else {
      yield put(fetchError(deletedCommand));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Requests interpreter options
 */
const fetchInterpreterOptionsRequest = () => RestManager.request(ENDPOINTS.customer.interpreters);

/**
 * @description Watcher for FETCH_INTERPRETER_OPTIONS_REQUEST
 */
export function* fetchInterpreterOptions() {
  const options = yield call(fetchInterpreterOptionsRequest);
  // TODO: check status, handle failure
  yield put(fetchInterpreterOptionsSuccess(options));
}

/**
 * @description Fetches selected interpreter data
 * @param {number} id
 * @param {number} customerId
 * @param {number} page
 * @returns {Promise<{}|{codes: {}, mappings: {}}>}
 */
const fetchInterpreterRequest = async (id, customerId, page = 0) => {
  if (!id) {
    return {};
  }

  const [codes, mappings] = await Promise.all([
    RestManager.request(`${ENDPOINTS.customer.interpreter}/${id}/codes?page=${page}`),
    RestManager.request(`${ENDPOINTS.customer.interpreter}/${id}/customer/${customerId}/mappings?page=${page}`),
  ]);
  return { codes, mappings };
};

/**
 * @description Watcher for FETCH_INTERPRETER_REQUEST
 * @param {number} id
 * @param {number} customerId
 * @param {number} page
 */
export function* fetchInterpreter({ payload: { id, customerId, page = 0 } }) {
  const data = yield call(fetchInterpreterRequest, id, customerId, page);
  // TODO: check status, handle failure
  yield put(fetchInterpreterSuccess(id, data));
}

/**
 * Upload document request
 * @param {Object} file data
 * @returns {Object}
 */

 const uploadJamfRequest = async (file, id, headers = []) => {
  return await RestManager.requestNewFile(
    `${ENDPOINTS.customer.fetchById}/${id}/mdm_tokens`,
    file,
    headers,
  );
};

/**
 * Handles Response and Request for Uploading CSV File
 * @param {Object} payload data
 * @returns {void}
 */
function* uploadJamfDocument(payload) {
  try {
    const uploadDocument = yield call(
      uploadJamfRequest,
      payload.payload.file.body,
      payload.payload.file.id,
    );

    if (uploadDocument) {
      const url = URL.createObjectURL(uploadDocument);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      a.download = 'mdm_tokens';
      a.click();
      window.URL.revokeObjectURL(url);
      yield put(uploadJamfDataSuccess());
      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchError(error))
  }
}

export function* actionsWatcher() {
  yield takeEvery(CONFIGURATION_CUSTOMERS_FETCH_DATA, fetchTableData);
  yield takeEvery(DELETE_CUSTOMER_REQUEST, delCustomer);
  yield takeEvery(FETCH_INDEMAND_INTERPRETER, doFetchIndemand);
  yield takeEvery(FETCH_STRATUS_INTERPRETER, doFetchStratus);
  yield takeEvery(DELETE_STRATUS_INTEPRETER_REQUEST, deleteStratusInterpreter);
  yield takeEvery(UPLOAD_INTERPRETER_REQUEST, uploadIntepreterMapping);
  yield takeEvery(SAVE_STRATUS_INTERPRETER_REQUEST, doSaveStratusInterpreter);
  yield takeEvery(CONFIGURATION_CUSTOMERS_FETCH_DEVICES, fetchTableDevices);
  yield takeEvery(PENDING_ACTION_DATA, fetchTablePendingActions);
  yield takeEvery(BULK_ACTION_REQUEST, addBulkAction);
  yield takeEvery(DELETE_PENDING_ACTION_REQUEST, deleteData);
  yield takeEvery(FETCH_INTERPRETER_OPTIONS_REQUEST, fetchInterpreterOptions);
  yield takeEvery(FETCH_INTERPRETER_REQUEST, fetchInterpreter);
  yield takeEvery(UPLOAD_JAMF_DATA_REQUEST, uploadJamfDocument);
}

export function* fetchCustomer() {
  yield takeEvery(FETCH_CUSTOMER_REQUEST, fetchCustomerById);
}

/**
 * @description Generator function which handles SAVE_CUSTOMER_REQUEST action
 */
export function* saveNewOrUpdateCustomer() {
  yield takeEvery(SAVE_CUSTOMER_REQUEST, saveCustomer);
}

export default function* rootSaga() {
  yield all([
    fork(actionsWatcher),
    fork(fetchCustomer),
    fork(saveNewOrUpdateCustomer),
  ]);
}
