import React, { Component } from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Table, Card } from 'antd';
import { injectIntl } from 'react-intl';
import RestManager from '@util/RestManager';
import { BASE_URL, ENDPOINTS } from '@constants/UHEEndpoints';
import UheHelper from 'util/UheHelper';

import IntlMessages from 'util/IntlMessages';
import ListingsTopFilter from '@filters/ListingsTopFilter';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import FacilityCell from '@components/tables/cells/FacilityCell';
import UnitCell from '@components/tables/cells/UnitCell';
import AddressCell from '@components/tables/cells/AddressCell';
import ActionsCell from '@components/tables/cells/ActionsCell';
import NonUheUnitCell from '@components/tables/cells/NonUheUnitCell';
import ExportCsv from '@components/ExportCsv/ExportCsv';
import { setSubtitle } from '@uhe_actions/SubtitleActions';

import { deleteNonUheBedCart } from '@uhe_actions/configuration/bedsCarts/bedsCartsActions';
import { onFetchData } from '@uhe_actions/monitoring/UHEActions';
import {
  LISTING_TABLES_PAGE_SIZE, TABLE_FILTER_PREFIX, TOP_FILTER_PREFIX, APP_PAGES_CONTEXT,
} from '@constants/UHESettings';

/**
 * @description Renders non Uhe table
 */
class NonUheTable extends Component {
  /**
   * @description Adapt data returned from the server
   * @param  {Array<Object>} data
   * @return {Array<Object>}
   */
  static dataAdapter(data = []) {
    const adaptedData = [];
    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        organization: {
          id: value.organization_id,
          name: value.organization_name,
        },
        customer: {
          id: value.customer_id,
          organizationId: value.organization_id,
          name: value.customer_name,
        },
        facility: {
          id: value.facility_id,
          organizationId: value.organization_id,
          customerId: value.customer_id,
          name: value.facility_name,
        },
        unit: {
          id: value.unit_id,
          organizationId: value.organization_id,
          customerId: value.customer_id,
          facilityId: value.facility_id,
          name: value.unit_name,
        },
        bedCart: {
          id: value.device_id,
          name: value.cart_name,
          customerId: value.customer_id,
        },
        protocol: value.dial_out_type,
        address: {
          id: value.device_id,
          name: value.alias,
        },
        actions: {
          device_id: value.device_id,
        },
      });
    });

    return adaptedData;
  }

  constructor(props) {
    super(props);

    this.filterTypes = {};
    this.data = [];
    this.columns = [];

    const { history } = this.props;

    this.topFilterMap = {
      [`${TOP_FILTER_PREFIX}organization`]: 'organization_id',
      [`${TOP_FILTER_PREFIX}customer`]: 'customer_id',
      [`${TOP_FILTER_PREFIX}facility`]: 'facility_id',
      [`${TOP_FILTER_PREFIX}unit`]: 'unit_id',
    };

    this.tableFilterMap = {
      organization: 'organization_name',
      customer: 'customer_name',
      facility: 'facility_name',
      unit: 'unit_name',
      bedCart: 'cart_name',
      protocol: 'dial_out_type',
      address: 'alias',
    };

    this.tableKeys = [
      'organization',
      'customer',
      'facility',
      'unit',
      'bedCart',
      'protocol',
      'address',
    ];

    this.topFilters = [
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byOrganization',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'organization',
        shouldSort: true,
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byCustomer',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'customer',
        shouldSort: true,
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byFacility',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'facility',
        shouldSort: true,
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byUnit',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'unit',
        shouldSort: true,
      },
    ];

    this.onPageChange = this.onPageChange.bind(this);

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);

    this.tableKeys.forEach((value, index) => {
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            showFilter={!(value === 'actions')}
          />
        ),
        sorter: (value === 'actions') ? false : { multiple: index },
        defaultSortOrder: (value === 'organization') ? this.checkSortTableOrder() : false,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.props.onFetchData(0);
    this.onPageChange(1);
  }

  componentDidMount() {
    const { setSubtitle } = this.props;
    setSubtitle('nonCareDevices.titles.list');
  }

  /**
   * @description Updates the component on location change
   * @param {Object} prevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }
  }

  /**
   * @param  {number} page
   * @return {void}
   */
  onPageChange(page) {
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const sort = [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);

      if (filterParam) {
        if (this.filterTypes[key]
          && this.filterTypes[key].type === 'dropdown'
        ) {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }

    });

    currSort.forEach(sortItem => {
      const key = sortItem.split(',')[0];
      const value = this.tableFilterMap[key];

      if (value) {
        sort.push(sortItem.replace(key, value));
      }
    });

    this.filter = filter;
    this.sort = sort;

    this.props.onFetchData(page - 1, sort, filter);
  }

  /**
   * @description Get endpoint url for data export
   */
  getCsvUrl = () => {
    const token = RestManager.getToken();

    const filterQueryString = this.filter && this.filter.length ? `&${this.filter.join('&')}` : '';
    const sortingQueryString = this.sorting && this.sorting.length
      ? `&sort=${this.sorting.join('&sort=')}`
      : '';

    const tokenParam = `&token=${token}`;
    return `${BASE_URL}${ENDPOINTS.bedCart.nonUheCsv}${filterQueryString}${sortingQueryString}${tokenParam}`;
  }

  /**
   * @description Check the default sort order for table
   * @returns {string}
   */
  checkSortTableOrder() {
    const { location } = this.props;
    let checkOrder = location.search.split('%2C')[1];

    if (checkOrder === 'asc') {
      checkOrder = 'ascend';
    }
    if (checkOrder === 'desc') {
      checkOrder = 'descend';
    }
    if (!checkOrder) {
      checkOrder = false;
    }

    return checkOrder;
  }

  /**
   * @description Renders different cell type depending on the column
   * @param  {Object} content
   * @param  {string} key
   * @return {ReactElement|Object}
   */
  cellRenderer(content, key) {
    let cell;
    const { intl, history, loggedUser } = this.props;

    switch (key) {
      case 'organization':
        cell = <OrganizationCell content={content} />;
        break;
      case 'customer':
        cell = <CustomerCell content={content} />;
        break;
      case 'facility':
        cell = <FacilityCell content={content} />;
        break;
      case 'unit':
        cell = <UnitCell content={content} />;
        break;
      case "bedCart":
        cell = <NonUheUnitCell content={content} />;
        break;
      case 'address':
        cell = <AddressCell editLink={`/configuration/beds-carts/non-uhe-units/edit/${content.id}`} content={content} />;
        break;
      case 'actions':
        cell = (
          <ActionsCell
            page={APP_PAGES_CONTEXT.nonUheUnits}
            loggedUser={loggedUser}
            intl={intl}
            content={content}
            editLink={`/configuration/beds-carts/non-uhe-units/edit/${content.device_id}`}
            deleteAction={() => {
              this.props.deleteNonUheBedCart(
                content.device_id,
                this.currentPage,
                this.sort,
                this.filter,
                history,
              );
            }}
          />
        );
        break;

      default:
        cell = content;
    }

    return cell;
  }

  /**
  * @description Render Add Button
  * @returns {JSX}
  */
  renderAddButton = () => {
    const { isCaregilitySystemAdmin, isOrganizationAdmin, isCustomerAdmin, isFacilityAdmin, isTechnicianAdmin } = this.props.loggedUser;

    if (isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin || isTechnicianAdmin) {
      return (
        <Link
          to="/configuration/beds-carts/non-uhe-units/new"
          shape="circle"
          className="page-icons page-icon-plus"
        >
          <i className="icon icon-add" />
          <span>
            <IntlMessages id="common.addText" />
          </span>
        </Link>
      )
    }

    return null;
  }

  /**
  * @description Render Export Csv Button
  * @returns {JSX}
  */
  renderExportCsvButton = () => {
    const { isCaregilitySystemAdmin, isCaregilityAdmin, isOrganizationAdmin, isCustomerAdmin, isFacilityAdmin } = this.props.loggedUser;

    if (isCaregilitySystemAdmin || isCaregilityAdmin || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin) {
      return (
        <ExportCsv
          getCsvUrl={UheHelper.getCsvUrl(
            this.filter,
            this.sorting,
            ENDPOINTS.ExportCsv.nonUhe,
          )}
        />
      )
    }
  }

  /**
  * @description Render table
  * @returns {JSX}
  */
  renderTable = () => {
    const { pagination, data, loading, loggedUser } = this.props;
    pagination.onChange = this.onPageChange;

    if (loggedUser.roles) {
      const { isCaregilitySystemAdmin, isTechnicianAdmin, isOrganizationAdmin, isCustomerAdmin, isFacilityAdmin } = this.props.loggedUser;

      if (isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin || isTechnicianAdmin) {
        this.tableFilterMap.actions = 'actions';

        this.tableKeys = Object.keys(this.tableFilterMap);
        if (this.columns.length <= 7) {

          this.tableKeys.filter(key => key === 'actions').forEach((value, index) => {
            const filter = this.filterTypes[value] || {};
            this.columns.push({
              title: (cellData) => (
                <ListingsTableInputFilter
                  cellData={cellData}
                  title={`uhe.table.${value}`}
                  dataKey={value}
                  showFilter={!(value === 'actions')}
                />
              ),
              sorter: (value === 'actions') ? false : { multiple: index },
              defaultSortOrder: (value === 'organization') ? this.checkSortTableOrder() : false,
              align: index > 3 ? 'center' : 'left',
              minWidth: 200,
              dataIndex: value,
              render: (content) => this.cellRenderer(content, value),
            });
          });
        }
      }

      return (
        <Table
          bordered
          className="gx-table-responsive"
          columns={this.columns}
          dataSource={NonUheTable.dataAdapter(data)}
          pagination={pagination}
          loading={loading}
        />
      )
    }
  }

  render() {
    const { pagination } = this.props;
    pagination.onChange = this.onPageChange;

    return (
      <>
        <Card className="filter-boxes gx-card">
          <ListingsTopFilter filters={this.topFilters} />
        </Card>
        <div className="uhe-table-header">
          <div className="buttons-container">
            {this.renderAddButton()}
            {this.renderExportCsvButton()}
          </div>
          <p>
            <IntlMessages id="uhe.table.matchingResults" />
            <span>{pagination.total}</span>
          </p>
        </div>
        <div className="uhe-table">
          {this.renderTable()}
        </div>
      </>
    );
  }
}

NonUheTable.propTypes = {
  data: PropTypes.array,
  pagination: PropTypes.object,
  intl: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  loading: PropTypes.bool,
}

/**
 *
 * @description Maps Global State to Component's Props
 * @param {Object} MonitoringStandards
 * @returns {Object}
 */
const mapStateToProps = ({ MonitoringStandards, ConfigurationUsers }) => {
  const { list, page } = MonitoringStandards.table || {};
  const { loading } = MonitoringStandards;
  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  return {
    data: list, pagination, loading,
    loggedUser: ConfigurationUsers.ownUser,
  };
};

/**
 *
 * @description Returns Object Which Dispatch Actions to the Store
 * @param {function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => ({
  deleteNonUheBedCart: (device_id, page, sorting, filter, history) => dispatch(
    deleteNonUheBedCart({
      device_id, page, sorting, filter, history,
    }),
  ),
  onFetchData: (page, sort, filter) => dispatch(onFetchData(page, sort, filter, 'standards')),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(NonUheTable)));
