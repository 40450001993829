import {
  DELETE_UHE_BED_CART_REQUEST,
  DELETE_UHE_BED_CART_SUCCESS,
  DELETE_NON_UHE_BED_CART_REQUEST,
  DELETE_NON_UHE_BED_CART_SUCCESS,
  FETCH_BED_CART_REQUEST,
  FETCH_BED_CART_SUCCESS,
  FETCH_DEVICE_TYPES_REQUEST,
  FETCH_DEVICE_TYPES_SUCCESS,
  CLEAR_BED_CART,
  SAVE_UHE_BEDCART_REQUEST,
  SAVE_UHE_BEDCART_SUCCESS,
  ADD_BEDCART_REQUEST,
  FETCH_DEVICE_ACTION,
  FETCH_DEVICE_QR_CODE_REQUEST,
  FETCH_DEVICE_QR_CODE_SUCCESS,
  FETCH_DEVICE_ADDITIONAL_QR_CODE_REQUEST,
  FETCH_DEVICE_ADDITIONAL_QR_CODE_SUCCESS,
  ADD_BEDCART_SUCCES,
  FETCH_ACKNOWLEDGED_REQUEST,
  FETCH_ACKNOWLEDGED_SUCCESS,
  FETCH_ACKNOWLEDGED_NOTE_RREQUEST,
  FETCH_ACKNOWLEDGED_NOTE_SUCCESS,
  FETCH_ACKNOWLEDGED_FALSE_REQUEST,
  FETCH_ACKNOWLEDGED_FALSE_SUCCESS,
  UPLOAD_BEDCART_PICTURE_REQUEST,
  UPLOAD_BEDCART_PICTURE_SUCCESS,
  FETCH_BEDCART_IMAGE_REQUEST,
  FETCH_BEDCART_IMAGE_SUCCESS,
  FETCH_CARE_DEVICE_MODES,
  FETCH_CARE_DEVICE_MODES_SUCCESS,
  FETCH_CALL_ACTION,
  FETCH_CALL_ACTION_SUCCESS,
  DELETE_ROOM_PICTURE,
  DELETE_ROOM_PICTURE_SUCCESS,
  SAVE_BEDCART_FAIL,
} from '@constants/UHEActionTypes';

/**
 * @description Handle FETCH_BED_CART_REQUEST Action
 * @param {string} id
 * @returns {Object}
 */
export const fetchBedCart = (id) => {
  return {
    type: FETCH_BED_CART_REQUEST,
    payload: id,
  };
};

/**
 * @description Handle FETCH_BED_CART_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchBedCartSuccess = (payload) => {
  return {
    type: FETCH_BED_CART_SUCCESS,
    payload,
  };
};

/**
 * @description Handle FETCH_DEVICE_ACTION Action
 * @param {Object} payload
 * @returns {string}
 */
export const fetchDeviceAction = (payload) => {
  return {
    type: FETCH_DEVICE_ACTION,
    payload,
  };
};

/**
 * @description Handle FETCH_DEVICE_ACTION Action
 * @param {Object} payload
 * @returns {string}
 */
export const fetchCallAction = (payload) => {
  return {
    type: FETCH_CALL_ACTION,
    payload,
  };
};

/**
 * @description Handle FETCH_CARE_DEVICE_MODES_SUCCESS Action
 * @param {Object} data
 * @returns {Object}
 */
export const fetchCallActionSuccess = (data) => {
  return {
    type: FETCH_CALL_ACTION_SUCCESS,
    data,
  };
};

/**
 * @description Handle DELETE_UHE_BED_CART_REQUEST Action
 * @param {Object} data
 * @returns {Object}
 */
export const deleteUheBedCart = (data) => {
  return {
    type: DELETE_UHE_BED_CART_REQUEST,
    payload: data,
  };
};

/**
 * @description Handle DELETE_UHE_BED_CART_SUCCESS Action
 * @returns {Object}
 */
export const deleteUheBedCartSuccess = () => {
  return {
    type: DELETE_UHE_BED_CART_SUCCESS,
  };
};

/**
 * @description Handle DELETE_NON_UHE_BED_CART_REQUEST Action
 * @param {Object} data
 * @returns {Object}
 */
export const deleteNonUheBedCart = (data) => {
  return {
    type: DELETE_NON_UHE_BED_CART_REQUEST,
    payload: data,
  };
};

/**
 * @description Handle DELETE_NON_UHE_BED_CART_SUCCESS Action
 * @returns {Object}
 */
export const deleteNonUheBedCartSuccess = () => {
  return {
    type: DELETE_NON_UHE_BED_CART_SUCCESS,
  };
};

/**
 * @description Handle FETCH_DEVICE_TYPES_REQUEST Action
 * @returns {Object}
 */
export const fetchDeviceTypes = () => {
  return {
    type: FETCH_DEVICE_TYPES_REQUEST,
  };
};

/**
 * @description Handle FETCH_DEVICE_TYPES_SUCCESS Action
 * @param {Object} data
 * @returns {Object}
 */
export const fetchDeviceTypesSuccess = (data) => {
  return {
    type: FETCH_DEVICE_TYPES_SUCCESS,
    data,
  };
};

/**
 * @description Handle FETCH_DEVICE_QR_CODE_REQUEST Action
 * @returns {Object}
 */
export const fetchDeviceQrCode = (id) => {
  return {
    type: FETCH_DEVICE_QR_CODE_REQUEST,
    payload: id,
  };
};

/**
 * @description Handle FETCH_DEVICE_ADDITIONAL_QR_CODE_REQUEST Action
 * @returns {Object}
 */
export const fetchDeviceAdditionalQrCode = (id) => {
  return {
    type: FETCH_DEVICE_ADDITIONAL_QR_CODE_REQUEST,
    payload: id,
  };
};

/**
 * @description Handle FETCH_DEVICE_QR_CODE_SUCCESS Action
 * @param {Object} data
 * @returns {Object}
 */
export const fetchDeviceQrCodeSuccess = (payload) => {
  return {
    type: FETCH_DEVICE_QR_CODE_SUCCESS,
    payload,
  };
};

/**
 * @description Handle FETCH_DEVICE_ADDITIONAL_QR_CODE_SUCCESS Action
 * @param {Object} data
 * @returns {Object}
 */
export const fetchDeviceAdditionalQrCodeSuccess = (payload) => {
  return {
    type: FETCH_DEVICE_ADDITIONAL_QR_CODE_SUCCESS,
    payload,
  };
};

/**
 * @description Handle FETCH_BEDCART_IMAGE_REQUEST Action
 * @returns {Object}
 */
export const fetchBedCartImage = (payload) => {
  return {
    type: FETCH_BEDCART_IMAGE_REQUEST,
    payload,
  };
};

/**
 * @description Handle FETCH_BEDCART_IMAGE_SUCCESS Action
 * @param {Object} data
 * @returns {Object}
 */
export const fetchBedCartImageSuccess = (payload) => {
  return {
    type: FETCH_BEDCART_IMAGE_SUCCESS,
    payload,
  };
};

/**
 * @description Handle CLEAR_BED_CART Action
 * @returns {Object}
 */
export const clearBedCart = () => {
  return {
    type: CLEAR_BED_CART,
  };
};

/**
 * @description Handle SAVE_UHE_BEDCART_REQUEST Action
 * @param {Object} data
 * @returns {Object}
 */
export const saveUheBedCart = (data) => {
  return {
    type: SAVE_UHE_BEDCART_REQUEST,
    payload: data,
  };
};

/**
 * @description Handle SAVE_UHE_BEDCART_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const saveUheBedCartSuccess = (payload) => {
  return {
    type: SAVE_UHE_BEDCART_SUCCESS,
    payload,
  };
};

/**
 * @description Handles UPLOAD_BEDCART_PICTURE_REQUEST action
 * @param {Object} file
 * @returns {Object}
 */
export const uploadBedCartPicture = (file, id) => ({
  type: UPLOAD_BEDCART_PICTURE_REQUEST,
  payload: {
    file,
    id,
  },
});

/**
 * @description Handles UPLOAD_USER_DOCUMENT_SUCCESS action
 * @returns {Object}
 */
export const uploadBedCartPictureSuccess = () => ({
  type: UPLOAD_BEDCART_PICTURE_SUCCESS,
});

/**
 * @description Handle ADD_BEDCART_REQUEST Action
 * @param {Object} data
 * @returns {Object}
 */
export const addBedCart = (data) => {
  return {
    type: ADD_BEDCART_REQUEST,
    payload: data,
  };
};


/**
 * @description Handle FETCH_ACKNOWLEDGED_REQUEST Action
 * @param {string} payload
 * @returns {Object}
 */
export const fetchAcknowledged = (payload) => {
  return {
    type: FETCH_ACKNOWLEDGED_REQUEST,
    id: payload,
  };
};

/**
 * @description Handle FETCH_ACKNOWLEDGED_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchAcknowledgedSuccess = (payload) => {
  return {
    type: FETCH_ACKNOWLEDGED_SUCCESS,
    payload: { ...payload },
  };
};


/**
 * @description Handle FETCH_ACKNOWLEDGED_FALSE_REQUEST Action
 * @param {string} payload
 * @returns {Object}
 */
export const fetchAcknowledgedFalse = (payload) => {
  return {
    type: FETCH_ACKNOWLEDGED_FALSE_REQUEST,
    id: payload,
  };
};

/**
 * @description Handle FETCH_ACKNOWLEDGED_FALSE_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchAcknowledgedFalseSuccess = (payload) => {
  return {
    type: FETCH_ACKNOWLEDGED_FALSE_SUCCESS,
    payload: { ...payload },
  };
};

/**
 * @description Handle FETCH_ACKNOWLEDGED_NOTE_RREQUEST Action
 * @param {string} payload
 * @returns {Object}
 */
export const fetchAcknowledgedNotes = (payload) => {
  return {
    type: FETCH_ACKNOWLEDGED_NOTE_RREQUEST,
    id: payload,
  };
};

/**
 * @description Handle FETCH_ACKNOWLEDGED_NOTE_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchAcknowledgedNotesSuccess = (payload) => {
  return {
    type: FETCH_ACKNOWLEDGED_NOTE_SUCCESS,
    payload: { ...payload },
  };
};

/**
 * @description Handle FETCH_CARE_DEVICE_MODES Action
 * @param {Object} data
 * @returns {Object}
 */
export const fetchCareDeviceModes = () => {
  return {
    type: FETCH_CARE_DEVICE_MODES,
  };
};

/**
 * @description Handle FETCH_CARE_DEVICE_MODES_SUCCESS Action
 * @param {Object} data
 * @returns {Object}
 */
export const fetchCareDeviceModesSuccess = (data) => {
  return {
    type: FETCH_CARE_DEVICE_MODES_SUCCESS,
    data,
  };
};

/**
 * Handle DELETE_ROOM_PICTURE Action
 * @param {Object} data for delete
 * @return {Object} bedCart data
 */
export const deleteRoomPicture = (data) => ({
  type: DELETE_ROOM_PICTURE,
  payload: data,
});

/**
 * Handle DELETE_ROOM_PICTURE_SUCCESS Action
 * @returns {Object} bedCart data
 */
export const deleteRoomPictureSuccess = () => {
  return {
    type: DELETE_ROOM_PICTURE_SUCCESS,
  };
};

/**
 * Fetch action when save request fail
 * @returns {boolean} after catch error
 */
 export const saveBedCartFail = () => {
  return {
    type: SAVE_BEDCART_FAIL,
  };
};
