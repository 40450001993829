import {
  all,
  call,
  fork,
  put,
  takeEvery,
} from "redux-saga/effects";

import {
  fetchUnitSuccess,
  saveUnitSuccess,
  deleteUnitSuccess,
  setRedirectUrl,
  saveUnitFail,
} from '@actions/uhe/UnitActions';
import {
  FETCH_UNIT_REQUEST,
  SAVE_UNIT_REQUEST,
  DELETE_UNIT_REQUEST,
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from "@util/RestManager";

/**
 * @param  {string} id
 * @return {Object}
 */
const getUnit = async (id) => {
  return await RestManager.request(`${ENDPOINTS.unit.saveUnit}/${id}`);
};
/**
 * @param  {string} id
 * @return {Object}
 */
const save = async (bodyData) => {
  return await RestManager.requestWithoutQueryParams(`${ENDPOINTS.unit.saveUnit}/${bodyData.id}`, 'POST', bodyData.body);
};
/**
 * @param  {Object} bodyData
 * @return {Object}
 */
const addNew = async (bodyData) => {
  return await RestManager.requestWithoutQueryParams(`${ENDPOINTS.unit.saveUnit}`, 'POST', bodyData);
};

/**
 * @param  {string} id
 */
const deleteUnitApi = async (id) => {
  return await RestManager.requestWithoutQueryParams(`${ENDPOINTS.configuration.DeleteUnits}/${id}`, 'DELETE');
}

/**
 * @param {object} - includes id
 */
function* fetchUnitById({ id }) {
  try {
    const fetchedUnit = yield call(getUnit, id);
    if (fetchedUnit) {
      yield put(fetchUnitSuccess(fetchedUnit));
    }

  } catch (error) {
    yield put(fetchError(error));
  }
}
/**
 * @description Save unit in case of update or create
 * @param {string} data - Data for saving
 */
function* saveUnit(data) {
  try {
    let unit = null;
    if (data.payload.id) {
      unit = yield call(save, data.payload);
      if (unit) {
        yield put(saveUnitSuccess({ ...unit, id: data.payload.id }));
        yield put(showMessage('save_success'));
      }
    } else {
      const body = { ...data.payload };
      unit = yield call(addNew, body.body);
      if (unit) {
        yield put(saveUnitSuccess(data.payload));
        yield put(setRedirectUrl({ url: `/configuration/units/edit/${unit.id}` }));
        yield put(showMessage('save_success'));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(saveUnitFail());
  }
}
/**
 * @description Delete unit by given id
 * @param {string} data - Data for deleting, contains unit id
 */
function* delUnit(data) {
  try {
    const deletedUnit = yield call(deleteUnitApi, data.payload.id);
    if (deletedUnit) {
      yield put(deleteUnitSuccess());
      yield put(setRedirectUrl({ url: '/configuration/units/new' }));
      yield put(showMessage('delete_success'));
    }
  } catch (error) {
    console.error('DELETE ERROR: ', error);
    yield put(fetchError(error));
  }
}
/**
 * @description Generator function which handles FETCH_UNIT_REQUEST action
 */
export function* fetchUnit() {
  yield takeEvery(FETCH_UNIT_REQUEST, fetchUnitById);
}
/**
 * @description Generator function which handles SAVE_UNIT_REQUEST action
 */
export function* saveNewOrUpdateUnit() {
  yield takeEvery(SAVE_UNIT_REQUEST, saveUnit);
}
/**
 * @description Generator function which handles DELETE_UNIT_REQUEST action
 */
export function* deleteUnit() {
  yield takeEvery(DELETE_UNIT_REQUEST, delUnit);
}

export default function* rootSaga() {
  yield all(
    [
      fork(fetchUnit),
      fork(saveNewOrUpdateUnit),
      fork(deleteUnit),
    ],
  );
}
