import {
  SUPPORT_VIDEO_FETCH_DATA,
  SUPPORT_VIDEO_FETCH_SUCCESS,
  CREATE_SUPPORT_VIDEO_REQUEST,
  CREATE_SUPPORT_VIDEO_SUCCESS,
  DELETE_SUPPORT_VIDEO_REQUEST,
  DELETE_SUPPORT_VIDEO_SUCCESS,
  SUPPORT_DOCUMENT_FETCH_DATA,
  SUPPORT_DOCUMENT_FETCH_DATA_SUCCESS,
  CREATE_SUPPORT_DOCUMENT_REQUEST,
  CREATE_SUPPORT_DOCUMENT_SUCCESS,
  UPDATE_SUPPORT_DOCUMENT_REQUEST,
  UPDATE_SUPPORT_DOCUMENT_SUCCESS,
  SUPPORT_SECTION_FETCH_DATA,
  SUPPORT_SECTION_FETCH_SUCCESS,
  DOWNLOAD_PDF_REQUEST,
  DOWNLOAD_PDF_SUCCESS,
  DELETE_SUPPORT_DOCUMENT_REQUEST,
  DELETE_SUPPORT_DOCUMENT_SUCCESS,
  CLEAR_SUPPORT_STATE,
  CREATE_SUPPORT_SECTION_REQUEST,
  CREATE_SUPPORT_SECTION_SUCCESS,
  DELETE_SUPPORT_SECTION_REQUEST,
  DELETE_SUPPORT_SECTION_SUCCESS,
} from '@constants/UHEActionTypes';

/**
 * @description Fetch support video data
 * @return {Object}
 */
export const supportVideoOnFetchData = () => {
  return {
    type: SUPPORT_VIDEO_FETCH_DATA,

  };
};

/**
 * @description Fetch success
 * @param  {Object} data
 * @return {Object}
 */
export const fetchSupportVideoDataSuccess = (data) => {
  return {
    type: SUPPORT_VIDEO_FETCH_SUCCESS,
    data,
  };
};

/**
 * @description Handle CREATE_SUPPORT_VIDEO_REQUEST Action
 * @param {Object} data
 * @returns {Object}
 */
export const saveSupportVideo = (data) => {
  return {
    type: CREATE_SUPPORT_VIDEO_REQUEST,
    payload: { ...data },
  };
};

/**
 * @description Handle CREATE_SUPPORT_VIDEO_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const saveSupportVideoSuccess = (payload) => {
  return {
    type: CREATE_SUPPORT_VIDEO_SUCCESS,
    payload: { ...payload },
  };
};

/**
 * @description DELETE request
 * @param {Object} data
 * @return {Promise}
 */
export const deleteVideo = (data) => {
  return {
    type: DELETE_SUPPORT_VIDEO_REQUEST,
    payload: data,
  };
};

/**
 * @description DELETE request success
 * @param {Object} data
 * @return {Promise}
 */
export const deleteVideoSuccess = (data) => {
  return {
    type: DELETE_SUPPORT_VIDEO_SUCCESS,
    payload: data,
  };
};

/**
 * @description Fetch support video data
 * @return {Object}
 */
export const supportDocumentOnFetchData = () => {
  return {
    type: SUPPORT_DOCUMENT_FETCH_DATA,

  };
};

/**
 * @description Fetch success
 * @param  {Object} data
 * @return {Object}
 */
export const fetchSupportDocumentDataSuccess = (data) => {
  return {
    type: SUPPORT_DOCUMENT_FETCH_DATA_SUCCESS,
    data,
  };
};

/**
 * @description Handles CREATE_SUPPORT_DOCUMENT_REQUEST Action
 * @returns {Object}
 */
export const uploadDocument = (file, name, sectionId) => ({
  type: CREATE_SUPPORT_DOCUMENT_REQUEST,
  payload: { file, name, sectionId },
});

/**
 * @description Handles CREATE_SUPPORT_DOCUMENT_SUCCESS Action
 * @returns {Object}
 */
export const uploadDocumentSuccess = (uploadedDocumentId, filename, name, sectionId) => {
  return {
    type: CREATE_SUPPORT_DOCUMENT_SUCCESS,
    payload: {
      uploadedDocumentId, filename, name, sectionId,
    },
  };
};

/**
 * @description Fetch support video data
 * @return {Object}
 */
export const supportSectionOnFetchData = () => {
  return {
    type: SUPPORT_SECTION_FETCH_DATA,

  };
};

/**
 * @description Fetch success
 * @param  {Object} data
 * @return {Object}
 */
export const supportSectionOnFetchDataSuccess = (data) => {
  return {
    type: SUPPORT_SECTION_FETCH_SUCCESS,
    data,
  };
};

/**
 * @description Fetch success
 * @param  {Number} id
 * @param  {String} name
 * @return {Object}
 */
export const downloadPdf = (id, name) => {
  return {
    type: DOWNLOAD_PDF_REQUEST,
    payload: {
      id,
      name,
    },
  };
};

/**
 * @description Fetch success
 * @param  {Object} data
 * @return {Object}
 */
export const downloadPdfSuccess = (data) => {
  return {
    type: DOWNLOAD_PDF_SUCCESS,
    data,
  };
};

/**
 * @description Handle SAVE_SUPPORT_DOCUMENT Action
 * @param {Object} data
 * @returns {Object}
 */
export const saveSupportDocument = (id, name) => {
  return {
    type: UPDATE_SUPPORT_DOCUMENT_REQUEST,
    payload: {
      id,
      name,
    }
  };
};

/**
 * @description Fetch success
 * @param  {Object} data
 * @return {Object}
 */
export const saveDocumentSuccess = (data) => {
  return {
    type: UPDATE_SUPPORT_DOCUMENT_SUCCESS,
    data,
  };
};

/**
 * @description DELETE request document
 * @param {Object} data
 * @return {Promise}
 */
export const deleteDocument = (data) => {
  return {
    type: DELETE_SUPPORT_DOCUMENT_REQUEST,
    payload: data,
  };
};

/**
 * @description DELETE request success
 * @param {number} id
 * @returns {Action}
 */
export const deleteDocumentSuccess = (id) => ({
  type: DELETE_SUPPORT_DOCUMENT_SUCCESS,
  payload: { id },
});

/**
 * @description Clear State
 */
export const clearSupportState = () => {
  return {
    type: CLEAR_SUPPORT_STATE,
  };
};

/**
 * @description Add section
 * @param {Object} data
 * @returns {Object}
 */
export const addSection = (data) => {
  return {
    type: CREATE_SUPPORT_SECTION_REQUEST,
    payload: { ...data },
  };
};

/**
 * @description Add section success action
 * @param {Object} payload
 * @returns {Object}
 */
export const addSectionSuccess = (payload) => {
  return {
    type: CREATE_SUPPORT_SECTION_SUCCESS,
    payload: { ...payload },
  };
};

/**
 * @description DELETE request
 * @param {Object} data
 * @return {Promise}
 */
export const deleteSection = (data) => {
  return {
    type: DELETE_SUPPORT_SECTION_REQUEST,
    payload: data,
  };
};

/**
 * @description DELETE request success
 * @param {Object} data
 * @return {Promise}
 */
export const deleteSectionSuccess = (data) => {
  return {
    type: DELETE_SUPPORT_SECTION_SUCCESS,
    payload: data,
  };
};
