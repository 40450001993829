import {
  MONITORING_UHE_FETCH_SUCCESS,
  MONITORING_UHE_FETCH_DATA,
} from "@constants/UHEActionTypes";

const INIT_STATE = {
  loading: true,
  table: {
    list: [],
    page: {},
  }
};

/**
 * @param  {Object} state
 * @param  {string} action
 * @return {Object}
 */
export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case MONITORING_UHE_FETCH_SUCCESS:
      const newState = { 'table': action.data, loading: false }
      return Object.assign({}, state, newState);
      break;

    case MONITORING_UHE_FETCH_DATA:
      return Object.assign({}, state, { loading: true });
      break;

    default:
      return state;
  }
}