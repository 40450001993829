import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row, Col, Card, Input, Checkbox, Form, Cascader, Table, Modal,
} from 'antd';
import EditHeader from '@components/uhe/configuration/EditHeader';
import * as userActions from '@uhe_actions/configuration/users/UsersActions';
import {
  onFetchUserAccessData as onFetchUserAccessDataDispatch,
  fetchUserGrant as fetchUserGrantDispatch,
  onFetchOwnPofile as onFetchOwnPofileDispatch,
} from '@uhe_actions/configuration/users/UsersActions';
import { onGetOptions } from '@uhe_actions/filters/ListingsTopFilterActions';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { EditableCell, EditableFormRow } from '@routes/System/Settings/EditableContext';
import UheHelper from 'util/UheHelper';

import { goBackTo, cascaderSearchFilter } from '@util/UheHelper';
import { withLastLocation } from 'react-router-last-location';
import {
  PROVIDER, CLINICAL_ROLES, NURSING, ANCILLARY, NUMBERS_PATTERN,
} from '@constants/UHESettings';
import AuditInfo, { AuditTypes } from '@components/uhe/configuration/AuditInfo';
import {
  shouldSaveUserDetails,
  checkOrganizationOptions,
  shouldShowEditHeader,
  shouldEditTechAuthorized,
  shouldBeAbleToEditUserDetails,
  shouldBeAbleToEditRolesAndPermissions,
  shouldShowTechAuthorized,
} from '@util/UheRoleChecker';
import UserPermissionsTable from './UserPermissionsTable';
import UserPermissionsUtils from './UserPermissionsUtils';

const { confirm } = Modal;

const layout = {
  labelCol: {
    xl: { span: 8 },
    lg: { span: 12 },
    md: { span: 12 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xl: { span: 16 },
    lg: { span: 12 },
    md: { span: 12 },
    sm: { span: 16 },
  },
};

/**
 * Renders User Add/Edit Page
 */
class ManageUser extends React.Component {
  constructor(props) {
    super(props);
    this.clinicalRoles = {
      provider: PROVIDER,
      nursing: NURSING,
      ancillary: ANCILLARY,
    };

    this.permissionColumns = [
      {
        title: <IntlMessages id="configuration.users.adminRole" />,
        dataIndex: 'adminRole',
        width: '30%',
        editable: false,
        render: (text, record, index) => <div className="action-btns">{record.adminRole.value}</div>,
      },
      {
        title: <IntlMessages id="uhe.table.organization" />,
        dataIndex: 'organization',
        width: '30%',
        editable: false,
        render: (text, record, index) => {
          if (record.organization.value === 'All') {
            return (
              <strong className="action-btns">
                {record.organization.value}
              </strong>
            );
          }
          return (
            <div className="action-btns">{record.organization.value}</div>
          );
        },
      },
      {
        title: <IntlMessages id="uhe.table.customer" />,
        dataIndex: 'customer',
        width: '30%',
        editable: false,
        render: (text, record, index) => {
          if (record.customer.value === 'All') {
            return (
              <strong className="action-btns">{record.customer.value}</strong>
            );
          }
          return <div className="action-btns">{record.customer.value}</div>;
        },
      },
      {
        title: <IntlMessages id="uhe.table.facility" />,
        dataIndex: 'facility',
        width: '30%',
        editable: false,
        render: (text, record, index) => {
          if (record.facility.value === 'All') {
            return (
              <strong className="action-btns">{record.facility.value}</strong>
            );
          }
          return <div className="action-btns">{record.facility.value}</div>;
        },
      },
      {
        title: <IntlMessages id="uhe.table.unit" />,
        dataIndex: 'unit',
        width: '30%',
        editable: false,
        render: (text, record, index) => {
          if (record.unit.value === 'All') {
            return <strong className="action-btns">{record.unit.value}</strong>;
          }
          return <div className="action-btns">{record.unit.value}</div>;
        },
      },
      {
        title: <IntlMessages id="uhe.table.device" />,
        dataIndex: 'device',
        width: '30%',
        editable: false,
        render: (text, record, index) => {
          if (record.device.value && record.device.value !== 'All') {
            return (
              <div className="action-btns">
                {record.device.isMobile ? (
                  <div>
                    <i className="icon icon-phone" />
                    {' '}
                    {`${record.device.value} `}
                  </div>
                ) : (
                  <div>
                    <i className="icon icon-data-display" />
                    {' '}
                    {record.device.value}
                    {' '}
                  </div>
                )}
              </div>
            );
          }
          return (
            <strong className="action-btns">{record.device.value}</strong>
          );
        },
      },
    ];
    this.isNew = this.props.location.pathname.indexOf('/new') !== -1;
    this.intl = this.props.intl;
    this.formRef = React.createRef();
    this.newTest = [];
    this.state = {
      role: '',
      isPopup: false,
      selectedOrganization: null,
      organizationName: '',
      userPermissionData: {},
      isDisabled: false,
      tableData: [],
      testArr: [],
      adminLevel: '',
      showTable: false,
      user: {
        id: null,
        mobile: {
          role: null,
          specialty: null,
        },
        organization: {
          id: null,
          name: '',
        },
        prefix: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: null,
        admin_level: null,
        is_notification: 0,
        is_clinician: 0,
        is_iobserver: 0,
        is_technician: 0,
        is_readonly: 0,
        tech_authorized: 0,
        is_technician_admin: false,
      },
      organizationOptions: [],
      dataCustomerServer: [],
      data: [],
      test: [],
      customer: [],
      error: false,
      hasChangedOrganization: false,
    };

    this.renderHeadLine = this.renderHeadLine.bind(this);

    if (this.isNew) {
      if (this.props.subtitle.langId !== 'configuration.users.addUser') {
        this.props.setSubtitle('configuration.users.addUser');
      }

      this.props.onGetOrganizationOptions();
      this.props.onGetCustomerOptions();
    } else if (this.props.subtitle.langId !== 'configuration.users.editUser') {
      this.props.setSubtitle('configuration.users.editUser');
    }

    this.history = this.props.history;
  }

  /**
   * @description Update local state
   * @returns {void}
   */
  componentDidMount() {
    this.fetchData();
  }

  /**
   * @description Watch for empty data and if is empty set new state data
   * @param {Readonly<P>} prevProps
   * @return {Object}
   */
  componentDidUpdate(prevProps) {
    const {
      savedUserId,
      error,
      history,
      user,
      data,
      userPermissionData,
    } = this.props;
    const user_data = this.state.user;

    if (prevProps.error !== this.props.error) {
      // handle system error
      if (error.code === 404 && !this.isNew) {
        history.push('/configuration/users');
      }
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.isNew = this.props.location.pathname.indexOf('/new') !== -1;
      this.fetchData();
    }

    if (this.isNew && savedUserId !== prevProps.savedUserId) {
      history.push(`/configuration/users/edit/${savedUserId}`);
    }
    if (!this.isNew && prevProps.userPermissionData && userPermissionData) {
      if (prevProps.userPermissionData !== userPermissionData) {
        const [_upd, globalGrantsData] = UserPermissionsUtils.fromApiToReact(userPermissionData);
        this.globalGrantsData = globalGrantsData;
        this.setState({
          userPermissionData: UserPermissionsUtils.fromReactToApi(_upd, globalGrantsData),
          _upd,
          globalGrantsData,
        }, this.customer);
      }
    }

    if (!this.isNew && prevProps.user && user && user.organization) {
      if (
        prevProps.user !== user
        || prevProps.user.id !== user.id
        || prevProps.user.saved !== user.saved
      ) {
        this.setState({ user });
        this.props.onGetOrganizationOptions();
        this.props.onGetCustomerOptions(user.organization.id);
      }
    }

    if (
      user
      && prevProps.user
      && prevProps.user.is_technician !== user.is_technician
    ) {
      this.setState({ user });
    }

    if (prevProps.data.length === 0 && data.length > 0) {
      this.setState({ data });
    }
    if (!this.isNew) {
      this.formRef.current.setFieldsValue({
        organization: [!user_data?.organization?.id ? null : user_data.organization.id],
      });
    }
    this.formRef.current.setFieldsValue({
      email: user_data.email,
      phone_number: user_data.phone_number,
      prefix: user_data.prefix,
      first_name: user_data.first_name,
      last_name: user_data.last_name,
    });
  }

  /**
   * @description Handle Changes in Organization Cascader
   * @param {Array} values
   */
  onChangeOrganizationDropdown = ([{ id, name } = {}] = [], hasChangedOrganization = false) => {
    const { user } = this.state;

    user.organization = { id, name };

    this.setState({ user, hasChangedOrganization }, () => {
      if (hasChangedOrganization) {
        this.saveUser();
      }
    });
  }

  /**
   * @description Forwarder for updating user permissions from UserPermissionsTable
   * @param {Object.<string, ReactivePermissionData>} permissions
   * @param {ReactivePermissionData} globalGrantsData
   */
  updatePermissions = (permissions, globalGrantsData) => {
    this.setState({
      _upd: { ...permissions },
      globalGrantsData: { ...globalGrantsData },
      userPermissionData: UserPermissionsUtils.fromReactToApi(permissions, globalGrantsData),
    });
  };

  /**
   * @description Save Changes on Save Button Click in the header
   * @returns {void}
   */
   saveUser = () => {
     const {
       actions, match: { params: { id } }, setSubtitle, loggedUser,
     } = this.props;
     const { user, _upd, hasChangedOrganization } = this.state;
     const body = UserPermissionsUtils.fromReactToApi(_upd, this.globalGrantsData);

     actions.saveUser({
       user, grants: { body, id }, shouldSaveUserDetails: shouldSaveUserDetails(loggedUser), hasChangedOrganization,
     });
     setSubtitle('configuration.users.editUser');
     this.setState({ hasChangedOrganization: false });
   }

  /**
   * @description Returns function for setting user field (related with name) according to key
   * @param {String} key Name of the user field
   * @returns {function(Event): void} Function for setting user field (related with name) according to key
   */
  onChangeHandlerName = (key) =>
    /**
     * @description Sets user field (related with name) according to key
     * @param {Event} event
     * @returns {void}
     */
    (event) => {
      if (!!event && !!event.persist && !!event.preventDefault) {
        event.persist();
        event.preventDefault();
      }
      this.setState({
        user: {
          ...this.state.user,
          [key]: event.target.value || '',
        },
      });
    }

  /**
   * @description Returns function for setting user field (related with checkbox) according to key
   * @param {String} key Name of the user field
   * @returns {function(Event): void} Function for setting user field (related with checkbox) according to key
   */
  onChangeCheckbox = (key) =>
    /**
     * @description Sets user field (related with checkbox) according to key
     * @param {Event} event
     */
    (event) => {
      const newState = event.target.checked ? 1 : 0;
      this.setState({ user: { ...this.state.user, [key]: newState } });
    }

  /**
   * @param {string} prop Name of the state property that has been updated
   * @returns {dependentOf: (function(string): {buildResult: function(any=, any=): (undefined | {[p: string]: boolean, isAllowed: boolean})})}
   */
  changedCascaderOf = (prop) => {
    const capitalizedProp = prop.charAt(0).toUpperCase() + prop.slice(1);
    return {
      /**
       * @param {string} dependentProp Name of the parent cascader this cascader is dependent
       * @returns {buildResult: (function(any=, any=): {[p: string]: boolean, isAllowed: boolean})}
       */
      dependentOf: (dependentProp) => {
        const capitalizedDependentProp = dependentProp.charAt(0).toUpperCase() + dependentProp.slice(1);
        return {
          /**
           * @description Checks input parameters, if invalid sets to default values. Checks if selected values are allowed
           * @param {Array<string>} value
           * @param {Array<string>} label
           * @returns {Object} Object containing templated properties for 'selectedT', 'selectedTFull' and 'TName'
           */
          buildResult: (value, label) => {
            if (label[0] === undefined) return;

            if (value && value.length === 0) {
              this.setState({
                [`selected${capitalizedProp}`]: '',
                [`selected${capitalizedProp}Full`]: '',
                [`${prop}Name`]: '',
              });
            }
            if (!value || !label || !value[0] || !label[0]) return;

            const selectedDependentPropFull = { ...this.state[`selected${capitalizedDependentProp}Full`] };

            const propName = label[0].label;
            const selectedProp = value[0];
            const selectedPropFull = [...selectedDependentPropFull.nested]
              .find((item) => item.name === propName
                && item[prop] === selectedProp);
            if (!selectedPropFull || !selectedPropFull.allowed) return;

            return {
              [`selected${capitalizedProp}`]: selectedProp,
              [`${prop}Name`]: propName,
              [`selected${capitalizedProp}Full`]: selectedPropFull,
              isAllowed: true,
            };
          },
        };
      },
    };
  }

  /**
   * @description Render 'All' if it's child of granted role
   */
  renderAllCol = () => ({
    value: this.intl.formatMessage({ id: 'common.all' }),
    key: 'All',
    id: 'All',
  });

  /**
   * @description Render rowsrenderHeadline
   * @param {Object} datarenderHeadline
   * @param {String} adminRole
   * @param {Object} organization
   * @param {Object} customer
   * @param {Object} facility
   * @param {Object} unit
   * @param {Object} device
   */
  renderRow(data, adminRole, organization, customer, facility, unit, device) {
    data.push({
      adminRole: {
        value: this.intl.formatMessage({
          id: `configuration.users.${adminRole}`,
        }),
        key: adminRole,
      },
      organization: organization
        ? {
          value: organization.name,
          key: organization.name,
          id: organization.organization,
        }
        : this.renderAllCol(),
      customer: customer
        ? {
          value: customer.name,
          key: customer.name,
          id: customer.customer,
        }
        : this.renderAllCol(),
      facility: facility
        ? {
          value: facility.name,
          key: facility.name,
          id: facility.facility,
        }
        : this.renderAllCol(),
      unit: unit
        ? {
          value: unit.name,
          key: unit.name,
          id: unit.unit,
        }
        : this.renderAllCol(),
      device: device
        ? {
          value: device.name,
          key: device.name,
          id: device.device || device.user,
          isMobile: !!device.user,
        }
        : this.renderAllCol(),
    });
  }

  /**
   * @description Populate Columns for User Permissions
   * @param {Array} userPermissionData
   * @return {Array}
   */
  dataAdapter(userPermissionData = []) {
    const adaptedData = [];
    if (
      Object.keys(userPermissionData).forEach((key) => {
        const currentPermission = userPermissionData[key];
        if (currentPermission.granted === true) {
          this.renderRow(adaptedData, key);
        } else if (currentPermission.nested) {
          currentPermission.nested.forEach((organization) => {
            if (organization.granted === true) {
              this.renderRow(adaptedData, key, organization);
            } else if (organization && organization.nested) {
              organization.nested.forEach((customer) => {
                if (customer.granted === true) {
                  this.renderRow(adaptedData, key, organization, customer);
                } else if (customer && customer.nested) {
                  customer.nested.forEach((facility) => {
                    if (facility.granted === true) {
                      this.renderRow(
                        adaptedData,
                        key,
                        organization,
                        customer,
                        facility,
                      );
                    } else if (facility && facility.nested) {
                      facility.nested.forEach((unit) => {
                        if (unit.granted === true) {
                          this.renderRow(
                            adaptedData,
                            key,
                            organization,
                            customer,
                            facility,
                            unit,
                          );
                        } else if (unit && unit.nested) {
                          const devices = unit.nested[0];
                          const mobileDevices = unit.nested[1];
                          if (devices.nested) {
                            devices.nested.forEach((device) => {
                              if (device.granted === true) {
                                this.renderRow(
                                  adaptedData,
                                  key,
                                  organization,
                                  customer,
                                  facility,
                                  unit,
                                  device,
                                );
                              }
                            });
                          }
                          if (mobileDevices.nested) {
                            mobileDevices.nested.forEach(
                              (mobileDevice) => {
                                if (mobileDevice.granted === true) {
                                  this.renderRow(
                                    adaptedData,
                                    key,
                                    organization,
                                    customer,
                                    facility,
                                    unit,
                                    mobileDevice,
                                  );
                                }
                              },
                            );
                          }
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      })
    );
    return adaptedData;
  }

  /**
   * @description Render User Permissions Table
   * @returns {JSX.Element}
   */
  renderTable() {
    const { loading } = this.props;
    const customer_components = {
      body: {
        row: (props) => EditableFormRow(props, this.customerForm),
        cell: EditableCell,
      },
    };
    const permissionColumns = this.permissionColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          type: col.type,
          handleSave: this.handleCustomerSave,
        }),
      };
    });
    return (
      <>
        {this.state.organization_id ? this.renderHeadLine() : ''}
        <Row lg={24} md={24} sm={24} sx={24} gutter={16}>
          <Col lg={24} md={24} sm={24} sx={24}>
            <Card
              className="permission-table-card gx-card customer-edit-info-card"
              title={(
                <IntlMessages
                  id="configuration.users.userPermission"
                />
              )}
            >
              <Row lg={24} md={24} sm={24} sx={24} gutter={16}>
                <Table
                  className="permission-table gx-table-responsive"
                  components={customer_components}
                  rowClassName={() => 'editable-row'}
                  scroll={{ x: false }}
                  bordered
                  dataSource={this.dataAdapter(this.state.userPermissionData)}
                  columns={permissionColumns}
                  pagination={false}
                  loading={loading}
                  rowKey={(rec) => `${rec.address}_${Math.random() * 1000000000}`}
                />
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  /**
   * @description Adapt data returned by the server
   * @return {Array}
   */
  role() {
    if (!this.state.userPermissionData) {
      return [];
    }

    return Object.keys(this.state.userPermissionData).reduce(
      (acc, value) => [...acc, {
        label: this.intl.formatMessage({ id: `configuration.users.${value}` }),
        value,
      }], [],
    );
  }

  /**
   * @description Adapt data returned by the server
   * @return {Array}
   */
  organization() {
    const { selectedAdminLevel } = this.state;
    if (!selectedAdminLevel || !selectedAdminLevel.nested) return [];

    return [...selectedAdminLevel.nested].reduce((acc, option) => [...acc, {
      label: option.name,
      value: option.organization,
    }], []);
  }

  /**
   * @description Adapt data returned by the server
   * @return {Array}
   */
  customer() {
    const { selectedOrganizationFull } = this.state;
    if (!selectedOrganizationFull || !selectedOrganizationFull.nested) return [];

    return [...selectedOrganizationFull.nested].reduce((acc, option) => [...acc, {
      label: option.name,
      value: option.customer,
    }], []);
  }

  /**
   * @description Adapt data returned by the server
   * @return {Array}
   */
  facility() {
    const { selectedCustomerFull } = this.state;
    if (!selectedCustomerFull || !selectedCustomerFull.nested) return [];

    return [...selectedCustomerFull.nested].reduce((acc, option) => [...acc, {
      label: option.name,
      value: option.facility,
    }], []);
  }

  /**
   * @description Adapt data returned by the server
   * @return {Array}
   */
  unit() {
    const { selectedFacilityFull } = this.state;
    if (!selectedFacilityFull || !selectedFacilityFull.nested) return [];

    return [...selectedFacilityFull.nested].reduce((acc, option) => [...acc, {
      label: option.name,
      value: option.unit,
    }], []);
  }

  /**
   * @description Adapt data returned by the server
   * @return {Array}
   */
  device() {
    const { selectedUnitFull } = this.state;
    if (!selectedUnitFull || !selectedUnitFull.nested) return [];

    return [...selectedUnitFull.nested].reduce((acc, option) => {
      if (option.nested) {
        [option.nested].forEach((deviceOption) => {
          acc.push({
            label: deviceOption.device
              ? (
                <div>
                  <i className="icon icon-data-display" />
                  {' '}
                  {deviceOption.name}
                </div>
              )
              : (
                <div>
                  <i className="icon icon-phone" />
                  {' '}
                  {deviceOption.name}
                </div>
              ),
            value: deviceOption.device || deviceOption.user,
            isMobile: !deviceOption.device,
          });
        });
      }
      return acc;
    }, []);
  }

  /**
   * Render title, back and save buttons
   * @returns {JSX.Element|null} EditHeader Component | Null
   */
  renderHeadLine() {
    const {
      editMode, loading, lastLocation, history,
    } = this.props;
    const titleKey = editMode
      ? 'configuration.users.addUser'
      : 'configuration.users.editUser';

    if (shouldShowEditHeader()) {
      return (
        <EditHeader
          goBack={goBackTo(`/configuration/users?sort=${encodeURIComponent('organization,asc')}`, lastLocation?.pathname, history)}
          loading={loading}
          titleKey={titleKey}
        />
      );
    }

    return null;
  }

  warning = () => {
    Modal.warning({
      content: this.intl.formatMessage({ id: 'common.notAllowed' }),
    });
  };

  /**
   * Fetches data for the component
   * @returns {void}
   */
  fetchData = () => {
    const { match: { params: { id: urlId } }, user: { id: userId } } = this.props;
    if (urlId && urlId !== userId) {
      this.props.actions.fetchUser({ id: urlId });
    }
    if (!this.isNew) {
      const { actions: { fetchUser }, onFetchUserAccessData, fetchUserGrant } = this.props;
      fetchUser({ id: urlId });
      onFetchUserAccessData({ id: urlId });
      fetchUserGrant({ id: urlId });
    }
  };

  /**
   * Renders AuditInfo component
   * @param {number} auditType Use AuditType for setting this field
   * @returns {JSX.Element} AuditInfo Component
   */
  renderAuditInfo(auditType) {
    const { user } = this.state;
    return (
      <AuditInfo
        className="manageUsers__userDetails--auditInfo"
        type={auditType}
        data={user}
      />
    );
  }

  /**
   * @description Renders Specialty Options Based on Clinical Role
   * @param {string} clinicalRoleKey
   * @returns {Array}
   */
  renderSpecialtyOptions = (clinicalRoleKey) => {
    const key = clinicalRoleKey?.toLowerCase();

    // Linter breaks with message: TypeError: Cannot read property 'range' of null
    // According to github issues the problem is known and is scheduled to be fixed soon
    // Problem occurs when using template string inline in places
    const getId = (option) => `configuration.bulkUpload.${key}.${option}`;

    return this.clinicalRoles[key]?.map((option) => ({
      value: this.intl.formatMessage({ id: getId(option) }),
      label: this.intl.formatMessage({ id: getId(option) }),
    }
    ));
  }

  /**
   * @param {'role'|'specialty'} key
   * @return {function([string]=): void}
   */
  onChangeOf = (key) =>
    /**
     * @param {string} value
     */
    // eslint-disable-next-line implicit-arrow-linebreak
    ([value] = []) => {
      const { user } = this.state;
      this.setState({
        user: {
          ...user,
          mobile: { ...user.mobile, [key]: value },
        },
      });
    };

  /**
   * Shows Confirmational Modal on Organization Change && Hides Permission Tables
   * @param {array} selected Array with the Selected Organization Object
   * @returns {void}
   */
  showConfirm = (selected) => {
    const { user } = this.state;

    confirm({
      title: this.intl.formatMessage({ id: 'configuration.users.changeOrgMessage' }),
      onOk: () => {
        this.onChangeOrganizationDropdown(selected, true);
      },
      onCancel: () => this.setState({ user }),
      autoFocusButton: 'cancel',
    });
  }

  /**
   * Renders ManageUsers Component
   * @returns {JXS.Element} ManageUsers Component
   */
  render() {
    const {
      loading, optionsList, loggedUser, user: currentUser,
    } = this.props;
    const {
      user, error, hasChangedOrganization, _upd, globalGrantsData,
    } = this.state;
    const options = {
      ...optionsList,
      organization: [
        {
          id: null,
          name: this.intl.formatMessage({ id: 'common.allOrganizations' }),
        },
        ...optionsList.organization,
      ],
    };
    const clinicalRoleOptions = CLINICAL_ROLES.map((option) => ({
      value: this.intl.formatMessage({ id: `configuration.bulkUpload.table.users.${option}` }),
      label: this.intl.formatMessage({ id: `configuration.bulkUpload.table.users.${option}` }),
    }));
    const filteredOrgOptions = options.organization.filter((item) => item.id);
    const sortedOrgOptions = options.organization.reduce((acc, current) => (current.id === null ? [current, ...acc] : [...acc, current]), []);

    return (
      <div className="manage-customer-wrapper system">
        <Form ref={this.formRef} onFinish={() => this.saveUser(loggedUser)}>
          {this.renderHeadLine()}
          {!loading && error && this.showError()}
          <Row lg={24} md={24} sm={24} sx={24} gutter={16}>
            <Col lg={24} md={24} sm={24} sx={24}>
              {/* Personal info */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="configuration.users.userDetails" />}
                loading={loading}
              >
                <Row lg={24} md={24} sm={24} sx={24} gutter={16}>
                  <Col lg={8} md={8} sm={24} sx={24}>
                    <Form.Item
                      {...layout}
                      className="form-item-row"
                      name="email"
                      label={<IntlMessages id="configuration.users.email" />}
                      rules={[
                        {
                          required: true,
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                      ]}
                      initialValue={user.email}
                    >
                      <Input
                        id="email"
                        onChange={this.onChangeHandlerName('email')}
                        longdesc={this.intl.formatMessage({
                          id: 'configuration.users.emailInput',
                        })}
                        disabled={!shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8} sm={24} sx={24}>
                    <Form.Item
                      {...layout}
                      className="form-item-row"
                      name="first_name"
                      label={
                        <IntlMessages id="configuration.users.firstName" />
                      }
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({
                            id: 'configuration.mobilePatients.firstNameInfo',
                          }),
                        },
                      ]}
                    >
                      <Input
                        id="aSettingsUsername"
                        onChange={this.onChangeHandlerName('first_name')}
                        value={user.first_name}
                        longdesc={this.intl.formatMessage({
                          id: 'configuration.users.firstNameInput',
                        })}
                        disabled={!shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)}
                        rules={[
                          {
                            required: true,
                            message: this.intl.formatMessage({
                              id: 'configuration.mobilePatients.firstNameInfo',
                            }),
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8} sm={24} sx={24}>
                    <Form.Item
                      {...layout}
                      className="form-item-row"
                      name="last_name"
                      label={<IntlMessages id="configuration.users.lastName" />}
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({
                            id: 'configuration.mobilePatients.lastNameInfo',
                          }),
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        id="aSettingsUserlastname"
                        name="last_name"
                        onChange={this.onChangeHandlerName('last_name')}
                        value={user.last_name}
                        longdesc={this.intl.formatMessage({
                          id: 'configuration.users.lastNameInput',
                        })}
                        disabled={!shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)}
                        rules={[
                          {
                            required: true,
                            message: this.intl.formatMessage({
                              id: 'configuration.mobilePatients.lastNameInfo',
                            }),
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8} sm={24} sx={24}>
                    <Form.Item
                      {...layout}
                      className="form-item-row"
                      name="organization"
                      label={<IntlMessages id="uhe.table.organization" />}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="common.error_organization" />,
                        },
                      ]}
                    >
                      <Cascader
                        className="top-filter-popup"
                        fieldNames={{ label: 'name', value: 'id' }}
                        key="organization"
                        expandTrigger="hover"
                        size="large"
                        changeOnSelect
                        allowClear={false}
                        options={checkOrganizationOptions(loggedUser) ? filteredOrgOptions : sortedOrgOptions || []}
                        placeholder={this.intl.formatMessage({
                          id: 'uhe.table.organization',
                        })}
                        showSearch={{ filter: cascaderSearchFilter }}
                        longdesc={this.intl.formatMessage({
                          id: 'configuration.users.organizationDropdown',
                        })}
                        disabled={!shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)}
                        onChange={(label, selected) => (this.isNew || hasChangedOrganization
                          ? this.onChangeOrganizationDropdown(selected)
                          : this.showConfirm(selected))}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8} sm={24} sx={24}>
                    <Form.Item
                      {...layout}
                      className="form-item-row"
                      label={
                        <IntlMessages id="configuration.users.clinicalRole" />
                      }
                    >
                      <Cascader
                        value={[user.mobile.role]}
                        options={clinicalRoleOptions}
                        onChange={this.onChangeOf('role')}
                        disabled={!shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8} sm={24} sx={24}>
                    <Form.Item
                      {...layout}
                      className="form-item-row"
                      label={
                        <IntlMessages id="configuration.users.specialty" />
                      }
                    >
                      <Cascader
                        value={[user.mobile.specialty]}
                        options={this.renderSpecialtyOptions(user.mobile.role)}
                        onChange={this.onChangeOf('specialty')}
                        disabled={!shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8} sm={24} sx={24}>
                    <Form.Item
                      className="form-item-row"
                      name="phone_number"
                      label={
                        <IntlMessages id="configuration.users.phoneNumber" />
                      }
                      rules={[
                        {
                          pattern: NUMBERS_PATTERN,
                          message: this.intl.formatMessage({ id: 'common.onlyNumbersMessage' }),
                        },
                        {
                          max: 15,
                          message: this.intl.formatMessage({ id: 'common.minCharactersMessage' }),
                        },
                      ]}
                      labelCol={{
                        xl: { span: 8 },
                        lg: { span: 12 },
                        md: { span: 12 },
                        sm: { span: 8 },
                      }}
                      wrapperCol={{
                        xl: { span: 16 },
                        lg: { span: 12 },
                        md: { span: 12 },
                        sm: { span: 16 },
                      }}
                    >
                      <Input
                        id="aSettingsPhoneNumber"
                        value={user.phone_number}
                        onChange={this.onChangeHandlerName('phone_number')}
                        longdesc={this.intl.formatMessage({
                          id: 'configuration.users.phoneNumber',
                        })}
                        disabled={!shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)}
                      />
                    </Form.Item>
                  </Col>
                  {this.isNew && (
                    <Form.Item
                      className="manageUsers__personalInfo--checkbox"
                      name="authentication"
                      label={
                        <IntlMessages id="configuration.users.partyAuth" />
                      }
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Checkbox
                        type="checkbox"
                        onChange={this.onChangeCheckbox('thirdpartyauth')}
                        checked={!!+user.thirdpartyauth}
                        longdesc={this.intl.formatMessage({
                          id: 'configuration.users.partyAuth',
                        })}
                        disabled={!shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)}
                      />
                    </Form.Item>
                  )}
                  {shouldShowTechAuthorized(user, this.isNew) && (
                    <Col lg={8} md={8} sm={24} sx={24}>
                      <Form.Item
                        label={
                          <IntlMessages id="configuration.users.isTechAuthorized" />
                        }
                      >
                        <Checkbox
                          type="checkbox"
                          className="gx-mt-3"
                          onChange={this.onChangeCheckbox('tech_authorized')}
                          checked={user.tech_authorized}
                          disabled={!shouldEditTechAuthorized(loggedUser)}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                {this.renderAuditInfo(AuditTypes.createdAt)}
                {this.renderAuditInfo(AuditTypes.updatedAt)}
              </Card>
            </Col>
          </Row>
          {!this.isNew ? (
            <>
              {/* Role Assignment */}
              <Row gutter={16}>
                <Col lg={24} md={24} sm={24} sx={24}>
                  <Card
                    className="permission-table-card gx-card customer-edit-info-card"
                    title={(
                      <IntlMessages
                        id="configuration.users.roleAssignment"
                      />
                  )}
                    loading={loading}
                  >
                    <UserPermissionsTable
                      dataSource={_upd}
                      globalGrantsData={globalGrantsData}
                      updatePermissions={this.updatePermissions}
                      formatMessage={this.intl.formatMessage}
                      disabled={!shouldBeAbleToEditRolesAndPermissions(loggedUser, currentUser)}
                    />
                  </Card>
                </Col>
              </Row>

              {this.renderTable()}
              {this.renderHeadLine()}
            </>
          ) : (
            <></>
          )}
        </Form>
      </div>
    );
  }
}

ManageUser.defaultProps = {
  user: {},
  userPermissionData: {},
  data: {},
  loading: true,
};

ManageUser.propTypes = {
  user: PropTypes.object,
  userPermissionData: PropTypes.object,
  intl: PropTypes.object,
  data: PropTypes.shape({}),
  onFetchUserAccessData: PropTypes.func.isRequired,
  fetchUserGrant: PropTypes.func.isRequired,
  setSubtitle: PropTypes.func.isRequired,
  loggedUser: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  lastLocation: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    saveUser: PropTypes.func.isRequired,
    saveUserGrants: PropTypes.func.isRequired,
  }).isRequired,
};

/**
 * @description Maps Global State to Component's Props
 * @returns {Object}
 */
const mapStateToProps = ({
  ConfigurationUsers,
  listingsTopFilter,
  subtitle,
  common,
}) => {
  const { savedUserId, editTable, userPerm } = ConfigurationUsers;
  UheHelper.setOwnUserProfile(ConfigurationUsers.ownUser);

  return {
    subtitle,
    savedUserId,
    error: common.error,
    loading: ConfigurationUsers.loading,
    user: ConfigurationUsers.selectedUser || {},
    userPermissionData: userPerm,
    optionsList: listingsTopFilter,
    data: editTable,
    loggedUser: ConfigurationUsers.ownUser,
  };
};

/**
 * @description Returns Object Which Dispatch Actions to the Store
 * @param {function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => ({
  onGetOrganizationOptions: () => dispatch(onGetOptions('organization')),
  onGetCustomerOptions: (id) => dispatch(onGetOptions('customer', id === 'All' ? 0 : id)),
  onGetFacilityOptions: (id) => dispatch(onGetOptions('facility', id)),
  onGetUnitOptions: (id) => dispatch(onGetOptions('unit', id)),
  onFetchUserAccessData: (id) => dispatch(onFetchUserAccessDataDispatch(id)),
  fetchUserGrant: (id) => dispatch(fetchUserGrantDispatch(id)),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  onFetchOwnPofile: () => dispatch(onFetchOwnPofileDispatch()),
  actions: bindActionCreators(userActions, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(injectIntl(withRouter(ManageUser))));
