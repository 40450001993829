import React from 'react';
import PropTypes from 'prop-types';
import { Input, Cascader } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import lodash from 'lodash';

import {
  INPUT_DEBOUNCE_MILLSEC,
  SEARCH_MIN_CHAR_LENGTH,
} from '@constants/SystemConstants';

import {
  TOP_FILTER_PREFIX,
  TABLE_FILTER_PREFIX,
} from '@constants/UHESettings';

/**
 * ListingsTableInputFilter Class Component
 */
class ListingsTableInputFilter extends React.Component {
  /**
   * Part of React lifecycle
   * @param  {object} nextProps Next Props
   * @param {object} nextState Next State
   * @return {null} null
   */
  static getDerivedStateFromProps(nextProps, nextState) {
    const { cellData, dataKey } = nextProps;
    const { sortColumns } = cellData;
    const sortColumn = sortColumns.find((element) => element.column.dataIndex === dataKey);

    if (!sortColumn) {
      return null;
    }
    const { dataIndex = '' } = sortColumn.column || {};

    if (dataIndex !== dataKey) {
      return null;
    }

    const ascKey = `${dataKey},asc`;
    const descKey = `${dataKey},desc`;
    let tableSorter;

    if (sortColumn.order === 'ascend') {
      tableSorter = ascKey;
    } else if (sortColumn.order === 'descend') {
      tableSorter = descKey;
    }

    const { history } = nextProps;
    const qParams = new URLSearchParams(history.location.search);
    const currSort = qParams.getAll('sort') || [];
    const sorterFound = currSort.find((element) => element === ascKey || element === descKey);

    // In case the event happens for different reason than sorting
    // nothing changed
    if (sorterFound === tableSorter) {
      return null;
    }

    qParams.delete('sort');
    lodash.remove(currSort, (n) => n === sorterFound);

    if (!sorterFound) {
      nextState.isClicked ? currSort.unshift(ascKey) : currSort.push(ascKey);
    } else if (ascKey === sorterFound) {
      nextState.isClicked ? currSort.unshift(descKey) : currSort.push(descKey);
    }

    currSort.forEach((value) => qParams.append('sort', value));
    history.push({ search: qParams.toString() });

    return null;
  }

  /**
   * ListingsTableInputFilter Constructor
   * @param {object} props ListingsTableInputFilter Props
   * @returns {void}
   */
  constructor(props) {
    super(props);

    this.state = {
      isClicked: false,
    };

    const { dataKey } = this.props;
    this.intl = this.props.intl;
    this.history = this.props.history;
    this.ascKey = `${dataKey},asc`;
    this.descKey = `${dataKey},desc`;
    this.qParams = new URLSearchParams(this.history.location.search);
    this.filterValue = this.qParams.get(`${TABLE_FILTER_PREFIX}${dataKey}`);
    this.showSearchIcon = !!(!this.filterValue || !this.filterValue.length);
    this.textInput = React.createRef();

    this.handleSearchProxy = this.handleSearchProxy.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(this.props.location.search);
    }
  }

  /**
   * Find if there is sort for this column
   *
   * @return {string|undefined} [description]
   */
  getCurrentSort() {
    const currSort = this.qParams.getAll('sort') || [];
    return currSort.find((element) => element === this.ascKey || element === this.descKey);
  }

  /**
   * Stop click propagation to prevent sort triggering
   * when the user clicks on the input
   *
   * @param  {Event} e
   * @return {void}
   */
  inputClickHandler(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  /**
   * Proxy function between the change event and the debounced handler
   * Used to control the search icon visibility
   *
   * @param  {Event} e
   * @return {void}
   */
  handleSearchProxy(e) {
    const { filterType, dataKey } = this.props;

    switch (filterType) {
      case 'dropdown':
        e && e.length
          ? this.qParams.set(`${TABLE_FILTER_PREFIX}${dataKey}`, e[0])
          : this.qParams.delete(`${TABLE_FILTER_PREFIX}${dataKey}`);
        this.history.push({ search: this.qParams.toString() });
        break;
      default:
        const { value } = e.currentTarget;
        this.showSearchIcon = !value.length;
        this.handleSearch();
        this.forceUpdate();
    }
  }

  /**
   * Debounced search change handler
   *
   * @return {void}
   */
  handleSearch = lodash.debounce(() => {
    const { dataKey, triggerCharsNum } = this.props;
    const { state } = this.textInput.current;

    if (!state.value.length) {
      this.qParams.delete(`${TABLE_FILTER_PREFIX}${dataKey}`);
    } else if (state.value.length < triggerCharsNum) {
      // No action yet
      return;
    } else {
      this.qParams.set(`${TABLE_FILTER_PREFIX}${dataKey}`, state.value);
    }

    this.history.push({ search: this.qParams.toString() });
  }, INPUT_DEBOUNCE_MILLSEC);

  /**
   *
   * @return {ReactElement}
   */
  renderFilterInput() {
    const { filterType } = this.props;

    switch (filterType) {
      case 'dropdown':
        return this.renderCascader();
        break;
      default:
        return this.renderTextInput();
    }
  }

  /**
   * @description Converts to lowercase and filter options.
   * @param {string} inputValue
   * @param {object} path
   * @returns {string}
   */
  tableCascaderFilter = (inputValue, path) => path.some((option) => option.label
    .toLowerCase()
    .indexOf(inputValue.toLowerCase()) > -1);

  /**
   * Renders Sort Icon Next to the Column Name
   * @return {ReactElement|null} Sort Icon
   */
  renderSortIcon() {
    const sort = this.getCurrentSort();
    let icon = null;
    if (!this.state.isClicked) {
      return icon
    } else if (sort === this.descKey) {
      icon = <span><i className="sort-icon icon icon-long-arrow-down" /></span>;
    } else if (sort === this.ascKey) {
      icon = <span><i className="sort-icon icon icon-long-arrow-up" /></span>;
    }

    return icon;
  }

  renderCascader() {
    const { filterOptions, dataKey } = this.props;
    const topFilterExists = !!this.qParams.get(`${TOP_FILTER_PREFIX}${dataKey}`);

    return (
      <Cascader
        showSearch={{ filter: this.tableCascaderFilter }}
        changeOnSelect
        size="large"
        expandTrigger="hover"
        popupClassName="top-filter-popup"
        disabled={topFilterExists}
        defaultValue={this.filterValue ? [this.filterValue] : undefined}
        options={filterOptions}
        onChange={this.handleSearchProxy}
        placeholder={this.props.intl.messages['common.select']}
      />
    );
  }

  /**
   *
   * @return {Input}
   */
  renderTextInput() {
    const { dataKey } = this.props;
    const topFilterExists = !!this.qParams.get(`${TOP_FILTER_PREFIX}${dataKey}`);

    return (
      <Input
        allowClear
        defaultValue={this.filterValue}
        disabled={topFilterExists}
        ref={this.textInput}
        placeholder={this.props.intl.messages['uhe.table.tableInputFilter']}
        suffix={this.showSearchIcon ? <i className="icon icon-search-new" /> : <span />}
        onChange={(е) => this.handleSearchProxy(е)}
      />
    );
  }

  /**
   * Renders ListingsTableInputFilter Component
   * @return {ReactElement} ListingsTableInputFilter Component
   */
  render() {
    const { title, showFilter } = this.props;
    return (
      <div className="table-filter-holder" onClick={() => this.setState({ isClicked: true })}>
        <span className="uhe-table-head">
          <FormattedMessage id={title} />
          {this.renderSortIcon()}
        </span>
        {showFilter && <div onClick={this.inputClickHandler}>{this.renderFilterInput()}</div>}
      </div>
    );
  }
}

ListingsTableInputFilter.defaultProps = {
  showFilter: true,
  filterType: 'input',
  filterOptions: [],
  triggerCharsNum: SEARCH_MIN_CHAR_LENGTH,
};

ListingsTableInputFilter.propTypes = {
  intl: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  filterType: PropTypes.string,
  showFilter: PropTypes.bool,
  filterOptions: PropTypes.array,
  triggerCharsNum: PropTypes.number,
};

export default (injectIntl(withRouter(ListingsTableInputFilter)));
