//ListingsTopFilterSaga
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  systemFetchDataSuccess,
  saveSystemSuccess,
  clearSelection,
  sendTestEmailSuccess,
  fetchLicensingSuccess,
  sendLicensingKeySuccess,
  fetchApplicationVersionSuccess,
} from '@actions/uhe/system/SystemActions';
import {
  SYSTEM_SETTINGS_SYSTEM_FETCH_DATA,
  SAVE_SYSTEM_SETTINGS_SYSTEM_REQUEST,
  SEND_TEST_EMAIL,
  FETCH_LICENSING_REQUEST,
  SEND_LICENSING_KEY_REQUEST,
  FETCH_APPLICATION_VERSION_REQUEST
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError,showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';

/**
 * @description Request data for system page
 * @param  {Array} // Array of objects
 */
const doFetchData = async () => {
  return await RestManager.request(`${ENDPOINTS.system.SystemData}`);
};

/**
 * @description Save request
 * @param  {object} bodyData
 * @return {Object}
 */
const save = async (bodyData) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.system.SaveSystemData}`,
    'POST',
    bodyData
  );
};

const send = async (bodyData) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.system.TestEmail}`,
    'POST',
    bodyData
  );
};

/**
 * @description Sends GET Request to /api/licensing
 * @returns {Object}
 */
const fetchLicensing = async () => {
  return await RestManager.request(`${ENDPOINTS.system.licensing}`);
};

/**
 * @description Sends POST Request to /api/licensing
 * @returns {Object}
 */
const sendLicensing = async (body) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.system.licensing}`,
    'POST',
    body
  );
};

function* sendLicensingKey(payload) {
  try {
    const licensingKey = yield call(sendLicensing, payload.body);
    if (licensingKey) {
      yield put(sendLicensingKeySuccess(licensingKey));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Get Response From GET /api/licensing
 * @param {Object} data
 * @returns {void}
 */
function* getLicensing(data) {
  try {
    const licensingData = yield call(fetchLicensing, data);
    if (licensingData) {
      yield put(fetchLicensingSuccess(licensingData));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Save System
 * @param {string} mail - Data for saving
 */
function* sendEmail(mail) {
  try {
    const { email } = yield call(send, mail.payload);
    if (email) {
      yield put(sendTestEmailSuccess(email));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @param {number} page
 * @param filter {Array}
 */
function* fetchSystemData({ page }) {
  try {
    yield put(clearSelection());
    const fetchedData = yield call(doFetchData, page);
    yield put(systemFetchDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Save System
 * @param {string} data - Data for saving
 */
function* saveSystem(data) {
  try {
    const system = yield call(save, data.payload);
    if (system) {
      yield put(saveSystemSuccess(system));
      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Sends GET Request to /version to get back-end version data
 * @returns {Object}
 */
const fetchApplicationVersion = async () => {
  return await RestManager.request(`${ENDPOINTS.system.version}`);
}

/**
 * @description Gets Response from GET /version
 * @param {Object} data 
 * @returns {void}
 */
function* getApplicationVersion(data) {
  try {
    const versionData = yield call(fetchApplicationVersion, data);

    if (versionData) {
      yield put(fetchApplicationVersionSuccess(versionData));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(SYSTEM_SETTINGS_SYSTEM_FETCH_DATA, fetchSystemData);
  yield takeEvery(SAVE_SYSTEM_SETTINGS_SYSTEM_REQUEST, saveSystem);
  yield takeEvery(SEND_TEST_EMAIL, sendEmail);
  yield takeEvery(FETCH_LICENSING_REQUEST, getLicensing);
  yield takeEvery(SEND_LICENSING_KEY_REQUEST, sendLicensingKey);
  yield takeEvery(FETCH_APPLICATION_VERSION_REQUEST, getApplicationVersion);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
