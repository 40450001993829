import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'styles/pages/support.less';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import {
  Card, Col, Row, Button, Form, Input, Popconfirm, Typography, Select, Upload,
} from 'antd';
import ReactPlayer from 'react-player';
import { setSubtitle as setSubtitleAction } from '@uhe_actions/SubtitleActions';
import * as SupportActions from '@uhe_actions/system/SupportActions';
import {
  getVideoList, getLoading, getSectionsWithMappedDocuments,
} from '@uhe_selectors/system/SupportSelectors';
import { YOUTUBE_URL } from '@constants/UHESettings';

import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import {
  shouldBeAbleToEditOnSupportPage,
  shouldBeAbleToViewSupportSectionsSection,
} from '@util/UheRoleChecker';

const { Title } = Typography;
const { Option } = Select;

const uploadVideoRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;
const alwaysFullWidth = {
  lg: 24, md: 24, sm: 24, xs: 24,
};

const Support = React.memo((props) => {
  const videoUploadFormRef = React.useRef();
  const supportDocumentsUploadFormRef = React.useRef();
  const addSectionFormRef = React.useRef();

  const videos = useSelector(getVideoList);
  const subtitle = useSelector((state) => state.subtitle);
  const loading = useSelector(getLoading);
  const sectionsWithMappedDocuments = useSelector(getSectionsWithMappedDocuments);

  const dispatch = useDispatch();
  const setSubtitle = (langId) => dispatch(setSubtitleAction(langId));
  const supportVideoOnFetchData = () => dispatch(SupportActions.supportVideoOnFetchData());
  const saveSupportVideo = (data) => dispatch(SupportActions.saveSupportVideo(data));
  const deleteVideo = (id) => dispatch(SupportActions.deleteVideo({ id }));
  const supportDocumentOnFetchData = () => dispatch(SupportActions.supportDocumentOnFetchData());
  const uploadDocument = (file, name, sectionId) => dispatch(SupportActions.uploadDocument(file, name, sectionId));
  const supportSectionOnFetchData = () => dispatch(SupportActions.supportSectionOnFetchData());
  const downloadPdf = (id, name) => dispatch(SupportActions.downloadPdf(id, name));
  const deleteDocument = (id) => dispatch(SupportActions.deleteDocument(id));
  const saveSupportDocument = (id, name) => dispatch(SupportActions.saveSupportDocument(id, name));
  const clearSupportState = () => dispatch(SupportActions.clearSupportState());
  const addSection = (data) => dispatch(SupportActions.addSection(data));
  const deleteSection = (id) => dispatch(SupportActions.deleteSection({ id }));

  const { intl: { formatMessage } } = props;

  useEffect(() => {
    clearSupportState();
    supportVideoOnFetchData();
    supportDocumentOnFetchData();
    supportSectionOnFetchData();

    if (subtitle && subtitle.langId !== 'sidebar.support') {
      setSubtitle('sidebar.support');
    }

    return () => clearSupportState();
  }, []);

  /**
   * @description Save new video data
   * @param {string} path Youtube path
   * @param {string} description
   * @returns {void}
   */
  const onSubmitUploadVideo = async ({ path, description }) => {
    try {
      await videoUploadFormRef.current.validateFields();
      saveSupportVideo({ youtube_path: path, video_description: description });
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @description Handles document title edit
   * @param {number} id Id of the document to edit
   * @return {function}
   */
  const onSubmitEditSupportDocument = (id) =>
    /**
     * @param {string} name New name of the document
     */
  // eslint-disable-next-line implicit-arrow-linebreak
    ({ name }) => {
      saveSupportDocument(id, name);
    };

  /**
   * @description Handles new section creation
   * @param {string} sectionTitle Name of the new section
   * @returns {Promise<void>}
   */
  const onSubmitAddSection = async ({ sectionTitle }) => {
    try {
      await addSectionFormRef.current.validateFields();
      addSection({ name: sectionTitle });
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @description Handles new document upload
   * @param {File} file PDF file document
   * @param {string} name Name of the document
   * @param {number} sectionId Section which the document will belong
   * @returns {Promise<void>}
   */
  const onSubmitNewSupportDocument = async ({ file, name, sectionId }) => {
    try {
      await supportDocumentsUploadFormRef.current.validateFields();
      uploadDocument(file, name, sectionId);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @description Render Video Upload Button
   * @returns {JSX.Element|null}
   */
  const renderVideoUploadForm = () => {
    if (!shouldBeAbleToEditOnSupportPage()) {
      return null;
    }

    return (
      <Form
        ref={videoUploadFormRef}
        className="support__videoUploadForm"
        onFinish={onSubmitUploadVideo}
      >
        <Form.Item
          name="path"
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'configuration.users.emptyField' }),
            },
            {
              pattern: uploadVideoRegex,
              message: formatMessage({ id: 'support.validVideo' }),
            },
          ]}
        >
          <Input
            placeholder={formatMessage({ id: 'support.inputURL' })}
          />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'configuration.users.emptyField' }),
            },
          ]}
        >
          <Input
            placeholder={formatMessage({ id: 'support.inputDescription' })}
          />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" className="sendButton">
            <IntlMessages id="sidebar.dataEntry.upload" />
          </Button>
        </Form.Item>
      </Form>
    );
  };

  /**
   * @description Render Delete Video Button
   * @returns {JSX.Element|null}
   */
  const renderDeleteVideoButton = (videoId) => {
    if (!shouldBeAbleToEditOnSupportPage()) {
      return null;
    }

    return (
      <Popconfirm
        title={formatMessage({ id: 'configuration.customer.confirm_delete' })}
        onConfirm={() => deleteVideo(videoId)}
      >
        <Button className="delete-icon support_delete">
          <i className="icon icon-trash" />
        </Button>
      </Popconfirm>
    );
  };

  /**
   * @description Renders videos view/delete section
   * @returns {JSX.Element}
   */
  const renderVideos = () => (
    <Row {...alwaysFullWidth} className="gx-d-flex gx-align-items-center" gutter={16}>
      <Col>
        {videos.map((video) => (
          <div key={video.id} className="videoFrame">
            <Card
              className="gx-card customer-edit-branding-card"
              loading={loading}
            >
              {renderDeleteVideoButton(video.id)}
              <ReactPlayer className="player" url={YOUTUBE_URL + video.youtube_path} />
              <p className="video-description">{video.video_description}</p>
            </Card>
          </div>
        ))}
      </Col>
    </Row>
  );

  /**
   * @description Render Upload Support Documents Form
   * @returns {JSX.Element|null}
   */
  const renderSupportDocumentsUploadForm = () => {
    if (!shouldBeAbleToEditOnSupportPage()) {
      return null;
    }
    return (
      <Form
        className="support-forms"
        ref={supportDocumentsUploadFormRef}
        onFinish={onSubmitNewSupportDocument}
      >
        <Row {...alwaysFullWidth} className="gx-d-flex gx-align-items-center" gutter={16}>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              className="doc-items required-fields"
              name="name"
              rules={[
                {
                  required: true,
                  message: formatMessage({ id: 'support.nameMessage' }),
                },
              ]}
            >
              <Input
                placeholder={formatMessage({ id: 'support.documentName' })}
              />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              name="sectionId"
              className="doc-items required-fields"
              rules={[
                {
                  required: true,
                  message: formatMessage({ id: 'support.selectName' }),
                },
              ]}
            >
              <Select
                placeholder={formatMessage({ id: 'support.sectionName' })}
                className="top-filter-popup"
              >
                {sectionsWithMappedDocuments
                  .map((d) => <Option key={d.id} value={d.id}>{d.name}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Form.Item
              name="file"
              className="doc-items"
              rules={[
                {
                  required: true,
                  message: formatMessage({ id: 'support.selectFile' }),
                },
              ]}
              getValueFromEvent={(e) => e.file.originFileObj}
            >
              <Upload
                className="upload_document"
                accept="application/pdf"
                beforeUpload={() => false}
                showUploadList={true}
              >
                <Button icon={<UploadOutlined />}>
                  &nbsp;
                  <IntlMessages id="support.selectFile" />
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col lg={4} md={4} sm={24} xs={24}>
            <Form.Item className="doc-items">
              <Button htmlType="submit" className="sendButton">
                <IntlMessages id="sidebar.dataEntry.upload" />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  /**
   * @description Render Delete Document Button
   * @returns {JSX.Element|null}
   */
  const renderDeleteDocumentButton = (documentId) => {
    if (!shouldBeAbleToEditOnSupportPage()) {
      return null;
    }
    return (
      <Popconfirm
        title={formatMessage({ id: 'configuration.customer.confirm_delete' })}
        onConfirm={() => deleteDocument(documentId)}
      >
        <Button className="support-delete">
          <IntlMessages id="common.delete" />
        </Button>
      </Popconfirm>
    );
  };

  /**
   * @description Render Add Section Button
   * @returns {JSX.Element|null}
   */
  const renderAddSectionForm = () => {
    if (!shouldBeAbleToEditOnSupportPage()) {
      return null;
    }
    return (
      <Row className="baseLineAlignedItems" gutter={16}>
        <Col {...alwaysFullWidth} className="gx-d-flex gx-align-items-center" gutter={24}>
          <Form
            className="support-forms"
            ref={addSectionFormRef}
            onFinish={onSubmitAddSection}
          >
            <Row gutter={16}>
              <Col lg={4} md={4} sm={24} xs={24}>
                <Form.Item
                  className="section-name required-fields"
                  name="sectionTitle"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({ id: 'support.nameMessage' }),
                    },
                  ]}
                >
                  <Input
                    placeholder={formatMessage({ id: 'support.sectionName' })}
                  />
                </Form.Item>
              </Col>
              <Col lg={4} md={4} sm={24} xs={24}>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="sendButton"
                  >
                    <IntlMessages id="support.add" />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  };

  /**
   * @description Renders support documents read/edit/delete section
   * @returns {JSX.Element|null}
   */
  const renderSupportDocuments = () => sectionsWithMappedDocuments.map((section) => {
    if (!section.documents?.length) {
      return null;
    }

    return (
      <Row {...alwaysFullWidth} className="gx-d-flex gx-align-items-center" gutter={16}>
        <Col {...alwaysFullWidth} key={section.id}>
          <h4
            className="section-titles"
            key={section.id}
          >
            {section.name}
          </h4>
          {section.documents.map((document) => (
            <Form onFinish={onSubmitEditSupportDocument(document.id)}>
              <Row {...alwaysFullWidth} gutter={16} key={document.id}>
                <Col lg={10} md={10} sm={10} xs={10}>
                  <Form.Item
                    className="form-item-row"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: formatMessage({ id: 'configuration.users.emptyField' }),
                      }]}
                  >
                    {shouldBeAbleToEditOnSupportPage()
                      ? (<Input className="document-link" defaultValue={document.name} />)
                      : (<span className="document-link">{document.name}</span>)}
                  </Form.Item>
                </Col>
                {shouldBeAbleToEditOnSupportPage() && (
                  <>
                    <Col lg={2} md={2} sm={24} xs={24}>
                      <Button
                        className="support-save"
                        htmlType="submit"
                      >
                        <IntlMessages id="support.save" />
                      </Button>
                    </Col>
                    <Col lg={2} md={2} sm={24} xs={24}>
                      {renderDeleteDocumentButton(document.id)}
                    </Col>
                  </>
                )}
                <Col lg={2} md={2} sm={24} xs={24}>
                  <Button
                    className="support-download"
                    onClick={() => downloadPdf(document.id, document.name)}
                  >
                    <IntlMessages id="common.download" />
                  </Button>
                </Col>
              </Row>
            </Form>
          ))}
        </Col>
      </Row>
    );
  });

  /**
   * @description Renders sections
   * @returns {JSX.Element|null}
   */
  const renderSections = () => {
    const renderSectionData = (section) => (
      <>
        <Title className="support-title" level={4}>
          {section.name}
          :
        </Title>
        <span className="support-description">
          {`${section.documents?.length} ${formatMessage({ id: 'support.associated' })}`}
        </span>
      </>
    );

    return sectionsWithMappedDocuments
      .map((section) => (
        <Row key={section.id} gutter={section.documents?.length !== 0 ? 24 : 16}>
          <Col {...alwaysFullWidth}>
            {section.documents?.length !== 0
              ? (
                <>
                  <ExclamationCircleOutlined />
                  {renderSectionData(section)}
                </>
              )
              : (
                <Popconfirm
                  title={formatMessage({ id: 'configuration.customer.confirm_delete' })}
                  onConfirm={() => deleteSection(section.id)}
                >
                  <Button className="delete-icon support_delete">
                    <i className="icon icon-trash" />
                  </Button>
                  {renderSectionData(section)}
                </Popconfirm>
              )}
          </Col>
        </Row>
      ));
  };

  return (
    <>
      {/* "Videos" Section */}
      <Card
        className="gx-card customer-edit-branding-card"
        title={<IntlMessages id="support.video" />}
        loading={loading}
      >
        {renderVideoUploadForm()}
        {renderVideos()}
      </Card>

      {/* "Support Documents: Upload/Edit" Section */}
      <Card
        className="gx-card customer-edit-branding-card"
        title={<IntlMessages id="support.documents" />}
        loading={loading}
      >
        {renderSupportDocumentsUploadForm()}
        {renderSupportDocuments()}
      </Card>

      {/* "Support sections" Section */}
      {shouldBeAbleToViewSupportSectionsSection() && (
        <Card
          className="gx-card customer-edit-branding-card"
          title={<IntlMessages id="support.sections" />}
          loading={loading}
        >
          {renderAddSectionForm()}
          {renderSections()}
        </Card>
      )}
    </>
  );
});

Support.displayName = 'Support';

Support.defaultProps = {
};

Support.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(withRouter(Support));
