import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Card, Modal, Button, Row, Col,
} from 'antd';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import {
  fetchDeviceAction,
  saveUheBedCart,
} from '@uhe_actions/configuration/bedsCarts/bedsCartsActions';
import {
  soft_reboot, hard_reboot, test_call, end_test_call, ring_doorbell,
} from '@constants/UHEDeviceActions';

const { confirm } = Modal;

/**
 * @description Renders Action Card in ManageUheBedCart file
 * @param {Object} bedCart
 * @param {Object} intl
 * @param {function} onChangeAction
 * @param {boolean} loading
 * @returns {JSX}
 */

class TechActionsCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.sendDeviceCommand = this.sendDeviceCommand.bind(this);
    this.testCall = this.testCall.bind(this);
    this.endTestCall = this.endTestCall.bind(this);
    this.ringDoorbell = this.ringDoorbell.bind(this);

    this.state = {
      command: '',
    };
  }

  /**
  * @description Confirms Action command
  * @returns {void}
  */
  confirmAction = () => {
    this.sendDeviceCommand();

    this.setState({
      command: '',
    });
  };

  /**
  * @description Clears State when Action Modal is Closed
  * @returns {void}
  */
  resetCommandState = () => {
    this.setState({
      command: '',
    });
  };

  /**
   * @description Show Confirmation Modal When Action Button Being Clicked
   * @param {string} commandToSend
   * @returns {void}
   */
  showConfirm = (commandToSend) => {
    const { intl } = this.props;
    this.setState({
      command: commandToSend,
    });
    confirm({
      title: intl.formatMessage({ id: `configuration.technician.${commandToSend}` }),
      onOk: this.confirmAction,
      onCancel: this.resetCommandState,
      autoFocusButton: 'cancel',
    });
  }

  /**
   * @description Sends Test Call command
   * * @param {string} bedCart.machine_name
     * @param {string} command
     * @param {object} params_p
   * @returns {void}
   */
  testCall() {
    const { bedCart, doFetchDeviceAction, bedCartId } = this.props;

    doFetchDeviceAction({
      machine_name: bedCart.machine_name,
      command: test_call,
      id: bedCartId,
    });
  }

  /**
   * @description Sends End Test Call command
   * * @param {string} bedCart.machine_name
     * @param {string} command
     * @param {object} params_p
   * @returns {void}
   */
  endTestCall() {
    const { bedCart, doFetchDeviceAction, bedCartId } = this.props;

    doFetchDeviceAction({
      machine_name: bedCart.machine_name,
      command: end_test_call,
      id: bedCartId,
    });
  }

  /**
   * @description Sends Play Sound command
   * * @param {string} bedCart.machine_name
     * @param {string} command
     * @param {object} params_p
   * @returns {void}
   */
  ringDoorbell() {
    const { bedCart, doFetchDeviceAction, bedCartId } = this.props;

    doFetchDeviceAction({
      machine_name: bedCart.machine_name,
      command: ring_doorbell,
      id: bedCartId,
    });
  }

  /**
   * @description Sends Device command and turns off TeamViewer
   * * @param {string} bedCart.machine_name
     * @param {string} command
     * @param {object} params_p
   * @returns {void}
   */
  sendDeviceCommand() {
    const { command } = this.state;
    const {
      bedCart, bedCartId, doFetchDeviceAction, saveUheBedCartEdit,
    } = this.props;
    bedCart.teamviewer_on = 0;

    saveUheBedCartEdit({
      body: bedCart,
      id: bedCartId,
    });

    doFetchDeviceAction({
      machine_name: bedCart.machine_name,
      command,
      id: bedCartId,
    });
  }

  render() {
    const { loading } = this.props;

    return (
      <div>
        <Card
          loading={loading}
          title={<IntlMessages id="configuration.bedsCarts.actions.title" />}
          className="gx-card"
        >
          <Row gutter={[16, 20]} className="form-item-row tech-cell">
            <Col lg={12} md={12} sm={12} xs={12}>
              <span className="gx-pl-2 tech-prop">
                <IntlMessages id="configuration.technician.testCall" />
              </span>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Button
                onClick={this.testCall}
                className="tech-button gx-ml-2"
              >
                <IntlMessages id="configuration.bedsCarts.actions.testCall" />
              </Button>
            </Col>
          </Row>
          <Row gutter={[16, 20]} className="form-item-row tech-cell">
            <Col lg={12} md={12} sm={12} xs={12}>
              <span className="gx-pl-2 tech-prop"><IntlMessages id="configuration.technician.endTestCall" /></span>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Button onClick={this.endTestCall} className="tech-button gx-ml-2">
                <IntlMessages id="configuration.bedsCarts.actions.endCall" />
              </Button>
            </Col>
          </Row>
          <Row gutter={[16, 20]} className="form-item-row tech-cell">
            <Col lg={12} md={12} sm={12} xs={12}>
              <span className="gx-pl-2 tech-prop"><IntlMessages id="configuration.technician.ringDoorbell" /></span>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Button onClick={this.ringDoorbell} className="tech-button gx-ml-2">
                <IntlMessages id="configuration.bedsCarts.actions.ringDoorbell" />
              </Button>
            </Col>
          </Row>
          <Row gutter={[16, 20]} className="form-item-row tech-cell">
            <Col lg={12} md={12} sm={12} xs={12}>
              <span className="gx-pl-2 tech-prop"><IntlMessages id="configuration.technician.softReboot" /></span>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Button onClick={() => this.showConfirm(soft_reboot)} className="tech-button gx-ml-2">
                <IntlMessages id="configuration.bedsCarts.actions.reboot" />
              </Button>
            </Col>
          </Row>
          <Row gutter={[16, 20]} className="form-item-row tech-cell">
            <Col lg={12} md={12} sm={12} xs={12}>
              <span className="gx-pl-2 tech-prop"><IntlMessages id="configuration.technician.hardReboot" /></span>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Button onClick={() => this.showConfirm(hard_reboot)} className="tech-button gx-ml-2">
                <IntlMessages id="configuration.bedsCarts.actions.reboot" />
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

TechActionsCard.propTypes = {
  bedCart: PropTypes.object,
  doFetchDeviceAction: PropTypes.func,
  fetchCallAction: PropTypes.func,
  saveUheBedCartEdit: PropTypes.func,
  intl: PropTypes.object,
  loading: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  doFetchDeviceAction: (data) => dispatch(fetchDeviceAction(data)),
  saveUheBedCartEdit: (data) => dispatch(saveUheBedCart(data)),
});

export default connect(
  null,
  mapDispatchToProps,
)(injectIntl(withRouter(TechActionsCard)));
