//ListingsTopFilterSaga
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { fetchDataSuccess, fetchPieChartDataSuccess, fetchGmapDataSuccess } from '@actions/uhe/monitoring/UHEActions';
import { standardsFetchDataSuccess } from '@actions/uhe/monitoring/StandardsActions';
import { MONITORING_UHE_FETCH_DATA, MONITORING_UHE_FETCH_PIE_CHART_DATA, MONITORING_UHE_FETCH_GMAP_DATA } from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { MONITORING_DEFAULT_SORTING_QUERY_STRING } from '@constants/UHESettings';
import { fetchError } from '@actions/Common';
import RestManager from '@util/RestManager';
import { hideSystemMessages } from '@util/UheRoleChecker';

/**
 * @param  {number} page
 * @param  {string} sorting
 * @param  {string} filter
 * @param  {string} dashboardType
 * @return {Object}
 */
const doFetchData = async (page, sorting, filter, dashboardType) => {
  const filterQueryString = filter && filter.length ? `&${filter.join('&')}` : '';
  const sortingQueryString = sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : MONITORING_DEFAULT_SORTING_QUERY_STRING;
  let endpoint;

  switch (dashboardType) {
    case "standards":
      endpoint = `${ENDPOINTS.monitoring.UHETable}?projection=standard&`;
      break;
    default:
      endpoint = `${ENDPOINTS.monitoring.UHETable}?`;
  }

  return await RestManager.request(`${endpoint}page=${page || 0}${sortingQueryString}${filterQueryString}`);
};

/**
 * @param  {array} filter
 * @return {Object}
 */
const doFetchPieChartData = async (filter) => {
  const filterQueryString = filter && filter.length ? `?${filter.join('&')}` : '';
  return await RestManager.request(`${ENDPOINTS.monitoring.UHEPieChart}${filterQueryString}`);
};

/**
 * @param  {array} params
 * @return {Object}
 */
const doFetchGmapData = async (filter) => {
  const filterQueryString = filter && filter.length ? `&${filter.join('&')}` : '';
  return await RestManager.request(`${ENDPOINTS.monitoring.UHEGoogleMap}&page=0&size=1000000${filterQueryString}`);
};

/**
 * @param {string} type
 * @param  {number} page
 * @param  {string} sorting
 * @param  {string} filter
 * @param  {string} dashboardType
 */
function* fetchTableData({ type, page, sorting, filter, dashboardType }) {
  try {
    const fetchedData = yield call(
      doFetchData,
      page,
      sorting,
      filter,
      dashboardType
    );
    switch (dashboardType) {
      case "standards":
        yield put(standardsFetchDataSuccess(fetchedData));
        break;

      default:
        yield put(fetchDataSuccess(fetchedData));
        break;
    }
  } catch (error) {
    const roles = (state) => state.ConfigurationUsers.ownUser;
    const loggedUser = yield select(roles);
    const hideErrorMessage = hideSystemMessages(loggedUser.isUserAdmin);
    if (!hideErrorMessage) {
      yield put(fetchError(error));
    }
  }
}

/**
 * @param {string} type
 * @param  {string} filter
 */
function* fetchPieChartData({ type, filter }) {
  try {
    const fetchedData = yield call(doFetchPieChartData, filter);
    yield put(fetchPieChartDataSuccess(fetchedData));
  } catch (error) {
    const roles = (state) => state.ConfigurationUsers.ownUser;
    const loggedUser = yield select(roles);
    const hideErrorMessage = hideSystemMessages(loggedUser.isUserAdmin);
    if (!hideErrorMessage) {
      yield put(fetchError(error));
    }
  }
}

/**
 * @param {string} type
 * @param  {string} filter
 */
function* fetchGmapData({ filter }) {
  try {
    const fetchedData = yield call(doFetchGmapData, filter);
    yield put(fetchGmapDataSuccess(fetchedData));
  } catch (error) {
    const roles = (state) => state.ConfigurationUsers.ownUser;
    const loggedUser = yield select(roles);
    const hideErrorMessage = hideSystemMessages(loggedUser.isUserAdmin);
    if (!hideErrorMessage) {
      yield put(fetchError(error));
    }
  }
}

function* fetchDataProxy(data) {
  switch (data.type) {
    case MONITORING_UHE_FETCH_PIE_CHART_DATA:
      yield call(fetchPieChartData, data);
      break;
    case MONITORING_UHE_FETCH_GMAP_DATA:
      yield call(fetchGmapData, data);
      break;
    default:
      yield call(fetchTableData, data);
  }

}

export function* fetchData() {
  yield takeEvery([MONITORING_UHE_FETCH_DATA, MONITORING_UHE_FETCH_PIE_CHART_DATA, MONITORING_UHE_FETCH_GMAP_DATA], fetchDataProxy);
}

export default function* rootSaga() {
  yield all([fork(fetchData)]);
}
