import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Popconfirm,
  Modal,
  Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import lodash from 'lodash';

import IntlMessages from 'util/IntlMessages';
import ListingsTopFilter from '@filters/ListingsTopFilter';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import { onGetOptions } from '@uhe_actions/filters/ListingsTopFilterActions';

import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import FacilityCell from '@components/tables/cells/FacilityCell';
import UnitCell from '@components/tables/cells/UnitCell';
import UheUnitCell from '@components/tables/cells/UheUnitCell';
import ActionsCell from '@components/tables/cells/ActionsCell';
import EditHeader from '@components/uhe/configuration/EditHeader';
import { bindActionCreators } from 'redux';

import {
  customersOnFetchDevicesData,
  fetchCustomer,
  pendingActionsData,
  cleanUpSelectedCustomer,
  deletePendingAction,
} from '@uhe_actions/configuration/customers/CustomersActions';
import * as bulkActions from '@uhe_actions/configuration/customers/CustomersActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import {
  LISTING_TABLES_PAGE_SIZE,
  TOP_FILTER_PREFIX,
  TABLE_FILTER_PREFIX,
  MAP_FILTER_PREFIX,
  APP_PAGES_CONTEXT,
  UPDATE_CERTIFICATE,
} from '@constants/UHESettings';
import { goBackTo } from '@util/UheHelper';
import { withLastLocation } from 'react-router-last-location';
import UpdateNetworkCertificateModal from '@components/uhe/configuration/customer/UpdateNetworkCertificateModal';

const { confirm } = Modal;

/**
 * @description Renders UHE page
 */
class BulkActions extends React.Component {
  /**
   * Adapt data returned from the server
   *
   * @param  {Array<Object>} data
   * @return {Array<Object>}
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        organization: {
          id: value.organization_id,
          name: value.organization_name,
        },
        customer: {
          id: value.customer_id,
          organizationId: value.organization_id,
          name: value.customer_name,
        },
        facility: {
          id: value.facility_id,
          organizationId: value.organization_id,
          customerId: value.customer_id,
          name: value.facility_name,
        },
        unit: {
          id: value.unit_id,
          organizationId: value.organization_id,
          customerId: value.customer_id,
          facilityId: value.facility_id,
          name: value.unit_name,
        },
        bedCart: {
          id: value.device_id,
          name: value.cart_name,
          customerId: value.customer_id,
        },
        type: value.type,
      });
    });
    return adaptedData;
  }

  /**
   * Adapt data returned from the server
   *
   * @param  {Array<Object>} data
   * @return {Array<Object>}
   */
  static pendingActionsDataAdapter(pendingActionData = []) {
    const adaptedData = [];
    if (pendingActionData) {
      pendingActionData.forEach((value, index) => {
        adaptedData.push({
          key: index,
          device: value.name,
          command: value.command_name,
          actions: {
            id: value.id,
          },
        });
      });
    }
    return adaptedData;
  }

  /**
   * BulkActions Constructor
   * @param {object} props Props
   */
  constructor(props) {
    super(props);
    this.onPageChange = this.onPageChange.bind(this);
    this.onPendingActionsChange = this.onPendingActionsChange.bind(this);
    const { intl, history } = this.props;

    this.topFilterMap = {
      [`${TOP_FILTER_PREFIX}customer`]: 'customer_id',
      [`${TOP_FILTER_PREFIX}facility`]: 'facility_id',
      [`${TOP_FILTER_PREFIX}unit`]: 'unit_id',
      [`${TOP_FILTER_PREFIX}bedCart`]: 'device_id',
    };

    this.tableFilterMap = {
      facility: 'facility_name',
      unit: 'unit_name',
      bedCart: 'cart_name',
      type: 'type',
    };

    this.tablePendingActions = {
      device: 'device',
      command: 'command',
      actions: 'actions',
    };

    this.state = {
      selectedRowKeys: [],
      customer: {
        name: '',
        id: null,
      },
      customerName: '',
      data: [],
      topFilters: [],
      crtContent: '',
      pemContent: '',
      showModal: false,
    };

    this.data = [];
    this.pendingActionData = [];
    this.columns = [];
    this.actionColumns = [];
    this.tableKeys = Object.keys(this.tableFilterMap);
    this.tableActionKeys = Object.keys(this.tablePendingActions);
    this.intl = this.props.intl;

    this.tableKeys.forEach((value, index) => {
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            triggerCharsNum={value === 'deviceId' || value === 'version' ? 0 : undefined}
          />
        ),
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.tableActionKeys.forEach((value, index) => {
      this.actionColumns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            triggerCharsNum={value === 'deviceId' || value === 'version' ? 0 : undefined}
            showFilter={
              !(
                value === 'actions'
                || value === 'device'
                || value === 'command'
              )
}
          />
        ),
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);

    this.props.setSubtitle('common.bulk');

    this.onPageChange(1);
    this.onPendingActionsChange(1);
  }

  componentDidMount() {
    const urlId = this.props.match.params.id;
    this.props.fetchCustomer({ id: urlId });
  }

  componentWillUnmount() {
    this.props.cleanUpSelectedCustomer();
  }

  /**
   * @description Updates the component on location change
   * @param {Object} prevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const {
      customer,
      location,
      error,
      history,
    } = this.props;
    const urlId = this.props.match.params.id;
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }

    if (prevProps.customer && customer) {
      if ((prevProps.customer !== customer || prevProps.customer.id !== customer.id)) {
        this.setState({ customer });
      }

      if (!prevProps.customer.id && this.props.customer.id && this.props.customer.organization.id) {
        this.buldTopFilters();
      }
    }
  }

  buldTopFilters = () => {
    this.setState({
      topFilters: [
        {
          placeholder: 'uhe.listingsTopFilter.inputLabels.byOrganization',
          fieldNames: { label: 'name', value: 'id' },
          showSearch: true,
          key: 'organization',
          disabled: true,
        },
        {
          placeholder: 'uhe.listingsTopFilter.inputLabels.byCustomer',
          fieldNames: { label: 'name', value: 'id' },
          showSearch: true,
          key: 'customer',
          disabled: true,
        },
        {
          placeholder: 'uhe.listingsTopFilter.inputLabels.byFacility',
          fieldNames: { label: 'name', value: 'id' },
          showSearch: true,
          key: 'facility',
        },
        {
          placeholder: 'uhe.listingsTopFilter.inputLabels.byUnit',
          fieldNames: { label: 'name', value: 'id' },
          showSearch: true,
          key: 'unit',
        },
        {
          placeholder: 'uhe.listingsTopFilter.inputLabels.byBedcart',
          fieldNames: { label: 'cart_name', value: 'device_id' },
          showSearch: true,
          key: 'bedCart',
        },
      ],
    }, () => {
      const params = new URLSearchParams();
      params.set(`${TOP_FILTER_PREFIX}organization`, this.props.customer.organization.id);
      params.set(`${TOP_FILTER_PREFIX}customer`, this.props.customer.id);
      const search = params.toString();
      this.props.history.push({ search });
    });
  }

  /**
   * @param  {number} page
   * @return {void}
   */
  onPageChange(page) {
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const sort = [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);
      const sorter = currSort.find((element) => element === `${key},asc` || element === `${key},desc`);

      if (filterParam) {
        if (!isNaN(filterParam)) {
          filter.push(`${value}=${encodeURIComponent(Number(filterParam))}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }

      if (sorter) {
        sort.push(sorter.replace(key, value));
      }
    });

    lodash.forOwn(this.locationFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${MAP_FILTER_PREFIX}${key}`);

      if (filterParam) {
        filter.push(`${value}=${encodeURIComponent(filterParam)}`);
      }
    });

    this.filter = filter;

    this.props.customersOnFetchDevicesData(page - 1, sort, filter, this.props.match.params.id);
  }

  onPendingActionsChange(page) {
    this.currentActionPage = page - 1;
    this.props.pendingActionsData(this.currentActionPage, null, null, this.props.match.params.id);
  }

  /**
   * Invokes Pending/Bulk Actions on Action Button Click
   * @param {string} action Command Action
   * @param {number} page Page Number
   * @param {string} sort Sort
   * @param {string} filter Filter
   * @returns {void}
   */
  onActionClick(action, page, sort, filter) {
    const { selectedRowKeys, crtContent, pemContent } = this.state;
    const {
      data, actions, pendingActionsData, match, customer,
    } = this.props;

    if (selectedRowKeys.length === data.length) {
      actions.addBulkAction({
        command: action, key: 'select_all', value: true, id: customer.id,
      });
      pendingActionsData(page, sort, filter, match.params.id);
    } else {
      const deviceIdsArr = [];

      selectedRowKeys.forEach((key) => {
        const currentDevice = data[key];

        if (currentDevice) {
          deviceIdsArr.push(currentDevice.device_id);
        }
      });
      if (action === UPDATE_CERTIFICATE) {
        actions.addBulkAction({
          command: action,
          key: 'endpoints',
          value: deviceIdsArr,
          id: customer.id,
          cert: crtContent,
          pem: pemContent,
          isUpdateNetworkCert: true,
        });
      } else {
        actions.addBulkAction({
          command: action,
          key: 'endpoints',
          value: deviceIdsArr,
          id: customer.id,
        });
      }
    }
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  /**
   * Use different cell type depending on the column
   * @param  {Object} content Data for Each Cell
   * @param  {string} key Column Name
   * @return {ReactElement|Object} Table Cell || Object
   */
  cellRenderer(content, key) {
    const { intl, match, deletePendingAction } = this.props;
    const urlId = match.params.id;
    let cell;

    switch (key) {
      case 'organization':
        cell = <OrganizationCell content={content} />;
        break;
      case 'customer':
        cell = <CustomerCell content={content} />;
        break;
      case 'facility':
        cell = <FacilityCell content={content} />;
        break;
      case 'unit':
        cell = <UnitCell content={content} />;
        break;
      case 'bedCart':
        cell = <UheUnitCell content={content} />;
        break;
      case 'actions':
        cell = (
          <ActionsCell
            page={APP_PAGES_CONTEXT.bulkActions}
            intl={intl}
            content={content}
            deleteAction={() => {
              deletePendingAction(
                urlId,
                content.id,
                this.currentPage,
                this.sort,
                this.filter,
              );
            }}
          />
        );
        break;
      default:
        cell = content;
    }

    return cell;
  }

  /**
   * Renders #x Commands Waiting To Run Text
   * @returns {JSX.Element} div Element with Text
   */
  pendingTableTitle() {
    const { actionPagination } = this.props;
    const { total } = actionPagination;
    const startMsg = this.intl.formatMessage({ id: 'common.waitingCommands' });

    return (
      <div>
        {total}
        {' '}
        {startMsg}
      </div>
    );
  }

  /**
   * Render back buttons
   * @returns {JSX} EditHeader Component
   */
  renderHeadLine() {
    const { loading, lastLocation, history } = this.props;
    return (
      <EditHeader
        // onClick={() => window.history.back()}
        backToPreviousPage={() => window.history.go(-2)}
        goBack={goBackTo('/configuration/customers', lastLocation?.pathname, history)}
        loading={loading}
      />
    );
  }

  /**
   * Refresh pending actions
   * @returns {void}
   */
  onButtonRefresh() {
    const { pendingActionsData, match } = this.props;

    pendingActionsData(this.currentActionPage === undefined
      ? 0
      : this.currentActionPage, null, null, match.params.id);
  }

  /**
   * Renders Action Buttons
   * @param {string} actionName Action Name
   * @returns {JSX.Element} Action Button
   */
  renderActionButton = (actionName) => {
    const { customer: { id }, intl: { formatMessage } } = this.props;
    const splittedActionName = actionName.split('_');
    const camelCaseActionName = [...splittedActionName].shift() + splittedActionName.slice(1)
      .map((char) => char[0].toUpperCase() + char.slice(1));
    return (
      <Popconfirm
        title={formatMessage({ id: 'common.areYouSureThat' })
        + formatMessage({ id: `configuration.bedsCarts.actions.${camelCaseActionName}` })
        + formatMessage({ id: 'common.questionMark' })}
        onConfirm={() => this.onActionClick(actionName)}
      >
        <Button
          htmlType="submit"
          className="my-btn"
          disabled={!id}
        >
          <IntlMessages id={`configuration.bedsCarts.actions.${camelCaseActionName}`} />
        </Button>
      </Popconfirm>
    );
  };

  /**
   * Gets Certificate File Content
   * @param {object} file File Data
   * @param {string} key Certificate
   * @returns {boolean} Prevents File Upload
   */
  getCertContent = (key) => (file) => {
    const reader = new FileReader();

    reader.readAsText(file);
    reader.onload = (event) => this.setState({ [key]: event.target.result });

    return false;
  }

  /**
   * Shows Update Network Confirmation Modal
   * @returns {void}
   */
  showConfirmationModal = () => {
    confirm({
      title: this.intl.formatMessage({ id: 'configuration.bedsCarts.actions.updateCertificateConfirmation' }),
      onOk: () => {
        this.onActionClick('update_wifi_cert');
        this.setState({
          crtContent: '',
          pemContent: '',
        });
      },
      okText: this.intl.formatMessage({ id: 'common.confirm' }),
      autoFocusButton: 'cancel',
    });
  }

  /**
   * Validates Update Network Modal
   * @returns {void}
   */
  validateUpdateNetworkCert = () => {
    const { pemContent, crtContent } = this.state;

    if (!!pemContent && !!crtContent) {
      this.setState({ showModal: false });
      this.showConfirmationModal();
    }
  }

  /**
   * Checks if Update Network Certificate Button Should be Enabled or Disabled
   * @returns {boolean} true || false
   */
  isUpdateWifiBtnDisabled = () => {
    const { customer } = this.props;
    const remoteWifiCertificate = customer?.attributes?.filter((item) => item.name === 'remote_update_network_certificate_enabled');

    if (remoteWifiCertificate) {
      if (remoteWifiCertificate[0]?.value === 'TRUE') {
        return false;
      }
      return true;
    }
  }

  /**
   * Renders BulkActions Component
   * @returns {JSX.Element} BulkActions Component
   */
  render() {
    const {
      pagination, actionPagination, data, pendingActionData, loading,
    } = this.props;
    pagination.onChange = this.onPageChange;
    actionPagination.onChange = this.onPendingActionsChange;
    const {
      selectedRowKeys, topFilters, customer, showModal,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    this.columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          type: col.type,
        }),
      };
    });

    return (
      <div className="dashboard bulk-action-page">
        <div className="manage-customer-wrapper">
          {this.renderHeadLine()}
        </div>
        <Card
          className="filter-boxes gx-card"
          title={this.pendingTableTitle()}
        >
          <Col lg={6} md={6} sm={6} sx={6}>
            <Button
              htmlType="submit"
              className="my-btn refresh-btn"
              onClick={() => { this.onButtonRefresh(); }}
            >
              <IntlMessages id="common.refresh" />
            </Button>
          </Col>
          <div className="uhe-table">
            <Table
              bordered
              className="gx-table-responsive"
              columns={this.actionColumns}
              dataSource={BulkActions.pendingActionsDataAdapter(pendingActionData)}
              onChange={this.handleTableChange}
              loading={loading}
              pagination={actionPagination}
            />
          </div>
        </Card>
        <Card
          className="filter-boxes gx-card"
          title={<IntlMessages id="uhe.title.filters" />}
        >
          { this.state.topFilters.length > 0
              && (
              <Row lg={24} md={24} sm={24} sx={24} gutter={16}>
                <Col lg={5} md={5} sm={5} sx={5} className="bulk-action-rows">
                  <Row lg={24} md={24} sm={24} sx={24} gutter={16} className="bulk-action-rows">
                    <Col lg={11} md={11} sm={11} sx={11} className="bulk-action-rows">
                      <IntlMessages id="common.organizationText" />
                    </Col>
                    <Col lg={13} md={13} sm={13} sx={13} className="bulk-action-rows">
                      {customer.organization ? customer.organization.name : ''}
                    </Col>
                  </Row>
                </Col>
                <Col lg={5} md={5} sm={5} sx={5} className="bulk-action-rows">
                  <Row lg={24} md={24} sm={24} sx={24} gutter={16} className="bulk-action-rows">
                    <Col lg={11} md={11} sm={11} sx={11} className="bulk-action-rows">
                      <IntlMessages id="common.customerText" />
                    </Col>
                    <Col lg={13} md={13} sm={13} sx={13} className="bulk-action-rows">
                      {customer ? customer.name : ''}
                    </Col>
                  </Row>
                </Col>
                <Col lg={14} md={14} sm={14} sx={14}>
                  <ListingsTopFilter filters={topFilters} />
                </Col>
              </Row>
              ) }
        </Card>
        <div>
          <div className="gx-d-flex gx-justify-content-end">
            <p>
              <IntlMessages id="uhe.table.matchingResults" />
              <span>{pagination.total}</span>
            </p>
          </div>
          <Card
            title={<IntlMessages id="common.deviceTable" />}
          >
            <div className="uhe-table">
              <Table
                bordered
                className="gx-table-responsive"
                columns={this.columns}
                dataSource={BulkActions.dataAdapter(data)}
                onChange={this.handleTableChange}
                pagination={pagination}
                rowClassName={() => 'editable-row'}
                rowSelection={rowSelection}
                loading={loading}
              />
            </div>
            <div className="ant-table-wrapper gx-table-responsive gx-mt-3">
              <Row lg={24} md={24} sm={24} sx={24} gutter={16}>
                <Col lg={6} md={6} sm={8} sx={8}>
                  {this.renderActionButton('soft_reboot')}
                </Col>
                <Col lg={6} md={6} sm={8} sx={8}>
                  {this.renderActionButton('hard_reboot')}
                </Col>
                <Col lg={6} md={6} sm={8} sx={8}>
                  {this.renderActionButton('update_version')}
                </Col>
                <Col lg={6} md={6} sm={8} sx={8}>
                  {showModal && (
                    <UpdateNetworkCertificateModal
                      visible={showModal}
                      onOk={this.validateUpdateNetworkCert}
                      getCertContent={this.getCertContent}
                      onCancel={() => this.setState({
                        showModal: false,
                        crtContent: '',
                        pemContent: '',
                      })}
                    />
                  )}
                  <Button
                    className="my-btn"
                    onClick={() => this.setState({ showModal: true })}
                    disabled={this.isUpdateWifiBtnDisabled()}
                  >
                    <IntlMessages id="configuration.bedsCarts.actions.updateNetworkCertificate" />
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

BulkActions.defaultProps = {
  data: [],
  loading: true,
  pagination: {
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
};

BulkActions.propTypes = {
  data: PropTypes.array,
  pendingActionData: PropTypes.array,
  loading: PropTypes.bool,
  pagination: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  intl: PropTypes.object,
  latestVersion: PropTypes.number,
  customersOnFetchDevicesData: PropTypes.func,
  pendingActionsData: PropTypes.func,
  setSubtitle: PropTypes.func,
  customer: PropTypes.object,
  lastLocation: PropTypes.object.isRequired,
};

/**
 * Maps State to Props
 * @param {object} param Desctructed Store Objects
 * @returns {object} Props
 */
const mapStateToProps = ({
  ConfigurationCustomers, subtitle, SystemSettingsSystem, common,
}) => {
  const { list = [], page = {} } = ConfigurationCustomers.table || {};
  const actionsTableData = ConfigurationCustomers.actionsTable || {};

  const { loading } = ConfigurationCustomers;
  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };
  const actionPagination = {
    total: actionsTableData.page.totalElements || 0,
    current: actionsTableData.page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  const { production_version = {} } = SystemSettingsSystem.data;
  const latestVersion = parseFloat(production_version.value) || null;

  return {
    data: list,
    pendingActionData: actionsTableData.list,
    pagination,
    actionPagination,
    loading,
    subtitle,
    latestVersion,
    customer: ConfigurationCustomers.selectedCustomer,
    error: common.error,
  };
};

/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Actions to Props
 * @returns {object} Actions
 */
const mapDispatchToProps = (dispatch) => ({
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  customersOnFetchDevicesData: (page, sort, filter, id) => dispatch(customersOnFetchDevicesData(page, sort, filter, id)),
  pendingActionsData: (page, sort, filter, id) => dispatch(pendingActionsData(page, sort, filter, id)),
  actions: bindActionCreators(bulkActions, dispatch),
  fetchCustomer: (id) => dispatch(fetchCustomer(id)),
  cleanUpSelectedCustomer: () => dispatch(cleanUpSelectedCustomer()),
  deletePendingAction: (orgID, id, page, sorting, filter) => dispatch(deletePendingAction({
    orgID, id, page, sorting, filter,
  })),
  onGetFacilityOptions: (id) => dispatch(onGetOptions('facility', id)),
  onGetUnitOptions: (id) => dispatch(onGetOptions('unit', id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(injectIntl(withRouter(BulkActions))));
