export const BASE_URL = '/api';
export const ENDPOINTS = {
  topFilter: {
    organization: '/organizations?projection=base',
    customer: '/customers?projection=base',
    facility: '/facilities?projection=base',
    unit: '/units?projection=base',
    bedCart: '/endpoints?projection=base',
  },
  monitoring: {
    UHETable: '/endpoints',
    UHEPieChart: '/endpoints/summary',
    UHEGoogleMap: '/endpoints?projection=geo',
  },
  configuration: {
    CustomersTable: '/customers',
    DeleteCustomer: '/customers',
    DeleteFacility: '/facilities',
    FacilitiesTable: '/facilities',
    UnitsTable: '/units',
    DeleteUnits: '/units',
    OrganizationsTable: '/organizations',
    UsersTable: '/users',
    UsersEditTable: '/users',
    DeleteUser: '/users',
  },
  organization: {
    fetchById: '/organizations',
    saveOrganization: '/organizations',
    fetchAll: '/organizations',
  },
  unit: {
    saveUnit: '/units', // add and update
    deleteUnit: '/units', // delete
  },
  customer: {
    fetchById: '/customers',
    saveCustomer: '/customers',
    interpreter: '/interpreter',
    interpreters: '/interpreters',
  },
  facility: {
    fetchAll: '/facilities',
  },
  users: {
    saveUser: '/users',
    fetchAll: '/users',
    uploadUser: '/users/bulk/upload/',
    uploadMobilePatients: '/users/bulk/upload/mobile',
    ownProfile: '/authentication/principal',
    activateUser: '/authentication/invitation/accept',
    uploadLogUsers: '/users/bulk/upload/log?users=true',
    uploadLogMobileDevices: '/users/bulk/upload/log?users=false',
    uploadLogDetailedReport: '/users/bulk/upload/log',
  },
  mobilePatients: {
    fetchData: '/users?is_mobile_user=1&',
    addMobilePatient: '/users',
  },
  system: {
    SystemData: '/configurables',
    SaveSystemData: '/configurables', // save
    TestEmail: '/configurables/mail/test', // send test email
    licensing: '/licensing',
    version: '/version',
  },
  bedCart: {
    nonUhe: '/endpoints?projection=standard',
    nonUheCsv: '/endpoints/csv?projection=standard',
  },
  bedsCarts: {
    deviceTypes: '/endpoints-types/filter',
    technician: '/technician/endpoint/',
    qrCodeMain: '/qr-code',
    qrCodeOne: '/one',
    qrCodeTwo: '/two',
    images: '/components/images',
    components: '/components/',
  },
  reports: {
    signInReports: '/sign_in_report',
    vmr: '/vmr_report',
  },
  iObserver: {
    controllerSettings: '/configurables',
    saveIobserver: '/configurables',
    defaultTurnServer: '/turn-servers/default',
    saveDefaultTurnServer: '/turn-servers/default',
    deleteDefaultTurnServer: '/turn-servers/default',
    customerTurnServer: '/turn-servers/customers',
    saveCustomerTurnServer: '/turn-servers/customers',
    deleteCustomerTurnServer: '/turn-servers/customers',
  },
  support: {
    videoSupport: '/support/videos',
    saveVideo: '/support/videos',
    deleteVideo: '/support/videos',
    documentSupport: '/support',
    sectionSupport: '/support/sections',
    download: (id) => `/support/${id}/pdf`,
    deleteDocument: '/support',
    savedocument: '/support',
    addSection: '/support/sections',
    deleteSection: '/support/sections',
  },
  developer: {
    developersTable: '/developers',
  },
  notifications: {
    systemAlertEmail: '/system-alert-email',
    systemAlertEmailGlobal: '/system-alert-email/global',
  },
  ExportCsv: {
    organizations: '/organizations/csv?',
    customers: '/customers/csv?',
    facilities: '/facilities/csv?',
    units: '/units/csv?',
    uhe: '/endpoints/csv?',
    nonUhe: '/endpoints/csv?projection=standard&',
    users: '/users/csv?',
    mobilePatients: '/users/mobile/csv?',
  },
  ssoProviders: {
    providers: '/providers',
  },
  password: {
    resetPassword: '/users',
  },
  resetPassword: '/password/change',
  observation: '/observation',
  command: '/command/',
  machineCommand: '/send_command_by_machine_name',
};
