import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Row, Col, Button,
} from 'antd';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import {
  fetchDeviceAction,
  saveUheBedCart,
} from '@uhe_actions/configuration/bedsCarts/bedsCartsActions';
import {
  update_version,
  mac_refresh,
} from '@constants/UHEDeviceActions';
import moment from 'moment-timezone';

/**
 * StatusReport Class Component
 * @returns {jsx} dasd
 */
class StatusReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  /**
   * Renders Left Part of the StatusReport
   * @returns {JSX.Element} Left Part of the StatusReport
   */
  deviceStatus = () => {
    const { bedCart, intl } = this.props;
    const {
      color_alert_button, color_camera, color_camera_controls, color_online, color_version,
    } = bedCart;

    if (bedCart.endpointStatus) {
      return (
        <div>
          <Row lg={24} md={24} className="status-items-row status-online">
            <Col lg={17} md={24}>
              <IntlMessages id="configuration.bedsCarts.statusReport.online" />
              {' '}
              :
            </Col>
            <Col lg={3} md={24}>
              <div
                className={`status-icon-${color_online}`}
              />
            </Col>
          </Row>
          <Row lg={24} md={24} className="status-items-row status-online">
            <Col lg={17} md={24}>
              <IntlMessages id="configuration.bedsCarts.statusReport.camera" />
              {' '}
              :
            </Col>
            <Col lg={3} md={24}>
              <div
                className={`status-icon-${color_camera}`}
              />
            </Col>
          </Row>
          <Row lg={24} md={24} className="status-items-row status-online">
            <Col lg={17} md={24}>
              <IntlMessages id="configuration.bedsCarts.statusReport.cameraControls" />
              {' '}
              :
            </Col>
            <Col lg={3} md={24}>
              <div
                className={`status-icon-${color_camera_controls}`}
              />
            </Col>
          </Row>
          <Row lg={24} md={24} className="status-items-row status-online">
            <Col lg={17} md={24}>
              <IntlMessages id="configuration.bedsCarts.statusReport.alertButton" />
              {' '}
              :
            </Col>
            <Col lg={3} md={24}>
              <div
                className={`status-icon-${color_alert_button}`}
              />
            </Col>
          </Row>
          <Row lg={24} md={24} className="status-items-row status-online">
            <Col lg={17} md={24}>
              <IntlMessages id="configuration.bedsCarts.statusReport.version" />
              {' '}
              :
            </Col>
            <Col lg={3} md={24}>
              <div
                className={`status-icon-${color_version}`}
              />
            </Col>
          </Row>
        </div>
      );
    }
    return (
      <div>
        <Row lg={24} md={24} className="status-items-row status-online">
          <Col lg={17} md={24}>
            <IntlMessages id="configuration.bedsCarts.statusReport.online" />
            {' '}
            :
          </Col>
          <Col lg={3} md={24}>
            <div
              className={`status-icon-${color_alert_button}`}
              title={intl.formatMessage({
                id: 'common.offline',
              })}
            />
          </Col>
        </Row>
        <Row lg={24} md={24} className="status-items-row status-online">
          <Col lg={17} md={24}>
            <IntlMessages id="configuration.bedsCarts.statusReport.camera" />
            {' '}
            :
          </Col>
          <Col lg={3} md={24}>
            <div
              className={`status-icon-${color_alert_button}`}
              title={intl.formatMessage({
                id: 'common.offline',
              })}
            />
          </Col>
        </Row>
        <Row lg={24} md={24} className="status-items-row status-online">
          <Col lg={17} md={24}>
            <IntlMessages
              id="configuration.bedsCarts.statusReport.cameraControls"
            />
            {' '}
            :
          </Col>
          <Col lg={3} md={24}>
            <div
              className={`status-icon-${color_alert_button}`}
              title={intl.formatMessage({
                id: 'common.offline',
              })}
            />
          </Col>
        </Row>
        <Row lg={24} md={24} className="status-items-row status-online">
          <Col lg={17} md={24}>
            <IntlMessages
              id="configuration.bedsCarts.statusReport.alertButton"
            />
            {' '}
            :
          </Col>
          <Col lg={3} md={24}>
            <div
              className={`status-icon-${color_alert_button}`}
              title={intl.formatMessage({
                id: 'common.offline',
              })}
            />
          </Col>
        </Row>
        <Row lg={24} md={24} className="status-items-row status-online">
          <Col lg={17} md={24}>
            <IntlMessages
              id="configuration.bedsCarts.statusReport.version"
            />
            {' '}
            :
          </Col>
          <Col lg={3} md={24}>
            <div
              className={`status-icon-${color_alert_button}`}
              title={intl.formatMessage({
                id: 'common.offline',
              })}
            />
          </Col>
        </Row>
      </div>
    );
  };

  update() {
    const {
      bedCart, doFetchDeviceAction, saveUheBedCartEdit, match,
    } = this.props;

    saveUheBedCartEdit({
      body: bedCart,
      id: match.params.id,
    });

    doFetchDeviceAction({
      machine_name: bedCart.machine_name,
      command: update_version,
      id: match.params.id
    });
  }

  refresh() {
    const {
      bedCart, doFetchDeviceAction, saveUheBedCartEdit, match,
    } = this.props;

    saveUheBedCartEdit({
      body: bedCart,
      id: match.params.id,
    });

    doFetchDeviceAction({
      machine_name: bedCart.machine_name,
      command: mac_refresh,
      id: match.params.id,
    });
  }

  /**
   * Shows Date and Time at Which is Created CAR System Device
   * @returns {string} Converted Date
   */
  convertToCurrentTimezone() {
    const { bedCart, timeZone } = this.props;

    if (!bedCart.endpointStatus) {
      return <IntlMessages id="common.null" />;
    }

    const timeStamp = bedCart.endpointStatus.created_at;
    const date = timeZone
      ? moment(timeStamp).tz(timeZone).format('YYYY-MM-DD hh:mm:ss z')
      : moment(timeStamp).format('YYYY-MM-DD hh:mm:ss z');

    return date;
  }

  /**
   * Renders StatusReport Component
   * @returns {JSX.Element} StatusReport Component
   */
  render() {
    const {
      bedCart, match, latestVersion,
    } = this.props;

    return (
      <div>
        <Row className="main-row">
          <Col lg={7} md={24}>
            {this.deviceStatus()}
          </Col>
          <Col lg={15} md={24}>
            <Row className="status-items-row status-online">
              <Col lg={8} md={24}>
                <IntlMessages id="configuration.bedsCarts.statusReport.deviceId" />
                {' '}
                :
              </Col>
              <Col lg={7} md={24}>
                <div>{match.params.id}</div>
              </Col>
            </Row>
            <Row className="status-items-row status-online">
              <Col lg={8} md={24}>
                <IntlMessages id="configuration.bedsCarts.statusReport.InternalIp" />
                {' '}
                :
              </Col>
              <Col lg={7} md={24}>
                <div>{bedCart.internal_ip ? bedCart.internal_ip : 'N/A'}</div>
              </Col>
            </Row>
            <Row className="status-items-row status-online">
              <Col lg={8} md={24}>
                <IntlMessages id="configuration.bedsCarts.statusReport.systemVersion" />
                {' '}
                :
              </Col>
              <Col lg={7} md={24}>
                <div>{latestVersion}</div>
              </Col>
            </Row>
            <Row className="status-items-row status-online">
              <Col lg={8} md={24}>
                <IntlMessages id="configuration.bedsCarts.statusReport.macAddress" />
                {' '}
                :
              </Col>
              <Col lg={13} md={24}>
                <div className="fixed-value-width">
                  {bedCart.endpointStatus
                    ? bedCart.endpointStatus.machine_id
                    : 'N/A'}
                </div>
                <Button onClick={this.refresh} className="save-icon header-btn act-btn"><IntlMessages id="common.refresh" /></Button>
              </Col>
              <Col lg={6} md={24} />
            </Row>
            <Row className="status-items-row status-online">
              <Col lg={8} md={24}>
                <IntlMessages id="configuration.bedsCarts.statusReport.deviceVersion" />
                {' '}
                :
              </Col>
              <Col lg={13} md={24}>
                <div className="fixed-value-width">
                  {bedCart.endpointStatus ? bedCart.endpointStatus.version : 'N/A'}
                </div>
                <Button onClick={this.update} className="save-icon header-btn act-btn"><IntlMessages id="configuration.users.updatePassword" /></Button>
              </Col>
              <Col lg={6} md={24} />
            </Row>
            <Row className="status-items-row status-online">
              <Col lg={8} md={24}>
                <IntlMessages id="configuration.bedsCarts.statusReport.firstCameOnline" />
                {' '}
                :
              </Col>
              <Col lg={16} md={24}>
                <div>
                  {this.convertToCurrentTimezone()}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

StatusReport.defaultProps = {
  match: {},
};

StatusReport.propTypes = {
  bedCart: PropTypes.shape().isRequired,
  match: PropTypes.shape(),
  intl: PropTypes.shape().isRequired,
  latestVersion: PropTypes.number.isRequired,
  doFetchDeviceAction: PropTypes.func.isRequired,
  saveUheBedCartEdit: PropTypes.func.isRequired,
};

/**
 * Maps Actions to the Props
 * @param {func} dispatch dispatch function
 * @returns {object} Actions
 */
const mapDispatchToProps = (dispatch) => ({
  doFetchDeviceAction: (command, machine, param) => dispatch(fetchDeviceAction(command, machine, param)),
  saveUheBedCartEdit: (data) => dispatch(saveUheBedCart(data)),
});

export default connect(
  null,
  mapDispatchToProps,
)(injectIntl(withRouter(StatusReport)));
