import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
  Card, Form, Row, Col, Input,
} from 'antd';

import {
  fetchBedCart,
  fetchDeviceTypes,
  clearBedCart,
  saveUheBedCart,
} from '@uhe_actions/configuration/bedsCarts/bedsCartsActions';
import EditHeader from '@components/uhe/configuration/EditHeader.jsx';
import DeviceTypeCard from '@components/uhe/configuration/bedsCarts/DeviceTypeCard';
import ActiveComponentsCard from '@components/uhe/configuration/bedsCarts/ActiveComponentsCard';
import { FORM_LAYOUT } from '@constants/UHESettings';
import { goBackTo } from '@util/UheHelper';
import { withLastLocation } from 'react-router-last-location';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import PropTypes from 'prop-types';
import { shouldShowEditHeader } from '@util/UheRoleChecker';

class ManageNonUheBedCart extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    const { setSubtitle } = this.props;

    this.state = {
      bedCart: {
        components: [],
        name: '',
      },
      deviceTypes: [],
    };

    this.saveBedCart = this.saveBedCart.bind(this);
    this.onChangeDeviceType = this.onChangeDeviceType.bind(this);
    this.onChangeCallFormat = this.onChangeCallFormat.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangeRoomBed = this.onChangeRoomBed.bind(this);

  }

  /**
   * @description Render title, back and save buttons
   * @returns {JSX}
   */
  renderHeadLine() {
    const { loading, lastLocation, history } = this.props;

    if (shouldShowEditHeader()) {
      return (
        <EditHeader
          goBack={goBackTo(`/configuration/beds-carts/non-uhe-units?sort=${encodeURIComponent('organization,asc')}`, lastLocation?.pathname, history)}
          save={this.saveBedCart}
          loading={loading}
        />
      );
    }

    return null;
  }

  componentDidMount() {
    const urlId = this.props.match.params.id || '';
    if (urlId) {
      this.props.clearBedCart();
      this.props.fetchDeviceTypes();

      if (!this.props.location.state) {
        this.props.fetchBedCart({ id: urlId });
      }
    }

    if (this.props.location.state) {
      const uheBedCart = this.props.location.state.uheDevice;
      uheBedCart.endpoint_type = this.props.location.state.selectedDevice;
      this.setState({ bedCart: uheBedCart });
    }
      const { setSubtitle } = this.props;
      setSubtitle('nonCareDevices.titles.edit');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      // handle system error
      if (this.props.error.code === 404) {
        this.props.history.push('/configuration/beds-carts/non-uhe-units');
        return;
      }
    }

    if (
      Object.keys(prevProps.bedCart).length === 0 &&
      Object.keys(this.props.bedCart).length > 0
    ) {
      this.setState({
        bedCart: this.props.bedCart,
      });
    }

    if (
      prevProps.deviceTypes.length === 0 &&
      this.props.deviceTypes.length > 0
    ) {
      this.setState({
        deviceTypes: this.props.deviceTypes,
      });
    }

    this.formRef.current.setFieldsValue({
      alias: this.state.bedCart.alias,
      endpoint_type: [this.state.bedCart.endpoint_type]
    });

  }

  /**
   * @description Sends POST Request on Save Button click w/ the state as body
   * @param {Object} event
   * @returns {void}
   */
  saveBedCart(event) {
    event.persist();
    event.preventDefault();

    this.formRef.current.validateFields()
      .then(values => {
        const { bedCart } = this.state;
        this.props.saveNonUheBedCartEdit({
          body: bedCart,
          id: this.props.match.params.id,
        });
      })
      .catch(info => {
        console.log('info:', info);
      });
  }

  /**
   * @description Handle Changes on Device Types Dropdown and Updates Local State
   * @param {array} device
   * @returns {void}
   */
  onChangeDeviceType(device) {
    const { bedCart } = this.state;

    bedCart.endpoint_type = device[0];
    if (device[0] !== 'Standards Based') {
      this.props.history.push(
        `/configuration/beds-carts/uhe-units/edit/${this.props.match.params.id}`,
        { nonUheDevice: bedCart, selectedDevice: device[0] }
      );
      return;
    }
    this.setState({ bedCart });
  }

  /**
   * @description Handle Changes on the Call Format Dropdown and Updates Local State
   * @param {Object} format
   * @param {string} key
   * @returns {void}
   */
  onChangeCallFormat(format, key) {
    const { bedCart } = this.state;
    const newState = { ...bedCart, [key]: format.props.children };
    this.setState({
      bedCart: newState,
    });
  }

  /**
   * @description Handle Changes on Sip Address Input Field and Updates Local State
   * @param {Object} event
   * @param {string} key
   * @returns {void}
   */
  onChangeAddress(event, key) {
    const { bedCart } = this.state;
    const newState = { ...bedCart, [key]: event.target.value };

    this.setState({
      bedCart: newState,
    });
  }

  /**
   * @description Handles Changes on Room/Bed Input
   * @param {Object} event
   * @returns {void}
   */
  onChangeRoomBed(event) {
    const { bedCart } = this.state;
    const newState = { ...bedCart, name: event.target.value };
    this.setState({
      bedCart: newState,
    });
  }

  render() {
    const { bedCart, deviceTypes } = this.state;
    const { loading, form } = this.props;

    return (
      <div className="manage-customer-wrapper">
        <Form {...FORM_LAYOUT} name="nonUheBedCart" ref={this.formRef}>
          {this.renderHeadLine()}
          <Row gutter={16}>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Card
                loading={loading}
                className="gx-card"
                title={<IntlMessages id="configuration.bedsCarts.roomBedName" />}
              >
                <Input
                  onChange={this.onChangeRoomBed}
                  value={bedCart.name}
                />
              </Card>
              <DeviceTypeCard
                bedCart={bedCart}
                loading={loading}
                deviceTypes={deviceTypes}
                onChangeDeviceType={this.onChangeDeviceType}
                onChangeCallFormat={this.onChangeCallFormat}
                onChangeAddress={this.onChangeAddress}
                form={form}
              />
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Card
                loading={loading}
                className="gx-card"
                title={
                  <IntlMessages id="configuration.bedsCarts.activeComponents.title" />
                }
              >
                <ActiveComponentsCard
                  bedCart={bedCart}
                  bedCartId={this.props.match.params.id}
                  imgColSize={6}
                />
              </Card>
            </Col>
          </Row>
          {this.renderHeadLine()}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = ({ bedsCarts, common, ConfigurationUsers }) => {
  return {
    error: common.error,
    bedCart: bedsCarts.selectedBedCart,
    deviceTypes: bedsCarts.deviceTypes,
    loading: bedsCarts.loading,
    loggedUser: ConfigurationUsers.ownUser,
  };
};
ManageNonUheBedCart.propTypes = {
  history: PropTypes.object,
  lastLocation:PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearBedCart: () => dispatch(clearBedCart()),
    fetchBedCart: (id) => dispatch(fetchBedCart(id)),
    fetchDeviceTypes: (data) => dispatch(fetchDeviceTypes(data)),
    saveNonUheBedCartEdit: (data) => dispatch(saveUheBedCart(data)),
    setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(withRouter(ManageNonUheBedCart)));
