// Table Input Filter Const
import momentTz from 'moment-timezone';

export const INPUT_DEBOUNCE_MILLSEC = 400;
export const SEARCH_MIN_CHAR_LENGTH = 2;
export const COUNTDOWN_TIMER_VALUE = 59000;
export const COUNTDOWN_REFRESH_VALUE = 60000;

export const TIMEZONES = momentTz.tz.names()
  .filter(x => !x.includes('Etc')
&& !x.includes('UCT')
&& !x.includes('EST')
&& !x.includes('EST5EDT')
&& !x.includes('EET')
&& !x.includes('GMT')
&& !x.includes('GB')
&& !x.includes('MST7MDT')
&& !x.includes('MET')
&& !x.includes('CST6CDT')
&& !x.includes('ROC')
&& !x.includes('ROK')
&& !x.includes('WET')
&& !x.includes('PST8PDT')
&& !x.includes('W-SU')
&& !x.includes('NZ')
&& !x.includes('PRC')
&& !x.includes('EST')
&& !x.includes('MST')
&& !x.includes('CET')
&& !x.includes('HST')
&& !x.includes('UTC'));
