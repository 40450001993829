import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import {
  withRouter,
} from 'react-router-dom';
import {
  Row,
  Col,
  Empty,
  Form,
} from 'antd';

import {
  FETCH_UNIT_REQUEST,
  SAVE_UNIT_REQUEST,
  DELETE_UNIT_REQUEST,
  FETCH_FACILITIES_REQUEST,
} from '@constants/UHEActionTypes';
import UnitNameCard from '@components/uhe/configuration/unit/UnitNameCard.jsx';
import EditHeader from '@components/uhe/configuration/EditHeader.jsx';
import * as unitActions from '@actions/uhe/UnitActions';
import { clearUnit } from '@actions/uhe/UnitActions';
import * as facilityActions from "@uhe_actions/configuration/facilities/FacilitiesActions";
import { TOP_FILTER_PAGE_SIZE } from '@constants/UHESettings';
import { goBackTo, cascaderSearchFilter } from '@util/UheHelper';
import { withLastLocation } from 'react-router-last-location';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import {
  shouldDisableInputFields,
} from '@util/UheRoleChecker';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

/**
 * ManageUnit Class Component
 */
export class ManageUnit extends React.Component {
  formRef = React.createRef();

  /**
   * ManageUnit Constructor
   * @param {*} props Props
   * @param {*} context Context
   */
  constructor(props, context) {
    super(props, context);
    this.parentName = 'facility';
    this.isNew = this.props.location.pathname.indexOf('/new') !== -1;

    if (!this.props.facilities.length) {
      this.props.facilityActions.onFetchData(0, null, null, TOP_FILTER_PAGE_SIZE);
    }

    if (props.editMode && props.urlId && !props.error) {
      props.actions.getUnit(props.urlId);
    }
    this.state = {
      unit: { ...props.unit },
      facilities: [...props.facilities],
      errors: {},
    };

    this.renderHeadLine = this.renderHeadLine.bind(this);
    this.updateUnitState = this.updateUnitState.bind(this);
    this.saveUnit = this.saveUnit.bind(this);
    this.resetUnitName = this.resetUnitName.bind(this);
    this.deleteUnit = this.deleteUnit.bind(this);
    this.onChangeFacility = this.onChangeFacility.bind(this);
    this.onChangeParent = this.onChangeParent.bind(this);
    if (this.isNew) {
      if (this.props.subtitle !== 'configuration.unit.title_add_new') {
        this.props.setSubtitle('configuration.unit.title_add_new');
      }
    } else if (this.props.subtitle !== 'configuration.unit.title_edit') {
      this.props.setSubtitle('configuration.unit.title_edit');
    }

    this.filters = [
      {
        placeholder: 'configuration.edit_dropdown.organization',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: { filter: cascaderSearchFilter },
        key: 'organization',
      },
      {
        placeholder: 'configuration.edit_dropdown.customer',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: { filter: cascaderSearchFilter },
        key: 'customer',
      },
      {
        placeholder: 'configuration.edit_dropdown.facility',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: { filter: cascaderSearchFilter },
        key: 'facility',
      },
    ];

  }

  /**
   * @description Compare previous state with the new one.
   * Redirect from component if the url is received from the store.
   * Update local store if it is needed.
   * @param {object} nextProps
   * @param {object} prevState
   *
   * @returns {null|object} -  return an object to update the state, or null to update nothing
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    const stateForUpdate = {};
    const { unit, facilities } = prevState;
    if (nextProps.redirectUrl) {
      const url = nextProps.redirectUrl;
      nextProps.actions.setRedirectUrl({ url: '' });
      nextProps.history.push(url);
      return null;
    }
    if (unit.id !== nextProps.unit.id) {
      stateForUpdate.unit = { ...nextProps.unit };
    }
    if (nextProps.facilities.length > 0 && !facilities.length) {
      stateForUpdate.facilities = [...nextProps.facilities];
    }
    if (Object.keys(stateForUpdate).length) {
      return stateForUpdate;
    }
    return null;
  }

  componentDidMount() {
    this.props.clearUnit();
  }

  /**
   * @description Update local state after update, if the prop has been changed
   * @param prevProps{object}
   * @param prevState{object}
   * @param snapshot{object}
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { unit, editMode } = this.props;

    if (prevProps.error !== this.props.error) {
      // handle system error
      if (this.props.error.code === 404 && editMode) {
        this.props.history.push('/configuration/units');
      }
    }

    if (editMode && unit.name !== prevProps.unit.name) {
      this.setState({ unit });
    }

    if (!editMode && unit.name !== prevProps.unit.name) {
      this.props.history.push(`/configuration/units/edit/${unit.id}`);
    }

    if (editMode && !prevProps.editMode) {
      this.props.actions.getUnit(this.props.match.params.id);
    }

    if (prevProps.unit.name !== this.props.unit.name) {
      this.formRef.current.setFieldsValue({
        name: unit.name
      });
    }
  }

  /**
   * @description Handle click on reset button, and clean up 'name' field
   */
  resetUnitName() {
    const unit = { ...this.state.unit };
    unit.name = '';
    this.setState({ unit });
  }

  /**
   * @description Handle click on reset button, and clean up 'name' field
   */
  updateUnitState(event) {
    event.persist();
    const field = event.target.name;
    const { unit } = this.state;
    unit[field] = event.target.value;
    this.setState({ unit });
  }

  /**
   * @description onChange handler for facility 'select'.
   * @param idValue{string}
   */
  onChangeFacility(idValue) {
    const { unit } = this.state;
    if (idValue) {
      unit.facility.id = idValue;
    }
    this.setState({ unit });
  }

  /**
   * @description onChange handler for dropdown 'selection'.
   * @param parent{object}
   */
  onChangeParent(parent) {
    const { unit } = this.state;
    if (parent && parent.name === this.parentName && parent.id !== 'undefined') {
      unit.facility.id = parent.id;
    }
    this.setState({ unit });
  }

  /**
   * @description Catch event from the save/add button and dispatch save action to the middleware
   * @param {object} event
   */
  saveUnit(event) {
    event.persist();
    event.preventDefault();

    this.formRef.current
      .validateFields(['name', 'organization', 'customer', 'facility'])
      .then((values) => {
        const { unit } = this.state;
        const { actions } = this.props;

        if (this.props.editMode) {
          actions.saveUnit({ id: this.props.match.params.id, body: unit });
        } else if (!this.props.editMode) {
          actions.saveUnit({ body: unit });
        }
      })
      .catch((info) => {
        console.log("info:", info);
      });
  }

  /**
   * @description Catch event from the delete button and dispatch delete action to the middleware
   * @param {object} event
   */
  deleteUnit(event) {
    event.persist();
    event.preventDefault();
    const { unit } = this.state;
    const { actions } = this.props;
    actions.deleteUnit(unit);
  }

  /**
   * @description - render UnitNameCard component if the respective fetched result exists
   * or returns ant-empty component
   *
   */
  renderUnitNameCard() {
    const { unit, errors, facilities } = this.state;
    const { name } = unit;
    const { isFetching, editMode, loading } = this.props;
    const { loggedUser } = this.props;
    const permission = shouldDisableInputFields(loggedUser);
    if ((unit && editMode) || !editMode) {
      return (
        <Col lg={14} md={14} sm={16} xs={16} offset={8}>
          <UnitNameCard
            onChange={this.updateUnitState}
            name={name}
            loading={loading}
            resetUnit={this.resetUnitName}
            saveUnit={this.saveUnit}
            deleteUnit={this.deleteUnit}
            errors={errors}
            editMode={editMode}
            facilities={facilities}
            unit={unit}
            onChangeFacility={this.onChangeFacility}
            filters={this.filters}
            onChangeParent={this.onChangeParent}
            permission={permission}
          />
        </Col>
      );
    }

    if (!isFetching && !unit.id && editMode) {
      return (
        <Col lg={12} md={10} sm={10} xs={16} offset={6}><Empty /></Col>);
    }
    return null;
  }

  /**
   * @description Render title, back and global for page save button
   */
  renderHeadLine() {
    const { editMode, loading, lastLocation, history } = this.props;
    const titleKey = editMode
      ? 'configuration.unit.title_edit'
      : 'configuration.unit.title_add_new';

    let { isCaregilitySystemAdmin, isCustomerAdmin, isOrganizationAdmin, isFacilityAdmin } = this.props.loggedUser;

    if (isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin) {
      return (
        <EditHeader
          goBack={goBackTo(`/configuration/units?sort=${encodeURIComponent('organization,asc')}`, lastLocation?.pathname, history)}
          save={this.saveUnit}
          loading={loading}
          titleKey={titleKey}
        />
      );
    }

    return null;
  }

  render() {
    return (
      <Form {...layout} ref={this.formRef} className="unit-form">
        <div className="manage-unit-wrapper">
          {this.renderHeadLine()}
          <Row className="gx-d-flex gx-justify-content-center baseLineAlignedItems" gutter={16}>
            {this.renderUnitNameCard()}
          </Row>
        </div>
      </Form>
    );
  }
}
/**
 * Prop types
 * */
ManageUnit.propTypes = {
  unit: PropTypes.object,
  urlId: PropTypes.string,
  redirectUrl: PropTypes.string,
  actions: PropTypes.object.isRequired,
  facilityActions: PropTypes.object.isRequired,
  facilities: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  history: PropTypes.object,
  lastLocation: PropTypes.object.isRequired,
};

ManageUnit.contextTypes = {
  router: PropTypes.object,
};
/**
 * Default props
 * */
ManageUnit.defaultProps = {
  unit: {
    name: '',
    facility: { id: '' },
  },
  urlId: '',
  redirectUrl: '',
};

function mapStateToProps(state, ownProps) {
  const initUnit = {
    id: '',
    name: '',
    facility: { id: '' },
  };
  const urlId = ownProps.match.params.id || ''; // from the path `.../unit/:id`

  /**
   * @description check store for action, which is marked as 'loadable'
   * @param actions{array}
   * @returns {boolean}
   */
  const isFetching = (actions) => {
    const res = _(actions)
      .some((action) => _.get(state, `loading.${action}`));
    return res;
  };

  /**
   * @description Get sub-string without suffix word (REQUEST, SUCCESS, FAILURE)
   * @param action{string}
   * @returns {string}
   */
  const getCleanAction = (action) => {
    return action.substring(0, action.lastIndexOf('_'));
  };

  const profile = state.ConfigurationUsers;


  const editMode = !!urlId;
  return {
    unit: editMode ? state.unit.selectedUnit : initUnit,
    redirectUrl: state.unit.redirectUrl,
    facilities: state.ConfigurationFacilities.table.list,
    loading: state.unit.loading,
    error: state.common.error,
    urlId,
    editMode,
    isFetching: isFetching([
      getCleanAction(FETCH_UNIT_REQUEST),
      getCleanAction(SAVE_UNIT_REQUEST),
      getCleanAction(DELETE_UNIT_REQUEST),
      getCleanAction(FETCH_FACILITIES_REQUEST),
    ]),
    loggedUser: profile.ownUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(unitActions, dispatch),
    clearUnit: () => dispatch(clearUnit()),
    facilityActions: bindActionCreators(facilityActions, dispatch),
    setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withLastLocation(withRouter(ManageUnit)));
