import {
  all,
  call,
  fork,
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  fetchDataSuccess,
  saveFacilitySuccess,
  fetchFacilitySuccess,
  saveFacilityFail,
} from '@actions/uhe/configuration/facilities/FacilitiesActions';
import { showMessage, fetchError } from '@actions/Common';
import {
  CONFIGURATION_FACILITIES_FETCH_DATA,
  DELETE_FACILITY_REQUEST,
  SAVE_FACILITY_REQUEST,
  FETCH_FACILITY_REQUEST,
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';

import RestManager from '@util/RestManager';

/**
 * @param  {number} page
 * @param  {Array} sorting
 */
const doFetchData = async (page, sorting, filter, size) => {
  const filterQueryString = filter && filter.length ? `&${filter.join('&')}` : '';
  const sortingQueryString = sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : '';
  const sizeQueryParam = size ? `&size=${size}` : '';
  return await RestManager.request(`${ENDPOINTS.configuration.FacilitiesTable}?page=${page || 0}${sortingQueryString}${filterQueryString}${sizeQueryParam}`);
};

/**
 * @description Delete request
 * @param {string} id
 * @return  {Promise}
 */
const deleteFacilityAction = async (id) => {
  return await RestManager.requestWithoutQueryParams(`${ENDPOINTS.configuration.DeleteFacility}/${id}`, 'DELETE');
}

/**
 * @param {number} page
 * @param {Array} sorting
 * @param filter {Array}
 */
function* fetchTableData({ page, sorting, filter, size }) {
  try {
    const fetchedData = yield call(doFetchData, page, sorting, filter, size);
    yield put(fetchDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Sends POST Request
 * @param {Object} bodyData
 * @returns {Array}
 */
const saveFacilityById = async (facility, id) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.facility.fetchAll}/${id}`,
    'POST',
    facility
  );
};

/**
 * @description Delete facility by given id
 * @param {string} data
 * @return {void}
 */
function* delFacility(data) {
  try {
    const deletedFacility = yield call(deleteFacilityAction, data.payload.id);
    if (deletedFacility && deletedFacility.status < 400) {
      yield call(fetchTableData, { sorting: data.payload.sorting, page: data.payload.page, filter: data.payload.filter, size: data.payload.size });
      yield put(showMessage('delete_success'));

    } else {
      console.error('DELETE ERROR: ', deletedFacility);
      yield put(fetchError(deletedFacility));
    }
  } catch (error) {
    console.error('DELETE ERROR: ', error);
    yield put(fetchError(error));
  }
}

/**
 * @param  {Object} bodyData
 * @return {Object}
 */
const addNew = async (facility) => {
  return await RestManager.requestWithoutQueryParams(`${ENDPOINTS.facility.fetchAll}`, 'POST', facility);
};

/**
 * @description Sends GET Request
 * @param {string} id
 * @returns {Array}
 */
const doFetchFacilityById = async id => {
  return await RestManager.request(`${ENDPOINTS.facility.fetchAll}/${id}`);
};

/**
 * @description
 * @param {Object} data
 * @returns {void}
 */
function* saveFacilityEdit(data) {
  try {
    if (data.payload.id) {
      const toSave = { ...data.payload, id: undefined };
      const facility = yield call(saveFacilityById, toSave, data.payload.id);

      if (facility) {
        facility.id = data.payload.id;
        facility.saved = true;
        yield put(fetchFacilitySuccess(facility));
        yield put(showMessage('save_success'));
      }
    } else {
      const facilityId = yield call(addNew, data.payload);

      if(facilityId) {
        yield put(saveFacilitySuccess(facilityId));
        yield put(showMessage('save_success'));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(saveFacilityFail());
  }
}

/**
 * @description Fetching Facility by Id
 * @param {Object} id
 * @returns {void}
 */
function* fetchFacilityById({ id }) {
  try {
    const fetchedFacility = yield call(doFetchFacilityById, id);
    if (fetchedFacility) {
      yield put(fetchFacilitySuccess({ ...fetchedFacility, id }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(CONFIGURATION_FACILITIES_FETCH_DATA, fetchTableData);
  yield takeEvery(DELETE_FACILITY_REQUEST, delFacility);
  yield takeEvery(SAVE_FACILITY_REQUEST, saveFacilityEdit);
  yield takeEvery(FETCH_FACILITY_REQUEST, fetchFacilityById);
}

export default function* rootSaga() {
  yield all(
    [
      fork(actionsWatcher),
    ]
  );
}