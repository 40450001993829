import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '@util/IntlMessages';
import {
  Card, Col, Input, Row, Select, Form,
} from 'antd';
import FilterDropdowns from '@components/uhe/configuration/FilterDropdowns.jsx';

const { Option } = Select;

/**
 * @description Render unit edit/add new card
 *
 * @param onChange{function}
 * @param onChangeFacility{function}
 * @param unit{object}
 * @param name{string}
 * @param facilities{array}
 * @param saving{boolean}
 * @param saveUnit{function}
 * @param resetUnit{function}
 * @param deleteUnit{function}
 * @param errors{object}
 * @param editMode{boolean}
 *
 * @returns {object}
 */
const UnitNameCard = ({
  onChange,
  onChangeParent,
  onChangeFacility,
  unit,
  facilities,
  loading,
  editMode,
  filters,
  permission
}) => {
  const renderFacilityDropdown = () => {
    if (facilities) {
      let selected;
      let select = null;
      const options = facilities.map((facility) => {
        if (editMode && unit && unit.facility && unit.facility.name) {
          selected = unit.facility.name;
        }
        return (
          <Option
            key={facility.id}
            value={facility.name}
            initialValues={facility.name}
          >
            {facility.name}
          </Option>
        );
      });
      select = (
        <Select style={{ width: '100%' }} value={selected} disabled={selected !== '0'} onChange={onChangeFacility}>
          {options}
        </Select>
      );
      return (
        <Form.Item
          className="gx-d-flex gx-align-items-center"
          label={<IntlMessages id="configuration.facility.facility" />}
        >
          {select}
        </Form.Item>
      );
    }
  };

  return (
    <Card
      className="gx-card unit-edit-card"
      loading={false}
    >
      <Form.Item
        className="gx-d-flex gx-align-items-center"
        onChange={onChange}
        name="name"
        label={<IntlMessages id="configuration.unit.new_name" />}
        rules={[
          {
            required: true,
            message: <IntlMessages id="configuration.unit.name_err" />,
          },
        ]}
      >
        <Input
          id="unitName"
          name="name"
          type="text"
          alt={<IntlMessages id="configuration.unit.change_unit_name" />}
          disabled={permission}
        />
      </Form.Item>
      {!editMode
        ? (
          <FilterDropdowns
            filters={filters}
            loading={loading}
            editMode={editMode}
            onChangeParent={onChangeParent}
          />
        )
        : null}
      {editMode ? renderFacilityDropdown() : null}
    </Card>
  );
};

UnitNameCard.propTypes = {
  onChange: PropTypes.func.isRequired,
  onChangeFacility: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  saveUnit: PropTypes.func.isRequired,
  deleteUnit: PropTypes.func.isRequired,
  resetUnit: PropTypes.func.isRequired,
  onChangeParent: PropTypes.func.isRequired,
  unit: PropTypes.object.isRequired,
  facilities: PropTypes.array.isRequired,
};

export default UnitNameCard;
