import React from "react";
import IntlMessages from "util/IntlMessages";
import { Card, Col, Input, Row, Switch } from "antd";

/**
 * @description Render Organization Edit Card
 * @param organization{object}
 * @param loading{boolean}
 * @param onChangeHandler{function}
 * @returns {JSX}
 */
const OrganizationReports = ({ organization, loading, onChangeHandler }) => {
  const sectionName = "advanced_reports";
  return (
    <Card
      className="gx-card org-edit"
      loading={loading}
      title="Advanced Reports"
    >
      <Row className="gx-mb-3" gutter={16}>
        <Col
          className="gx-d-flex gx-align-items-center"
          lg={8}
          md={8}
          sm={24}
          xs={24}
        >
          <div>
            <IntlMessages id="configuration.organizations.edit.advanced_reports" />
          </div>
        </Col>
        <Col lg={16} md={16} sm={24} xs={24}>
          <Switch 
            checked={!!organization.advanced_reports.active}
            onChange={value => onChangeHandler(null, "active", sectionName, { val: value ? 1 : 0})}
          />
        </Col>
      </Row>
      <Row className="gx-mb-3" gutter={16}>
        <Col
          className="gx-d-flex gx-align-items-center"
          lg={8}
          md={8}
          sm={24}
          xs={24}
        >
          <div>
            <IntlMessages id="configuration.organizations.edit.advanced_reports_username" />
          </div>
        </Col>
        <Col lg={16} md={16} sm={24} xs={24}>
          <Input
            name="username"
            type="text"
            value={organization.advanced_reports.username}
            onChange={event => onChangeHandler(event, "username", sectionName)}
          />
        </Col>
      </Row>
      <Row className="gx-mb-3" gutter={16}>
        <Col
          className="gx-d-flex gx-align-items-center"
          lg={8}
          md={8}
          sm={24}
          xs={24}
        >
          <div>
            <IntlMessages id="configuration.organizations.edit.advanced_reports_dashboard_id" />
          </div>
        </Col>
        <Col lg={16} md={16} sm={24} xs={24}>
          <Input
            name="dashboard"
            type="text"
            value={organization.advanced_reports.dashboard}
            onChange={event => onChangeHandler(event, "dashboard", sectionName)}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default OrganizationReports;
