import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {
  Card, Table, Form, Input, Modal,
} from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import RestManager from '@util/RestManager';
import { BASE_URL, ENDPOINTS } from '@constants/UHEEndpoints';
import UheHelper from 'util/UheHelper';

import ListingsTopFilter from '@filters/ListingsTopFilter';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import FacilityCell from '@components/tables/cells/FacilityCell';
import UnitCell from '@components/tables/cells/UnitCell';
import AddressCell from '@components/tables/cells/AddressCell';
import ActionsCell from '@components/tables/cells/ActionsCell';
import ExportCsv from '@components/ExportCsv/ExportCsv';

import { mobilePatientsData, deleteMobilePatient } from '@uhe_actions/configuration/mobilePatients/MobilePatientsActions';
import { resetUserPassword, changeUserPassword } from '@uhe_actions/configuration/users/UsersActions';
import {
  LISTING_TABLES_PAGE_SIZE, TOP_FILTER_PREFIX, TABLE_FILTER_PREFIX, APP_PAGES_CONTEXT,
} from '@constants/UHESettings';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { AddButton, ImportButton, ExportButton } from '@components/uhe/listings/Buttons';
import ButtonsContainer from '@components/uhe/listings/ButtonsContainer';
import { getOwnUser } from '@uhe_selectors/configuration/users/UsersSelectors';
import {
  shouldBeAbleToExport,
  shouldBeAbleToImport,
  shouldBeAbleToAdd,
} from '@util/UheRoleChecker';
import ChangePasswordModal from '@components/uhe/listings/ChangePasswordModal';

const { confirm } = Modal;

/**
 * @description Renders mobile patients table
 */
class MobilePatients extends React.Component {
  changePasswordFormRef = React.createRef();

  static pageContext = APP_PAGES_CONTEXT.mobilePatients;

  /**
   * Adapt data returned by the server
   * @param  {Array<Object>} data
   * @return {Object}
   */

  static dataAdapter(data = []) {
    const adaptedData = [];
      data.forEach((value, index) => {
        adaptedData.push({
          key: index,
          firstName: value.first_name,
          lastName: value.last_name,
          email: {
            id: value.id,
            name: value.email,
          },
          organization: {
            id: value.mobile.cart.facility.customer.organization.id,
            name: value.mobile.cart.facility.customer.organization.name,
          },
          customer: {
            id: value.mobile.cart.facility.customer.id,
            organizationId: value.mobile.cart.facility.customer.organization.id,
            name: value.mobile.cart.facility.customer.name,
          },
          facility: {
            id: value.mobile.cart.facility.id,
            customerId: value.mobile.cart.facility.customer.id,
            organizationId: value.mobile.cart.facility.customer.organization.id,
            name: value.mobile.cart.facility.name,
          },
          unit: {
            id: value.mobile.cart.id,
            customerId: value.customer.id,
            organizationId: value.organization.id,
            facilityId: value.mobile.location.id,
            name: value.mobile.cart.name,
          },
          alias: value.mobile.alias,
          actions: {
            id: value.id,
            firstName: value.first_name,
            lastName: value.last_name,
          },
        });
      });
      return adaptedData;
    }

  constructor(props) {
    super(props);

    const { history, subtitle } = this.props;
    this.intl = this.props.intl;
    this.onPageChange = this.onPageChange.bind(this);

    this.state = {
      password: '',
      confirmPassword: '',
    };

    this.topFilterMap = {
      [`${TOP_FILTER_PREFIX}organization`]: 'mobile.cart.facility.customer.organization.id',
      [`${TOP_FILTER_PREFIX}customer`]: 'mobile.cart.facility.customer.id',
      [`${TOP_FILTER_PREFIX}facility`]: 'mobile.cart.facility.id',
      [`${TOP_FILTER_PREFIX}unit`]: 'mobile.cart.id',
    };

    this.tableFilterMap = {firstName: 'first_name',
      lastName: 'last_name',
      email: 'email',
      organization: 'mobile.cart.facility.customer.organization.name',
      customer: 'mobile.cart.facility.customer.name',
      facility: 'mobile.cart.facility.name',
      unit: 'mobile.cart.name',
      alias: 'mobile.alias',
    };

    this.data = [];
    this.columns = [];
    this.tableKeys = [
      'firstName',
      'lastName',
      'email',
      'organization',
      'customer',
      'facility',
      'unit',
      'alias',
    ];

    this.filterTypes = {};

    this.tableKeys.forEach((value, index) => {
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            showFilter={
              !(
                value === 'actions'
              )
            }
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
          />
        ),
        sorter: (value === 'actions') ? false : { multiple: index },
        defaultSortOrder: (value === 'firstName') ? this.checkSortTableOrder() : false,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.topFilters = [
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byOrganization',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'organization',
        shouldSort: true,
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byCustomer',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'customer',
        shouldSort: true,
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byFacility',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'facility',
        shouldSort: true,
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byUnit',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'unit',
        shouldSort: true,
      },
    ];

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);

    if (
      subtitle && subtitle.langId !== 'configuration.mobilePatients.title'
    ) {
      this.props.setSubtitle('configuration.mobilePatients.title');
    }

    this.onPageChange(1);
  }

  /**
   * @description Updates the table on location change
   * @param {Object} prevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const password_data = { ...this.state };

    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }
  }

  /**
   * @description Change data after filtering
   * @param {number} page
   * @return {Void}
   */
  onPageChange(page) {
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const sort = [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);
      const sorter = currSort.find(
        (element) => element === `${key},asc` || element === `${key},desc`,
      );

      if (filterParam) {
        if (
          this.filterTypes[key]
          && this.filterTypes[key].type === 'dropdown'
        ) {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }
    });

    currSort.forEach(sortItem => {
      const key = sortItem.split(',')[0];
      const value = this.tableFilterMap[key];

      if (value) {
        sort.push(sortItem.replace(key, value));
      }
    });

    this.filter = filter;
    this.sort = sort;
    this.props.mobilePatientsData(page - 1, sort, filter);
  }

  /**
   * @description Get endpoint url for data export
   * @returns {string}
   */
  getCsvUrl = () => {
    const token = RestManager.getToken();

    const filterQueryString = this.filter && this.filter.length ? `&${this.filter.join('&')}` : '';
    const sortingQueryString = this.sorting && this.sorting.length ? `&sort=${this.sorting.join('&sort=')}` : '';
    const tokenParam = `${filterQueryString || sortingQueryString ? `&token=${token}` : `token=${token}`}`;

    return `${BASE_URL}${ENDPOINTS.mobilePatients.addMobilePatient}/csv?${filterQueryString}${sortingQueryString}${tokenParam}`;
  }

  /**
   * @description Check the default sort order for table
   * @returns {string}
   */
  checkSortTableOrder() {
    const { location } = this.props;
    let checkOrder = location.search.split('%2C')[1];

    if (checkOrder === 'asc') {
      checkOrder = 'ascend';
    }
    if (checkOrder === 'desc') {
      checkOrder = 'descend';
    }
    if (!checkOrder) {
      checkOrder = false;
    }

    return checkOrder;
  }

  /**
   * @description Renders cell
   * @param {string} content
   * @param {string} key
   * @return {JSX}
   */
  cellRenderer(content, key) {
    let cell;
    const { intl, loggedUser, data } = this.props;

    switch (key) {
      case 'email':
        cell = <AddressCell editLink={`/configuration/mobile-patients/edit/${content.id}`} content={content} />;
        break;
      case 'organization':
        cell = content.id ? (
          <OrganizationCell content={content} />
        ) : (
          <div className="uhe-table-td">
            <IntlMessages id="configuration.users.allOrganizations" />
          </div>
        );
        break;
      case 'customer':
        cell = content.id ? (
          <CustomerCell content={content} />
        ) : (
          <div className="uhe-table-td">
            <IntlMessages id="configuration.users.allCustomers" />
          </div>
        );
        break;
      case 'unit':
        cell = <UnitCell content={content} />;
        break;
      case 'facility':
        cell = content.id ? (
          <FacilityCell content={content} />
        ) : (
          <div className="uhe-table-td">
            <IntlMessages id="configuration.users.allFacilities" />
          </div>
        );
        break;
      case 'actions':
        cell = (
          <ActionsCell
            page={MobilePatients.pageContext}
            loggedUser={loggedUser}
            content={content}
            data={data}
            editLink={`/configuration/mobile-patients/edit/${content.id}`}
            deleteAction={() => {
              this.props.deleteMobilePatient(
                content.id,
                this.currentPage,
                this.sort,
                this.filter,
              );
            }}
            resetPasswordAction={() => {
              this.props.resetUserPassword({ body: {}, id: content.id });
            }}
            openChangePasswordModal={() => this.toggleChangePasswordModal({
              id: content.id, firstName: content.firstName, lastName: content.lastName,
            })}
          />
        );
        break;
      default:
        cell = content;
    }

    return cell;
  }

  /**
  * @description Render table
  * @returns {JSX}
  */
  renderTable = () => {
    const {
      pagination, data, loading, loggedUser,
    } = this.props;
    pagination.onChange = this.onPageChange;

    if (loggedUser.roles) {
      const {
        isCaregilitySystemAdmin, isOrganizationAdmin, isCustomerAdmin, isTechnicianAdmin,
      } = this.props.loggedUser;

      if (isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isTechnicianAdmin) {
        if (this.columns.length <= 8) {
          const foundActions = this.tableKeys.filter((key) => key === 'actions');

          if (foundActions.length === 0) {
            this.tableKeys.push('actions');
          }

          this.tableKeys.filter((key) => key === 'actions').forEach((value, index) => {
            this.columns.push({
              title: (cellData) => (
                <ListingsTableInputFilter
                  showFilter={
                    !(
                      value === 'actions'
                    )
                  }
                  cellData={cellData}
                  title={`uhe.table.${value}`}
                  dataKey={value}
                />
              ),
              sorter: (value === 'actions') ? false : { multiple: index },
              defaultSortOrder: (value === 'firstName') ? this.checkSortTableOrder() : false,
              align: index > 3 ? 'center' : 'left',
              minWidth: 200,
              dataIndex: value,
              render: (content) => this.cellRenderer(content, value),
            });
          });
        }
      }

      return (
        <Table
          bordered
          className="gx-table-responsive"
          columns={this.columns}
          dataSource={MobilePatients.dataAdapter(data)}
          pagination={pagination}
          loading={loading}
        />
      );
    }
  }

  toggleChangePasswordModal = (user = null) => {
    this.setState({ passwordChangingUser: user });
  }

  /**
   * Change other users password
   * @return {function(): Promise<void>} Awaitable validation task
   */
  onSubmitChangePassword = async () => {
    const { passwordChangingUser } = this.state;
    const { changeUserPassword } = this.props;
    await this.changePasswordFormRef.current?.validateFields();
    const password = this.changePasswordFormRef.current?.getFieldValue('password');
    changeUserPassword({ body: password, id: passwordChangingUser?.id });
    this.toggleChangePasswordModal();
  };

  render() {
    const { pagination, loggedUser } = this.props;
    const { passwordChangingUser } = this.state;
    pagination.onChange = this.onPageChange;

    return (
      <div className="dashboard configuration">
        <ChangePasswordModal
          user={passwordChangingUser}
          onSubmit={this.onSubmitChangePassword}
          onCancel={this.toggleChangePasswordModal}
          ref={this.changePasswordFormRef}
        />
        <div className="customers-filter-box">
          <Card
            className="filter-boxes gx-card"
            title={<IntlMessages id="uhe.title.filters" />}
          >
            <ListingsTopFilter filters={this.topFilters} />
          </Card>
        </div>
        <div>
          <div className="uhe-table-header">
            <ButtonsContainer>
              <AddButton
                visible={shouldBeAbleToAdd(loggedUser, MobilePatients.pageContext)}
                url="/configuration/mobile-patients/new"
              />
              <ImportButton
                visible={shouldBeAbleToImport(loggedUser, MobilePatients.pageContext)}
                url="/configuration/mobile-patients/bulk/upload"
              />
              <ExportButton
                visible={shouldBeAbleToExport(loggedUser, MobilePatients.pageContext)}
                url={UheHelper.getCsvUrl(this.filter, this.sorting, ENDPOINTS.ExportCsv.mobilePatients)}
              />
            </ButtonsContainer>
            <p>
              <IntlMessages id="uhe.table.matchingResults" />
              <span>{pagination.total}</span>
            </p>
          </div>
          <div className="uhe-table">
            {this.renderTable()}
          </div>
        </div>
      </div>
    );
  }
}

MobilePatients.defaultProps = {
  data: [],
  loading: true,
  pagination: {
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
};

MobilePatients.propTypes = {
  data: PropTypes.array,
  pagination: PropTypes.object,
  intl: PropTypes.object,
  history: PropTypes.object,
  subtitle: PropTypes.object,
  location: PropTypes.object,
  loading: PropTypes.bool,
  mobilePatientsData: PropTypes.func,
  setSubtitle: PropTypes.func,
  loggedUser: PropTypes.object,
};

/**
 * @description Map global state to props
 * @param {{}} state
 * @return {Object}
 */
const mapStateToProps = (state) => {
  const { ConfigurationMobilePatients, subtitle } = state;
  const { list, page } = ConfigurationMobilePatients.table || { page: {}, list: [] };
  const { loading } = ConfigurationMobilePatients;
  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  return {
    data: list,
    pagination,
    loading,
    subtitle,
    loggedUser: getOwnUser(state),
  };
};

/**
 * @description Map dispatch to props
 * @param {function} dispatch
 * @return {Object}
 */
const mapDispatchToProps = (dispatch) => ({
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  mobilePatientsData: (page, sort, filter) => dispatch(mobilePatientsData(page, sort, filter)),
  deleteMobilePatient: (id, page, sorting, filter) => dispatch(deleteMobilePatient({
    id, page, sorting, filter,
  })),
  resetUserPassword: (id) => dispatch(resetUserPassword(id)),
  changeUserPassword: (id) => dispatch(changeUserPassword(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(MobilePatients)));
