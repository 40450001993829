import {
  CONFIGURATION_MOBILE_PATIENTS_DATA,
  CONFIGURATION_MOBILE_PATIENTS_DATA_SUCCESS,
  UPLOAD_MOBILE_PATIENT_DOCUMENT_REQUEST,
  UPLOAD_MOBILE_PATIENT_DOCUMENT_SUCCESS,
  DELETE_MOBILE_PATIENT_REQUEST,
  DELETE_MOBILE_PATIENT_REQUEST_SUCCESS,
  SAVE_MOBILE_PATIENT,
  SAVE_MOBILE_PATIENT_SUCCESS,
  SAVE_MOBILE_PATIENT_ID,
  SAVE_MOBILE_PATIENT_ID_SUCCESS,
  FETCH_MOBILE_PATIENT,
  FETCH_MOBILE_PATIENT_SUCCESS,
  MOBILE_PATIENT_ACCESS_DATA,
  MOBILE_PATIENT_ACCESS_DATA_SUCCESS,
  SAVE_PERMISSIONS_REQUEST,
  SAVE_PERMISSIONS_SUCCESS,
  SAVE_MOBILE_PATIENT_FAIL,
  FETCH_MDM_TOKEN,
  FETCH_MDM_TOKEN_SUCCESS,
} from '@constants/UHEActionTypes';

/**
 * @description Fetch data
 * @param  {number} page
 * @param  {string} sorting
 * @return {Object}
 */
export const mobilePatientsData = (page, sorting, filter) => {
  return {
    type: CONFIGURATION_MOBILE_PATIENTS_DATA,
    page,
    sorting,
    filter,
  };
};

/**
 * @description Fetch success
 * @param  {Object} data
 * @return {Object}
 */
export const mobilePatientsDataSuccess = (data) => {
  return {
    type: CONFIGURATION_MOBILE_PATIENTS_DATA_SUCCESS,
    data,
  };
};

/**
 * @description Handles UPLOAD_MOBILE_PATIENT_REQUEST action
 * @param {Object} file 
 * @returns {Object}
 */
export const uploadMobilePatientsDocument = (file) => {
  return {
    type: UPLOAD_MOBILE_PATIENT_DOCUMENT_REQUEST,
    payload: {
      file,
    }
  }
}

/** 
 * @description DELETE request
 * @param {Object} data
 * @return {Promise}
 */
export const deleteMobilePatient = (data) => {
  return {
    type: DELETE_MOBILE_PATIENT_REQUEST,
    payload: data,
  };
};

/**
 * @description DELETE request success
 * @param {Object} data
 * @return {Promise}
 */
export const deleteMobilePatientSuccess = (data) => {
  return {
    type: DELETE_MOBILE_PATIENT_REQUEST_SUCCESS,
    payload: data,
  };
};

/**
 * @description Handle SAVE_MOBILE_PATIENT Action
 * @param {Object} data
 * @returns {Object}
 */
export const saveMobilePatient = (data) => {
  return {
    type: SAVE_MOBILE_PATIENT,
    payload: { ...data },
  };
};

/**
 * @description Handles UPLOAD_MOBILE_PATIENT_DOCUMENT_SUCCESS action
 * @returns {Object}
 */
export const uploadMobilePatientsDocumentSuccess = () => {
  return {
    type: UPLOAD_MOBILE_PATIENT_DOCUMENT_SUCCESS
  }
}

/** 
 * @description Handle SAVE_MOBILE_PATIENT_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const saveMobilePatientSuccess = (payload) => {
  return {
    type: SAVE_MOBILE_PATIENT_SUCCESS,
    payload: { ...payload },
  };
};

/**
 * @description Handle SAVE_MOBILE_PATIENT_ID Action
 * @param {Object} payload
 * @returns {Object}
 */
export const saveMobilePatientById = (payload) => {
  return {
    type: SAVE_MOBILE_PATIENT_ID,
    id: payload.id,
  };
};

/**
 * @description Handle SAVE_MOBILE_PATIENT_ID_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const saveMobilePatientByIdSuccess = (payload) => {
  return {
    type: SAVE_MOBILE_PATIENT_ID_SUCCESS,
    payload: { ...payload },
  };
};

/**
 * @description Handle FETCH_MOBILE_PATIENT Action
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchMobilePatient = (payload) => {
  return {
    type: FETCH_MOBILE_PATIENT,
    id: payload.id,
  };
};

/**
 * @description Handle FETCH_MOBILE_PATIENT_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchMobilePatientSuccess = (payload) => {
  return {
    type: FETCH_MOBILE_PATIENT_SUCCESS,
    payload: { ...payload },
  };
};

/**
 * @description Handle MOBILE_PATIENT_ACCESS_DATA Action
 * @param {Object} payload
 * @returns {Object}
 */
export const mobilePatientAccessData = (payload) => {
  return {
    type: MOBILE_PATIENT_ACCESS_DATA,
    id: payload.id,
  };
};

/**
 * @description Fetch success
 * @param  {Object} data
 * @return {Object}
 */
export const mobilePatientAccessDataSuccess = (payload) => {
  return {
    type: MOBILE_PATIENT_ACCESS_DATA_SUCCESS,
    payload,
  };
};

/**
 * @description Handle SAVE_PERMISSIONS_REQUEST Action
 * @param {Object} data
 * @returns {Object}
 */
export const savePermissions = (data) => {
  return {
    type: SAVE_PERMISSIONS_REQUEST,
    payload: data,
  };
};

/**
 * @description Handle SAVE_PERMISSIONS_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const savePermissionsSuccess = (payload) => {
  return {
    type: SAVE_PERMISSIONS_SUCCESS,
    payload,
  };
};

/**
 * Fetch action when save request fail
 * @returns {boolean} after catch error
 */
export const saveMobilePatientFail = () => {
  return {
    type: SAVE_MOBILE_PATIENT_FAIL,
  };
};

/**
 * Handle FETCH_MDM_TOKEN Action
 * @param {Object} payload
 * @returns {Object}
 */

 export const fetchMDMToken = (payload) => {
  return {
    type: FETCH_MDM_TOKEN,
    id: payload,
  };
};

/**
 * Handle FETCH_MDM_TOKEN_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */

export const fetchMDMTokenSuccess = (payload) => {
  return {
    type: FETCH_MDM_TOKEN_SUCCESS,
    payload: { ...payload },
  };
};
