
import React from 'react';
import { Input, Form, message } from 'antd';
import IntlMessages from 'util/IntlMessages';
import Password from 'antd/lib/input/Password';

const EditableContext = React.createContext();

const EditableFormRow = (props, form) => {
  return(
    <EditableContext.Provider value={form}>
      <tr {...props} />
    </EditableContext.Provider>
  );
};

class EditableCell extends React.Component {
  constructor(props){
      super(props);

      this.state = {
        editing: false,
      };
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    const { record, handleSave } = this.props;

    this.form.current.validateFields()
    .then(values => {
      this.toggleEdit();
      handleSave({ ...record, ...values });
    })
    .catch(error_info => {
      console.error(error_info);
    });
  };

  getRules(dataIndex) {
    if(dataIndex === "address") {
      return [
        {
          type: "string",
          required: true,
          pattern: new RegExp("^[0-9.]*$"),
          message: <IntlMessages id="common.requiredIP" />,
        }
      ];
    }
    if(dataIndex === "username") {
      return [
        {
          required: true,
          pattern: new RegExp("^[0-9a-zA-Z]+$"),
          message: <IntlMessages id="common.requiredUsername" />,
        }
      ];
    }
    if(dataIndex === "credential") {
      return [
        {
          required: true,
          pattern: new RegExp("^[0-9a-zA-Z]+$"),
          message: <IntlMessages id="common.requiredPassword" />,
        }
      ];
    }
    return [];
  }

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    const isPassword = dataIndex === 'credential';
    return editing ? (
      <Form.Item
        style={{
          margin: 0,
          padding: 0,
        }}
        rules={this.getRules(dataIndex)}
        name={dataIndex}
      >
        <Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {isPassword ? "********" : children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

export { EditableFormRow, EditableCell};
