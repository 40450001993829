import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import {
  Button, Card, Col, Radio, Row, Table, Popconfirm, Form, Input, InputNumber, Upload, Modal,
} from 'antd';
import { CLOUDBREAK, INDEMAND, STRATUS, LANGUAGELINE, LANGUAGELINEPSTN } from '@constants/UHESettings';

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = (data) => (this.props.inputType === 'number' ? <InputNumber value={data} /> : <Input value={data} />);

  renderCell = () => {
    const {
      editing, dataIndex, title, inputType, record, index, children, ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
            initialValue={record[dataIndex]}
            name={dataIndex}
          >
            {this.getInput(record[dataIndex])}
          </Form.Item>
        ) : (children)}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

/**
 * Render Interpreter Settings Card
 * @param {Object} props Props
 * @returns {JSX.Element} InterpreterSettings Component
 */
const InterpreterSettings = (props) => {
  const {
    customer,
    readyMap,
    onChangeRadio,
    handleStratusDelete,
    uploadInterpreter,
    form,
    intl,
    disabled,
    interpreters,
    isNew,
  } = props;

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  const codesColumns = [
    {
      title: 'Language',
      dataIndex: 'name',
    },
    {
      title: 'Prefix',
      dataIndex: 'prefix',
    },
  ];

  const currentInterpreter = interpreters
    ?.find((interpreter) => interpreter.id === customer.interpreter.id);

  const [form1] = Form.useForm();
  const [editingId, setEditingId] = useState('');
  const isEditing = (record) => record.id === editingId;

  /**
   * @description Handle Save Row After Editing it
   * @param {Object} editForm
   * @param {Object} record
   * @returns {void}
   */
  const save = (editForm, record) => {
    const { handleStratusSave } = props;

    form1.validateFields().then((values) => {
      handleStratusSave(record.id, values);
      setEditingId('');
    }).catch(() => {});
  };

  /**
   * @description Handles Row Editing Upon Edit Button Click
   * @param {number} record
   * @returns {void}
   */
  const edit = (record) => {
    setEditingId(record);
  };

  /**
   * @description Handles Cancelation for Editing Upon Cancel Button Click
   * @returns {void}
   */
  const cancel = () => setEditingId('');

  /**
   * Handles File Upload
   * @param {Object} file Selected File Data
   * @returns {void}
   */
  const selectedFileHandler = ({ file }) => {
    const fd = new FormData();

    fd.append('patch[file]', file);

    uploadInterpreter(customer.interpreter.id, customer.id, fd);
  };

  const prepareColumn = (col) => ({
    title: col.title,
    dataIndex: col.dataIndex,
    editable: col.editable,
    onCell(record) {
      return {
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      };
    },
  });

  const columns = [
    prepareColumn({
      title: 'Description',
      dataIndex: 'description',
      editable: true,
    }),
    prepareColumn({
      title: 'URI',
      dataIndex: 'uri',
      editable: true,
    }),
    {
      title: 'Actions',
      render: (text, record) => {
        const editable = isEditing(record);

        if (editable) {
          return (
            <div>
              <span>
                <EditableContext.Consumer>
                  {(editForm) => (
                    <Button
                      onClick={() => save(editForm, record)}
                      style={{ marginRight: 8 }}
                    >
                      <IntlMessages id="configuration.save" />
                    </Button>
                  )}
                </EditableContext.Consumer>
                <Popconfirm
                  title={intl.formatMessage({ id: 'configuration.customer.confirm_cancel' })}
                  onConfirm={() => cancel(record.id)}
                >
                  <a>
                    <IntlMessages id="common.cancel" />
                  </a>
                </Popconfirm>
              </span>
            </div>
          );
        }

        return (
          <div className="gx-d-flex gx-justify-content-around">
            <Button
              disabled={editingId.editingId !== ''}
              onClick={() => edit(record.id)}
            >
              <i className="icon icon-ckeditor" />
            </Button>
            <Popconfirm
              title={intl.formatMessage({ id: 'configuration.customer.confirm_delete' })}
              onConfirm={() => handleStratusDelete(customer.interpreter.id, customer.id, record.id)}
            >
              <Button title="Delete">
                <i className="icon icon-trash" />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  /**
   * Renders Radio Button
   * @param {object} interpreter Interprete Object with ID and Name
   * @returns {JSX.Element} Radio Button
   */
  const interpreterToRadio = (interpreter) => (
    <Radio
      className="interpreter-setting"
      value={interpreter.id}
      checked={interpreter.id === (customer.interpreter.id || 0)}
      disabled={disabled}
    >
      {interpreter.name}
    </Radio>
  );

  /**
   * @description Render radio buttons section
   * @returns {JSX.Element}
   */
  const renderRadioGroup = () => (
    <Radio.Group onChange={onChangeRadio} value={customer.interpreter.id}>
      {[{ id: null, name: 'none' }, ...interpreters].map(interpreterToRadio)}
    </Radio.Group>
  );

  /**
   * Renders In Demand Table
   * @returns {JSX.Element|null} In Demand Table | null
   */
  const renderCodes = () => (currentInterpreter.name === CLOUDBREAK
    || currentInterpreter.name === STRATUS
    || currentInterpreter.name === LANGUAGELINE
    || currentInterpreter.name === LANGUAGELINEPSTN ? null
    : (
      <Table
        bordered
        className="gx-table-responsive"
        pagination={{ pageSize: currentInterpreter?.codes?.page.size }}
        columns={codesColumns}
        dataSource={currentInterpreter?.codes?.list}
        rowKey="id"
      />
    )
  );

  /**
   * Renders Mappings Based on the Selected Interpreter
   * @returns {JSX.Element} Mappings
   */
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {setIsModalVisible(true)};
  const handleCancel = () => {setIsModalVisible(false)};

  /**
   * Renders Cloudbreak ID Based on the Selected Interpreter
   * @returns {JSX.Element} Mappings
   */
  const renderCloudbreakID = () => (currentInterpreter.name === INDEMAND
    || currentInterpreter.name === STRATUS
    || currentInterpreter.name === LANGUAGELINE
    || currentInterpreter.name === LANGUAGELINEPSTN ? null : (
      <>
        <Row className="baseLineAlignedItems" gutter={16}>
          <Col lg={12} md={12} sm={24} xs={24} className="gx-d-flex gx-align-items-center">
            <IntlMessages id="configuration.customer.cloudbreakId" />
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Input
              readOnly
              bordered={false}
              value={intl.formatMessage({ id: 'configuration.customer.definedInCustomerAttr' })}
            />
          </Col>
        </Row>
      </>
    ));

  /**
    *  Render Mapping Based on the Selected Interpreter
    * @returns {JSX.Element} Mappings
    */
  const renderMappings = () => (currentInterpreter.name === INDEMAND
    || currentInterpreter.name === CLOUDBREAK ? null : (
      <>
        <Row className="baseLineAlignedItems interpreter-buttons-row" gutter={16}>
          <Col lg={16} md={24} sm={24} xs={24} className="gx-d-flex gx-align-items-center">
            <Row className="upload-box" gutter={16}>
              <Col lg={8} md={24} sm={24} xs={24}>
                <div>
                  <IntlMessages id="configuration.customer.interpreter_data" />
                </div>
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <Upload
                  type="file"
                  accept=".csv"
                  beforeUpload={() => false}
                  maxCount={1}
                >
                  <Button>
                    <IntlMessages id="configuration.bulkUpload.selectFile" />
                  </Button>
                </Upload>
              </Col>
            </Row>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} className="gx-d-flex gx-align-items-center">
            <Row gutter={16}>
              <Col lg={12} md={24} sm={24} xs={24}>
                <Button onClick={showModal}>
                  <IntlMessages id="configuration.customer.help" />
                </Button>
                {currentInterpreter.name === LANGUAGELINEPSTN ? (
                  <Modal title={<h1><IntlMessages id="configuration.customer.modal_title_upload" /></h1>} visible={isModalVisible} onCancel={handleCancel} footer={null}>
                    <p><IntlMessages id="configuration.customer.modal_content_phone" /></p>
                    <h3><IntlMessages id="configuration.customer.example" /></h3>
                    <p><IntlMessages id="configuration.customer.modal_content_phone_a" /></p>
                    <p><IntlMessages id="configuration.customer.modal_content_phone_b" /></p>
                    <p><IntlMessages id="configuration.customer.modal_content_phone_c" /></p>
                  </Modal>
                ) : (
                  <Modal title={<h1><IntlMessages id="configuration.customer.modal_title_upload" /></h1>} visible={isModalVisible} onCancel={handleCancel} footer={null}>
                    <p><IntlMessages id="configuration.customer.modal_content_uri" /></p>
                    <h3><IntlMessages id="configuration.customer.example" /></h3>
                    <p><IntlMessages id="configuration.customer.modal_content_uri_a" /></p>
                    <p><IntlMessages id="configuration.customer.modal_content_uri_b" /></p>
                    <p><IntlMessages id="configuration.customer.modal_content_uri_c" /></p>
                  </Modal>
                )}
              </Col>
              <Col lg={12} md={24} sm={24} xs={24} className="gx-d-flex gx-align-items-center">
                <Button htmlType="upload" className="uploadButton" onClick={uploadInterpreter}>
                  <IntlMessages id="sidebar.dataEntry.upload" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <EditableContext.Provider value={form}>
          <Form form={form1}>
            <Table
              bordered
              className="gx-table-responsive gx-mt-4"
              pagination={{ pageSize: currentInterpreter?.mappings?.page.size }}
              columns={columns}
              dataSource={currentInterpreter?.mappings?.list}
              components={components}
              rowClassName="editable-row"
              rowKey="id"
            />
          </Form>
        </EditableContext.Provider>
      </>
    ));

  return (
    <Card
      className="gx-card unit-edit-card"
      title={
        <IntlMessages id="configuration.customer.interpreter_settings" />
      }
      loading={!readyMap.interpreters}
    >
      <Row gutter={16}>
        <Col lg={4} md={4} sm={24}>
          <div>
            <IntlMessages id="configuration.customer.interpreter_service" />
          </div>
        </Col>
        <Col lg={18} md={18} sm={24} xs={24}>
          {renderRadioGroup()}
        </Col>
      </Row>
      <Card loading={!isNew && !readyMap.selectedInterpreter} bordered={false}>
        {currentInterpreter?.codes?.list && renderCodes()}
        {currentInterpreter?.mappings?.list && renderMappings()}
        {currentInterpreter?.mappings?.list && renderCloudbreakID()}
      </Card>
    </Card>
  );
};

InterpreterSettings.defaultProps = {
  isNew: true,
};

InterpreterSettings.propTypes = {
  customer: PropTypes.object.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  interpreterNames: PropTypes.object.isRequired,
  readyMap: PropTypes.shape({
    interpreters: PropTypes.bool,
    selectedInterpreter: PropTypes.bool,
  }).isRequired,
  isNew: PropTypes.bool,
};

export default injectIntl(InterpreterSettings);
