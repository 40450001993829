import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Checkbox, Popconfirm, Table, Tag, Tabs, Popover, Breadcrumb,
} from 'antd';
import IntlMessages from 'util/IntlMessages';
import ReactivePermissionData, {
  getIndexFromLevel, getLevelName, LevelType,
} from './Models/ReactivePermissionData';

const permissionsTableLabels = [...Object.entries(LevelType), [undefined]]
  .reduce((acc, [levelName]) => ({
    ...acc,
    [levelName]: (
      <div
        className="roleAssignment__table--headers"
      >
        {!levelName ? <></> : <IntlMessages id={`uhe.table.${levelName}`} />}
      </div>
    ),
  }), {});

/**
 * @description Wrapper to render function for creating clickable item
 * @param render
 * @return {function(*=, *=)}
 */
const clickable = (render) => (text, record) => (
  <span className="roleAssignment__table--cell-clickable">
    {render(text, record)}
  </span>
);

/**
 @description Show tag around selected element
 * @param {string} text
 * @param {{}} record
 * @return {JSX.Element}
 */
const showSelected = (text, record) => (
  <>
    {record.id === record.selectedId
      ? (
        <Tag
          color="blue"
          style={{
            fontSize: '14px',
            marginBottom: '0',
          }}
        >
          {record.name}
        </Tag>
      )
      : record.name}
  </>
);

/**
 * @description Apply custom styling to buttons for table navigation
 * @param children
 * @return {JSX.Element}
 */
const PathItem = ({ children }) => (
  <Button type="link" className="roleAssignment__table--pathItem">
    {children}
  </Button>
);

const UserPermissionsTable = React.memo((props) => {
  const {
    dataSource,
    updatePermissions,
    globalGrantsData,
    formatMessage,
    disabled,
  } = props;

  const [displayAdmins, setDisplayAdmins] = useState(false);
  const [shouldConfirm, setShouldConfirm] = useState({ key: '', roleName: '', globalGrant: false });
  const [leadingLevel, setLeadingLevel] = useState(LevelType.organization);
  const [keychain, setKeychain] = useState([]);

  /**
   * @description Go deeper in passed data structure
   * @param {string} key
   * @param {Object.<string, ReactivePermissionData>} levelData
   */
  const goLevelDeeper = (key, levelData) => {
    if (leadingLevel + 1 <= LevelType.device
      && Object.keys(levelData[key].children).length) {
      setKeychain([...keychain, key]);
      setLeadingLevel(leadingLevel + 1);
    }
  };

  /**
   * @description Go levels back in passed data structure
   * @param {number} times
   * @param {boolean} force
   * @return {function(): void}
   */
  const goLevelsBack = (times = 1, force = false) => () => {
    if (times === 0 && !force) {
      return;
    }

    setKeychain(keychain.slice(0, -times));
    setLeadingLevel(Math.max(leadingLevel - times, 1));
  };

  /**
   * @description Change parent level of passed structure
   * @param {string} newKey
   * @param {Object.<string, ReactivePermissionData>} levelData
   * @return {Promise<void>}
   */
  const changeParentLevel = async (newKey, levelData = {}) => {
    if (Object.keys(levelData[newKey].children).length) {
      setKeychain(keychain
        .map((key, index) => (index === getIndexFromLevel(leadingLevel - 1) ? newKey : key)));
    }
  };

  /**
   * @description Function for easy level searching in the dataSource using the keychain
   * @param {number} level The 'one' based value of the level
   * (i.e.: LevelTypes.organization is first level /1/)
   * @param {Object<string, ReactivePermissionData>} children
   * @param {number} depth
   * @type {
   * function(
   *    level: number,
   *    children: Object<string, ReactivePermissionData> = dataSource,
   *    depth: number = 1
   *  ): ({})
   * }
   * @returns {Object<string, ReactivePermissionData>}
   */
  const getToLevel = ReactivePermissionData.getToLevel(dataSource, keychain);

  const parentLeveledData = getToLevel(leadingLevel - 1);
  const leadingLeveledData = getToLevel(leadingLevel, parentLeveledData, leadingLevel - 1);

  const renderData = (level) => ([key, { name, id, type }]) => ({
    key,
    displayName: type
      ? (
        <>
          <i
            className={`icon ${type === 'DEV'
              ? 'icon-data-display'
              : 'icon-phone'}`}
          />
          {` ${name}`}
        </>
      )
      : name,
    name,
    id,
    selectedId: parentLeveledData[keychain[getIndexFromLevel(level)]]?.id,
    type,
  });

  /**
   @description Wrapper for toggling permission with updating upper state
   * @param {string} roleName
   * @param data
   */
  const togglePermission = (roleName, data) => {
    ReactivePermissionData.togglePermission(roleName, data, globalGrantsData);
    updatePermissions(dataSource, globalGrantsData);
  };

  /**
   * @description Wrapper for toggling global grant with updating upper state
   * @param {string} roleName
   */
  const toggleGlobalGrant = (roleName) => {
    ReactivePermissionData.toggleGlobalGrant(roleName, dataSource, globalGrantsData);
    updatePermissions(dataSource, globalGrantsData);
  };

  /*
   * @description Wrapper for toggling permission with updating upper state
   * @param currentElement
   * @param {string} roleName
   */
  const revokeWithParentsGrants = (currentElement, roleName) => {
    ReactivePermissionData
      .revokeWithParentsGrants(currentElement, roleName, dataSource, keychain, globalGrantsData)();
    updatePermissions(dataSource, globalGrantsData);
  };

  /**
   * @description Renders confirmable checkbox
   * @param {string} key
   * @param {string} roleName
   * @param {boolean} granted
   * @param {boolean} allowed
   * @param {boolean} artificial
   * @param {boolean} inherited
   * @param data
   * @return {JSX.Element}
   */
  const renderConfirmableCheckbox = (
    key,
    roleName,
    {
      granted, allowed, artificial, inherited,
    },
    data,
  ) => (
    <>
      <Popconfirm
        title={formatMessage({ id: 'common.endQuestion' })}
        visible={
            (shouldConfirm?.key === key || (!!shouldConfirm?.globalGrant && typeof key === 'undefined'))
            && shouldConfirm?.roleName === roleName
          }
        onCancel={() => setTimeout(() => setShouldConfirm(null), 100)}
        onConfirm={() => {
          (typeof key === 'undefined'
            ? toggleGlobalGrant(roleName)
            : togglePermission(roleName, data[key]));
          setTimeout(() => setShouldConfirm(null), 100);
        }}
      />
      <Checkbox
        checked={typeof key === 'undefined'
          ? !!globalGrantsData[roleName]?.granted
          : granted}
        disabled={(typeof key === 'undefined'
          ? !globalGrantsData[roleName]?.allowed
          : !allowed || artificial) || disabled}
        onClick={() => {
          if (typeof key === 'undefined') {
            if (!globalGrantsData[roleName]?.granted || !ReactivePermissionData
              .areLowerLevelsSet(
                roleName,
                dataSource,
              )) {
              toggleGlobalGrant(roleName);
              return;
            }

            setShouldConfirm({ roleName, globalGrant: true });
            return;
          }

          if (granted) {
            if (inherited) {
              revokeWithParentsGrants(data[key], roleName, data[key].discriminator);
              return;
            }
            togglePermission(roleName, data[key]);
            return;
          }

          if (!granted && !ReactivePermissionData
            .areLowerLevelsSet(
              roleName,
              data[key].children,
            )) {
            togglePermission(roleName, data[key]);
            return;
          }

          setShouldConfirm({ key, roleName });
        }}
      />
    </>
  );

  /**
   * @description Renders permission checkbox
   * @param {string} key
   * @param {string} value
   * @return {*&{key}}
   */
  const renderPermissionCheckbox = ([key, value]) => ({
    key,
    ...Object.entries(value.permissions)
      .reduce((acc, [roleName, permission]) => ({
        ...acc,
        [roleName]: (
          <>
            {!permission.allowed || permission.artificial
              ? (
                <Popover
                  content={formatMessage({ id: 'common.notAllowed' })}
                >
                  {renderConfirmableCheckbox(key, roleName, permission, leadingLeveledData)}
                </Popover>
              )
              : renderConfirmableCheckbox(key, roleName, permission, leadingLeveledData)}
          </>
        ),
      }), {}),
  });

  const permissionLabels = Object.entries(Object.values(leadingLeveledData)
    .shift()?.permissions || {})
    .filter(([name]) => (name.indexOf('_admin') !== -1 ? displayAdmins : !displayAdmins))
    .map(([roleName]) => {
      const key = keychain.slice(-1).pop();
      const { [roleName]: permission = {} } = parentLeveledData[key]?.permissions || {};
      return ({
        title: (
          <>
            <IntlMessages id={`configuration.users.${roleName}`} />
            <hr />
            <span>All: </span>
            {renderConfirmableCheckbox(key, roleName, permission, parentLeveledData)}
          </>),
        dataIndex: roleName,
      });
    });

  const checkboxTable = (
    <Table
      className="roleAssignment__table--rightTable"
      bordered
      columns={[
        {
          title: permissionsTableLabels[getLevelName(leadingLevel)],
          dataIndex: 'displayName',
          render: clickable((text, record) => record.displayName),
          onCell(record) {
            return {
              onClick: () => goLevelDeeper(record.key, leadingLeveledData),
            };
          },
          width: 300,
        },
        ...permissionLabels,
      ].map((el) => ({ ...el, align: 'center' }))}
      dataSource={Object.entries(leadingLeveledData)
        .map((kvp) => {
          const names = renderData(leadingLevel)(kvp);
          const permissions = renderPermissionCheckbox(kvp);
          return { ...names, ...permissions };
        })}
    />
  );

  const path = (
    <div className="roleAssignment__table--path">
      <Breadcrumb separator=">">
        <Breadcrumb.Item onClick={goLevelsBack(keychain.length, true)}>
          <PathItem>
            <span className="icon icon-home" />
          </PathItem>
        </Breadcrumb.Item>
        {keychain.map((key, i) => (
          <Breadcrumb.Item
            onClick={goLevelsBack(keychain.length - i - 1)}
          >
            <PathItem>
              {getToLevel(i + 1)[key]?.name}
            </PathItem>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );

  return (
    <>
      {path}
      <div className="gx-d-flex roleAssignment__row">
        <div>
          <Button
            className="roleAssignment__table--backButton"
            type="secondary"
            disabled={!keychain.length}
            onClick={goLevelsBack()}
          >
            Back
          </Button>
          <Table
            className="roleAssignment__table--leftTable"
            columns={[{
              title: permissionsTableLabels[getLevelName(leadingLevel - 1)] || '-',
              dataIndex: 'displayName',
              render: clickable(showSelected),
              onCell(record) {
                return {
                  onClick: () => changeParentLevel(record.key, parentLeveledData),
                };
              },
              align: 'center',
              width: 300,
            }]}
            dataSource={Object.entries(parentLeveledData).map(renderData(leadingLevel - 1))}
          />
        </div>
        <Tabs
          className="roleAssignment__table--tabs"
          onChange={(key) => setDisplayAdmins(key !== '1')}
          type="card"
        >
          <Tabs.TabPane
            tab={formatMessage({ id: 'configuration.roles.functionalRoles' })}
            key="1"
          >
            {checkboxTable}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={formatMessage({ id: 'configuration.roles.adminRoles' })}
            key="2"
          >
            {checkboxTable}
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
});

UserPermissionsTable.defaultProps = {
  globalGrantsData: {},
};

UserPermissionsTable.propTypes = {
  dataSource: PropTypes.objectOf(PropTypes.instanceOf(ReactivePermissionData)).isRequired,
  updatePermissions: PropTypes.func.isRequired,
  globalGrantsData: PropTypes.objectOf(PropTypes.shape({
    allowed: PropTypes.bool.isRequired,
    granted: PropTypes.bool.isRequired,
    artificial: PropTypes.bool.isRequired,
    inherited: PropTypes.bool.isRequired,
  })),
  formatMessage: PropTypes.func.isRequired,
};

export default UserPermissionsTable;
