import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Table } from 'antd';
import lodash from 'lodash';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { injectIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';

import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import OrganizationCell from '@components/tables/cells/OrganizationCell';
import ActionsCell from '@components/tables/cells/ActionsCell';
import ExportCsv from '@components/ExportCsv/ExportCsv';
import { organizationsOnFetchData, deleteOrganization } from '@uhe_actions/configuration/organizations/OrganizationsActions';
import {
  LISTING_TABLES_PAGE_SIZE,
  TABLE_FILTER_PREFIX,
  APP_PAGES_CONTEXT,
} from '@constants/UHESettings';
import UheHelper from 'util/UheHelper';
import { setSubtitle } from '@uhe_actions/SubtitleActions';

/**
 * @description Renders Organization table
 */
class Organizations extends Component {
  /**
   * @description Adapts the data from the API
   * @param {Array} data
   * @returns {Array}
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        organization: {
          id: value.id,
          name: value.name,
        },
        actions: {
          id: value.id,
        },
        customerNo: value.total_customers,
        clinicians: value.no_clinicians,
        numberOfBedsCarts: value.total_devices,
        advancedReports: value.status === 1 ? <IntlMessages id="common.on" /> : <IntlMessages id="common.off" />,
      });
    });

    return adaptedData;
  }

  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);
    const {
      intl, subtitle, history, setSubtitle,
    } = this.props;

    this.data = [];
    this.columns = [];
    this.filterTypes = {
      advancedReports: {
        type: 'dropdown',
        options: [
          { value: '1', label: intl.formatMessage({ id: 'common.on' }) },
          { value: '0', label: intl.formatMessage({ id: 'common.off' }) },
        ],
      },
    };

    this.tableFilterMap = {
      organization: 'name',
      customerNo: 'total_customers',
      clinicians: 'no_clinicians',
      numberOfBedsCarts: 'total_devices',
      advancedReports: 'status',
    };

    this.tableKeys = [
      'organization',
      'customerNo',
      'clinicians',
      'numberOfBedsCarts',
      // 'actions',
    ];

    this.tableKeys.forEach((value, index) => {
      const filter = this.filterTypes[value] || {};
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            filterType={filter.type}
            filterOptions={filter.options}
            showFilter={!(value === 'actions' || value === 'customerNo' || value === 'clinicians' || value === 'numberOfBedsCarts')}
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            triggerCharsNum={value === 'customerNo' || value === 'clinicians' || value === 'numberOfBedsCarts' ? 0 : undefined}
          />
        ),
        sorter: (value === 'actions') ? false : { multiple: index },
        defaultSortOrder: (value === 'organization') ? this.checkSortTableOrder() : false,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    if (
      subtitle
      && subtitle.langId !== 'configuration.organizations.title'
    ) {
      setSubtitle('configuration.organizations.title');
    }

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);

    this.onPageChange(1);
  }

  /**
   * @description Updates table when location is changed
   * @param {Object} prevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }
  }

  /**
   * @param  {number} page
   * @return {void}
   */
  onPageChange(page) {
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const sort = [];
    const filter = [];

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);

      if (filterParam) {
        if (this.filterTypes[key] && this.filterTypes[key].type === 'dropdown') {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }
    });

    currSort.forEach(sortItem => {
      const key = sortItem.split(',')[0];
      const value = this.tableFilterMap[key];

      if (value) {
        sort.push(sortItem.replace(key, value));
      }
    });

    this.filter = filter;
    this.sort = sort;

    this.props.organizationsOnFetchData(page - 1, sort, filter);
  }

  /**
   * @description Check the default sort order for table
   * @returns {string}
   */
  checkSortTableOrder() {
    const { location } = this.props;
    let checkOrder = location.search.split('%2C')[1];

    if (checkOrder === 'asc') {
      checkOrder = 'ascend';
    }
    if (checkOrder === 'desc') {
      checkOrder = 'descend';
    }
    if (!checkOrder) {
      checkOrder = false;
    }

    return checkOrder;
  }

  /**
   * @param {string} content
   * @param {string} key
   * @returns {JSX}
   */
  cellRenderer(content, key) {
    let cell;
    const { intl, loggedUser } = this.props;

    switch (key) {
      case 'organization':
        cell = <OrganizationCell content={content} />;
        break;
      case 'actions':
        cell = (
          <ActionsCell
            page={APP_PAGES_CONTEXT.organizations}
            loggedUser={loggedUser}
            intl={intl}
            content={content}
            editLink={`/configuration/organizations/edit/${content.id}`}
            deleteAction={() => {
              this.props.deleteOrganization(
                content.id,
                this.currentPage,
                this.sort,
                this.filter,
              );
            }}
          />
        );
        break;

      default:
        cell = content;
    }

    return cell;
  }

  /**
  * @description Render Add Button
  * @returns {JSX}
  */
  renderAddButton = () => {
    const { isCaregilitySystemAdmin } = this.props.loggedUser;

    if (isCaregilitySystemAdmin) {
      return (
        <Link
          to="/configuration/organizations/new"
          shape="circle"
          icon="+"
          className="page-icons page-icon-plus"
        >
          <i className="icon icon-add" />
          <span><IntlMessages id="common.addText" /></span>
        </Link>
      );
    }

    return null;
  }

  /**
  * @description Render Export Csv Button
  * @returns {JSX}
  */
  renderExportCsvButton = () => {
    const { isCaregilitySystemAdmin, isCaregilityAdmin } = this.props.loggedUser;

    if (isCaregilitySystemAdmin || isCaregilityAdmin) {
      return (
        <ExportCsv
          getCsvUrl={UheHelper.getCsvUrl(
            this.filter, this.sorting, ENDPOINTS.ExportCsv.organizations,
          )}
        />
      );
    }

    return null;
  }

  /**
  * @description Render table
  * @returns {JSX}
  */
  renderTable = () => {
    const { pagination, data, loading, loggedUser } = this.props;
    pagination.onChange = this.onPageChange;

    if (loggedUser.roles) {
      if (loggedUser.isCaregilitySystemAdmin) {

        if (this.columns.length <= 4) {

          const foundActions = this.tableKeys.filter(key => key === 'actions')

          if (foundActions.length === 0) {
            this.tableKeys.push('actions');
          }

          this.tableKeys.filter((key) => key === 'actions').forEach((value, index) => {
            const filter = this.filterTypes[value] || {};
            this.columns.push({
              title: (cellData) => (
                <ListingsTableInputFilter
                  filterType={filter.type}
                  filterOptions={filter.options}
                  showFilter={!(value === 'actions' || value === 'customerNo' || value === 'clinicians' || value === 'numberOfBedsCarts')}
                  cellData={cellData}
                  title={`uhe.table.${value}`}
                  dataKey={value}
                  triggerCharsNum={value === 'customerNo' || value === 'clinicians' || value === 'numberOfBedsCarts' ? 0 : undefined}
                />
              ),
              sorter: (value === 'actions') ? false : { multiple: index },
              defaultSortOrder: (value === 'organization') ? this.checkSortTableOrder() : false,
              align: index > 3 ? 'center' : 'left',
              minWidth: 200,
              dataIndex: value,
              render: (content) => this.cellRenderer(content, value),
            });
          });
        }
      }

      return (
        <Table
          bordered
          className="gx-table-responsive"
          columns={this.columns}
          dataSource={Organizations.dataAdapter(data)}
          pagination={pagination}
          loading={loading}
        />
      );
    }
  }

  render() {
    const { pagination, data, loading } = this.props;
    pagination.onChange = this.onPageChange;

    return (
      <div className="organizations">
        <div className="uhe-table-header">
          <div className="buttons-container">
            {this.renderAddButton()}
            {this.renderExportCsvButton()}
          </div>
          <p>
            <IntlMessages id="uhe.table.matchingResults" />
            <span>{pagination.total}</span>
          </p>
        </div>
        <div className="uhe-table">
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

Organizations.propTypes = {
  data: PropTypes.array,
  intl: PropTypes.object,
  subtitle: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  pagination: PropTypes.object,
  setSubtitle: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ ConfigurationOrganizations, subtitle, ConfigurationUsers }) => {
  const { list, page } = ConfigurationOrganizations.table || {};
  const { loading } = ConfigurationOrganizations;
  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  return {
    data: list, pagination, loading, subtitle,
    loggedUser: ConfigurationUsers.ownUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  deleteOrganization: (id, page, sort, filter, size) => dispatch(deleteOrganization(
    id, page, sort, filter, size,
  )),
  //call action in ManageOrganization
  organizationsOnFetchData: (page, sort, filter) => dispatch(organizationsOnFetchData(
    page, sort, filter,
  )),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(Organizations)));
