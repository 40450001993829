import {
  CONFIGURATION_USERS_FETCH_SUCCESS,
  CONFIGURATION_USERS_FETCH_DATA,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  SAVE_USER_SUCCESS,
  SAVE_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_REQUEST,
  CONFIGURATION_USERS_FETCH_ACCESS_SUCCESS,
  FETCH_IMPERSONATE_TOKEN_SUCCESS,
  CLEAR_LOADING,
  FETCH_OWN_PROFILE_REQUEST,
  FETCH_OWN_PROFILE_SUCCESS,
  SAVE_PERMISSIONS_REQUEST,
  SAVE_PERMISSIONS_SUCCESS,
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD_SUCCESS,
  FETCH_USER_GRANT_REQUEST,
  FETCH_USER_GRANT_REQUEST_SUCCESS,
  SAVE_USERS_GRANTS_REQUEST,
  SAVE_USERS_GRANTS_REQUEST_SUCCESS,
  FETCH_UPLOAD_LOGS_SUCCESS,
  FETCH_UPLOAD_LOGS_REQUEST,
} from '@constants/UHEActionTypes';

import * as AdminRoles from '@constants/AdminRoles';
import { CaseHelper } from 'util/UheHelper';

const INIT_STATE = {
  ownUser: {
    loading: true,
  },
  selectedUser: {},
  userPerm: {},
  organization: [],
  loading: false,
  saved: false,
  table: {
    list: [],
    page: {},
  },
  editTable: [],
  token: {},
  uploadLogs: [],
};

/**
 * @description USERS reducer
 * @param  {Object} state
 * @param  {string} action
 * @return {Object}
 */
export default (state = INIT_STATE, action) => {
  let newState;

  switch (action.type) {
    case FETCH_OWN_PROFILE_REQUEST:
      newState = { ownUser: { loading: true } };
      return { ...state, ...newState };

    case FETCH_OWN_PROFILE_SUCCESS: {
      newState = { ownUser: { ...action.payload, loading: false } };
      const rolesMap = newState.ownUser.roles.reduce((acc, role) => ({ ...acc, [role]: true }), {});
      Object.entries(AdminRoles)
        .forEach(([key, value]) => newState.ownUser[`is${CaseHelper.fromUpperSnakeToPascalCase(key)}`] = !!rolesMap[value]);
      return { ...state, ...newState };
    }
    case CONFIGURATION_USERS_FETCH_SUCCESS:
      newState = { table: action.data, loading: false };
      return { ...state, ...newState };

    case CONFIGURATION_USERS_FETCH_ACCESS_SUCCESS:
      newState = { ...state, editTable: action.payload, loading: false };
      return newState;

    case CONFIGURATION_USERS_FETCH_DATA:
      newState = { loading: true, saved: false };
      return { ...state, ...newState };

    case FETCH_USER_REQUEST:
      return Object.assign(state, {
        loading: true,
        savedUserId: null,
      });
    case DELETE_USERS_REQUEST: {
      return { ...state, loading: true };
    }

    case DELETE_USERS_SUCCESS: {
      const selectedUser = { ...INIT_STATE.selectedUser };
      newState = { selectedUser };
      return { ...state, ...newState, loading: false };
    }

    case SAVE_USER_REQUEST: {
      return { ...state, loading: true };
    }

    case SAVE_USER_SUCCESS: {
      newState = { loading: false, savedUserId: action.payload.id };
      return { ...state, ...newState };
    }

    case FETCH_USER_SUCCESS: {
      const selectedUser = { ...state.selectedUser, ...action.payload };
      newState = { selectedUser, loading: false };
      return { ...state, ...newState };
    }

    case CLEAR_LOADING: {
      return { ...state, loading: false };
    }

    case FETCH_IMPERSONATE_TOKEN_SUCCESS: {
      const newToken = { ...state.token, ...action.token };
      newState = { newToken };
      return { ...state, ...newState };
    }

    case SAVE_PERMISSIONS_REQUEST: {
      return { ...state, loading: true };
    }

    case SAVE_PERMISSIONS_SUCCESS: {
      return { ...state, loading: false };
    }
    case RESET_USER_PASSWORD: {
      return { ...state, loading: true };
    }
    case RESET_USER_PASSWORD_SUCCESS: {
      newState = { loading: false, savedUserId: action.payload.id };
      return { ...state, ...newState };
    }
    case CHANGE_USER_PASSWORD: {
      return { ...state, loading: true };
    }
    case CHANGE_USER_PASSWORD_SUCCESS: {
      newState = { loading: false, savedUserId: action.payload.id };
      return { ...state, ...newState };
    }
    case FETCH_USER_GRANT_REQUEST:
      newState = { loading: true, saved: false };
      return { ...state, ...newState };

    case FETCH_USER_GRANT_REQUEST_SUCCESS:
      newState = { userPerm: action.payload, loading: false };
      return { ...state, ...newState };

    case SAVE_USERS_GRANTS_REQUEST: {
      return { ...state, loading: true };
    }

    case SAVE_USERS_GRANTS_REQUEST_SUCCESS: {
      newState = { loading: false, savedUserId: action.payload.id };
      return { ...state, loading: true };
    }

    case FETCH_UPLOAD_LOGS_REQUEST:
      return { ...state, loading: true };

    case FETCH_UPLOAD_LOGS_SUCCESS:
      newState = { uploadLogs: action.payload, loading: false };
      return { ...state, ...newState };
    default:
      return state;
  }
};
