import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Spin, Form } from 'antd';
import IntlMessages from 'util/IntlMessages';
import {
  shouldShowSaveButton,
  shouldShowCancelButton,
} from '@util/UheRoleChecker';
import { getOwnUser } from '@uhe_selectors/configuration/users/UsersSelectors';
import { useSelector } from 'react-redux';

/**
 * Render Header for edit/add screens
 * @param goBack{function}
 * @param save{string}
 * @param loading{boolean}
 * @param titleKey{boolean}
 *
 * @returns {object}
 */

const EditHeader = ({ goBack, save, loading }) => {
  const loggedUser = useSelector(getOwnUser);
  return (
    <div className="gx-mb-3">
      {loading ? (
        <Row>
          <Col align="center" lg={24} md={24} sm={24} xs={24}>
            <Spin />
          </Col>
        </Row>
      ) : (
        <Row gutter={16}>
          <Col lg={12} md={12} sm={12} xs={12} />
          <Col lg={12} md={12} sm={12} xs={12} className="save-btn-container gx-d-flex gx-justify-content-end">
            <Button
              className="header-btn gx-d-flex gx-justify-content-between"
              onClick={goBack}
            >
              <i className="icon icon-close" />
              <IntlMessages id="common.back" />
            </Button>
            {!shouldShowSaveButton(loggedUser) && <Button
              className="save-icon header-btn gx-d-flex gx-justify-content-between"
              onClick={save}
              htmlType="submit"
            >
              <i className="icon icon-check" />
              <IntlMessages id="configuration.save" />
            </Button>}
            {!shouldShowCancelButton(loggedUser) && <Button
              className="header-btn cancel-btn gx-d-flex gx-justify-content-between"
              onClick={goBack}
            >
              <i className="icon icon-close" />
              <IntlMessages id="common.cancel" />
            </Button>}
          </Col>
        </Row>
      )}
    </div>
  );
};

EditHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  save: PropTypes.func,
  goBack: PropTypes.func.isRequired,
  backToPreviousPage: PropTypes.func.isRequired,
};

export default EditHeader;
