import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';

import { footerText } from 'util/config';
import App from 'routes/index';
import { useSelector } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router-dom';
import UHESystemNotifications from 'components/uhe/UHESystemNotifications';
import UheHelper from 'util/UheHelper';
import { useIntl } from 'react-intl';
import { isNotAdmin } from '@util/UheRoleChecker';
import { getOwnUser } from '@uhe_selectors/configuration/users/UsersSelectors';
import Sidebar from '../Sidebar/index';
import HorizontalDefault from '../Topbar/HorizontalDefault/index';
import HorizontalDark from '../Topbar/HorizontalDark/index';
import InsideHeader from '../Topbar/InsideHeader/index';
import AboveHeader from '../Topbar/AboveHeader/index';
import BelowHeader from '../Topbar/BelowHeader/index';

import Topbar from '../Topbar/index';
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
} from '../../constants/ThemeSetting';
import NoHeaderNotification from '../Topbar/NoHeaderNotification/index';

const { Content, Footer } = Layout;

/**
 *
 */
const MainApp = () => {
  const [hasPermision, setHasPermision] = useState(false);
  const { width, navStyle } = useSelector(({ settings }) => settings);
  const match = useRouteMatch();
  const location = useLocation();
  const qParams = new URLSearchParams(location.search);
  const technicianParam = qParams.has('technician');

  /**
   *
   * @param {*} navStyle
   */
  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return 'gx-container-wrap';
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return 'gx-container-wrap';
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return 'gx-container-wrap';
      case NAV_STYLE_BELOW_HEADER:
        return 'gx-container-wrap';
      case NAV_STYLE_ABOVE_HEADER:
        return 'gx-container-wrap';
      default:
        return '';
    }
  };

  /**
   *
   * @param {*} navStyle
   */
  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        return <Topbar />;
      case NAV_STYLE_DRAWER:
        return <Topbar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return null;
    }
  };

  /**
   *
   * @param {*} navStyle
   * @param {*} width
   */
  const getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar />;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <Sidebar />;
      case NAV_STYLE_DRAWER:
        return <Sidebar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar />;
      default:
        return null;
    }
  };

  const loggedUser = useSelector(getOwnUser);

  const intl = useIntl();

  useEffect(() => {
    if (isNotAdmin(loggedUser)) {
      setHasPermision(true);
    }
  }, [loggedUser]);

  /**
   *
   */
  const removeToken = () => {
    UheHelper.clearAuthTokens();
    UheHelper.removeOwnUserProfile();
    window.location.href = (window.env_overrides.REACT_APP_SSO_REDIRECT_URL || process.env.REACT_APP_SSO_REDIRECT_URL).replace('?', 'logout/?');
  };

  return (
    <Layout className="gx-app-layout">
      <UHESystemNotifications />
      {hasPermision ? '' : getSidebar(navStyle, width)}

      <Layout>
        {getNavStyles(navStyle)}
        {hasPermision
          && (
            <div className="removeToken">
              <p>{intl.formatMessage({ id: 'noAccessMsg' })}</p>
              <span onClick={() => removeToken()}>{intl.formatMessage({ id: 'goToDashBoard' })}</span>
            </div>
          )}
        {(technicianParam || !isNotAdmin(loggedUser)) && (
          <Content
            className={`gx-layout-content ${getContainerClass(navStyle)} `}
          >
            <App match={match} location={location} />
            <Footer>
              <div className="gx-layout-footer-content">
                {footerText}
                <small className="footer-version">
                  v
                  {process.env.REACT_APP_VERSION}
                </small>
              </div>
            </Footer>
          </Content>
        )}
      </Layout>
    </Layout>
  );
};
export default MainApp;
