import {
  SYSTEM_SETTINGS_SYSTEM_FETCH_DATA,
  SYSTEM_SETTINGS_SYSTEM_FETCH_SUCCESS,
  SAVE_SYSTEM_SETTINGS_SYSTEM_REQUEST,
  SAVE_SYSTEM_SETTINGS_SYSTEM_SUCCESS,
  CLEAR_SYSTEM_SETTINGS,
  SEND_TEST_EMAIL,
  SEND_TEST_EMAIL_SUCCESS,
  FETCH_LICENSING_REQUEST,
  FETCH_LICENSING_SUCCESS,
  SEND_LICENSING_KEY_REQUEST,
  SEND_LICENSING_KEY_SUCCESS,
  FETCH_APPLICATION_VERSION_REQUEST,
  FETCH_APPLICATION_VERSION_SUCCESS,
} from '@constants/UHEActionTypes';

/**
 * @description Fetch system data
 * @param  {number} page
 * @return {Object}
 */
export const systemOnFetchData = (page) => {
  return {
    type: SYSTEM_SETTINGS_SYSTEM_FETCH_DATA,
    page,
  };
};

/**
 * @description System data response
 * @param  {Object} data
 * @return {Object}
 */
export const systemFetchDataSuccess = (data) => {
  return {
    type: SYSTEM_SETTINGS_SYSTEM_FETCH_SUCCESS,
    data,
  };
};

/**
 * @description Save system request
 * @param {Object} data
 * @return {Object}
 */
export const saveSystem = (data) => {
  return {
    type: SAVE_SYSTEM_SETTINGS_SYSTEM_REQUEST,
    payload: data,
  };
};

/**
 * @description Save request success
 * @param {object} payload
 * @return {object}
 */
export const saveSystemSuccess = (payload) => {
  return {
    type: SAVE_SYSTEM_SETTINGS_SYSTEM_SUCCESS,
    payload,
  };
};

/**
 * @description Send email request
 * @param {Object} data
 * @return {Object}
 */
export const sendTestEmail = (mail) => {
  return {
    type: SEND_TEST_EMAIL,
    payload: mail,
  };
};

/**
 * @description Send email success
 * @param {object} payload
 * @return {object}
 */
export const sendTestEmailSuccess = (payload) => {
  return {
    type: SEND_TEST_EMAIL_SUCCESS,
    payload,
  };
};

/**
 * @description Clear system settings
 */
export const clearSelection = () => {
  return {
    type: CLEAR_SYSTEM_SETTINGS,
  };
};

/**
 * @description Handles FETCH_LICENSING_REQUEST Action
 * @returns {Object}
 */
export const fetchLicensing = () => {
  return {
    type: FETCH_LICENSING_REQUEST,
  };
};

/**
 * @description Handles FETCH_LICENSING_SUCCESS Action
 * @returns {Object}
 */
export const fetchLicensingSuccess = (data) => {
  return {
    type: FETCH_LICENSING_SUCCESS,
    data,
  };
};

/**
 * @description Handles SEND_LICENSING_KEY_REQUEST Action
 * @param {Object} bodyData
 * @returns {Object}
 */
export const sendLicensingKey = (body) => {
  return {
    type: SEND_LICENSING_KEY_REQUEST,
    body,
  };
};

/**
 * @description Handles SEND_LICENSING_KEY_SUCCESS Action
 * @returns {Object}
 */
export const sendLicensingKeySuccess = () => {
  return {
    type: SEND_LICENSING_KEY_SUCCESS,
  };
};

/**
 * @description Handles FETCH_APPLICATION_VERSION_REQUEST Action
 * @returns {Object}
 */
export const fetchApplicationVersion = () => {
  return {
    type: FETCH_APPLICATION_VERSION_REQUEST
  };
};

/**
 * @description Handles FETCH_APPLICATION_VERSION_SUCCESS Action
 * @param {Object} data 
 * @returns {Object}
 */
export const fetchApplicationVersionSuccess = (data) => {
  return {
    type: FETCH_APPLICATION_VERSION_SUCCESS,
    data
  };
};