import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import RestManager from '@util/RestManager';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import {
  getNotifactionsSuccess,
  getGlobalNotificationsSuccess,
  updateGlobalNotificationsSuccess,
  getCustomerNotificationsSuccess,
  updateCustomerNotificationsSuccess,
} from '@actions/uhe/system/NotificationsActions';
import {
  NOTIFICATIONS_REQUEST,
  GLOBAL_NOTIFICATIONS_REQUEST,
  UPDATE_GLOBAL_NOTIFICATIONS_REQUEST,
  CUSTOMER_NOTIFICATIONS_REQUEST,
  UPDATE_CUSTOMER_NOTIFICATIONS_REQUEST,
} from '@constants/UHEActionTypes';

/**
 * @description Sends GET Request to api/system-alert-email
 * @returns {Object}
 */
const fetchNotifications = async () => {
  const request = await RestManager.request(ENDPOINTS.notifications.systemAlertEmail);

  return request;
};

/**
 * @description Sends GET Request to api/system-alert-email/global
 * @returns {Object}
 */
const fetchGlobalNotifications = async () => {
  const request = await RestManager.request(ENDPOINTS.notifications.systemAlertEmailGlobal);

  return request;
};

/**
 * @description Sends POST Request to api/system-alert-email/global
 * @param {Object} body
 * @returns {Object}
 */
const updateGlobalNotifications = async (body) => {
  const request = await RestManager.requestWithoutQueryParams(
    ENDPOINTS.notifications.systemAlertEmailGlobal,
    'POST',
    body,
  );

  return request;
};

/**
 * @description Sends GET Request to api/system-alert-email/{customer-id}
 * @param {string} id
 * @returns {Object}
 */
const fetchCustomerNotifications = async (id) => {
  const request = await RestManager.request(`${ENDPOINTS.notifications.systemAlertEmail}/${id}`);

  return request;
};

/**
 * @description Sends POST Request to api/system-alert-email/{customer-id
 * @param {Object} body
 * @param {string} id
 * @returns {Object}
 */
const updateCustomerNotifications = async (body, id) => {
  const request = await RestManager.requestWithoutQueryParams(`${ENDPOINTS.notifications.systemAlertEmail}/${id}`, 'POST', body);

  return request;
};

/**
 * @description Dispatches Action to the Store with Notifications Fetched Data
 * @returns {void}
 */
function* getNotifications() {
  try {
    const notificationsData = yield call(fetchNotifications);

    if (notificationsData) {
      yield put(getNotifactionsSuccess(notificationsData));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Dispatches Action to the Store with Global Notifications Fetched Data
 * @returns {void}
 */
function* getGlobalNotifications() {
  try {
    const globalNotificationsData = yield call(fetchGlobalNotifications);

    if (globalNotificationsData) {
      yield put(getGlobalNotificationsSuccess(globalNotificationsData));
    }
  } catch (error) {
    if(error.code !== 403){
      yield put(fetchError(error));
    }
  }
}

/**
 * @description Dispatches Action with Global Notifications Data
 * @param {Object} data
 * @returns {void}
 */
function* adaptGlobalNotifications(data) {
  try {
    const globalNotifications = yield call(updateGlobalNotifications, data.payload.body);

    if (globalNotifications) {
      yield put(updateGlobalNotificationsSuccess(globalNotifications));
      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Dispatches Action to the Store for a Specific Customer Notifications
 * @param {Object} payload
 * @returns {void}
 */
function* getCustomerNotifications({ payload }) {
  try {
    const customerNotificationsData = yield call(fetchCustomerNotifications, payload.id);

    if (customerNotificationsData) {
      yield put(getCustomerNotificationsSuccess({ ...customerNotificationsData }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Dispatches Action with Specific Customer Notifications Data
 * @param {Object} data
 * @returns {void}
 */
function* adaptCustomerNotifications(data) {
  try {
    const customerNotifications = yield call(updateCustomerNotifications, data.payload.body, data.payload.id);

    if (customerNotifications) {
      yield put(updateCustomerNotificationsSuccess(customerNotifications));
      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Saga Watcher
 * @returns {void}
 */
export function* actionsWatcher() {
  yield takeEvery(NOTIFICATIONS_REQUEST, getNotifications);
  yield takeEvery(GLOBAL_NOTIFICATIONS_REQUEST, getGlobalNotifications);
  yield takeEvery(UPDATE_GLOBAL_NOTIFICATIONS_REQUEST, adaptGlobalNotifications);
  yield takeEvery(CUSTOMER_NOTIFICATIONS_REQUEST, getCustomerNotifications);
  yield takeEvery(UPDATE_CUSTOMER_NOTIFICATIONS_REQUEST, adaptCustomerNotifications);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
