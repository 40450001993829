const getVideoList = (state) => state.SupportSettings?.videoData?.list || [];

const getDocumentsList = (state) => state.SupportSettings?.documentData?.list || [];

const getSectionsList = (state) => state.SupportSettings?.sectionData?.list || [];

const getLoading = (state) => state.SupportSettings.loading;

const getSectionsWithMappedDocuments = (state) => {
  const sections = getSectionsList(state);
  const documents = getDocumentsList(state);
  return sections.map((section) => ({
    ...section,
    documents: documents.filter((x) => x.section.id === section.id),
  }));
};

export {
  getVideoList,
  getLoading,
  getSectionsWithMappedDocuments,
};
