export const INIT_CUSTOMER_STATE = {
  loading: true,
  table: {
    list: [],
    page: {},
  },
  actionsTable: {
    list: [],
    page: {},
  },
  selectedCustomer: {
    id: '',
    name: '',
    organization: {
      id: '',
      name: '',
    },
    advanced_reports: {
      active: 0,
      username: '',
      dashboard: '',
    },
    interconnect: {
      server: '',
      username: '',
      password: '',
    },
    perfectServe: {
      url: '',
    },
    interpreter: {
      id: null,
      name: '',
    },
    customerAttributes: {
      getwellUrlWithProtocol: '',
      getwellApiPath: '',
      getwellSystemName: '',
      getwellApiTokenPath: '',
      getwellClientId: '',
      getwellClientSecret: '',
      secondsBeforeReinvitingPatientToCall: '',
    },
    clinicianSidebarConfig: {
      layout: 'default',
      screenshotPermission: 0,
      contentSharingPermission: 0,
    },
    branding: {
      banner_color: '',
      font_color: '',
      attachment: '',
    },
  },
  redirectUrl: '',
  inDemand: {},
  stratus: {},
  readyMap: {},
  interpreterOptions: {
    list: [],
    page: {},
  },
};
