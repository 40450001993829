import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import {
  fetchDataSuccess,
  saveUserSuccess,
  savePermissionsSuccess,
  fetchUserSuccess,
  fetchUserAccessDataSuccess,
  fetchImpersonateTokenSuccess,
  clearLoading,
  onFetchOwnPofileSuccess,
  activateUserSuccess,
  uploadUserDocumentSuccess,
  resetUserPasswordSuccess,
  changeUserPasswordSuccess,
  fetchUserGrant,
  fetchUserGrantSuccess,
  saveUserGrants as saveUserGrantsRequest,
  saveUserGrantsSuccess,
  fetchUser,
  fetchUploadLogsSuccess,
  fetchUploadLogsRequest,
} from '@actions/uhe/configuration/users/UsersActions';
import {
  CONFIGURATION_USERS_FETCH_DATA,
  DELETE_USERS_REQUEST,
  SAVE_USER_REQUEST,
  FETCH_USER_REQUEST,
  CONFIGURATION_USERS_FETCH_ACCESS_DATA,
  SAVE_PERMISSIONS_REQUEST,
  FETCH_IMPERSONATE_TOKEN,
  FETCH_OWN_PROFILE_REQUEST,
  ACTIVATE_USER_REQUEST,
  UPLOAD_USER_DOCUMENT_REQUEST,
  RESET_USER_PASSWORD,
  CHANGE_USER_PASSWORD,
  FETCH_USER_GRANT_REQUEST,
  SAVE_USERS_GRANTS_REQUEST,
  FETCH_UPLOAD_LOGS_REQUEST,
  FETCH_DETAILED_REPORT_REQUEST,
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';
import { IMPERSONATE_TOKEN, ACCESS_TOKEN_KEY } from '@constants/UHESettings';
import { notification } from 'antd';

const getDetailedReport = async (id) => {
  try {
    const response = await RestManager.requestFile(`${ENDPOINTS.users.uploadLogDetailedReport}/${id}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

/**
 * @description Depending on URL sends GET request either to /users/bulk/upload/log?users=false || /users/bulk/upload/log?users=true
 * @returns {object}
 */
const getUploadLogs = async () => {
  const isUsers = window.location.pathname.indexOf('/users') !== -1;
  try {
    const response = await RestManager.request(isUsers ? ENDPOINTS.users.uploadLogUsers : ENDPOINTS.users.uploadLogMobileDevices);
    return response;
  } catch (error) {
    console.log(error);
  }
};

/**
 * @description Request data
 * @param  {number} page
 * @param  {string} sorting
 * @param  {string} filter
 * @return {Object}
 */
const doFetchData = async (page, sorting, filter) => {
  const filterQueryString = filter && filter.length ? `&${filter.join('&')}` : '';
  const sortingQueryString = sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : '';
  return await RestManager.request(
    `${ENDPOINTS.configuration.UsersTable}?page=${
      page || 0
    }${sortingQueryString}${filterQueryString}`,
  );
};

/**
 * @description Request Permission Data
 * @param {number} id
 * @return {Object}
 */
const doFetchEditData = async (id) => await RestManager.request(
  `${ENDPOINTS.configuration.DeleteUser}/${id}/permissions`,
);

/**
 * @description Save request for Permission data
 * @param {number} id
 * @param {Object} data
 * @return {Object}
 */
const doSavePermissions = async (id, data) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.configuration.DeleteUser}/${id}/permissions`,
  'POST',
  data,
);

/**
 * @description Sends GET Request to /users/:id/impersonate
 * @param {number} id
 * @returns {Object}
 */
const getImpersonateToken = async (id) => await RestManager.request(
  `${ENDPOINTS.configuration.UsersTable}/${id}/impersonate`,
);

/**
 * @description Handles Response from /users/:id/impersonate
 * @param {Object} action
 */
function* doFetchImpersonateToken(action) {
  try {
    const token = yield call(getImpersonateToken, action.id);
    if (token) {
      yield put(fetchImpersonateTokenSuccess(token));
      localStorage.setItem(IMPERSONATE_TOKEN, token.auth_token);
      window.location.reload();
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(clearLoading());
  }
}

/**
 * @description Save permission
 * @param {Object} data
 */
function* saveUserPermissions(data) {
  try {
    const user = yield call(
      doSavePermissions,
      data.payload.id,
      data.payload.body,
    );
    if (user) {
      yield put(savePermissionsSuccess(user));
      yield put(showMessage('save_success'));
    }
    const fetchedUser = yield call(doFetchUserById, data.payload.id);
    yield put(fetchUserSuccess(fetchedUser));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Delete request
 * @param {string} id
 * @return  {Promise}
 */
const deleteUserAction = async (id) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.configuration.DeleteUser}/${id}`,
  'DELETE',
);

/**
 * @description Request filtering users table data
 * @param {number} page
 * @param {Array} sorting
 * @param {string} filter
 * @return {Objects}
 */
function* fetchTableData({ page, sorting, filter }) {
  try {
    const fetchedData = yield call(doFetchData, page, sorting, filter);
    yield put(fetchDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchEditTableData({ id }) {
  try {
    const fetchedData = yield call(doFetchEditData, id);
    yield put(fetchUserAccessDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Sends POST Request
 * @param {Object} bodyData
 * @returns {Array}
 */
const saveUserById = async (user, id) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.users.saveUser}/${id}`,
  'POST',
  user,
);

/**
 * @description Delete user by given id
 * @param {string} data
 * @return {void}
 */
function* delUsers(data) {
  try {
    const deletedUser = yield call(deleteUserAction, data.payload.id);
    if (deletedUser && deletedUser.status >= 200 && deletedUser.status < 300) {
      yield call(fetchTableData, {
        sorting: data.payload.sorting,
        page: data.payload.page,
        filter: data.payload.filter,
      });
      yield put(showMessage('delete_success'));
    } else {
      console.error('DELETE ERROR: ', deletedUser);
      yield put(fetchError(deletedUser));
    }
  } catch (error) {
    console.error('DELETE ERROR: ', error);
    yield put(fetchError(error));
  }
}

/**
 * @description Request for add new user
 * @param  {Object} bodyData
 * @return {Object}
 */
const addNew = async (bodyData) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.users.saveUser}`,
  'POST',
  bodyData,
);

/**
 * @description Save user
 * @param {Object} data
 * @returns {void}
 */
function* saveUser(data) {
  try {
    if (data.payload.user.id) {
      if (data.payload.shouldSaveUserDetails) {
        const trimmedData = Object.entries(data.payload.user)
          .filter(([key]) => !(key.startsWith('is_') || key === 'id'))
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
        const user = yield call(saveUserById, trimmedData, data.payload.user.id);

        if (user) {
          user.id = data.payload.user.id;
          user.saved = true;
          yield put(fetchUserSuccess(user));
          yield put(showMessage('save_success'));
        }
      }
      if (data.payload.hasChangedOrganization) {
        yield put(fetchUserGrant({ id: data.payload.user.id }));
      } else {
        yield put(saveUserGrantsRequest(data.payload.grants));
      }
    } else {
      const userId = yield call(addNew, data.payload.user);

      if (userId) {
        if (data.payload.user.thirdpartyauth === true) {
          yield put(saveUserSuccess(userId));
          yield put(showMessage('save_success'));
          yield put(showMessage('auth_success'));
        } else {
          yield put(saveUserSuccess(userId));
          yield put(showMessage('save_success'));
          const fetchedData = yield call(doFetchEditData, userId.id);
          yield put(fetchUserAccessDataSuccess(fetchedData));
        }
      }
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(clearLoading());
  }
}

/**
 * @description Sends GET Request
 * @param {string} id
 * @returns {Array}
 */
const doFetchUserById = async (id) => await RestManager.request(`${ENDPOINTS.users.fetchAll}/${id}`);

/**
 * @description Sends GET Request
 *
 * @returns {Array}
 */
const doFetchOwnProfile = async (id) => await RestManager.request(`${ENDPOINTS.users.ownProfile}`);

/**
 * @description Fetching Facility by Id
 * @param {Object} id
 * @returns {void}
 */
function* fetchUserById({ id }) {
  try {
    const fetchedUser = yield call(doFetchUserById, id);
    if (fetchedUser) {
      yield put(fetchUserSuccess({ ...fetchedUser, id }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Fetching own user profile
 * @param {Object} id
 * @returns {void}
 */
function* fetchOwnProfile() {
  try {
    const profile = yield call(doFetchOwnProfile);
    if (profile) {
      yield put(onFetchOwnPofileSuccess({ ...profile }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Sends POST Request to /authentication/invitation/accept
 * @param {Object} activation
 * @returns {Object}
 */
const activateUserRequest = async (activation) => {
  const response = await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.users.activateUser}`,
    'POST',
    activation,
    [],
    true,
    false,
  );
  return response;
};

/**
 * @description Handles Activate User POST Request
 * @param {Object} userData
 * @returns {void}
 */
function* activateUser(userData) {
  try {
    const activationData = yield call(activateUserRequest, userData.activation);
    if (activationData) {
      yield put(showMessage('save_success'));
      yield put(activateUserSuccess(activationData));
      userData.activation.redirectOnAuth(ACCESS_TOKEN_KEY);
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Upload user document request
 * @param {Object} file
 * @returns {Object}
 */
const uploadUserDocumentRequest = async (file) => await RestManager.formDataRequest(
  `${ENDPOINTS.users.uploadUser}`,
  file,
);

/**
 * @description Handles Response and Request for uploading CSV file
 * @param {Object} payload
 * @returns {void}
 */
function* onUploadUserDocument(payload) {
  try {
    const uploadUserDocument = yield call(
      uploadUserDocumentRequest,
      payload.payload.file,
    );

    if (uploadUserDocument) {
      yield put(uploadUserDocumentSuccess());
      yield put(fetchUploadLogsRequest())
      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Request reset user's own password
 * @param  {Object} bodyData
 * @return {Object}
 */
const reset = async (id, bodyData) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.password.resetPassword}/${id}/password/reset`,
  'POST',
  bodyData,
);

/**
 * @description Reset password
 * @param {Object} data
 * @returns {void}
 */
function* resetPassword(data) {
  try {
    const password = yield call(reset, data.payload.id);
    if (password) {
      yield put(resetUserPasswordSuccess(password));
      yield put(showMessage('reset_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
    const fetchedData = yield call(doFetchData);
    yield put(fetchDataSuccess(fetchedData));
  }
}

/**
 * @description Request reset user's own password
 * @param  {Object} bodyData
 * @return {Object}
 */
const change = async (id, bodyData) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.password.resetPassword}/${id}/password/change`,
  'POST',
  bodyData,
);

/**
 * @description Reset password
 * @param {Object} data
 * @returns {void}
 */
function* changePassword(data) {
  try {
    const password = yield call(change, data.payload.id, data.payload.body);
    if (password) {
      yield put(changeUserPasswordSuccess(password));
      yield put(showMessage('change_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
    const fetchedData = yield call(doFetchData);
    yield put(fetchDataSuccess(fetchedData));
  }
}

/**
 * @description Sends GET Request
 *@param {Number} id
 * @returns {Array}
 */
const doFetchUserGrant = async (id) => await RestManager.request(`${ENDPOINTS.users.saveUser}/${id}/grants`);

/**
 * @description Request filtering users table data
 * @param {Object} data
 * @return {Objects}
 */

function* fetchUserGrantData(data) {
  try {
    const fetchedData = yield call(doFetchUserGrant, data.id);
    yield put(fetchUserGrantSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Handles Upload Log Data
 * @returns {void}
 */
function* fetchUploadLogs() {
  try {
    const fetchedUploadLogs = yield call(getUploadLogs);
    if (fetchedUploadLogs) {
      yield put(fetchUploadLogsSuccess(fetchedUploadLogs));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Sends POST Request
 * @param {Object} user
 * @param {Number} id
 * @returns {Array}
 */
const saveUserGrantsById = async (user, id) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.users.saveUser}/${id}/grants`,
  'POST',
  user,
);

/**
 * @description Save user grants
 * @param {Object} data
 * @returns {void}
 */
function* saveUserGrants(data) {
  try {
    if (data.payload.id) {
      const toSave = { ...data.payload.body, id: undefined };
      const user = yield call(saveUserGrantsById, toSave, data.payload.id);

      if (user) {
        yield put(saveUserGrantsSuccess(user));
        yield put(fetchUser({ id: data.payload.id }));
        yield put(showMessage('save_success'));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(clearLoading());
  }
}
/**
 * @description Handles Detailed Report File Download
 * @param {object} action
 * @param {object} action.payload
 * @returns {void}
 */
function* fetchDetailedReport({ payload }) {
  try {
    const fetchedDetailedReport = yield call(getDetailedReport, payload.id);
    if (fetchedDetailedReport) {
      const url = URL.createObjectURL(fetchedDetailedReport);
      const anchorTag = document.createElement('a');
      document.body.appendChild(anchorTag);
      anchorTag.style = 'display: none';
      anchorTag.href = url;
      anchorTag.download = `detailed_report_${payload.name.replace('.csv', '.txt')}`;
      anchorTag.click();
      document.body.removeChild(anchorTag);
      window.URL.revokeObjectURL(url);
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(FETCH_OWN_PROFILE_REQUEST, fetchOwnProfile);
  yield takeEvery(CONFIGURATION_USERS_FETCH_DATA, fetchTableData);
  yield takeEvery(DELETE_USERS_REQUEST, delUsers);
  yield takeEvery(SAVE_USER_REQUEST, saveUser);
  yield takeEvery(FETCH_USER_REQUEST, fetchUserById);
  yield takeEvery(CONFIGURATION_USERS_FETCH_ACCESS_DATA, fetchEditTableData);
  yield takeEvery(SAVE_PERMISSIONS_REQUEST, saveUserPermissions);
  yield takeEvery(FETCH_IMPERSONATE_TOKEN, doFetchImpersonateToken);
  yield takeEvery(ACTIVATE_USER_REQUEST, activateUser);
  yield takeEvery(UPLOAD_USER_DOCUMENT_REQUEST, onUploadUserDocument);
  yield takeEvery(RESET_USER_PASSWORD, resetPassword);
  yield takeEvery(CHANGE_USER_PASSWORD, changePassword);
  yield takeEvery(FETCH_USER_GRANT_REQUEST, fetchUserGrantData);
  yield takeEvery(SAVE_USERS_GRANTS_REQUEST, saveUserGrants);
  yield takeEvery(FETCH_UPLOAD_LOGS_REQUEST, fetchUploadLogs);
  yield takeEvery(FETCH_DETAILED_REPORT_REQUEST, fetchDetailedReport);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
