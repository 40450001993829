import {
    CONFIGURATION_FACILITIES_FETCH_SUCCESS,
    CONFIGURATION_FACILITIES_FETCH_DATA,
    DELETE_FACILITY_REQUEST,
    DELETE_FACILITY_SUCCESS,
    SAVE_FACILITY_REQUEST,
    SAVE_FACILITY_SUCCESS,
    FETCH_FACILITY_SUCCESS,
    FETCH_FACILITY_REQUEST,
    CLEAR_FACILITY_STATE,
    SAVE_FACILITY_FAIL,
  } from "@constants/UHEActionTypes";
  
  const INIT_STATE = {
    selectedFacility: {},
    loading: false,
    table: {
      list: [],
      page: {},
    }
  };
  
  /**
   * @param  {Object} state
   * @param  {string} action
   * @return {Object}
   */
  export default (state = { ...INIT_STATE }, action) => {
    switch (action.type) {
  
      case CONFIGURATION_FACILITIES_FETCH_SUCCESS:
        const newState = { 'table': action.data, loading: false }
        return Object.assign({}, state, newState);
        break;
      case CONFIGURATION_FACILITIES_FETCH_DATA:
        return Object.assign({}, state, { loading: true });
        break;
      case FETCH_FACILITY_REQUEST:
        return Object.assign(state, { ...INIT_STATE }, { loading: true, savedFacilityId: null });
      case DELETE_FACILITY_SUCCESS: {
        const selectedFacility = { ...INIT_STATE.selectedFacility };
        const newState = { selectedFacility };
        return { ...state, ...newState };
      }
      case SAVE_FACILITY_REQUEST: {
        return { ...state, loading: true }
      }
      case SAVE_FACILITY_FAIL:
        return { ...state, loading: false };
      case SAVE_FACILITY_SUCCESS: {
        const newState = { savedFacilityId: action.payload.id };
        return { ...state, ...newState, loading: false };
      }
      case FETCH_FACILITY_SUCCESS: {
        const selectedFacility = { ...state.selectedFacility, ...action.payload };
        const newState = { selectedFacility, loading: false };
        return { ...state, ...newState };
      }
      case CLEAR_FACILITY_STATE: {
        return { ...INIT_STATE };
      }
      
      default:
        return state;
    }
  }