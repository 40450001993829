import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Card, Table, Collapse,
} from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Countdown from 'react-countdown';
import lodash, { isFunction } from 'lodash';
import RestManager from '@util/RestManager';
import { BASE_URL, ENDPOINTS } from '@constants/UHEEndpoints';
import UheHelper from 'util/UheHelper';

import IntlMessages from 'util/IntlMessages';
import ListingsTopFilter from '@filters/ListingsTopFilter';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import MapClustering from '@components/map/googlemap/mapClustering';

import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import FacilityCell from '@components/tables/cells/FacilityCell';
import UnitCell from '@components/tables/cells/UnitCell';
import VersionCell from '@components/tables/cells/VersionCell';
import InCallCell from '@components/tables/cells/InCallCell';
import IsLiveCell from '@components/tables/cells/IsLiveCell';
import StatusCell from '@components/tables/cells/StatusCell';
import LastEventCell from '@components/tables/cells/LastEventCell';
import UheUnitCell from '@components/tables/cells/UheUnitCell';
import { onFetchOwnPofile } from '@uhe_actions/configuration/users/UsersActions';

import { onFetchData, onFetchPieChartData, onFetchGmapData } from '@uhe_actions/monitoring/UHEActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { systemOnFetchData } from '@uhe_actions/system/SystemActions';
import {
  LISTING_TABLES_PAGE_SIZE,
  TOP_FILTER_PREFIX,
  TABLE_FILTER_PREFIX,
  MAP_FILTER_PREFIX,
  ACKNOWLEDGED_NOTES,
  ACKNOWLEDGED_BY,
} from '@constants/UHESettings';
import {
  COUNTDOWN_TIMER_VALUE,
  COUNTDOWN_REFRESH_VALUE,
} from '@constants/SystemConstants';
import UheChart from './UheChart';

const { Panel } = Collapse;

/**
 * Renders UHE page
 */
class Uhe extends React.Component {
  /**
   * Adapt data returned from the server
   * @param  {Array<Object>} data to adapt
   * @param  {Array<Object>} profile user profile
   * @return {Array<Object>} adapted data
   */
  static dataAdapter(data = [], profile) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        organization: {
          id: value.organization_id,
          name: value.organization_name,
        },
        customer: {
          id: value.customer_id,
          organizationId: value.organization_id,
          name: value.customer_name,
        },
        facility: {
          id: value.facility_id,
          organizationId: value.organization_id,
          customerId: value.customer_id,
          name: value.facility_name,
        },
        unit: {
          id: value.unit_id,
          organizationId: value.organization_id,
          customerId: value.customer_id,
          facilityId: value.facility_id,
          name: value.unit_name,
        },
        bedCart: {
          id: value.device_id,
          name: value.cart_name,
          customerId: value.customer_id,
        },
        deviceId: value.device_id,
        type: value.type,
        version: value.version,
        inCall: value.in_a_call === 1 ? value.named_clinician_in_call : '',
        isLive: value.is_live === 0
          ? (
            <div className="table-popover">
              <p>
                <IntlMessages id="common.mode" />
                :
                {' '}
                {value.device_config_mode}
              </p>
              <p>
                <IntlMessages id="configuration.bedsCarts.deviceConfig.caregilityMonitor" />
                :
                {' '}
                {value.is_live ? 'ON' : 'OFF'}
              </p>
              <p>
                <IntlMessages id="configuration.bedsCarts.deviceConfig.customerNotification" />
                :
                {' '}
                {value.customer_auto_notification ? 'ON' : 'OFF'}
              </p>
            </div>
          )
          : '',
        lastEvent: value.last_event,
        status: {
          status: value.status,
          statusColor: value.status_color,
        },
        acknowledgedBy: <div title={value.acknowledged_by}>{value.acknowledged_notes}</div>,
      });
    });
    return adaptedData;
  }

  /**
   * Uhe Constructor
   * @param {object} props Props
   */
  constructor(props) {
    super(props);
    this.onPageChange = this.onPageChange.bind(this);
    this.date = Date.now();
    const { intl, history } = this.props;

    this.topFilterMap = {
      [`${TOP_FILTER_PREFIX}organization`]: 'organization_id',
      [`${TOP_FILTER_PREFIX}customer`]: 'customer_id',
      [`${TOP_FILTER_PREFIX}facility`]: 'facility_id',
      [`${TOP_FILTER_PREFIX}unit`]: 'unit_id',
    };

    this.tableFilterMap = {
      organization: 'organization_name',
      customer: 'customer_name',
      facility: 'facility_name',
      unit: 'unit_name',
      bedCart: 'cart_name',
      deviceId: 'device_id',
      status: 'status',
      type: 'type',
      version: 'version',
      inCall: 'in_a_call',
      lastEvent: 'last_event',
      isLive: 'is_live',
      acknowledgedBy: 'acknowledged_by',
    }

    this.numberInputs = {
      deviceId: 'number',
    };

    this.locationFilterMap = {
      longitude_min: 'longitude_min',
      latitude_min: 'latitude_min',
      longitude_max: 'longitude_max',
      latitude_max: 'latitude_max',
    };

    this.data = [];
    this.columns = [];
    this.tableKeys = Object.keys(this.tableFilterMap);
    this.filterTypes = {
      status: {
        type: 'dropdown',
        options: [
          { value: 'not_configured', label: intl.formatMessage({ id: 'uhe.table.filter.status.not_configured' }) },
          { value: 'healthy', label: intl.formatMessage({ id: 'uhe.table.filter.status.healthy' }) },
          { value: 'unhealthy', label: intl.formatMessage({ id: 'uhe.table.filter.status.unhealthy' }) },
        ],
      },
      inCall: {
        type: 'dropdown',
        options: [
          { value: '1', label: intl.formatMessage({ id: 'common.yes' }) },
          { value: '0', label: intl.formatMessage({ id: 'common.no' }) },
        ],
      },
      isLive: {
        type: 'dropdown',
        options: [
          { value: '1', label: intl.formatMessage({ id: 'common.yes' }) },
          { value: '0', label: intl.formatMessage({ id: 'common.no' }) },
        ],
      },
      lastEvent: {
        type: 'dropdown',
        options: [
          { value: '1', label: 'to 1 minute' },
          { value: '30', label: 'to 30 minutes' },
          { value: '60', label: 'to 1 hour' },
          { value: '360', label: 'to 6 hours' },
          { value: '720', label: 'to 12 hours' },
          { value: '1440', label: 'to 1 day' },
          { value: '10080', label: 'to 1 week' },
          { value: '44640', label: 'to 1 month' },
          { value: '525600', label: 'to 1 year' },
          { value: '5256000', label: 'to 10 years' },
        ],
      },
    };

    this.tableKeys.forEach((value, index) => {
      const filter = this.filterTypes[value] || {};
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            filterType={filter.type}
            filterOptions={filter.options}
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            triggerCharsNum={value === 'deviceId' || value === 'version' ? 0 : undefined}
          />
        ),
        sorter: (value === 'actions') ? false : { multiple: index },
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.topFilters = [
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byOrganization',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'organization',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byCustomer',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'customer',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byFacility',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'facility',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byUnit',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'unit',
      },
    ];

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);

    this.props.setSubtitle('uhe.title');
    this.props.systemOnFetchData();

    this.onPageChange(1);
    this.state = { 
      showCounter: true,
      showHidePanel: intl.formatMessage({ id: 'common.show' }),
    };
  }

  /**
   * Updates the component on location change
   * @param {Object} prevProps data
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { location, profile } = this.props;
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }
  }

  componentWillUnmount() {
    if(this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  /**
   * @param  {number} page
   * @return {void}
   */
  onPageChange(page) {
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const sort = [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);
      const sorter = currSort.find((element) => element === `${key},asc` || element === `${key},desc`);
      if (filterParam) {
        if (this.filterTypes[key] && this.filterTypes[key].type === 'dropdown') {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else if (this.numberInputs[key]) {
          filter.push(`${value}=${encodeURIComponent(Number(filterParam))}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }

      if (sorter) {
        if (key === ACKNOWLEDGED_BY) {
          sort.push(sorter.replace(key, ACKNOWLEDGED_NOTES));
        } else {
          sort.push(sorter.replace(key, value));
        }
      }
    });

    lodash.forOwn(this.locationFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${MAP_FILTER_PREFIX}${key}`);

      if (filterParam) {
        filter.push(`${value}=${encodeURIComponent(filterParam)}`);
      }
    });

    this.filter = filter;

    this.props.onFetchData(page - 1, sort, filter);
  }

  /**
   * @description Get endpoint url for data export
   * @returns {string}
   */
  getCsvUrl = () => {
    const token = RestManager.getToken();

    const filterQueryString = this.filter
      && this.filter.length ? `&${this.filter.join('&')}` : '';
    const sortingQueryString = this.sorting
      && this.sorting.length ? `&sort=${this.sorting.join('&sort=')}` : '';
    const tokenParam = `${filterQueryString || sortingQueryString ? `&token=${token}` : `token=${token}`}`;
    return `${BASE_URL}${ENDPOINTS.monitoring.UHETable}/csv?${filterQueryString}${sortingQueryString}${tokenParam}`;
  }

  /**
   * Use different cell type depending on the column
   *
   * @param  {Object} content
   * @param  {string} key
   * @return {ReactElement|Object}
   */
  cellRenderer(content, key) {
    const { latestVersion } = this.props;
    let cell;

    switch (key) {
      case 'organization':
        cell = <OrganizationCell content={content} />;
        break;
      case 'customer':
        cell = <CustomerCell content={content} />;
        break;
      case 'facility':
        cell = <FacilityCell content={content} />;
        break;
      case 'unit':
        cell = <UnitCell content={content} />;
        break;
      case 'bedCart':
        cell = <UheUnitCell content={content} />;
        break;
      case 'version':
        cell = <VersionCell content={parseFloat(content) || null} latestVersion={latestVersion} />;
        break;
      case 'inCall':
        cell = <InCallCell content={content} />;
        break;
      case 'isLive':
        cell = <IsLiveCell content={content} />;
        break;
      case 'lastEvent':
        cell = <LastEventCell content={content} />;
        break;
      case 'status':
        cell = <StatusCell content={content} />;
        break;

      default:
        cell = content;
    }

    return cell;
  }

  /**
   * Call component's actions when finish count end
   * @return {void}
   */
  onFinishCount = () => {
    const { onFetchPieChartData, onFetchGmapData } = this.props;
    onFetchPieChartData(this.filter);
    onFetchGmapData(this.filter);
    this.onPageChange(1);
    this.setState({ showCounter: false }, () => {
      this.timeout = setTimeout(() => {
        this.date = Date.now();
        this.setState({ showCounter: true });
        this.timeout = null;
      }, 200);
    });
  }

  /**
   * Change header title when collapse
   * @param {Array} event unique key of panel
   * @returns {String} changed string
   */
  onColapseChange = (event) => {
    const { intl } = this.props;
    const hideText = intl.formatMessage({ id: 'common.hide' });
    const showText = intl.formatMessage({ id: 'common.show' })
    if (event && event[0] === "1") {
      this.setState({ showHidePanel: hideText});
    } else {
      this.setState({ showHidePanel: showText });
    }
  }

  /**
   * Renders Uhe Component
   * @returns {JSX.Element} Uhe Component
   */
  render() {
    const { pagination, data, loading, profile } = this.props;
    const { showCounter, showHidePanel } = this.state;
    pagination.onChange = this.onPageChange;

    return (
      <div className="dashboard">
        <Collapse onChange={this.onColapseChange}>
          <Panel className="gmap-chart-panel" header={showHidePanel} key="1">
            <Row className="chart-map-section" gutter={16}>
              <Col xl={8} lg={24} sm={24} xs={24}>
                <UheChart filter={this.filter} />
              </Col>
              <Col xl={16} lg={24} sm={24} xs={24}>
                <MapClustering filter={this.filter} showResetButton />
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <div>
          <div className="gx-d-flex gx-justify-content-end">
            <p className="gx-mr-3">
              <IntlMessages id="uhe.table.autoRefresh" />
              {showCounter
                && (
                <Countdown
                  onComplete={this.onFinishCount}
                  date={this.date + COUNTDOWN_TIMER_VALUE}
                  renderer={({ seconds }) => (
                    <span className="gx-ml-1">
                      {seconds}
                    </span>
                  )}
                />
                )}
            </p>
            <p>
              <IntlMessages id="uhe.table.matchingResults" />
              <span>{pagination.total}</span>
            </p>
          </div>
          <div className="uhe-table">
            <Table
              bordered
              className="gx-table-responsive"
              columns={this.columns}
              dataSource={Uhe.dataAdapter(data, profile)}
              onChange={this.handleTableChange}
              pagination={pagination}
              loading={loading}
            />
          </div>
        </div>
      </div>
    );
  }
}

Uhe.defaultProps = {
  data: [],
  loading: true,
  pagination: {
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
};

Uhe.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  pagination: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  intl: PropTypes.object,
  latestVersion: PropTypes.number,
  onFetchData: PropTypes.func,
  setSubtitle: PropTypes.func,
};

const mapStateToProps = ({
  MonitoringUHE, subtitle, SystemSettingsSystem, ConfigurationUsers,
}) => {
  const { list = [], page = {} } = MonitoringUHE.table || {};
  const { loading } = MonitoringUHE;
  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  const { production_version = {} } = SystemSettingsSystem.data;
  const latestVersion = parseFloat(production_version.value) || null;

  return {
    data: list,
    pagination,
    loading,
    subtitle,
    latestVersion,
    profile: ConfigurationUsers.ownUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  onFetchData: (page, sort, filter) => dispatch(onFetchData(page, sort, filter)),
  systemOnFetchData: (page) => dispatch(systemOnFetchData(page)),
  onFetchOwnPofile: () => dispatch(onFetchOwnPofile()),
  onFetchPieChartData: (filter) => dispatch(onFetchPieChartData(filter)),
  onFetchGmapData: (filter) => dispatch(onFetchGmapData(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(Uhe)));
