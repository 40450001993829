import {
  DELETE_UHE_BED_CART_SUCCESS,
  DELETE_NON_UHE_BED_CART_SUCCESS,
  FETCH_BED_CART_SUCCESS,
  FETCH_DEVICE_TYPES_SUCCESS,
  FETCH_DEVICE_QR_CODE_SUCCESS,
  FETCH_DEVICE_ADDITIONAL_QR_CODE_SUCCESS,
  CLEAR_BED_CART,
  FETCH_BED_CART_REQUEST,
  SAVE_UHE_BEDCART_REQUEST,
  SAVE_UHE_BEDCART_SUCCESS,
  FETCH_ACKNOWLEDGED_REQUEST,
  FETCH_ACKNOWLEDGED_SUCCESS,
  FETCH_ACKNOWLEDGED_NOTE_RREQUEST,
  FETCH_ACKNOWLEDGED_NOTE_SUCCESS,
  FETCH_ACKNOWLEDGED_FALSE_REQUEST,
  FETCH_ACKNOWLEDGED_FALSE_SUCCESS,
  FETCH_BEDCART_IMAGE_SUCCESS,
  FETCH_CARE_DEVICE_MODES,
  FETCH_CARE_DEVICE_MODES_SUCCESS,
  FETCH_CALL_ACTION,
  FETCH_CALL_ACTION_SUCCESS,
  DELETE_ROOM_PICTURE_SUCCESS,
  SAVE_BEDCART_FAIL,
} from '@constants/UHEActionTypes';

const INIT_STATE = {
  selectedBedCart: {},
  deviceTypes: [],
  loading: false,
  modes: {},
  callInfo: {},
  qrData: {},
  qrAdditionalData: {},
};

/**
 *
 * @description UHE AND NON UHE Beds/Carts Reducers
 * @param  {Object} state
 * @param  {string} action
 * @return {Object}
 */
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DELETE_UHE_BED_CART_SUCCESS: {
      const selectedUheBedCart = { ...INIT_STATE.selectedUheBedCart };
      const newState = { selectedUheBedCart };
      return { ...state, ...newState };
    }

    case DELETE_NON_UHE_BED_CART_SUCCESS: {
      const selectedNonUheBedCart = { ...INIT_STATE.selectedNonUheBedCart };
      const newState = { selectedNonUheBedCart };
      return { ...state, ...newState };
    }

    case FETCH_BED_CART_SUCCESS: {
      const selectedBedCart = {
        ...INIT_STATE.selectedBedCart,
        ...action.payload,
      };
      const newState = { selectedBedCart, loading: false };
      return { ...state, ...newState };
    }

    case FETCH_BED_CART_REQUEST: {
      return { ...state, loading: true };
    }

    case FETCH_DEVICE_TYPES_SUCCESS: {
      const deviceTypes = [];
      action.data.types.list.forEach((type) => {
        const currentType = {
          value: type.device_common_name,
          active: type.active,
          disabled: type.active !== 1,
          list_priority: type.list_priority,
        };
        deviceTypes.push(currentType);
      });
      return { ...state, deviceTypes };
    }

    case FETCH_DEVICE_QR_CODE_SUCCESS: {
      const qrData = action.payload;

      return { ...state, qrData };
    }

    case FETCH_DEVICE_ADDITIONAL_QR_CODE_SUCCESS: {
      const qrAdditionalData = action.payload;

      return { ...state, qrAdditionalData };
    }

    case CLEAR_BED_CART: {
      return { ...INIT_STATE };
    }
    case SAVE_UHE_BEDCART_REQUEST: {
      return { ...state, loading: true };
    }
    case SAVE_BEDCART_FAIL:
      return { ...state, loading: false };
    case FETCH_ACKNOWLEDGED_REQUEST: {
      return { ...state, loading: false };
    }
    case FETCH_ACKNOWLEDGED_SUCCESS: {
      const newState = { savedUheBedCartId: action.payload.id };
      return { ...state, ...newState, loading: false };
    }
    case FETCH_ACKNOWLEDGED_FALSE_REQUEST: {
      return { ...state, loading: false };
    }
    case FETCH_ACKNOWLEDGED_FALSE_SUCCESS: {
      const newState = { savedUheBedCartId: action.payload.id };
      return { ...state, ...newState, loading: false };
    }
    case FETCH_ACKNOWLEDGED_NOTE_RREQUEST: {
      return { ...state, loading: false };
    }
    case FETCH_ACKNOWLEDGED_NOTE_SUCCESS: {
      const newState = { savedUheBedCartId: action.payload.id };
      return { ...state, ...newState, loading: false };
    }

    case SAVE_UHE_BEDCART_SUCCESS: {
      const newState = { savedUheBedCartId: action.payload.id };
      return { ...state, ...newState, loading: false };
    }
    case FETCH_CALL_ACTION: {
      const newState = { savedUheBedCartId: action.payload };
      return Object.assign({}, state, newState);
    }
    case FETCH_CALL_ACTION_SUCCESS: {
      const newState = { callInfo: action.data };
      return { ...state, ...newState };
    }
    case FETCH_CARE_DEVICE_MODES: {
      const newState = { loading: true, saved: true };
      return Object.assign({}, state, newState);
    }
    case FETCH_CARE_DEVICE_MODES_SUCCESS: {
      const newState = { modes: action.data, loading: false };
      return Object.assign({}, state, newState);
    }
    case DELETE_ROOM_PICTURE_SUCCESS: {
      const selectedUheBedCart = { ...INIT_STATE.selectedUheBedCart };
      const newState = { ...selectedUheBedCart };
      return { ...state, ...newState };
    }
    default:
      return state;
  }
};
