import {
  BULK_ACTION_REQUEST,
  BULK_ACTION_REQUEST_SUCCESS,
  CLEAN_UP_SELECTED_CUSTOMER,
  CLEAR_CUSTOMER_LOADING,
  CONFIGURATION_CUSTOMERS_FETCH_DATA,
  CONFIGURATION_CUSTOMERS_FETCH_DEVICES,
  CONFIGURATION_CUSTOMERS_FETCH_DEVICES_SUCCESS,
  CONFIGURATION_CUSTOMERS_FETCH_SUCCESS,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_PENDING_ACTION_REQUEST,
  DELETE_PENDING_ACTION_SUCCESS,
  DELETE_STRATUS_INTEPRETER_SUCCESS,
  FETCH_BRANDING_SUCCESS,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_INDEMAND_INTERPRETER,
  FETCH_INDEMAND_INTERPRETER_SUCCESS,
  FETCH_STRATUS_INTERPRETER,
  FETCH_STRATUS_INTERPRETER_SUCCESS,
  PENDING_ACTION_DATA,
  PENDING_ACTION_DATA_SUCCESS,
  SAVE_CUSTOMER_REQUEST,
  SAVE_CUSTOMER_SUCCESS,
  SET_REDIRECT_URL,
  FETCH_INTERPRETER_OPTIONS_REQUEST,
  FETCH_INTERPRETER_OPTIONS_SUCCESS,
  FETCH_INTERPRETER_REQUEST,
  FETCH_INTERPRETER_SUCCESS,
  UPLOAD_JAMF_DATA_REQUEST,
  UPLOAD_JAMF_DATA_REQUEST_SUCCESS,
} from '@constants/UHEActionTypes';
import { INIT_CUSTOMER_STATE } from '@constants/UHEInitStateObjects';

/**
 * @param  {Object} state
 * @param  {string} action
 * @return {Object}
 */
export default (state = { ...INIT_CUSTOMER_STATE }, action) => {
  let newState = null;
  switch (action.type) {
    case CONFIGURATION_CUSTOMERS_FETCH_SUCCESS:
      newState = { table: action.data, loading: false };
      return { ...state, ...newState };

    case CONFIGURATION_CUSTOMERS_FETCH_DATA:
      return { ...state, loading: true };

    case FETCH_CUSTOMER_SUCCESS:
      const selectedCustomer = {
        ...state.selectedCustomer,
        ...action.payload,
        loading: false,
      };
      newState = { selectedCustomer };
      return { ...state, ...newState, readyMap: { ...state.readyMap, customer: true } };

    case SAVE_CUSTOMER_REQUEST: {
      return {
        ...state,
        loading: true,
        readyMap: {
          ...Object.keys(state.readyMap).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
        },
      };
    }

    case SAVE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        selectedCustomer: { ...action.payload },
        loading: false,
        readyMap: {
          ...Object.keys(state.readyMap)
            .reduce((readyMap, key) => ({ ...readyMap, [key]: true }), {}),
        },
      };
    }

    // Case is never reached because on delete all data is fetched again and insted of
    // DELETE_CUSTOMER_SUCCESS, CONFIGURATION_CUSTOMERS_FETCH_SUCCESS is called
    case DELETE_CUSTOMER_SUCCESS: {
      newState = {
        ...state,
        table: {
          list: state.table.list.filter((i) => i.id !== action.payload.id),
          page: {
            ...state.table.page,
            totalElements: state.table.page.totalElements - 1,
          },
        },
      };
      return newState;
    }

    case FETCH_BRANDING_SUCCESS:
      return {
        ...state,
        selectedBranding: { ...action.payload },
        readyMap: { ...state.readyMap, customer: true },
      };

    case SET_REDIRECT_URL:
      const redirectUrl = action.payload.url;
      newState = { redirectUrl };
      return { ...state, ...newState };

    case CLEAN_UP_SELECTED_CUSTOMER: {
      return { ...INIT_CUSTOMER_STATE };
    }

    case CLEAR_CUSTOMER_LOADING: {
      return { ...state, loading: false };
    }

    case FETCH_INDEMAND_INTERPRETER: {
      return { ...state, loading: true };
    }

    case FETCH_INDEMAND_INTERPRETER_SUCCESS: {
      const inDemand = { ...action.data };
      newState = { inDemand };

      return { ...state, ...newState };
    }

    case FETCH_STRATUS_INTERPRETER: {
      return { ...state, loading: true };
    }

    case FETCH_STRATUS_INTERPRETER_SUCCESS: {
      const stratus = { ...action.data };
      newState = { stratus };

      return { ...state, ...newState, loading: false };
    }

    case DELETE_STRATUS_INTEPRETER_SUCCESS: {
      const stratus = { ...state.stratus };
      const newState = { stratus };

      return { ...state, ...newState };
    }

    case CONFIGURATION_CUSTOMERS_FETCH_DEVICES:
      return { ...state, loading: true };

    case CONFIGURATION_CUSTOMERS_FETCH_DEVICES_SUCCESS:
      newState = { table: action.data, loading: false };
      return { ...state, ...newState };

    case BULK_ACTION_REQUEST: {
      return { ...state, loading: true };
    }

    case BULK_ACTION_REQUEST_SUCCESS: {
      const selectedCustomer = { ...action.payload };
      newState = { selectedCustomer };
      return { ...state, ...newState, loading: false };
    }
    case PENDING_ACTION_DATA_SUCCESS:
      newState = { actionsTable: action.data, loading: false };
      return { ...state, ...newState };

    case PENDING_ACTION_DATA:
      return { ...state, loading: true };

    case DELETE_PENDING_ACTION_REQUEST: {
      return { ...state, loading: true };
    }
    case DELETE_PENDING_ACTION_SUCCESS: {
      const selectedDomain = { ...INIT_CUSTOMER_STATE.actionsTable };
      newState = { selectedDomain };
      return { ...state, ...newState, loading: false };
    }
    case FETCH_INTERPRETER_OPTIONS_REQUEST:
      return {
        ...state, readyMap: { ...state.readyMap, interpreters: false },
      };
    case FETCH_INTERPRETER_OPTIONS_SUCCESS:
      return {
        ...state,
        interpreterOptions: action.payload,
        readyMap: { ...state.readyMap, interpreters: true },
      };
    case FETCH_INTERPRETER_REQUEST:
      return { ...state, readyMap: { ...state.readyMap, selectedInterpreter: false } };
    case FETCH_INTERPRETER_SUCCESS:
      return {
        ...state,
        readyMap: { ...state.readyMap, selectedInterpreter: true },
        interpreterOptions: {
          ...state.interpreterOptions,
          list: state.interpreterOptions.list
            .map((interpreter) => (interpreter.id === action.payload.id
              ? { ...interpreter, ...action.payload.data }
              : interpreter)),
        },
      };
    case UPLOAD_JAMF_DATA_REQUEST: {
      newState = { loading: true, saved: false };
      return { ...state, ...newState };
    }
    case UPLOAD_JAMF_DATA_REQUEST_SUCCESS: {
      newState = { loading: false, savedDocumentId: action };
      return { ...state, ...newState };
    }
    default:
      return state;
  }
};
