import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row, Col, Card, Input, Select, Form, Cascader, Button, notification,
} from 'antd';
import EditHeader from '@components/uhe/configuration/EditHeader';
import * as mobilePatientActions from '@uhe_actions/configuration/mobilePatients/MobilePatientsActions';
import { mobilePatientAccessData as mobilePatientAccessDataDispatch, fetchMDMToken } from '@uhe_actions/configuration/mobilePatients/MobilePatientsActions';
import { onGetOptions } from '@uhe_actions/filters/ListingsTopFilterActions';
import { setSubtitle as setSubtitleDispatch } from '@uhe_actions/SubtitleActions';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
  MEDICAL_ROLE_OPTIONS,
  MEDICAL_SPECIALITY_OPTIONS,
} from '@constants/UHESettings';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import CustomerCell from '@components/tables/cells/CustomerCell';
import { goBackTo, cascaderSearchFilter } from '@util/UheHelper';
import { withLastLocation } from 'react-router-last-location';
import AuditInfo, { AuditTypes } from '@components/uhe/configuration/AuditInfo';
import ReactiveMobilePatient from './ReactiveMobilePatient';
import { MobilePatientTransformer } from './MobilePatientUtils';
import UheHelper from 'util/UheHelper';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import RestManager from '@util/RestManager';
import {
  shouldShowEditHeaderByod,
} from '@util/UheRoleChecker';
const { Option } = Select;

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
};

/**
 * @param {Array<String>} data
 * @returns {Select.Option[]}
 */
const renderOptions = (data = []) => data
  .map((item) => (<Option key={item} value={item}>{item}</Option>));

const optionMedicalRoleItems = renderOptions(MEDICAL_ROLE_OPTIONS);
const optionMedicalSpecialtyItems = renderOptions(MEDICAL_SPECIALITY_OPTIONS);

/**
 * @description Renders Mobile Patient Add/Edit Page
 */
class ManageMobilePatient extends React.Component {
  formRef = React.createRef();

  data = [];

  constructor(props) {
    super(props);
    const { location, intl } = this.props;
    this.isNew = location.pathname.indexOf('/new') !== -1;
    this.intl = intl;

    this.state = {
      mobilePatient: new ReactiveMobilePatient(),
      data: [],
      error: null,
    };

    const { subtitle, setSubtitle } = this.props;
    if (this.isNew) {
      const { onGetOrganizationOptions } = this.props;
      if (subtitle.langId !== 'configuration.mobilePatients.add') {
        setSubtitle('configuration.mobilePatients.add');
      }

      onGetOrganizationOptions();
    } else if (subtitle.langId !== 'configuration.mobilePatients.edit') {
      setSubtitle('configuration.mobilePatients.edit');
    }

    this.tableKeys = [
      'customer',
      'clinician',
      'iobserver',
      'technician',
    ];

    this.columns = this.tableKeys
      .reduce((acc, value, index) => {
        acc.push({
          title: (cellData) => (
            <ListingsTableInputFilter
              showFilter={!this.tableKeys.filter((x) => x === value)}
              cellData={cellData}
              title={`configuration.users.${value}`}
              dataKey={value}
            />
          ),
          sorter: false,
          align: index > 3 ? 'center' : 'left',
          minWidth: 200,
          dataIndex: value,
        });

        return acc;
      }, []);

  }

  /**
   * @returns {void}
   */
  componentDidMount() {
    const {
      match: { params: { id } },
      mobilePatientAccessData,
      actions: { fetchMobilePatient },
      mobilePatient: { id: mobilePatientId },
    } = this.props;
    if (!this.isNew) {
      mobilePatientAccessData({ id });
      fetchMobilePatient({ id });
    }

    if (id && id !== mobilePatientId) {
      fetchMobilePatient({ id });
    }
  }

  /**
   * @description Watch for empty data and if is empty set new state data
   */
  componentDidUpdate(prevProps) {
    const { savedMobilePatientId } = this.props;

    const { location } = this.props;
    this.isNew = location.pathname.indexOf('/new') !== -1;

    {
      const { error, history, token } = this.props;
      if (prevProps.error !== error) {
        // handle system error
        switch (error.code) {
          case 404:
            if (!this.isNew) {
              history.push('/configuration/mobile-patients');
            }
            break;
          case 400:
            history.push(location.pathname);
            break;
          default:
            break;
        }
      }

      if (this.isNew && savedMobilePatientId !== prevProps.savedMobilePatientId) {
        const {
          actions: { fetchMobilePatient },
        } = this.props;
        fetchMobilePatient({ id: savedMobilePatientId });
        history.push(`/configuration/mobile-patients/edit/${savedMobilePatientId}`);
        return;
      }
    }

    {
      const {
        mobilePatient,
        onGetOrganizationOptions,
        onGetCustomerOptions,
        onGetFacilityOptions,
        onGetUnitOptions,
      } = this.props;
      if (!this.isNew
        && prevProps.mobilePatient
        && mobilePatient
        && mobilePatient.organization) {
        if (prevProps.mobilePatient.id !== mobilePatient.id
          || prevProps.mobilePatient.saved !== mobilePatient.saved) {
          const normalizedPatient = MobilePatientTransformer.fromApiToReact(mobilePatient);
          this.setState({ mobilePatient: normalizedPatient });
          const { facility, customer, organization } = normalizedPatient;
          onGetOrganizationOptions();
          onGetCustomerOptions(organization.id);
          onGetFacilityOptions(customer.id);
          onGetUnitOptions(facility.id);
        }
      }
    }

    {
      const { data } = this.props;
      if (prevProps.data.length === 0 && data.length > 0) {
        this.setState({ data });
      }
    }

    const { mobilePatient } = this.state;
    this.formRef.current.setFieldsValue({
      ...mobilePatient,
      organization: [mobilePatient.organization?.name].filter((x) => !!x),
      customer: [mobilePatient.customer?.name].filter((x) => !!x),
      facility: [mobilePatient.facility?.name].filter((x) => !!x),
      unit: [mobilePatient.unit?.name].filter((x) => !!x),
      mobileDeviceType: mobilePatient.specialty,
    });
  }

  /**
   * @description Renders different cell type depending on the column
   *
   * @param  {Object} content
   * @param  {string} key
   * @return {ReactElement|Object}
   */
  cellRenderer = (content, key) => {
    let cell;

    switch (key) {
      case 'customer':
        cell = content.id ? <CustomerCell content={content} /> : 'All Customers';
        break;
      default:
        cell = content;
    }
    return cell;
  }

  /**
   * @description Save Changes on Save Button Click for Permission Settings
   * @param {Object} event
   * @returns {void}
   */
  saveUserPermissions = (event) => {
    event.persist();
    event.preventDefault();
    const { actions, match: { params: { id } } } = this.props;
    const { data: body } = this.state;

    actions.savePermissions({ body, id });
  }

  /**
   * @description Save Changes on Save Button Click in the header
   * @param {Object} event
   * @returns {void}
   */
  saveMobilePatient = async (event) => {
    event.persist();
    event.preventDefault();

    try {
      await this.formRef.current.validateFields();

      const { actions, setSubtitle } = this.props;
      const { mobilePatient } = this.state;
      actions.saveMobilePatient({
        mobilePatient: {
          ...MobilePatientTransformer.fromReactToApi(mobilePatient, this.isNew),
        },
      });
      setSubtitle('configuration.mobilePatients.edit');
    } catch (info) {
      console.log('info:', info);
    }
  }

  /**
   * @description Render title, back and save buttons
   * @returns {JSX.Element|null}
   */
  renderHeadLine = () => {
    const { editMode, loading, lastLocation, history } = this.props;
    const titleKey = editMode
      ? 'configuration.users.addUser'
      : 'configuration.users.editUser';

    if (shouldShowEditHeaderByod()) {
      return (
        <EditHeader
          goBack={goBackTo(`/configuration/mobile-patients?sort=${encodeURIComponent('firstName,asc')}`, lastLocation?.pathname, history)}
          save={this.saveMobilePatient}
          loading={loading}
          titleKey={titleKey}
        />
      );
    }

    return null;
  }

  /**
   * @description Render title, back and save buttons
   * @returns {JSX.Element}
   */
  renderSaveTable = () => {
    const { loading } = this.props;
    return (
      <EditHeader
        save={this.saveUserPermissions}
        loading={loading}
        goBack={this.goBack}
      />
    );
  }

  /**
   * @description
   * @param key
   */
  onChangeOf = (key) => ({
    /**
     * @description Handles changes in input fields
     * @param {ChangeEvent<HTMLInputElement>} event
     * @returns {void}
     */
    input: (event) => {
      if (event && event.persist && event.preventDefault) {
        event.persist();
        event.preventDefault();
      }

      const { mobilePatient } = this.state;
      this.setState({
        mobilePatient: {
          ...mobilePatient,
          [key]: event.target.value,
        },
      });
    },
    /**
     * @description Handles changes in checkboxes
     * @param {ChangeEvent<HTMLInputElement>} event
     * @returns {void}
     */
    checkbox: (event) => {
      const { mobilePatient } = this.state;
      this.setState({
        mobilePatient: {
          ...mobilePatient,
          [key]: event.target.checked ? 1 : 0,
        },
      });
    },
    /**
     * @description Returns event handler for specified key with data loader for dependency
     * @param {string|null} dependentProp If specified,
     * calls function for data load onGet_PROPERTY_Options
     * @returns {function}
     */
    cascader: (dependentProp = null) =>
      /**
       * @description Handles changes in Cascader
       * @param {CascaderValueType} value
       * @param {string} id
       * @param {string} name
       * @returns {void}
       */
      // eslint-disable-next-line implicit-arrow-linebreak
      (value, [{ id, name }]) => {
        const { mobilePatient } = this.state;
        if (id !== undefined && !!dependentProp) {
          const { mobilePatient: { [key]: { id: prevId } } } = this.state;
          if (prevId !== id) {
            mobilePatient[dependentProp] = { id: null, name: '' };
          }

          const upperCasePropName = dependentProp.charAt(0).toUpperCase() + dependentProp.slice(1);
          const { [`onGet${upperCasePropName}Options`]: func } = this.props;
          if (func) {
            func(id);
          }
        }

        this.setState({ mobilePatient: { ...mobilePatient, [key]: { id, name } } });
      },
    /**
     * @description Handles changes in SelectOption
     * @param {string} value
     * @returns {void}
     */
    option: (value) => {
      const { mobilePatient } = this.state;
      mobilePatient[key] = value;
      this.setState({ mobilePatient });
    },
  })

  /**
   * @description Handles changes in Permission checkboxes
   * @param {string} key
   * @param {number} index
   */
  onChangePermissionCheckbox = (key, index) =>
    /**
     * @param {CheckboxChangeEvent} event
     * @returns {void}
     */
    // eslint-disable-next-line implicit-arrow-linebreak
    (event) => {
      const { data: [...data] } = this.state;
      data[index][key] = event.target.checked;
      this.setState({ data });
    }

  /**
   * @description Renders AuditInfo component
   * @param {number} auditType Use AuditType for setting this field
   * @returns {JSX.Element}
   */
  renderAuditInfo(auditType) {
    const { mobilePatient } = this.state;
    return (
      <AuditInfo
        className="audit-info"
        type={auditType}
        data={mobilePatient}
      />
    );
  }

  /**
   * Copy text helper method
   * @param {String} text for token
   * @param {Boolean} useRange
   * @returns copied text
   */

  copyTextToClipboard = (text, useRange) => {
    if (!navigator.clipboard) {
      let isSuccessful = true;
      const temp = document.createElement(useRange ? 'DIV' : 'INPUT');
      document.body.append(temp);
      if (useRange) {
        temp.innerText = text;
        const range = document.createRange();
        range.selectNode(temp);
        window.getSelection().addRange(range);
      } else {
        temp.setAttribute('type', 'text');
        temp.setAttribute('value', text);
        temp.focus();
        temp.select();
      }
      try {
        isSuccessful = document.execCommand('copy');
        if (!isSuccessful && !useRange) {
          document.body.removeChild(temp);
          return this.copyTextToClipboard(text, true);
        }
      } catch (error) {
        isSuccessful = false;
        console.log('[GO] Copy to clipboard Fallback error', error);
      }
      window.getSelection().removeAllRanges();
      document.body.removeChild(temp);
      temp.remove();
      return isSuccessful ? Promise.resolve(text) : Promise.reject(text);
    }
    return navigator.clipboard.writeText(text)
      .then(() => text, (err) => {
        console.log('[GO] Async copy to clipboard: error', err);
        throw text;
      });
  };

  /**
   * Show notification message
   * @param {String} type
   */

  openNotificationWithIcon = (type) => {
    notification[type]({
      message: this.intl.formatMessage({ id: 'common.copySuccess' }),
      description: this.intl.formatMessage({ id: 'common.copySuccessInfo' }),
    });
  };

  /**
   * Generate mdm token
   * @param {Object} event
   */

  copyMdmToken = async (event) => {
    event.persist();
    event.preventDefault();
    const { match } = this.props;
    try {
      const generatedToken = await RestManager.requestWithoutQueryParams(
        `${ENDPOINTS.configuration.UsersTable}/${match.params.id}/mdm_token`,
        'POST',
      );
      if (generatedToken) {
        await this.copyTextToClipboard(generatedToken.mdm_token, false);
        this.openNotificationWithIcon('success');
      }
    } catch (info) {
      console.log('info:', info);
    }
  }

  render() {
    const { loading, optionsList: options, token } = this.props;
    const { mobilePatient, error } = this.state;

    return (
      <div className="manage-customer-wrapper system">
        <Form {...layout} ref={this.formRef}>
          {this.renderHeadLine()}
          {!loading && error && this.showError()}
          <Row gutter={16}>
            <Col lg={10} md={10} sm={10} sx={24}>
              {/* Personal info */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="configuration.users.personalInfo" />}
                loading={loading}
              >
                <Form.Item
                  className="form-item-row"
                  name="mobileDeviceRole"
                  label={<IntlMessages id="configuration.mobilePatients.mobileDeviceRole" />}
                  initialValue={MEDICAL_ROLE_OPTIONS[0]}
                  rules={[
                    {
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.mobilePatients.deviceRoleInfo' }),
                    },
                  ]}
                >
                  <Select
                    onChange={this.onChangeOf('role').option}
                    className="top-filter-popup"
                    value={mobilePatient.role = MEDICAL_ROLE_OPTIONS[0]}
                    longdesc={this.intl.formatMessage({ id: 'configuration.mobilePatients.mobileDeviceRole' })}
                  >
                    {optionMedicalRoleItems}
                  </Select>
                </Form.Item>
                <Form.Item
                  className="form-item-row"
                  name="mobileDeviceType"
                  label={<IntlMessages id="configuration.mobilePatients.fieldMobileDeviceType" />}
                  rules={[
                    {
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.mobilePatients.deviceTypeInfo' }),
                    },
                  ]}
                  initialValue={mobilePatient.specialty}
                >
                  <Select
                    onChange={this.onChangeOf('specialty').option}
                    className="top-filter-popup"
                    longdesc={this.intl.formatMessage({ id: 'configuration.mobilePatients.fieldMobileDeviceType' })}
                  >
                    {optionMedicalSpecialtyItems}
                  </Select>
                </Form.Item>
                <Form.Item
                  className="form-item-row"
                  name="email"
                  label={<IntlMessages id="configuration.users.email" />}
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: this.intl.formatMessage({ id: 'configuration.mobilePatients.emailInfo' }),
                    },
                  ]}
                  initialValue={mobilePatient.email}
                >
                  <Input
                    onChange={this.onChangeOf('email').input}
                    longdesc={this.intl.formatMessage({ id: 'configuration.users.emailInput' })}
                  />
                </Form.Item>
                {this.isNew
                  ? (
                    <Form.Item
                      className="form-item-row"
                      name="password"
                      label={<IntlMessages id="appModule.password" />}
                      initialValue={mobilePatient.password}
                      rules={[
                        {
                          required: mobilePatient.specialty === MEDICAL_SPECIALITY_OPTIONS[0],
                          message: this.intl.formatMessage({ id: 'configuration.mobilePatients.passwordInfo' }),
                        },
                      ]}
                    >
                      <Input.Password
                        disabled={mobilePatient.specialty !== MEDICAL_SPECIALITY_OPTIONS[0]}
                        onChange={this.onChangeOf('password').input}
                        longdesc={this.intl.formatMessage({ id: 'appModule.password' })}
                      />
                    </Form.Item>
                  )
                  : <></>}
                <Form.Item
                  className="form-item-row"
                  name="firstName"
                  label={
                    mobilePatient.specialty === MEDICAL_SPECIALITY_OPTIONS[0] ? (
                      <IntlMessages id="configuration.users.roomName" />
                    ) : (
                      <IntlMessages id="configuration.users.firstName" />
                    )
                  }
                  initialValue={mobilePatient.firstName}
                  rules={[
                    {
                      required: true,
                      message:
                        mobilePatient.specialty === MEDICAL_SPECIALITY_OPTIONS[0]
                          ? this.intl.formatMessage({
                            id: 'configuration.mobilePatients.roomNameInfo',
                          })
                          : this.intl.formatMessage({
                            id: 'configuration.mobilePatients.firstNameInfo',
                          }),
                    },
                  ]}
                >
                  <Input
                    id="aSettingsUsername"
                    onChange={this.onChangeOf('firstName').input}
                    value={mobilePatient.firstName}
                    longdesc={this.intl.formatMessage({ id: 'configuration.users.roomNameInput' })}
                  />
                </Form.Item>
                <Form.Item
                  className="form-item-row"
                  name="lastName"
                  label={
                    mobilePatient.specialty === MEDICAL_SPECIALITY_OPTIONS[0] ? (
                      <IntlMessages id="configuration.users.roomDetails" />
                    ) : (
                      <IntlMessages id="configuration.users.lastName" />
                    )
                  }
                >
                  <Input
                    id="aSettingsUserlastname"
                    onChange={this.onChangeOf('lastName').input}
                    value={mobilePatient.lastName}
                    longdesc={this.intl.formatMessage({ id: 'configuration.users.roomDetailsInput' })}
                  />
                </Form.Item>
              </Card>
            </Col>
            <Col lg={14} md={14} sm={14} sx={24}>
              {/* Customer Assignment */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="configuration.users.customerAssignment" />}
                loading={loading}
              >
                <Form.Item
                  className="form-item-row"
                  label={<IntlMessages id="uhe.table.organization" />}
                  name="organization"
                  rules={[
                    {
                      type: 'array',
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.mobilePatients.organizationMsg' }),
                    },
                  ]}
                >
                  <Cascader
                    className="top-filter-popup"
                    fieldNames={{ label: 'name', value: 'id' }}
                    key="organization"
                    expandTrigger="hover"
                    size="large"
                    changeOnSelect
                    value={[mobilePatient.organization.id]}
                    allowClear={false}
                    options={options.organization || []}
                    onChange={this.onChangeOf('organization').cascader('customer')}
                    placeholder={this.intl.formatMessage({ id: 'uhe.table.organization' })}
                    showSearch={{ filter: cascaderSearchFilter }}
                    longdesc={this.intl.formatMessage({ id: 'configuration.users.organizationDropdown' })}
                  />
                </Form.Item>
                <Form.Item
                  className="form-item-row"
                  label={<IntlMessages id="uhe.table.customer" />}
                  name="customer"
                  rules={[
                    {
                      type: 'array',
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.mobilePatients.customerMsg' }),
                    },
                  ]}
                >
                  <Cascader
                    className="top-filter-popup"
                    fieldNames={{ label: 'name', value: 'id' }}
                    key="customer"
                    expandTrigger="hover"
                    size="large"
                    changeOnSelect
                    value={[mobilePatient.customer.id]}
                    allowClear={false}
                    options={options.customer || []}
                    onChange={this.onChangeOf('customer').cascader('facility')}
                    placeholder={this.intl.formatMessage({ id: 'uhe.table.customer' })}
                    showSearch={{ filter: cascaderSearchFilter }}
                    disabled={!mobilePatient.organization.id}
                  />
                </Form.Item>
                <Form.Item
                  label={<IntlMessages id="uhe.table.facility" />}
                  className="gx-mb-3 form-item-row"
                  name="facility"
                  rules={[
                    {
                      type: 'array',
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.mobilePatients.facilityMsg' }),
                    },
                  ]}
                >
                  <Cascader
                    className="top-filter-popup"
                    fieldNames={{ label: 'name', value: 'id' }}
                    key="facility"
                    expandTrigger="hover"
                    size="large"
                    changeOnSelect
                    value={[mobilePatient.facility.id]}
                    allowClear={false}
                    options={options.facility || []}
                    onChange={this.onChangeOf('facility').cascader('unit')}
                    placeholder={this.intl.formatMessage({ id: 'uhe.table.facility' })}
                    showSearch={{ filter: cascaderSearchFilter }}
                    disabled={!mobilePatient.customer.id}
                    longdesc={this.intl.formatMessage({ id: 'configuration.users.facilityDropdown' })}
                  />
                </Form.Item>
                <Form.Item
                  className="form-item-row"
                  label={<IntlMessages id="uhe.table.unit" />}
                  name="unit"
                  rules={[
                    {
                      type: 'array',
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.mobilePatients.unitMsg' }),
                    },
                  ]}
                >
                  <Cascader
                    className="top-filter-popup"
                    fieldNames={{ label: 'name', value: 'id' }}
                    key="unit"
                    expandTrigger="hover"
                    size="large"
                    changeOnSelect
                    value={[mobilePatient.unit.id]}
                    allowClear={false}
                    options={options.unit || []}
                    onChange={this.onChangeOf('unit').cascader()}
                    placeholder={this.intl.formatMessage({ id: 'uhe.table.unit' })}
                    showSearch={{ filter: cascaderSearchFilter }}
                    disabled={!mobilePatient.facility.id}
                    longdesc={this.intl.formatMessage({ id: 'configuration.users.unitDropdown' })}
                  />
                </Form.Item>
                <Form.Item
                  className="form-item-row"
                  label={<IntlMessages id="configuration.mobilePatients.alias" />}
                >
                  <Input
                    onChange={this.onChangeOf('alias').input}
                    value={mobilePatient.alias}
                    longdesc={this.intl.formatMessage({ id: 'configuration.users.lastNameInput' })}
                  />
                </Form.Item>
                {!this.isNew && <Form.Item
                  className="form-item-row"
                  label={<IntlMessages id="common.mdmToken" />}
                >
                  <Button
                    onClick={this.copyMdmToken}
                    title={this.intl.formatMessage({ id: 'common.copy' })}
                  >
                    <i className="icon icon-copy"></i>
                    </Button>
                </Form.Item>}
              </Card>
            </Col>
          </Row>
          {!this.isNew && (
            <Row gutter={16}>
              <Col lg={24} md={24} sm={24} sx={24}>
                <Card className="gx-card customer-edit-info-card">
                  {this.renderAuditInfo(AuditTypes.createdAt)}
                  {this.renderAuditInfo(AuditTypes.updatedAt)}
                </Card>
              </Col>
            </Row>
          )}          
          {this.renderHeadLine()}
        </Form>
      </div>
    );
  }
}

ManageMobilePatient.defaultProps = {
  mobilePatient: {},
  data: [],
  loading: true,
  savedMobilePatientId: undefined,
  error: {},
};

ManageMobilePatient.propTypes = {
  mobilePatient: PropTypes.shape(),
  data: PropTypes.arrayOf(PropTypes.object),
  mobilePatientAccessData: PropTypes.func.isRequired,
  setSubtitle: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  location: PropTypes.shape(window.location).isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  subtitle: PropTypes.shape({ langId: PropTypes.string }).isRequired,
  onGetOrganizationOptions: PropTypes.func.isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }).isRequired,
  actions: PropTypes.shape({
    fetchMobilePatient: PropTypes.func,
    savePermissions: PropTypes.func,
    saveMobilePatient: PropTypes.func,
  }).isRequired,
  savedMobilePatientId: PropTypes.number,
  error: PropTypes.shape({ code: PropTypes.number }),
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  onGetCustomerOptions: PropTypes.func.isRequired,
  onGetFacilityOptions: PropTypes.func.isRequired,
  onGetUnitOptions: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  loggedUser: PropTypes.shape({
    isCaregilitySystemAdmin: PropTypes.bool,
    isCustomerAdmin: PropTypes.bool,
    isOrganizationAdmin: PropTypes.bool,
    isTechnicianAdmin: PropTypes.bool,
  }).isRequired,
  optionsList: PropTypes.shape({
    organization: PropTypes.arrayOf(PropTypes.object),
    customer: PropTypes.arrayOf(PropTypes.object),
    facility: PropTypes.arrayOf(PropTypes.object),
    units: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  history: PropTypes.object,
  lastLocation:PropTypes.object.isRequired,
};

/**
 * @description Maps Global State to Component's Props
 * @returns {Object}
 */
const mapStateToProps = ({
  ConfigurationMobilePatients, listingsTopFilter, subtitle, common, ConfigurationUsers,
}) => {
  const { savedMobilePatientId, editTable } = ConfigurationMobilePatients;

  return {
    subtitle,
    savedMobilePatientId,
    error: common.error,
    loading: ConfigurationMobilePatients.loading,
    mobilePatient: ConfigurationMobilePatients.selectedMobilePatient || {},
    token: ConfigurationMobilePatients.token.mdm_token,
    optionsList: listingsTopFilter,
    data: editTable,
    loggedUser: ConfigurationUsers.ownUser,
  };
};

/**
 * @description Returns Object Which Dispatch Actions to the Store
 * @param {function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => ({
  onGetOrganizationOptions: (id) => dispatch(onGetOptions('organization', id)),
  onGetCustomerOptions: (id) => dispatch(onGetOptions('customer', id)),
  onGetFacilityOptions: (id) => dispatch(onGetOptions('facility', id)),
  onGetUnitOptions: (id) => dispatch(onGetOptions('unit', id)),
  setSubtitle: (langId) => dispatch(setSubtitleDispatch(langId)),
  actions: bindActionCreators(mobilePatientActions, dispatch),
  mobilePatientAccessData: (id) => dispatch(mobilePatientAccessDataDispatch(id)),
  fetchMDMToken: (id) => dispatch(fetchMDMToken(id)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(injectIntl(withRouter(ManageMobilePatient))));
