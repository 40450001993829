import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Select, Col, Row, Input, Form, Popover,
} from 'antd';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';

const { Option } = Select;

const DeviceAttrCard = ({
  bedCart, onChangeDeviceAttr, intl, loading,
}) => {
  const notifications = [intl.formatMessage({ id: 'common.yes' }), intl.formatMessage({ id: 'common.no' })];
  const notify = notifications.map((notification, index) => (
    <Option key={index}>{notification}</Option>
  ));

  /**
   * @description Renders Each Device Attribute Form Item
   * @param {object} attr
   * @param {number} index
   * @returns {JSX}
   */
  const renderDeviceAttr = (attr, index) => {
    const { value, endpointAttributeType } = attr;
    const { description, is_boolean, send_to_device } = endpointAttributeType;
    if (!is_boolean) {
      return (
        <Col className="gx-align-items-center" lg={12} md={24} sm={24} xs={24} key={index}>
          <Form.Item
            className="gx-align-items-center device-attr"
            name={description}
            label={(
              <Popover content={description}>
                <span className="device-attr-title">
                  {description}
                </span>
              </Popover>
            )}
          >
            <Input
              disabled={!!(send_to_device === null || 0)}
              onChange={(e) => onChangeDeviceAttr(e, index)}
              value={value}
            />
          </Form.Item>
        </Col>
      );
    }
  };

  return (
    <div>
      <Card
        className="gx-card"
        title={<IntlMessages id="configuration.bedsCarts.deviceAttr.title" />}
        loading={loading}
      >
        <Row gutter={16} className="device-attr-row gx-align-items-center">
          {bedCart.endpointAttributes.map((attr, index) => renderDeviceAttr(attr, index))}
          <Col className="gx-align-items-center" lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              className="gx-align-items-center"
              name="notifications"
              label={(
                <IntlMessages
                  id="configuration.bedsCarts.deviceAttr.notifications"
                />
              )}
            >
              <Select
                value="Yes"
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.notifications',
                })}
              >
                {notify}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

DeviceAttrCard.propTypes = {
  bedCart: PropTypes.shape().isRequired,
  onChangeDeviceAttr: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  intl: PropTypes.shape(),
};

export default injectIntl(DeviceAttrCard);
