import {
  CONFIGURATION_USERS_FETCH_DATA,
  CONFIGURATION_USERS_FETCH_SUCCESS,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  CLEAR_USER,
  SAVE_USER_REQUEST,
  SAVE_USER_SUCCESS,
  CONFIGURATION_USERS_FETCH_ACCESS_DATA,
  CONFIGURATION_USERS_FETCH_ACCESS_SUCCESS,
  SAVE_PERMISSIONS_REQUEST,
  SAVE_PERMISSIONS_SUCCESS,
  FETCH_IMPERSONATE_TOKEN,
  FETCH_IMPERSONATE_TOKEN_SUCCESS,
  CLEAR_LOADING,
  FETCH_OWN_PROFILE_REQUEST,
  FETCH_OWN_PROFILE_SUCCESS,
  ACTIVATE_USER_REQUEST,
  ACTIVATE_USER_SUCCESS,
  UPLOAD_USER_DOCUMENT_REQUEST,
  UPLOAD_USER_DOCUMENT_SUCCESS,
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD_SUCCESS,
  FETCH_USER_GRANT_REQUEST,
  FETCH_USER_GRANT_REQUEST_SUCCESS,
  SAVE_USERS_GRANTS_REQUEST,
  SAVE_USERS_GRANTS_REQUEST_SUCCESS,
  FETCH_UPLOAD_LOGS_REQUEST,
  FETCH_UPLOAD_LOGS_SUCCESS,
  FETCH_DETAILED_REPORT_REQUEST,
} from '@constants/UHEActionTypes';

/**
 * @description Fetch own user profile
 * @return {Object}
 */
export const onFetchOwnPofile = () => ({
  type: FETCH_OWN_PROFILE_REQUEST,
});

/**
 * @description Fetch own user profile
 * @return {Object}
 */
export const onFetchOwnPofileSuccess = (data) => ({
  type: FETCH_OWN_PROFILE_SUCCESS,
  payload: data,
});

/**
 * @description Fetch system data
 * @param  {number} page
 * @param  {string} sorting
 * @return {Object}
 */
export const usersOnFetchData = (page, sorting, filter) => ({
  type: CONFIGURATION_USERS_FETCH_DATA,
  page,
  sorting,
  filter,
});

/**
 * @description Fetch success
 * @param  {Object} data
 * @return {Object}
 */
export const fetchDataSuccess = (data) => ({
  DELETE_USERS_SUCCESS,
  type: CONFIGURATION_USERS_FETCH_SUCCESS,
  data,
});

/**
 * @description DELETE request
 * @param {Object} data
 * @return {Promise}
 */
export const deleteUser = (data) => ({
  type: DELETE_USERS_REQUEST,
  payload: data,
});

/**
 * @description DELETE request success
 * @param {Object} data
 * @return {Promise}
 */
export const deleteUserSuccess = (data) => ({
  type: DELETE_USERS_SUCCESS,
  payload: data,
});

/**
 * @description Handle FETCH_USER_REQUEST Action
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchUser = (payload) => ({
  type: FETCH_USER_REQUEST,
  id: payload.id,
});

/**
 * @description Handle FETCH_USER_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchUserSuccess = (payload) => ({
  type: FETCH_USER_SUCCESS,
  payload: { ...payload },
});

/**
 * @description Handle CLEAR_USER Action
 * @returns {Object}
 */
export const clearSelectedUser = () => ({
  type: CLEAR_USER,
});

/**
 * @description Handle SAVE_USER_REQUEST Action
 * @param {Object} data
 * @returns {Object}
 */
export const saveUser = (data) => ({
  type: SAVE_USER_REQUEST,
  payload: { ...data },
});

/**
 * @description Handle SAVE_USER_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const saveUserSuccess = (payload) => ({
  type: SAVE_USER_SUCCESS,
  payload: { ...payload },
});

/**
 * @description Handle CONFIGURATION_USERS_FETCH_ACCESS_DATA Action
 * @param {Object} payload
 * @returns {Object}
 */
export const onFetchUserAccessData = (payload) => ({
  type: CONFIGURATION_USERS_FETCH_ACCESS_DATA,
  id: payload.id,
});

/**
 * @description Fetch success
 * @param  {Object} data
 * @return {Object}
 */
export const fetchUserAccessDataSuccess = (payload) => ({
  type: CONFIGURATION_USERS_FETCH_ACCESS_SUCCESS,
  payload,
});

/**
 * @description Handle SAVE_PERMISSIONS_REQUEST Action
 * @param {Object} data
 * @returns {Object}
 */
export const savePermissions = (data) => ({
  type: SAVE_PERMISSIONS_REQUEST,
  payload: data,
});

/**
 * @description Handle SAVE_PERMISSIONS_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const savePermissionsSuccess = (payload) => ({
  type: SAVE_PERMISSIONS_SUCCESS,
  payload,
});

/**
 * @description Handles FETCH_IMPERSONATE_TOKEN Action
 * @param {number} id
 * @returns {Object}
 */
export const fetchImpersonateToken = (id) => ({
  type: FETCH_IMPERSONATE_TOKEN,
  id,
});

/**
 * @description Handles FETCH_IMPERSONATE_TOKEN_SUCCESS Action
 * @param {Object} token
 * @returns {Object}
 */
export const fetchImpersonateTokenSuccess = (token) => ({
  type: FETCH_IMPERSONATE_TOKEN_SUCCESS,
  token,
});

/**
 * @description Handles CLEAR_LOADING Action
 * @returns {Object}
 */
export const clearLoading = () => ({
  type: CLEAR_LOADING,
});

/**
 * @description Handles ACTIVATE_USER_REQUEST Action
 * @param {Object} activation
 * @returns {Object}
 */
export const activateUser = (activation) => ({
  type: ACTIVATE_USER_REQUEST,
  activation,
});

/**
 * @description Handles ACTIVATE_USER_SUCCESS Action
 * @param {Object} activation
 * @returns {Object}
 */
export const activateUserSuccess = () => ({
  type: ACTIVATE_USER_SUCCESS,
});

/**
 * @description Handles UPLOAD_USER_DOCUMENT_REQUEST action
 * @param {Object} file
 * @returns {Object}
 */
export const uploadUserDocument = (file) => ({
  type: UPLOAD_USER_DOCUMENT_REQUEST,
  payload: {
    file,
  },
});

/**
 * @description Handles UPLOAD_USER_DOCUMENT_SUCCESS action
 * @returns {Object}
 */
export const uploadUserDocumentSuccess = () => ({
  type: UPLOAD_USER_DOCUMENT_SUCCESS,
});

/**
 * @description Handle SAVE_USER_REQUEST Action
 * @param {Object} data
 * @returns {Object}
 */
export const resetUserPassword = (data) => ({
  type: RESET_USER_PASSWORD,
  payload: { ...data },
});

/**
 * @description Handle SAVE_USER_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const resetUserPasswordSuccess = (payload) => ({
  type: RESET_USER_PASSWORD_SUCCESS,
  payload: { ...payload },
});

/**
 * @description Handle SAVE_USER_REQUEST Action
 * @param {Object} data
 * @returns {Object}
 */
export const changeUserPassword = (data) => ({
  type: CHANGE_USER_PASSWORD,
  payload: { ...data },
});

/**
 * @description Handle SAVE_USER_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const changeUserPasswordSuccess = (payload) => {
  return {
    type: CHANGE_USER_PASSWORD_SUCCESS,
    payload: { ...payload },
  };
};

/**
 * @description Handle FETCH_USER_GRANT_REQUEST Action
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchUserGrant = (payload) => {
  return {
    type: FETCH_USER_GRANT_REQUEST,
    id: payload.id,
  };
};

/**
 * @description Handle FETCH_USER_GRANT_REQUEST_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchUserGrantSuccess = (payload) => {
  return {
    type: FETCH_USER_GRANT_REQUEST_SUCCESS,
    payload: { ...payload },
  };
};

/**
 * @description Handle SAVE_USERS_GRANTS_REQUEST Action
 * @param {Object} data
 * @returns {Object}
 */
export const saveUserGrants = (data) => {
  return {
    type: SAVE_USERS_GRANTS_REQUEST,
    payload: { ...data },
  };
};

/**
 * @description Handle SAVE_USERS_GRANTS_REQUEST_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const saveUserGrantsSuccess = (payload) => {
  return {
    type: SAVE_USERS_GRANTS_REQUEST_SUCCESS,
    payload: { ...payload },
  };
};

/**
 * @description Handle FETCH_UPLOAD_LOGS_REQUEST Action
 * @param {*} payload
 * @returns {object}
 */
export const fetchUploadLogsRequest = (payload) => ({
  type: FETCH_UPLOAD_LOGS_REQUEST,
  payload,
});

/**
 * @description Handle FETCH_UPLOAD_LOGS_SUCCESS Action
 * @param {*} payload
 * @returns {object}
 */
export const fetchUploadLogsSuccess = (payload) => ({
  type: FETCH_UPLOAD_LOGS_SUCCESS,
  payload,
});

/**
 * @description Handle FETCH_DETAILED_REPORT_REQUEST Action
 * @param {object} payload
 * @returns {object}
 */
export const fetchDetailedReportRequest = (payload) => ({
  type: FETCH_DETAILED_REPORT_REQUEST,
  payload,
});
