import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import {
  Row, Col, Select, Input, Form, Card, Cascader, Upload, Button, Popover,
} from 'antd';
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import momentTz from 'moment-timezone';

import IntlMessages from 'util/IntlMessages';

import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import CustomerCell from '@components/tables/cells/CustomerCell';
import { organizationFetchDataCustomers, organizationFetchDataFacility }
  from '@uhe_actions/configuration/organizations/OrganizationsActions';

import EditHeader from '@components/uhe/configuration/EditHeader';
import ClinicianSidebarConfig from '@components/uhe/configuration/customer/ClinicianSidebarConfig';
import Branding from '@components/uhe/configuration/customer/Branding';
import EpicInterConnectApiSettings from '@components/uhe/configuration/customer/EpicInterConnectApiSettings';
import PerfectServe from '@components/uhe/configuration/customer/PerfectServe';
import CustomerAttributes from '@components/uhe/configuration/customer/CustomerAttributes';
import InterpreterSettings from '@components/uhe/configuration/customer/InterpreterSettings';
import * as customerActions from '@uhe_actions/configuration/customers/CustomersActions';
import {
  fetchStratusInterpreter,
  fetchIndemandInterpreter,
  deleteStratusInterpreter,
  uploadInterpreter,
  saveStratusInterpreter,
  fetchInterpreterOptions,
  uploadJamfData,
} from '@uhe_actions/configuration/customers/CustomersActions';
import { onGetOptions } from '@uhe_actions/filters/ListingsTopFilterActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { BASE_URL, ENDPOINTS } from '@constants/UHEEndpoints';
import countryList from 'react-select-country-list';
import defaultTimezones from 'timezones.json';
import {
  LISTING_TABLES_PAGE_SIZE,
  STATES_OPTIONS,
  CANADIAN_PROVINCES,
  FICAL_YEAR,
  TELEHEALTH,
  NUMBERS_PATTERN,
  COUNTRIES,
} from '@constants/UHESettings';
import { goBackTo, cascaderSearchFilter, cascaderCustomerFiscalFilter, timeZoneListing } from '@util/UheHelper';
import { withLastLocation } from 'react-router-last-location';
import AuditInfo, { AuditTypes } from '@components/uhe/configuration/AuditInfo';
import {
  shouldDisableInputFields,
} from '@util/UheRoleChecker';

const { Option } = Select;

const mapToOption = (key, value, label) => <Option key={key} value={value}>{label}</Option>;

const states_options = STATES_OPTIONS.map((item, index) => mapToOption(index, item, item));

const canadian_provinces = CANADIAN_PROVINCES.map((item, index) => mapToOption(index, item, item));

const months_options = FICAL_YEAR.map((item, index) => mapToOption(index, index, item));

const telehealth_options = TELEHEALTH.map((item, index) => mapToOption(index, item, item));

const interpreterNames = {
  1: 'None',
  2: 'InDemand',
  3: 'Stratus',
};

const layoutNames = {
  1: 'actionBased',
  2: 'default',
};
const customerInfo = {
  customerName: 'customerName',
  orgName: 'orgName',
};

const layout = {
  labelCol: {
    xl: { span: 8 },
    lg: { span: 10 },
    md: { span: 4 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xl: { span: 16 },
    lg: { span: 14 },
    md: { span: 20 },
    sm: { span: 16 },
  },
};

const addressLayout = {
  labelCol: {
    xl: { span: 12 },
    lg: { span: 10 },
    md: { span: 12 },
    xs: { span: 8 },
  },
  wrapperCol: {
    xl: { span: 12 },
    lg: { span: 14 },
    md: { span: 12 },
    xs: { span: 16 },
  },
};

const countryOptions = countryList().data
  .reduce((acc, { value, label }) => (['US', 'CA']
    .some((val) => val === value)
    ? [mapToOption(value, label, label), ...acc]
    : [...acc, mapToOption(value, label, label)]), []);

/**
 * ManageCustomer Class Component
 */
export class ManageCustomer extends React.Component {
  /**
  * @description Adapts the data from the API
  * @param {Array} data
  * @returns {Array}
  */
  static dataAdapterCustomer(data = []) {
    const adaptedDataCustomer = [];

    data.forEach((value, index) => {
      adaptedDataCustomer.push({
        key: index,
        customer: {
          id: value.id,
          name: value.name,
        },

      });
    });

    return adaptedDataCustomer;
  }

  /**
   * @description Adapts the data from the API
   * @param {Array} dataFacility
   * @returns {Array}
   */
  static dataAdapterFacility(dataFacility = []) {
    const adaptedDataFacility = [];

    dataFacility.forEach((value, index) => {
      adaptedDataFacility.push({
        key: index,
        facility: {
          id: value.id,
          name: value.name,
        },
      });
    });

    return adaptedDataFacility;
  }

  formRef = React.createRef();

  constructor(props, context) {
    super(props, context);
    this.intl = props.intl;

    this.state = {
      customer: {
        ...props.customer,
        address_state: '',
        shipping_address_state: '',
        name: '',
        organization: [],
        customer_alias: '',
        customer_address: '',
        address_street: '',
        shipping_address_street: '',
        main_phone_number: null,
        address_city: '',
        shipping_address_city: '',
        shipping_address_zip_code: null,
        additional_link_1: '',
        additional_link_2: '',
        address_zip_code: null,
        time_zone: '',
        website: '',
        fiscal_year_start: '',
        address_country: '',
        shipping_address_country: '',
        updated_by: '',
        accreditation: [],
      },
      errors: {},
      inDemand: {},
      stratus: {},
      value: 1,
      editingKey: '',
      isTrue: false,
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onChangeBrandingHandler = this.onChangeBrandingHandler.bind(this);
    this.onChangeInterpreterHandler = this.onChangeInterpreterHandler.bind(this);
    this.saveCustomer = this.saveCustomer.bind(this);
    this.onChangeCustomerInfo = this.onChangeCustomerInfo.bind(this);
    this.customerFormIsValid = this.customerFormIsValid.bind(this);
    this.doSave = this.doSave.bind(this);
    this.onChangeCustomerCode = this.onChangeCustomerCode.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.handleStratusSave = this.handleStratusSave.bind(this);
    this.handleStratusDelete = this.handleStratusDelete.bind(this);

    this.onPageChange(
      this.state.customer.interpreter.id,
      this.state.customer.id,
      1,
    );
    this.history = this.props.history;
    this.qParams = new URLSearchParams(this.history.location.search);

    this.columns = [];
    this.culomnsFacility = [];

    this.tableKeysCustomer = [
      'customer',
      'address',
      'city',
      'state',
      'zip',
      'timezone',
      'comment',
    ];

    this.tableKeyFasility = [
      'facility',
      'address',
      'city',
      'state',
      'zip',
      'timezone',
      'comment',
    ];

    this.tableKeysCustomer.forEach((value, index) => {
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            showFilter={false}
          />
        ),
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRendererCutomer(content, value),
      });
    });

    this.tableKeyFasility.forEach((value, index) => {
      this.culomnsFacility.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            showFilter={false}
          />
        ),
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRendererFacility(content, value),
      });
    });
  }

  componentDidMount() {
    const {
      getOrganizations, isNew, actions, subtitle, setSubtitle, urlId,
    } = this.props;

    getOrganizations();

    if (!isNew) {
      actions.fetchCustomer({ id: urlId });
      if (subtitle.langId !== 'customers.edit_title') {
        setSubtitle('customers.edit_title');
      }
    } else if (subtitle.langId !== 'customers.new_title') {
      setSubtitle('customers.new_title');
    }

    actions.fetchInterpreterOptions();
  }

  /**
   * @description Update local state depends on the props
   * @param {object} prevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const {
      inDemand,
      stratus,
      loggedUser,
    } = this.props;

    if (prevProps.error !== this.props.error) {
      // handle system error
      if (this.props.error.code === 404) {
        this.props.history.push('/configuration/customers');
      }
    }

    const { redirectUrl, history } = this.props;
    if (redirectUrl) {
      history.push(redirectUrl);
      return;
    }

    const { customer, actions: { fetchInterpreter } } = this.props;
    if (!prevProps.customer.id && customer.id) {
      this.setState({ customer: cloneDeep(customer) });
      fetchInterpreter(customer.interpreter.id, customer.id);
    }

    if (
      Object.keys(prevProps.inDemand).length === 0
      && Object.keys(inDemand).length > 0
    ) {
      this.setState({
        inDemand,
      });
    }

    if (
      Object.keys(prevProps.stratus).length === 0
      && Object.keys(stratus).length > 0
    ) {
      this.setState({
        stratus,
      });
    } else if (prevProps.stratus.page && this.props.stratus.page) {
      if (prevProps.stratus.page.number !== this.props.stratus.page.number) {
        this.setState({
          stratus,
        });
      }
    }

    if (this.formRef.current) {
      const { customer: stateCustomer } = this.state;
      this.formRef.current.setFieldsValue({
        ...stateCustomer,
        organization: [stateCustomer.organization.id],
      });
    }
  }

  componentWillUnmount() {
    this.props.actions.cleanUpSelectedCustomer();
  }

  cellRendererCutomer(content, key) {
    let cell;
    const { intl } = this.props;

    switch (key) {
      case 'customer':
        cell = <CustomerCell content={content} showIco />;
        break;
      default:
        cell = content;
    }
    return cell;
  }

  /**
     * @param {string} content
     * @param {string} key
     * @returns {JSX}
     */
  cellRendererFacility(content, key) {
    let cell;
    const { intl } = this.props;

    switch (key) {
      case 'facility':
        cell = <CustomerCell content={content} showIco />;
        break;
      default:
        cell = content;
    }
    return cell;
  }

  /**
   * @description Handle Pagination
   * @param {number} page
   * @returns {void}
   */
  onPageChange(page) {
    // TODO: refactor or remove if possible
    const { customer, value } = this.state;

    if (customer.interpreter.id && value === 3) {
      this.props.fetchStratusInterpreter(
        customer.interpreter.id,
        customer.id,
        page - 1,
      );
    } else if (customer.interpreter.id && value === 2) {
      this.props.fetchIndemandInterpreter(customer.interpreter.id, page - 1);
    }
  }

  /**
   * @description Change handler for Countries
   * @param {string} value
   */
  onChangeCountry = (value) => {
    const { customer } = this.state;
    customer.address_country = value;
    customer.address_state = null;
    this.setState({ customer });
  }

  onChangeTimeZone = (values) => {
    const { customer } = this.state;

    customer.time_zone = values;
    this.setState({ customer });
  }

  onChangeHenlerShippingCountry = (values) => {
    const { customer } = this.state;

    customer.shipping_address_country = values;
    customer.shipping_address_state = null;
    this.setState({ customer });
  }

  /**
   * @description Common event handler for onChange event fired from the inputs
   * @param {object} event
   * @param {string} fieldName
   * @param {object} valueObj contains {val: ''}
   * @returns {void}
   */
  onChangeHandler(event, fieldName, sectionName, fieldValue) {
    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }
    const copiedCustomerState = { ...this.state.customer };
    const valueToSet = fieldValue
      ? fieldValue.val
      : event && event.target && event.target.value
        ? event.target.value
        : '';

    if (sectionName) {
      copiedCustomerState[sectionName][fieldName] = valueToSet;
    } else {
      copiedCustomerState[fieldName] = valueToSet;
    }

    this.setState({ customer: copiedCustomerState });
  }

  /**
   * @description Change input name
   * @param {string} key
   */
  onChangeHandlerName = (key) => (event) => {
    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }
    this.setState({
      customer: {
        ...this.state.customer,
        [key]: event.target.value,
      },
    });
  }

  /**
   * @description Event handler for onChange event fired in the Branding config section
   * @param {string} fieldName
   * @param {object} valueObj contains {val: ''}
   * @returns {void}
   */
  onChangeBrandingHandler(fieldName, fieldValue) {
    const { customer } = this.state;
    customer.branding[fieldName] = fieldValue.val;
    this.setState({ customer });
  }

  /**
   * @description Event handler for onChange event fired in the Interpreter section
   * @param {object} event
   * @param {string} fieldName
   * @returns {void}
   */
  onChangeInterpreterHandler(event, fieldName) {
    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }

    const copiedCustomerState = { ...this.state.customer };
    const valueToSet = event && event.target && event.target.value
      ? interpreterNames[event.target.value]
      : '';
    if (valueToSet !== '') {
      copiedCustomerState.interpreter[fieldName] = valueToSet;
      this.setState({ customer: copiedCustomerState }, () => { });
    }
  }

  /**
   * @description Event handler for onChange event fired in the Sidebar config section
   * @param {object} event
   * @param {string} fieldName
   * @param {object} valueObj contains {val: ''}
   * @returns {void}
   */
  onChangeSidebarConfig = (event, fieldName, valueObj) => {
    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }

    const copiedCustomerState = { ...this.state.customer };
    let valueToSet = null;
    if (fieldName === 'clinician_sidebutton_layout') {
      valueToSet = event.target.value || 0;
    } else {
      valueToSet = valueObj ? valueObj.val : null;
    }

    copiedCustomerState.clinicianSidebarConfig[fieldName] = valueToSet;
    this.setState({ customer: copiedCustomerState }, () => { });
  }

  /**
   * @description Catch event from the save/add button and dispatch save action to the middleware
   * @param {object} event
   * @returns {void}
   */
  saveCustomer(event) {
    event.persist();
    event.preventDefault();

    this.formRef.current.validateFields()
      .then((values) => {
        const newCustomer = { ...this.state.customer };
        newCustomer.name = newCustomer.name.trim();
        this.setState({ customer: newCustomer }, this.doSave);
      })
      .catch((info) => {
        console.log('info:', info);
      });
  }

  /**
   * Invokes saveCustomer Action
   * @returns {void}
   */
  doSave() {
    const { customer } = this.state;
    const { actions } = this.props;
    const formIsValid = this.customerFormIsValid();
    const newCustomer = { ...customer };

    if (!formIsValid) {
      return;
    }

    if (newCustomer && newCustomer.attributes) {
      newCustomer.attributes.forEach((attribute) => {
        attribute.value = attribute?.value?.toUpperCase();
      });
    }

    const data = { customer: newCustomer };
    actions.saveCustomer(data);
  }


  /**
   * @description Render  title, back and save buttons
   * @returns {JSX}
   */
  renderHeadLine() {
    const { isNew, readyMap, lastLocation, history } = this.props;
    const titleKey = isNew
      ? 'configuration.customer.title_add_new'
      : 'configuration.customer.title_edit';

    const { isCaregilitySystemAdmin, isCustomerAdmin, isOrganizationAdmin } = this.props.loggedUser;

    if (isCaregilitySystemAdmin || isOrganizationAdmin) {
      return (
        <EditHeader
          goBack={goBackTo(`/configuration/customers?sort=${encodeURIComponent('organization,asc')}`, lastLocation?.pathname, history)}
          save={this.saveCustomer}
          loading={!readyMap.customer && !isNew}
          titleKey={titleKey}
        />
      );
    }

    return null;
  }

  /**
   * @description onChange handler for customer info.
   * @param e[string,object] - event object from input or string value from select component
   * @param propName{string}
   * @returns {void}
   */
  onChangeCustomerInfo(e, propName) {
    const customer = { ...this.state.customer };

    if (propName === customerInfo.orgName) {
      customer.organization.id = e;
    } else if (propName === customerInfo.customerName) {
      e.persist();
      customer.name = e.target.value;
    }

    this.setState({ customer }, this.customerFormIsValid);
  }

  /**
   * @description Creates a function for handling changes in input fields
   * @param {string} key Key that will be used in state.customer object
   */
  onChangeOf = (key) => (value) => {
    const { customer } = this.state;
    this.setState({
      customer: {
        ...customer,
        [key]: value,
      },
    });
  };

  /**
   * @description Returns function for rendering address or shipping state according to selected country
   * @param {'address'|'shipping_address'} key
   * @returns {function(): *}
   */
  renderStateField = (key) => () => {
    const { customer } = this.state;
    const { loggedUser } = this.props;
    const countryKey = `${key}_country`;
    const stateKey = `${key}_state`;
    const placeholder = this.intl.formatMessage({ id: `configuration.customer.select_${stateKey}` });
    const title = this.intl.formatMessage({ id: `configuration.customer.customer_${stateKey}` });

    if (customer[countryKey] !== 'United States' && customer[countryKey] !== 'Canada') {
      return (
        <Input
          name={stateKey}
          type="text"
          value={customer[stateKey]}
          placeholder={placeholder}
          title={title}
          onChange={(event) => this.onChangeHandler(event, stateKey)}
          disabled={shouldDisableInputFields(loggedUser)}
        />
      );
    }

    const options = customer[countryKey] === 'Canada' ? canadian_provinces : states_options;
    return (
      <Select
        showSearch
        placeholder={placeholder}
        title={title}
        onChange={this.onChangeOf(stateKey)}
        disabled={shouldDisableInputFields(loggedUser)}
      >
        {options}
      </Select>
    );
  }

  /**
  * @description Renders State Address according to selected country
  * @returns Select with Options or Input
  */
  renderStreetStateField = () => {
    const { customer } = this.state;
    const { loggedUser } = this.props;
    if (customer.address_country !== 'US' && customer.address_country !== 'CA') {
      return (
        <Input
          name="address_state"
          type="text"
          value={customer.state}
          placeholder={this.intl.formatMessage({ id: 'configuration.customer.select_state' })}
          title={this.intl.formatMessage({ id: 'configuration.customer.customer_state_address' })}
          onChange={(event) => this.onChangeHandler(event, 'address_state')}
          disabled={shouldDisableInputFields(loggedUser)}
        />
      );
    }

    const options = customer.address_country === 'CA' ? canadian_provinces : states_options;
    return (
      <Select
        showSearch
        placeholder={this.intl.formatMessage({ id: 'configuration.customer.select_state' })}
        title={this.intl.formatMessage({ id: 'configuration.customer.customer_state_address' })}
        onChange={this.onChangeOf('address_state')}
        disabled={shouldDisableInputFields(loggedUser)}
      >
        {options}
      </Select>
    );
  }

  /**
  * @description Handle Changes in Organization Cascader
  * @param {number} selectedId
  */
  onChangeOrganizationHandler = ([selectedId]) => {
    const { customer } = this.state;
    const { organizations } = this.props;
    const { id, name } = organizations.find((organization) => organization.id === selectedId) || {};
    this.setState({
      customer: {
        ...customer,
        organization: { id, name },
      },
    });
  }

  /**
   * @description Validate customer data before submit it
   * @returns {boolean}
   */
  customerFormIsValid() {
    let formIsValid = true;
    const errors = {};
    const { customer } = this.state;
    const nameRes = /^[a-zA-Z0-9 _.-]{3,}$/.test(customer.name.trim());
    const isInvalidOrganization = !!customer.organization.id && customer.organization.id !== '0';
    if (!nameRes && customer.name.length < 3) {
      errors.name = true;
      formIsValid = false;
    } else if (!isInvalidOrganization) {
      errors.organization = true;
      formIsValid = false;
    }

    this.setState({ errors });
    return formIsValid;
  }

  onChangeHandlerTest = (event, index) => {
    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }
    const newCustomer = { ...this.state.customer };
    newCustomer.attributes[index].value = event.target.value;

    this.setState({
      customer: newCustomer,
    });
  };

  /**
   * @description Handle Changes in the Customer Code Input
   * @param {Object} event
   * @returns {void}
   */
  onChangeCustomerCode(event) {
    const { customer } = this.state;
    const newCustomer = { ...customer };

    newCustomer.translation_customer_code = event.target.value;

    this.setState({
      customer: newCustomer,
    });
  }

  /**
   * @description Handle Changes When Radio Button is Clicked on the Interprete Settings Section
   * @param {Object} event
   * @returns {void}
   */
  onChangeInterpreterRadio = (event) => {
    const { customer } = this.state;
    const { actions: { fetchInterpreter } } = this.props;
    fetchInterpreter(event.target.value, customer.id);
    this.setState({
      customer: {
        ...customer,
        interpreter: {
          ...customer.interpreter,
          id: event.target.value,
        },
      },
    });
  }

  /**
   * @description
   * @param {number} editedEntyId
   * @param {Object} editedEntry
   * @returns {void}
   */
  handleStratusSave(editedEntyId, editedEntry) {
    const { saveStratusInterpreter } = this.props;
    const { customer } = this.state;
    const stratus = { ...this.state.stratus };
    const index = stratus.list.findIndex((item) => editedEntyId === item.id);

    if (index > -1) {
      const item = stratus.list[index];
      stratus.list.splice(index, 1, {
        ...item,
        ...editedEntry,
      });

      this.setState({ stratus });

      saveStratusInterpreter(
        customer.interpreter.id,
        customer.id,
        editedEntyId,
        editedEntry,
      );
    }
  }

  /**
   * @description Handles Stratus Intepreter Delete
   * @param {number} interpreterId
   * @param {number} customerId
   * @param {number} mappingId
   * @returns {void}
   */
  handleStratusDelete(interpreterId, customerId, mappingId) {
    const { deleteStratusInterpreter } = this.props;
    const stratus = { ...this.state.stratus };

    deleteStratusInterpreter(interpreterId, customerId, mappingId);
    stratus.list = stratus.list.filter((li) => li.id !== mappingId);
    this.setState({ stratus });
  }

  CheckboxChecked(key, event) {
    const newState = !!event.target.checked;
    this.setState({ customer: { ...this.state.customer, [key]: newState } });
  }

  /**
   * @description Renders AuditInfo component
   * @param {number} auditType Use AuditType for setting this field
   * @returns {JSX.Element}
   */
  renderAuditInfo(auditType) {
    const { customer } = this.state;
    return (
      <AuditInfo
        className="customer-status manageCustomer__status--auditInfo"
        type={auditType}
        data={customer}
      />
    );
  }

  /**
   * Disables element according to role permission
   * @returns {boolean}
   */

  disableElementPermission = () => {
    const { loggedUser } = this.props;

    return !loggedUser.isCaregilitySystemAdmin && !loggedUser.isOrganizationAdmin;
  }

  onFileChange = (file) => {
    const selectedFile = file;
    this.setState({ selectedFile });
    return false;
  }

  uploadDocument = () => {
    const fd = new FormData();
    fd.append('file', this.state.selectedFile);
    this.props.uploadJamfData({ body: fd, id: this.state.customer.id });
  }

  content = () => (
    <div>
      <p><IntlMessages id="configuration.customer.jamf_content" /></p>
      <p><IntlMessages id="configuration.customer.jamf_example" /></p>
      <p><IntlMessages id="configuration.users.email" /></p>
      <p><IntlMessages id="configuration.customer.jamf_email1" /></p>
      <p><IntlMessages id="configuration.customer.jamf_email2" /></p>
    </div>
  );

  /**
  * Check attributes jamf_enabled value
  * @returns {boolean} true|false
  */
  JamfValueIsTrue() {
    const { customer } = this.props;
    const attribute = customer?.attributes?.filter((item) => item.name === 'jamf_enabled');

    if (attribute) {
      if (attribute[0]?.value === 'TRUE') {
        return true;
      }
    }
    return false;
  }

  /**
   * Renders ManageCustomers Component
   * @returns {JSX.Element} ManageCustomers
   */
  render() {
    const {
      customer, inDemand, stratus, editingKey,
    } = this.state;
    const { address_country, shipping_address_country } = customer;
    const {
      isNew,
      pagination,
      uploadInterpreter,
      organizations,
      readyMap,
      interpreters,
      loggedUser
    } = this.props;

    const timezoneOptions = (timeZoneListing);

    pagination.onChange = this.onPageChange;

    return (
      <div className="manage-customer-wrapper">
        {this.renderHeadLine()}
        <div>
        <Card
          className="gx-card"
          loading={!readyMap.customer && !isNew}
        >            
          <Form ref={this.formRef} name="manage-customer" {...layout}>
              <Row gutter={16} className="form-item-row">
                <Col lg={12} md={24} sm={24} xs={24}>
                  <Form.Item
                    className="align-item-city form-item-row"
                    name="organization" // for some reason not working when name="organization"
                    label={<IntlMessages id="configuration.organizations.edit.name" />}
                    rules={[
                      {
                        required: true,
                        message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                      },
                    ]}
                  >
                    <Cascader
                      showSearch={{ filter: cascaderSearchFilter }}
                      value={[customer?.organization?.id]}
                      placeholder={this.intl.formatMessage({ id: 'configuration.customer.customer_organization' })}
                      fieldNames={{ label: 'name', value: 'id' }}
                      options={organizations}
                      onChange={this.onChangeOrganizationHandler}
                      disabled={!isNew || shouldDisableInputFields(loggedUser)}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <div>
                    <Form.Item
                      className="align-item-city form-item-row"
                      name="name" // not working when name="name"
                      label={<IntlMessages id="configuration.customer.customer_name" />}
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        value={customer.name}
                        initialValue={customer.name}
                        placeholder={this.intl.formatMessage({ id: 'configuration.customer.customer_name' })}
                        title={this.intl.formatMessage({ id: 'configuration.customer.customer_name' })}
                        onChange={this.onChangeHandlerName('name')}
                        disabled={shouldDisableInputFields(loggedUser)}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={16} className="form-item-row">
                <Col lg={12} md={24} sm={24} xs={24}>
                  <div>
                    <Form.Item
                      className="align-item-city form-item-row"
                      name="customer_alias"
                      label={<IntlMessages id="configuration.customer.customerAlais" />}
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                        {
                          min: 2,
                          message: this.intl.formatMessage({ id: 'common.minCharAlias' }),
                        },
                        {
                          max: 6,
                          message: 'Must not be more than 6 characters',
                        },
                      ]}
                    >
                      <Input
                        name="customer_alias"
                        value={customer.alias}
                        placeholder={this.intl.formatMessage({ id: 'configuration.customer.customerAlais' })}
                        title={this.intl.formatMessage({ id: 'configuration.customer.customerAlais' })}
                        onChange={this.onChangeHandlerName('customer_alias')}
                        disabled={shouldDisableInputFields(loggedUser)}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <Form.Item
                    className="align-item-city form-item-row"
                    name="fiscal_year_start"
                    label={<IntlMessages id="configuration.customer.customer_fiscal_year" />}
                    rules={[
                      {
                        required: true,
                        message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                      },
                    ]}
                  >
                    <Select
                      showSearch={{ filter: cascaderSearchFilter }}
                      filterOption={cascaderCustomerFiscalFilter}
                      value={customer.fiscal_year_start}
                      placeholder={this.intl.formatMessage({ id: 'configuration.customer.customer_fiscal_year' })}
                      title={this.intl.formatMessage({ id: 'configuration.customer.customer_fiscal_year' })}
                      onChange={this.onChangeOf('fiscal_year_start')}
                      disabled={shouldDisableInputFields(loggedUser)}

                    >
                      {months_options}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="form-item-row">
                <Col className="row-align" lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    className="align-item-city form-item-row"
                    label={<IntlMessages id="configuration.customer.telehealthe_acc" />}
                    labelCol={{
                      xl: { span: 4 },
                      lg: { span: 5 },
                      md: { span: 4 },
                      sm: { span: 8 },
                    }}
                    wrapperCol={{
                      xl: { span: 20 },
                      lg: { span: 19 },
                      md: { span: 20 },
                      sm: { span: 16 },
                    }}
                  >
                    <Select
                      showSearch
                      name="accreditation"
                      mode="multiple"
                      value={customer.accreditation}
                      placeholder={<IntlMessages id="configuration.customer.telehealthe_acc" />}
                      onChange={this.onChangeOf('accreditation')}
                      disabled={shouldDisableInputFields(loggedUser)}
                    >
                      {telehealth_options}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="form-item-row align-roww">
                <Col xl={8} lg={12} md={8} sm={24} xs={24}>
                  <div>
                    <Form.Item
                      {...addressLayout}
                      className="align-item-city form-item-row"
                      name="address_street"
                      label={<IntlMessages id="configuration.customer.customer_street_address" />}
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                        {
                          max: 50,
                          message: 'Must not be more that 50 characters',

                        },
                      ]}
                    >
                      <Input
                        name="address_street"
                        value={customer.address_street}
                        placeholder={this.intl.formatMessage({ id: 'configuration.customer.customer_street_address' })}
                        title={this.intl.formatMessage({ id: 'configuration.customer.customer_street_address' })}
                        onChange={this.onChangeHandlerName('address_street')}
                        disabled={shouldDisableInputFields(loggedUser)}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xl={5} lg={7} md={8} sm={24} xs={24}>
                  <div>
                    <Form.Item
                      {...addressLayout}
                      className="align-item-city form-item-row align-label"
                      name="address_country"
                      label={<IntlMessages id="configuration.organizations.edit.country" />}
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                      ]}
                      labelCol={{
                        xl: { span: 10 },
                        lg: { span: 10 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                      wrapperCol={{
                        xl: { span: 14 },
                        lg: { span: 14 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                    >
                      <Select
                        showSearch
                        placeholder={this.intl.formatMessage({ id: 'configuration.customer.customer_country_address' })}
                        title={this.intl.formatMessage({ id: 'configuration.customer.customer_country_address' })}
                        value={customer.address_country}
                        onChange={this.onChangeCountry}
                        disabled={shouldDisableInputFields(loggedUser)}
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ width: 'fit-content' }}
                      >
                        {countryOptions}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col xl={4} lg={5} md={8} sm={24} xs={24}>
                  <Form.Item
                    {...addressLayout}
                    className="align-item-city form-item-row align-label"
                    name="address_city"
                    label={<IntlMessages id="uhe.table.city" />}
                    rules={[
                      {
                        required: true,
                        message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                      },
                      {
                        max: 50,
                        message: 'Must not be more that 50 characters',
                      },
                    ]}
                    labelCol={{
                      xl: { span: 10 },
                      lg: { span: 10 },
                      md: { span: 12 },
                      sm: { span: 12 },
                    }}
                    wrapperCol={{
                      xl: { span: 14 },
                      lg: { span: 14 },
                      md: { span: 12 },
                      sm: { span: 12 },
                    }}
                  >
                    <Input
                      name="address_city"
                      value={customer.address_city}
                      placeholder={this.intl.formatMessage({ id: 'configuration.customer.address_city' })}
                      title={this.intl.formatMessage({ id: 'configuration.customer.address_city' })}
                      onChange={this.onChangeHandlerName('address_city')}
                      disabled={shouldDisableInputFields(loggedUser)}
                    />
                  </Form.Item>
                </Col>
                <Col xl={4} lg={12} md={8} sm={24} xs={24}>
                  <Form.Item
                    {...addressLayout}
                    className="align-item-city form-item-row align-label"
                    name="address_state"
                    label={<IntlMessages id="configuration.organizations.edit.state" />}
                    rules={[
                      {
                        required: true,
                        message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                      },

                    ]}
                    labelCol={{
                      xl: { span: 10 },
                      lg: { span: 10 },
                      md: { span: 12 },
                      sm: { span: 12 },
                    }}
                    wrapperCol={{
                      xl: { span: 14 },
                      lg: { span: 14 },
                      md: { span: 12 },
                      sm: { span: 12 },
                    }}
                  >
                    {this.renderStateField('address')()}
                  </Form.Item>
                </Col>
                <Col xl={3} lg={7} md={8} sm={24} xs={24}>
                  <Form.Item
                    {...addressLayout}
                    className="align-item-city form-item-row align-label"
                    name="address_zip_code"
                    label={<IntlMessages id="configuration.organizations.edit.zip" />}
                    rules={[
                      {
                        required: true,
                        message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                      },
                      {
                        max: 15,
                        message: <IntlMessages id="common.minCharactersMessage" />,
                      },
                      {
                        pattern: address_country === COUNTRIES.usa
                          || address_country === COUNTRIES.usaIslands
                          ? NUMBERS_PATTERN
                          : '',
                        message: <IntlMessages id="common.onlyNumbersMessage" />,
                      },
                    ]}
                    labelCol={{
                      xl: { span: 10 },
                      lg: { span: 10 },
                      md: { span: 12 },
                      sm: { span: 12 },
                    }}
                    wrapperCol={{
                      xl: { span: 14 },
                      lg: { span: 14 },
                      md: { span: 12 },
                      sm: { span: 12 },
                    }}
                  >
                    <Input
                      name="address_zip_code"
                      placeholder={this.intl.formatMessage({ id: 'configuration.customer.address_zip_code' })}
                      title={this.intl.formatMessage({ id: 'configuration.customer.address_zip_code' })}
                      value={customer.address_zip_code}
                      onChange={this.onChangeHandlerName('address_zip_code')}
                      disabled={shouldDisableInputFields(loggedUser)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="form-item-row align-roww">
                <Col xl={8} lg={12} md={8} sm={24} xs={24}>
                  <div>
                    <Form.Item
                      {...addressLayout}
                      className="align-item-city form-item-row align-label"
                      name="shipping_address_street"
                      label={<IntlMessages id="configuration.customer.customer_shipping_street_address" />}
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                        {
                          max: 50,
                          message: 'Must not be more that 50 characters',

                        },
                      ]}
                    >
                      <Input
                        name="shipping_address_street"
                        placeholder={this.intl.formatMessage({ id: 'configuration.customer.customer_shipping_street_address' })}
                        title={this.intl.formatMessage({ id: 'configuration.customer.customer_shipping_street_address' })}
                        onChange={this.onChangeHandlerName('shipping_address_street')}
                        disabled={shouldDisableInputFields(loggedUser)}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xl={5} lg={7} md={8} sm={24} xs={24}>
                  <div>
                    <Form.Item
                      {...addressLayout}
                      className="align-item-city form-item-row align-label"
                      name="shipping_address_country"
                      label={<IntlMessages id="configuration.organizations.edit.country" />}
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                      ]}
                      labelCol={{
                        xl: { span: 10 },
                        lg: { span: 10 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                      wrapperCol={{
                        xl: { span: 14 },
                        lg: { span: 14 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                    >
                      <Select
                        showSearch
                        placeholder={this.intl.formatMessage({ id: 'configuration.customer.customer_shipping_country_address' })}
                        title={this.intl.formatMessage({ id: 'configuration.customer.customer_shipping_country_address' })}
                        value={customer.shipping_address_country}
                        onChange={this.onChangeHenlerShippingCountry}
                        disabled={shouldDisableInputFields(loggedUser)}
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ width: 'fit-content' }}
                      >
                        {countryOptions}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col xl={4} lg={5} md={8} sm={24} xs={24}>
                  <Form.Item
                    {...addressLayout}
                    className="align-item-city form-item-row align-label"
                    name="shipping_address_city"
                    label={<IntlMessages id="uhe.table.city" />}
                    rules={[
                      {
                        required: true,
                        message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                      },
                      {
                        max: 50,
                        message: 'Must not be more that 50 characters',
                      },
                    ]}
                    labelCol={{
                      xl: { span: 10 },
                      lg: { span: 10 },
                      md: { span: 12 },
                      sm: { span: 12 },
                    }}
                    wrapperCol={{
                      xl: { span: 14 },
                      lg: { span: 14 },
                      md: { span: 12 },
                      sm: { span: 12 },
                    }}
                  >
                    <Input
                      name="shipping_address_city"
                      value={customer.shipping_address_city}
                      placeholder={this.intl.formatMessage({ id: 'configuration.customer.shipping_city' })}
                      title={this.intl.formatMessage({ id: 'configuration.customer.shipping_city' })}
                      onChange={this.onChangeHandlerName('shipping_address_city')}
                      disabled={shouldDisableInputFields(loggedUser)}
                    />
                  </Form.Item>
                </Col>
                <Col xl={4} lg={12} md={8} sm={24} xs={24}>
                  <Form.Item
                    {...addressLayout}
                    className="align-item-city form-item-row align-label"
                    name="shipping_address_state"
                    label={<IntlMessages id="configuration.organizations.edit.state" />}
                    rules={[
                      {
                        required: true,
                        message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                      },
                    ]}
                    labelCol={{
                      xl: { span: 10 },
                      lg: { span: 10 },
                      md: { span: 12 },
                      sm: { span: 12 },
                    }}
                    wrapperCol={{
                      xl: { span: 14 },
                      lg: { span: 14 },
                      md: { span: 12 },
                      sm: { span: 12 },
                    }}
                  >
                    {this.renderStateField('shipping_address')()}
                  </Form.Item>
                </Col>
                <Col xl={3} lg={7} md={8} sm={24} xs={24}>
                  <Form.Item
                    {...addressLayout}
                    className="align-item-city form-item-row align-label"
                    name="shipping_address_zip_code"
                    label={<IntlMessages id="configuration.organizations.edit.zip" />}
                    rules={[
                      {
                        required: true,
                        message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                      },
                      {
                        max: 15,
                        message: <IntlMessages id="common.minCharactersMessage" />,
                      },
                      {
                        pattern: shipping_address_country === COUNTRIES.usa
                          || shipping_address_country === COUNTRIES.usaIslands
                          ? NUMBERS_PATTERN
                          : '',
                        message: <IntlMessages id="common.onlyNumbersMessage" />,
                      },
                    ]}
                    labelCol={{
                      xl: { span: 10 },
                      lg: { span: 10 },
                      md: { span: 12 },
                      sm: { span: 12 },
                    }}
                    wrapperCol={{
                      xl: { span: 14 },
                      lg: { span: 14 },
                      md: { span: 12 },
                      sm: { span: 12 },
                    }}
                  >
                    <Input
                      name="shipping_address_zip_code"
                      value={customer.shipping_address_zip_code}
                      placeholder={this.intl.formatMessage({ id: 'configuration.customer.shipping_zip_code' })}
                      title={this.intl.formatMessage({ id: 'configuration.customer.shipping_zip_code' })}
                      onChange={this.onChangeHandlerName('shipping_address_zip_code')}
                      disabled={shouldDisableInputFields(loggedUser)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="form-item-row">
                <Col lg={12} md={24} sm={24} xs={24}>
                  <Form.Item
                    className="align-item-city form-item-row"
                    name="time_zone"
                    label={<IntlMessages id="configuration.customer.cutomer_time_zone" />}
                    rules={[
                      {
                        required: true,
                        message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      value={customer.time_zone}
                      onChange={(value) => { this.onChangeTimeZone(value); }}
                      placeholder={this.intl.formatMessage({ id: 'configuration.customer.cutomer_time_zone' })}
                      title={this.intl.formatMessage({ id: 'configuration.customer.cutomer_time_zone' })}
                      disabled={shouldDisableInputFields(loggedUser)}
                      options={timezoneOptions.map((x) => ({ value: x.zone, label: `${x.zone} / ${x.name}` }))}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <Form.Item
                    className="align-item-city form-item-row"
                    name="main_phone_number"
                    label={<IntlMessages id="configuration.customer.customer_main_phone" />}
                    rules={[
                      {
                        required: true,
                        message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                      },
                      {
                        max: 15,
                        message: 'It must contain only 15 characters',
                      },
                      {
                        pattern: NUMBERS_PATTERN,
                        message: 'This field must contain only numbers',
                      },
                    ]}
                  >
                    <Input
                      name="main_phone_number"
                      value={customer.main_phone_number}
                      placeholder={this.intl.formatMessage({ id: 'configuration.customer.main_phone_number' })}
                      title={this.intl.formatMessage({ id: 'configuration.customer.main_phone_number' })}
                      onChange={this.onChangeHandlerName('main_phone_number')}
                      disabled={shouldDisableInputFields(loggedUser)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="form-item-row">
                <Col className="row-align" lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    className="align-item-city form-item-row"
                    name="website"
                    label={<IntlMessages id="configuration.customer.customer_web" />}
                    labelCol={{
                      xl: { span: 4 },
                      lg: { span: 5 },
                      md: { span: 4 },
                      sm: { span: 8 },
                    }}
                    wrapperCol={{
                      xl: { span: 20 },
                      lg: { span: 19 },
                      md: { span: 20 },
                      sm: { span: 16 },
                    }}
                  >
                    <Input
                      name="website"
                      placeholder={this.intl.formatMessage({ id: 'configuration.customer.customer_web' })}
                      title={this.intl.formatMessage({ id: 'configuration.customer.customer_web' })}
                      onChange={this.onChangeHandlerName('website')}
                      value={customer.website}
                      disabled={shouldDisableInputFields(loggedUser)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="form-item-row">
                <Col lg={12} md={24} sm={24} xs={24}>
                  <Form.Item
                    className="align-item-city form-item-row"
                    label={<IntlMessages id="configuration.customer.customerlink1" />}
                  >
                    <Input
                      name="additional_link_1"
                      placeholder={this.intl.formatMessage({ id: 'configuration.customer.customerlink1' })}
                      title={this.intl.formatMessage({ id: 'configuration.customer.customerlink1' })}
                      value={customer.additional_link_1}
                      onChange={this.onChangeHandlerName('additional_link_1')}
                      disabled={shouldDisableInputFields(loggedUser)}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <Form.Item
                    className="align-item-city form-item-row"
                    label={<IntlMessages id="configuration.customer.customerlink2" />}
                  >
                    <Input
                      name="additional_link_2"
                      placeholder={this.intl.formatMessage({ id: 'configuration.customer.customerlink2' })}
                      title={this.intl.formatMessage({ id: 'configuration.customer.customerlink2' })}
                      value={customer.additional_link_2}
                      onChange={this.onChangeHandlerName('additional_link_2')}
                      disabled={shouldDisableInputFields(loggedUser)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {this.renderAuditInfo(AuditTypes.createdAt)}
              {this.renderAuditInfo(AuditTypes.updatedAt)}
            </Form>
          </Card>
        </div>
        <Row gutter="16">
          <Col lg={12} md={12} sm={24} sx={24}>
            {/* CLINICIAN SIDEBAR CONFIGURATION */}
            <Row gutter="16">
              <Col lg={24} md={24} sm={24} sx={24}>
                <ClinicianSidebarConfig
                  customer={customer}
                  readyMap={readyMap}
                  isNew={isNew}
                  layoutNames={layoutNames}
                  onChangeHandler={this.onChangeSidebarConfig}
                  disabled={shouldDisableInputFields(loggedUser)}
                />
              </Col>
            </Row>
            {/* BRANDING */}
            <Row gutter="16">
              <Col lg={0} md={0} sm={0} sx={0}>
                <Branding
                  branding={customer.branding}
                  readyMap={readyMap}
                  isNew={isNew}
                  onChangeHandler={this.onChangeBrandingHandler}
                  iconUrl={`${BASE_URL}${ENDPOINTS.customer.fetchById}`}
                />
              </Col>
            </Row>
            <Row gutter="16">
              {/* INTERPRETER SETTINGS */}
              <Col lg={24} md={24} sm={24} sx={24}>
                <InterpreterSettings
                  interpreters={interpreters}
                  readyMap={readyMap}
                  isNew={isNew}
                  customer={customer}
                  inDemand={inDemand}
                  stratus={stratus}
                  onChangeHandler={this.onChangeInterpreterHandler}
                  onChangeRadio={this.onChangeInterpreterRadio}
                  onPageChange={this.onPageChange}
                  pagination={pagination}
                  handleStratusDelete={this.handleStratusDelete}
                  uploadInterpreter={uploadInterpreter}
                  editingKey={editingKey}
                  handleStratusSave={this.handleStratusSave}
                  disabled={shouldDisableInputFields(loggedUser)}
                />
              </Col>
            </Row>
            <Row gutter="16">
              {/* INTERPRETER SETTINGS */}
              {!isNew && this.JamfValueIsTrue()
                && <Col lg={24} md={24} sm={24} sx={24}>
                  <Card
                  className="gx-card jamf-support"
                  loading={!readyMap.customer && !isNew}
                  title={<IntlMessages id="configuration.customer.jamf_title" />}
                >    
                  <Form
                    className="support-forms"
                    onFinish={this.uploadDocument}
                  >
                    <Row className="gx-d-flex gx-align-items-center" gutter={16}>
                      <Col lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="file"
                          className="doc-items"
                          rules={[
                            {
                              required: true,
                              message: this.intl.formatMessage({ id: 'support.selectJamfFile' }),
                            },
                          ]}
                          getValueFromEvent={(e) => e.file.originFileObj}
                        >
                          <Upload
                            beforeUpload={this.onFileChange}
                            className="upload_document"
                            accept=".csv"
                            type="file"
                            maxCount={1}
                          >
                            <Button className="upload-btn" icon={<UploadOutlined />}>
                              <IntlMessages id="support.selectJamfFile" />
                            </Button>
                          </Upload>
                        </Form.Item>
                      
                    </Col>
                    <Col lg={22} md={22} sm={23} xs={23}>
                      <Form.Item className="doc-items">
                        <Button htmlType="submit" htmlType="submit" className="sendButton upload-btn">
                          <IntlMessages id="configuration.customer.jamf_process" />
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col lg={1} md={1} sm={2} xs={2}>
                          <Popover className="mdm-token" content={this.content} title={this.intl.formatMessage({ id: 'configuration.customer.jamf_info' })}>
                          <div className="mdm">
                        <QuestionCircleOutlined />
                        </div>
                      </Popover>
                    </Col>
                  </Row>
                </Form>
                </Card>
                                </Col>}
            </Row>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            {/* EPIC INTERCONNECT API SETTINGS */}
            <Row gutter="16">
              <Col lg={24} md={24} sm={24} sx={24}>
                <EpicInterConnectApiSettings
                  customer={customer}
                  readyMap={readyMap}
                  isNew={isNew}
                  onChangeHandler={this.onChangeHandler}
                  disabled={shouldDisableInputFields(loggedUser)}
                />
              </Col>
            </Row>
            {/* PERFECT SERVE */}
            <Row gutter="16">
              <Col lg={24} md={24} sm={24} sx={24}>
                <PerfectServe
                  customer={customer}
                  readyMap={readyMap}
                  isNew={isNew}
                  onChangeHandler={this.onChangeHandler}
                  disabled={shouldDisableInputFields(loggedUser)}
                />
              </Col>
            </Row>
            {!isNew && (
              <Row gutter="16">
                {/* CUSTOMER ATTRIBUTES */}
                <Col lg={24} md={24} sm={24} sx={24}>
                  <CustomerAttributes
                    customer={customer}
                    readyMap={readyMap}
                    isNew={isNew}
                    onChangeHandlerTest={this.onChangeHandlerTest}
                    disabled={shouldDisableInputFields(loggedUser)}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        {this.renderHeadLine()}
      </div>
    );
  }
}

/**
 * Prop types
 * */
ManageCustomer.propTypes = {
  customer: PropTypes.object,
  urlId: PropTypes.string,
  actions: PropTypes.object.isRequired,
  getOrganizations: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  organizations: PropTypes.array.isRequired,
  redirectUrl: PropTypes.string,
  dataFacility: PropTypes.array,
  data: PropTypes.array,
  history: PropTypes.object,
  lastLocation: PropTypes.object.isRequired,
};

ManageCustomer.contextTypes = {
  router: PropTypes.object,
};
/**
 * Default props
 * */
ManageCustomer.defaultProps = {
  customer: {
    name: '',
  },
  urlId: '',
  loading: true,
  showIco: false,
  timezones: defaultTimezones,
  isInterpreterLoading: true,
};

function mapStateToProps(state, ownProps, listingsTopFilter) {
  const { page = {} } = state.ConfigurationCustomers.stratus || {};
  const urlId = ownProps.match.params.id; // from the path `.../unit/:id`

  const { list } = state.ConfigurationCustomers.customerTable || { page: {}, list: [] };
  const facilityTable = state.ConfigurationCustomers.facilityTable || { page: {}, list: [] };
  const profile = state.ConfigurationUsers;

  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  return {
    customer: state.ConfigurationCustomers.selectedCustomer,
    error: state.common.error,
    urlId,
    isNew: !urlId,
    organizations: state.listingsTopFilter.organization,
    redirectUrl: state.ConfigurationCustomers.redirectUrl,
    subtitle: state.subtitle,
    loading: state.ConfigurationCustomers.loading,
    inDemand: state.ConfigurationCustomers.inDemand,
    stratus: state.ConfigurationCustomers.stratus,
    pagination,
    dataFacility: facilityTable.list,
    data: list,
    optionsList: listingsTopFilter,
    loggedUser: profile.ownUser,
    interpreters: state.ConfigurationCustomers.interpreterOptions?.list || [],
    readyMap: state.ConfigurationCustomers.readyMap,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(customerActions, dispatch),
    fetchIndemandInterpreter: (id, page) => dispatch(fetchIndemandInterpreter(id, page)),
    fetchStratusInterpreter: (interpreterId, customerId, page) => dispatch(fetchStratusInterpreter(interpreterId, customerId, page)),
    getOrganizations: (id) => dispatch(onGetOptions('organization', id)),
    setSubtitle: (langId) => dispatch(setSubtitle(langId)),
    deleteStratusInterpreter: (interpreterId, customerId, mappingId) => dispatch(deleteStratusInterpreter(interpreterId, customerId, mappingId)),
    uploadInterpreter: (interpreterId, customerId, file) => dispatch(uploadInterpreter(interpreterId, customerId, file)),
    saveStratusInterpreter: (interpreterId, customerId, mappingId, data) => dispatch(
      saveStratusInterpreter(interpreterId, customerId, mappingId, data),
    ),
    organizationFetchDataCustomers: (page, sort, filter) => dispatch(organizationFetchDataCustomers(
      page, sort, filter,
    )),
    organizationFetchDataFacility: (page, sort, filter) => dispatch(organizationFetchDataFacility(
      page, sort, filter,
    )),
    fetchInterpreterOptions: () => dispatch(fetchInterpreterOptions),
    uploadJamfData: (documentId, file) => dispatch(uploadJamfData(documentId, file)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(injectIntl(withRouter(ManageCustomer))));
