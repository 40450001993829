import React from 'react';
import lodash from 'lodash';
import { Card, Table } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import RestManager from '@util/RestManager';
import { BASE_URL, ENDPOINTS } from '@constants/UHEEndpoints';
import UheHelper from 'util/UheHelper';

import ListingsTopFilter from '@filters/ListingsTopFilter';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import FacilityCell from '@components/tables/cells/FacilityCell';
import UnitCell from '@components/tables/cells/UnitCell';
import ActionsCell from '@components/tables/cells/ActionsCell';
import ExportCsv from '@components/ExportCsv/ExportCsv';

import { unitsOnFetchData, deleteUnits } from '@uhe_actions/configuration/units/UnitsActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { LISTING_TABLES_PAGE_SIZE, TOP_FILTER_PREFIX, TABLE_FILTER_PREFIX, APP_PAGES_CONTEXT } from '@constants/UHESettings';

/**
 * @description Renders units table
 */
class Units extends React.Component {
  /**
   * @description Adapt data returned by the server
   * @param  {Array<Object>} data
   * @return {Object}
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        organization: {
          id: value.facility.customer.organization.id,
          name: value.facility.customer.organization.name,
        },
        customer: {
          id: value.facility.customer.id,
          organizationId: value.facility.customer.organization.id,
          name: value.facility.customer.name,
        },
        facility: {
          id: value.facility.id,
          customerId: value.facility.customer.id,
          organizationId: value.facility.customer.organization.id,
          name: value.facility.name,
        },
        unit: {
          id: value.id,
          organizationId: value.facility.customer.organization.id,
          customerId: value.facility.customer.id,
          facilityId: value.facility.id,
          name: value.name,
        },
        actions: {
          id: value.id,
        },
      });
    });

    return adaptedData;
  }

  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);
    const { history, subtitle, setSubtitle } = this.props;

    this.data = [];
    this.columns = [];

    this.topFilterMap = {
      [`${TOP_FILTER_PREFIX}organization`]: 'facility.customer.organization.id',
      [`${TOP_FILTER_PREFIX}customer`]: 'facility.customer.id',
      [`${TOP_FILTER_PREFIX}facility`]: 'facility.id',
    };
    this.tableFilterMap = {
      organization: 'facility.customer.organization.name',
      customer: 'facility.customer.name',
      facility: 'facility.name',
      unit: 'name',
    };

    this.filterTypes = {};
    this.tableKeys = [
      'organization',
      'customer',
      'facility',
      'unit',
    ];

    this.tableKeys.forEach((value, index) => {
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            showFilter={!(value === 'actions')}
          />
        ),
        sorter: (value === 'actions') ? false : { multiple: index },
        defaultSortOrder: (value === 'organization') ? this.checkSortTableOrder() : false,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.topFilters = [
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byOrganization',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'organization',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byCustomer',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'customer',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byFacility',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'facility',
      },
    ];

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);

    if (subtitle && subtitle.langId !== 'configuration.unit.title') {
      setSubtitle('configuration.unit.title');
    }

    this.onPageChange = this.onPageChange.bind(this);

    this.onPageChange(1);
  }

  /**
   * @description updates the component on location change
   * @param {Object} prevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { location, loggedUser } = this.props;
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }
  }

  /** @description Change data after filtering
   * @param {number} page
   * @return {Void}
   */
  onPageChange(page) {
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const sort = [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);

      if (filterParam) {
        if (this.filterTypes[key] && this.filterTypes[key].type === 'dropdown') {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }

    });

    currSort.forEach(sortItem => {
      const key = sortItem.split(',')[0];
      const value = this.tableFilterMap[key];

      if (value) {
        sort.push(sortItem.replace(key, value));
      }
    });

    this.filter = filter;
    this.sort = sort;

    this.props.unitsOnFetchData(page - 1, sort, filter);
  }

  /**
 * @description Get endpoint url for data export
 */
  getCsvUrl = () => {
    const token = RestManager.getToken();

    const filterQueryString = this.filter && this.filter.length ? `&${this.filter.join('&')}` : '';
    const sortingQueryString = this.sorting && this.sorting.length ? `&sort=${this.sorting.join('&sort=')}` : '';
    const tokenParam = `${filterQueryString || sortingQueryString ? `&token=${token}` : `token=${token}`}`;
    return `${BASE_URL}${ENDPOINTS.configuration.UnitsTable}/csv?${filterQueryString}${sortingQueryString}${tokenParam}`;
  }

  /**
   * @description Check the default sort order for table
   * @returns {string}
   */
  checkSortTableOrder() {
    const { location } = this.props;
    let checkOrder = location.search.split('%2C')[1];

    if (checkOrder === 'asc') {
      checkOrder = 'ascend';
    }
    if (checkOrder === 'desc') {
      checkOrder = 'descend';
    }
    if (!checkOrder) {
      checkOrder = false;
    }

    return checkOrder;
  }

  /**
   * @description Renders cell
   * @param {string} content
   * @param {string} key
   * @return {JSX}
   */
  cellRenderer(content, key) {
    let cell;
    const { intl, deleteUnits, loggedUser } = this.props;

    switch (key) {
      case 'organization':
        cell = <OrganizationCell content={content} />;
        break;
      case 'customer':
        cell = <CustomerCell content={content} />;
        break;
      case 'unit':
        cell = <UnitCell content={content} />;
        break;
      case 'facility':
        cell = <FacilityCell content={content} />;
        break;
      case 'actions':
        cell = (
          <ActionsCell
            page={APP_PAGES_CONTEXT.units}
            loggedUser={loggedUser}
            intl={intl}
            content={content}
            editLink={`/configuration/units/edit/${content.id}`}
            deleteAction={() => {
              deleteUnits(
                content.id, this.currentPage, this.sort, this.filter,
              );
            }}
          />
        );
        break;

      default:
        cell = content;
    }

    return cell;
  }

  /**
  * @description Render Add Button
  * @returns {JSX}
  */
  renderAddButton = () => {
    const { isCaregilitySystemAdmin, isOrganizationAdmin, isCustomerAdmin, isFacilityAdmin } = this.props.loggedUser;

    if (isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin) {
      return (
        <Link to="/configuration/units/new" shape="circle" icon="+" className="page-icons page-icon-plus">
          <i className="icon icon-add" />
          <span><IntlMessages id="common.addText" /></span>
        </Link>
      )
    }

    return null;
  }

  /**
  * @description Render Export Csv Button
  * @returns {JSX}
  */
  renderExportCsvButton = () => {
    const { isCaregilitySystemAdmin, isCaregilityAdmin, isOrganizationAdmin, isCustomerAdmin, isFacilityAdmin } = this.props.loggedUser;

    if (isCaregilitySystemAdmin || isCaregilityAdmin || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin) {
      return (
        <ExportCsv
          getCsvUrl={UheHelper.getCsvUrl(
            this.filter, this.sorting, ENDPOINTS.ExportCsv.units,
          )}
        />
      )
    }
  }


  /**
  * @description Render table
  * @returns {JSX}
  */
  renderTable = () => {
    const { pagination, data, loading, loggedUser } = this.props;
    pagination.onChange = this.onPageChange;

    if (loggedUser.roles) {
      const { isCaregilitySystemAdmin, isOrganizationAdmin, isCustomerAdmin, isFacilityAdmin } = this.props.loggedUser;

      if (isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin) {

        if (this.columns.length <= 4) {

          const foundActions = this.tableKeys.filter(key => key === 'actions')

          if (foundActions.length === 0) {
            this.tableKeys.push('actions');
          }

          this.tableKeys.filter(key => key === 'actions').forEach((value, index) => {
            this.columns.push({
              title: (cellData) => (
                <ListingsTableInputFilter
                  cellData={cellData}
                  title={`uhe.table.${value}`}
                  dataKey={value}
                  showFilter={!(value === 'actions')}
                />
              ),
              sorter: (value === 'actions') ? false : { multiple: index },
              defaultSortOrder: (value === 'organization') ? this.checkSortTableOrder() : false,
              align: index > 3 ? 'center' : 'left',
              minWidth: 200,
              dataIndex: value,
              render: (content) => this.cellRenderer(content, value),
            });
          });

        }
      }

      return (
        <Table
          bordered
          className="gx-table-responsive"
          columns={this.columns}
          dataSource={Units.dataAdapter(data)}
          pagination={pagination}
          loading={loading}
        />
      )
    }
  }

  render() {
    const { pagination, loading, data } = this.props;
    pagination.onChange = this.onPageChange;

    return (
      <div className="dashboard configuration">
        <div className="customers-filter-box">
          <Card className="filter-boxes gx-card" title={<IntlMessages id="uhe.title.filters" />}>
            <ListingsTopFilter filters={this.topFilters} />
          </Card>
        </div>
        <div>
          <div className="uhe-table-header">
            <div className="buttons-container">
              {this.renderAddButton()}
              {this.renderExportCsvButton()}
            </div>
            <p>
              <IntlMessages id="uhe.table.matchingResults" />
              <span>{pagination.total}</span>
            </p>
          </div>
          <div className="uhe-table">
            {this.renderTable()}
          </div>
        </div>
      </div>
    );
  }
}

Units.defaultProps = {
  data: [],
  loading: true,
  pagination: {
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
};

Units.propTypes = {
  data: PropTypes.array,
  location: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  intl: PropTypes.object,
  pagination: PropTypes.object,
  subtitle: PropTypes.object,
  loading: PropTypes.bool,
  unitsOnFetchData: PropTypes.func,
  setSubtitle: PropTypes.func,
};

const mapStateToProps = ({ ConfigurationUnits, subtitle, ConfigurationUsers }) => {
  const { list, page } = ConfigurationUnits.table || {};
  const { loading } = ConfigurationUnits;
  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  return {
    data: list, pagination, loading, subtitle,
    loggedUser: ConfigurationUsers.ownUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteUnits: (id, page, sorting, filter) => dispatch(deleteUnits({
    id, page, sorting, filter,
  })),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  unitsOnFetchData: (page, sort, filter) => dispatch(unitsOnFetchData(page, sort, filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(Units)));
