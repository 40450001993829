import { all } from 'redux-saga/effects';
import authSagas from '@sagas/Auth';
import notesSagas from '@sagas/Notes';
import ListingsTopFilterSagas from '@uhe_sagas/filters/ListingsTopFilterSaga';
import UHESaga from '@uhe_sagas/monitoring/UHESaga';
import FacilitiesSaga from '@uhe_sagas/configuration/facilities/FacilitiesSaga';
import OrganizationsSaga from '@uhe_sagas/configuration/organizations/OrganizationsSaga';
import CustomersSaga from '@uhe_sagas/configuration/customers/CustomersSaga';
import UnitsSaga from '@uhe_sagas/configuration/units/UnitsSaga';
import unitSagas from '@uhe_sagas/UnitSaga';
import BedsCartsSaga from '@uhe_sagas/configuration/bedsCarts/BedsCartsSaga';
import UsersSaga from '@uhe_sagas/configuration/users/UsersSaga';
import MobilePatientsSaga from '@uhe_sagas/configuration/mobilePatients/MobilePatientsSaga';
import SystemSaga from '@uhe_sagas/system/SystemSaga';
import iObserverSaga from '@uhe_sagas/system/iObserverSaga';
import SupportSaga from '@uhe_sagas/system/SupportSaga';
import SignInReportsSaga from '@uhe_sagas/reports/SignInReportsSaga';
import VmrReportSaga from '@uhe_sagas/reports/VmrReportSaga';
import SsoProvidersSaga from '@uhe_sagas/system/SsoProvidersSaga';
import DeveloperSaga from '@uhe_sagas/system/DeveloperSaga';
import ResetPasswordSaga from '@uhe_sagas/ResetPasswordSaga';
import NotificationsSaga from '@uhe_sagas/system/NotificationsSaga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    notesSagas(),

    // UHE sagas below
    ListingsTopFilterSagas(),
    UHESaga(),
    OrganizationsSaga(),
    FacilitiesSaga(),
    CustomersSaga(),
    unitSagas(),
    UnitsSaga(),
    BedsCartsSaga(),
    UsersSaga(),
    SystemSaga(),
    iObserverSaga(),
    SignInReportsSaga(),
    VmrReportSaga(),
    MobilePatientsSaga(),
    DeveloperSaga(),
    NotificationsSaga(),
    SupportSaga(),
    SsoProvidersSaga(),
    ResetPasswordSaga(),
  ]);
}
