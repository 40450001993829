/**
 * @description Class JavaScript object for react state maintaining and type checking
 */
export default class ReactiveMobilePatient {
  constructor(obj) {
    this.id = 0;
    this.unit = {};
    this.facility = {};
    this.customer = {};
    this.organization = {};
    this.prefix = '';
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.password = '';
    this.phoneNumber = '';
    this.techAuthorized = false;
    this.isClinician = false;
    this.isIobserver = false;
    this.isTechnician = false;
    this.isNotification = false;
    this.isReadonly = false;
    this.isMobileUser = false;
    this.alias = '';
    this.role = '';
    this.specialty = '';
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
