import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import {
  Card, Form, Input,
} from 'antd';
import isEqual from 'lodash/isEqual';
import { withLastLocation } from 'react-router-last-location';
import EditHeader from '@components/uhe/configuration/EditHeader';
import { goBackTo, setObjectEmptyStringPropertiesToNull } from '@util/UheHelper';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { getCustomerNotifications, updateCustomerNotifications } from '@actions/uhe/system/NotificationsActions';

class ManageNotifications extends Component {
  constructor(props) {
    super(props);
    const { subtitle, setSubtitle } = this.props;
    this.formRef = React.createRef();

    this.state = {
      customerNotifications: {},
    };

    if (subtitle && subtitle.langId !== 'notifications.editTitle') {
      setSubtitle('notifications.editTitle');
    }
  }

  /**
   * @description Invokes Actions for the Customer Notifications Data
   * @returns {void}
   */
  componentDidMount() {
    const { customerNotificationsData, match } = this.props;
    const urlId = match.params.id || '';

    customerNotificationsData({ id: urlId });
  }

  /**
   * @description Updates Local State Depending on prevProps && this.props
   * @param {Object} prevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (!isEqual(prevProps.data, data)) {
      this.setState({ customerNotifications: data }, () => {
        const { customerNotifications } = this.state;
        const { email1, email2, email3 } = customerNotifications;

        this.formRef.current.setFieldsValue({
          email1,
          email2,
          email3,
        });
      });
    }
  }

  /**
   * @description Render title, back and save buttons
   * @returns {JSX}
   */
  renderHeadLine = () => {
    const { loading, lastLocation, history } = this.props;
    return (
      <EditHeader
        goBack={goBackTo('/system/notifications', lastLocation?.pathname, history)}
        loading={loading}
      />
    );
  }

  /**
   * @description Handle Changes in the Input Fields
   * @param {Object} event
   * @param {string} key
   * @returns {void}
   */
  changeHandler = (event, key) => {
    const { customerNotifications } = this.state;

    this.setState({
      customerNotifications: {
        ...customerNotifications,
        [key]: event.target.value,
      },
    });
  }

  /**
   * @description Invokes Save Customer Notifications Action
   * @returns {void}
   */
  saveNotifications = () => {
    const { customerNotifications } = this.state;
    const { saveCustomerNotifications, match } = this.props;
    const customerId = match.params.id;
    
    if (customerId !== undefined) {
      saveCustomerNotifications({ body: setObjectEmptyStringPropertiesToNull(customerNotifications), id: customerId });
    }
  }

  render() {
    const { loading } = this.props;
    const { customerNotifications } = this.state;
    const { email1, email2, email3 } = customerNotifications;

    return (
      <Form className="manage-customer-wrapper" ref={this.formRef} onFinish={this.saveNotifications}>
        {this.renderHeadLine()}
        <Card
          className="gx-card add-new-card"
          title={<IntlMessages id="notifications.editCardTitle" />}
          loading={loading}
        >
          <Form.Item
            className="form-item-row"
            name="email1"
            label={<IntlMessages id="notifications.notificationEmail1" />}
            rules={[
              {
                type: 'email',
                message: <IntlMessages id="common.error_email" />,
              },
            ]}
            initialValue={email1}
          >
            <Input onChange={(event) => this.changeHandler(event, 'email1')} />
          </Form.Item>
          <Form.Item
            className="form-item-row"
            name="email2"
            label={<IntlMessages id="notifications.notificationEmail2" />}
            rules={[
              {
                type: 'email',
                message: <IntlMessages id="common.error_email" />,
              },
            ]}
            initialValue={email2}
          >
            <Input onChange={(event) => this.changeHandler(event, 'email2')} />
          </Form.Item>
          <Form.Item
            className="form-item-row"
            name="email3"
            label={<IntlMessages id="notifications.notificationEmail3" />}
            rules={[
              {
                type: 'email',
                message: <IntlMessages id="common.error_email" />,
              },
            ]}
            initialValue={email3}
          >
            <Input onChange={(event) => this.changeHandler(event, 'email3')} />
          </Form.Item>
        </Card>
        {this.renderHeadLine()}
      </Form>
    );
  }
}

ManageNotifications.propTypes = {
  customerNotificationsData: PropTypes.func.isRequired,
  saveCustomerNotifications: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  subtitle: PropTypes.shape().isRequired,
  setSubtitle: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object,
  lastLocation:PropTypes.object.isRequired,
};

const mapStateToProps = ({ Notifications, ConfigurationUsers, subtitle }) => {
  const { loading, customerNotifications } = Notifications;
  const { ownUser } = ConfigurationUsers;

  return {
    data: customerNotifications,
    loggedUser: ownUser,
    loading,
    subtitle,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  customerNotificationsData: (id) => dispatch(getCustomerNotifications(id)),
  saveCustomerNotifications: (data) => dispatch(updateCustomerNotifications(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(injectIntl(withRouter(ManageNotifications))));
