import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Card, Input, Cascader, Row, Col, Form, Switch, Popover,
} from 'antd';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import EditHeader from '@components/uhe/configuration/EditHeader';
import ActionsCard from '@components/uhe/configuration/bedsCarts/ActionsCard';
import SettingOptionsCard from '@components/uhe/configuration/bedsCarts/SettingOptionsCard';
import IpConfigurationCard from '@components/uhe/configuration/bedsCarts/IpConfigurationCard';
import DeviceAttrCard from '@components/uhe/configuration/bedsCarts/DeviceAttrCard';
import StatusReport from '@components/uhe/configuration/bedsCarts/StatusReport';
import ActiveComponentsCard from '@components/uhe/configuration/bedsCarts/ActiveComponentsCard';
import {
  fetchBedCart,
  fetchDeviceTypes,
  clearBedCart,
  saveUheBedCart,
  fetchAcknowledged,
  fetchAcknowledgedFalse,
  fetchAcknowledgedNotes,
  fetchCareDeviceModes,
} from '@uhe_actions/configuration/bedsCarts/bedsCartsActions';
import { fetchCustomer } from '@uhe_actions/configuration/customers/CustomersActions';
import { onFetchOwnPofile } from '@uhe_actions/configuration/users/UsersActions';
import { systemOnFetchData } from '@uhe_actions/system/SystemActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { FORM_LAYOUT } from '@constants/UHESettings';
import { goBackTo } from '@util/UheHelper';
import { withLastLocation } from 'react-router-last-location';
import { shouldShowEditHeader } from '@util/UheRoleChecker';

/**
 * Renders Manage uhe bed/carts view
 */
class ManageUheBedCart extends Component {
  /**
   * Validate if URL has protocol prefix and
   * if there is no blank spaces or invalid characters
   * @param {string} url URL String
   * @returns {boolean} Regex Pattern
   */
  static validateURL(url) {
    const pattern = new RegExp('^(https?:\\/\\/)'
      + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
      + '((\\d{1,3}\\.){3}\\d{1,3}))'
      + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'
      + '(\\?[;&a-z\\d%_.~+=-]*)?'
      + '(\\#[-a-z\\d_]*)?$', 'i');

    return !!pattern.test(url);
  }

  /**
   * @description Renders error message if the URL is not valid
   * @param {string} url
   * @returns {JSX}
   */
  static renderErrorMessage(url) {
    let message = null;
    if (!ManageUheBedCart.validateURL(url) && url) {
      message = (
        <div className="errorMessage">
          <IntlMessages id="configuration.bedsCarts.alert.invalidUrl" />
        </div>
      );
    }

    return message;
  }

  formRef = React.createRef();

  /**
   * ManageUheBedCart Constructor
   * @param {*} props Props
   */
  constructor(props) {
    super(props);
    this.props.fetchAcknowledgedNotes();
    const { setSubtitle, intl } = this.props;
    this.intl = intl;

    this.formRef = React.createRef();

    this.state = {
      bedCart: {
        endpointStatus: {},
        endpointAttributes: [],
        components: [],
        notes: '',
        under_maintenance: 0,
        device_config_mode: '',
        name: '',
      },
      deviceTypes: [],
    };

    setSubtitle('configuration.bedsCarts.editUheTitle');

    this.onChangeDeviceType = this.onChangeDeviceType.bind(this);
    this.saveUheBedCart = this.saveUheBedCart.bind(this);
    this.onChangeInputHandler = this.onChangeInputHandler.bind(this);
    this.onChangeOption = this.onChangeOption.bind(this);
    this.onChangeAction = this.onChangeAction.bind(this);
    this.onChangeDeviceAttr = this.onChangeDeviceAttr.bind(this);
    this.onChangeAuth = this.onChangeAuth.bind(this);

  }

  /**
   * @description Updates local state
   * @returns {void}
   */
  componentDidMount() {
    const {
      match,
      clearBedCart,
      fetchDeviceTypes,
      systemOnFetchData,
      location,
      fetchBedCart,
      fetchCareDeviceModes,
      fetchCustomer,
    } = this.props;
    const urlId = match.params.id || '';
    const customerId = match.params.customer_id || '';

    if (urlId) {
      clearBedCart();
      fetchDeviceTypes();
      systemOnFetchData();
      fetchCareDeviceModes();
      fetchCustomer({ id: customerId });

        fetchBedCart({ id: urlId });
  
    }

    if (location.state) {
      const nonUheBedCart = location.state.nonUheDevice;
      nonUheBedCart.endpoint_type = location.state.selectedDevice;
      this.setState({ bedCart: nonUheBedCart });
    }
  }

  /**
   * @description Updates local state depends on props
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const {
      bedCart, deviceTypes, error, history,
    } = this.props;

    if (prevProps.error !== error) {
      // handle system error
      if (error.code === 404) {
        history.push('/configuration/beds-carts/uhe-units');
        return;
      }
    }

    if (
      Object.keys(prevProps.bedCart).length === 0
      && Object.keys(bedCart).length > 0
    ) {
      this.setState({ bedCart: { ...bedCart } });
    }

    if (
      prevProps.deviceTypes.length === 0
      && deviceTypes.length > 0
    ) {
      this.setState({ deviceTypes });
    }

    const data_bedCart = this.state.bedCart;

    this.formRef.current.setFieldsValue({
      acknowledged_by: this.state.bedCart.acknowledged_by,
      notes: data_bedCart.notes,
    });
  }

  /**
   * Handle Changes on Device Types Dropdown and Updates Local State
   * @param {array} device Device Data
   * @returns {void}
   */
  onChangeDeviceType(device) {
    const { bedCart } = this.state;
    const { history, match } = this.props;

    bedCart.endpoint_type = device[0];
    if (device[0] === 'Standards Based') {
      history.push(
        `/configuration/beds-carts/non-uhe-units/edit/${match.params.id}`,
        { uheDevice: bedCart, selectedDevice: device[0] },
      );
      return;
    }
    this.setState({ bedCart });
  }

  /**
   * @description Handle Changes on the Alert URL Input Field and Updates Local State
   * @param {Object} event
   * @param {string} key
   * @returns {void}
   */
  onChangeInputHandler(event, key) {
    const { bedCart } = this.state;
    const newState = { ...bedCart, [key]: event.target.value };
    this.setState({ bedCart: newState });
  }

  /**
   * @description Handle Changes on Each Setting Options Dropdown and Updates Local State
   * @param {number} vol
   * @param {string} key
   * @returns {void}
   */
  onChangeOption(vol, key) {
    const { bedCart } = this.state;
    const newState = { ...bedCart, [key]: parseInt(vol) };

    this.setState({ bedCart: newState });
  }

  /**
   * @description Handle Changes on Each Switch Action and Updates Local State
   * @param {boolean} isOn
   * @param {string} key
   * @returns {void}
   */
  onChangeAction(isOn, key) {
    const { bedCart } = this.state;
    const newState = { ...bedCart, [key]: isOn ? 1 : 0 };

    this.setState({
      bedCart: newState,
    });
  }

  /**
   * @description Handle Changes in Device Attributes Input Fields and Updates Local State
   * @param {Object} event
   * @param {number} index
   * @returns {void}
   */
  onChangeDeviceAttr(event, index) {
    const { bedCart } = this.state;
    const newState = bedCart.endpointAttributes;
    if (newState[index]) {
      newState[index].value = event.target.value;
    } else {
      newState[index] = { value: null };
    }

    this.setState({ bedCart: { ...bedCart, endpointAttributes: newState } });
  }

  /**
   * @description Handle Changes in the Enterprise Wifi Authentication and
   * Inner Authentication Dropdown and Updates Local State
   * @param {number} index
   * @param {Object} auth
   * @returns {void}
   */
  onChangeAuth(index, auth, key) {
    const { bedCart } = this.state;
    let selectedAuth = index[0];

    selectedAuth = auth.props.children;
    bedCart[key] = selectedAuth;

    this.setState({ bedCart });
  }

  /**
   * @description Handle Changes in Acknowledged Switch and Updates Local State
   * @param {any} key
   * @param {boolean} checked
   * @returns {void}
   */
  onChangeHendleSwitch(key, checked) {
    const { bedCart } = this.state;
    this.setState({
      acknowledged_by: this.props.profile.username,
    });
    const newState = !!checked;
    if (newState) {
      this.setState({ bedCart: { ...this.state.bedCart, ...this.state.bedCart, [key]: newState } });
      this.props.fetchAcknowledged({ body: this.props.profile.username, id: this.props.match.params.id });
    } else {
      this.setState({ bedCart: { ...this.state.bedCart, ...this.state.bedCart, [key]: newState } });
      this.props.fetchAcknowledgedFalse({ body: bedCart.acknowledged_by, id: this.props.match.params.id });
    }
    return newState;
  }

  /**
   * @description Change cascader options
   * @param {String} value
   * @param {Object} label
   */
  onSelectMode(value, label) {
    const { bedCart } = this.state;
    bedCart.device_config_mode = label[0].label;

    if (bedCart.device_config_mode === 'INSTALL_TESTING') {
      bedCart.is_live = 0;
      bedCart.customer_auto_notification = false;
      bedCart.under_maintenance = 0;
    } else if (bedCart.device_config_mode === 'OPERATIONAL') {
      bedCart.is_live = 1;
      bedCart.customer_auto_notification = true;
      bedCart.under_maintenance = 0;
    } else if (bedCart.device_config_mode === 'PRIVATE_DISABLED') {
      bedCart.is_live = 1;
      bedCart.customer_auto_notification = true;
      bedCart.under_maintenance = 1;
    }

    this.setState({ ...bedCart });
  }

  /**
   * @description Change isLive switch
   * @param {String} key
   * @param {Object} checked
   */
  onChangeIsLiveSwitch(key, checked) {
    const { bedCart } = this.state;
    const newState = checked ? 1 : 0;

    if (newState) {
      this.setState({ bedCart: { ...bedCart, ...bedCart, [key]: newState } });
    } else {
      this.setState({ bedCart: { ...bedCart, ...bedCart, [key]: newState } });
    }
  }

  /**
   * @description Change Auto Notification switch
   * @param {String} key
   * @param {Object} checked
   */
  onChangeAutoNotification(key, checked) {
    const { bedCart } = this.state;
    const newState = !!checked;

    if (newState) {
      this.setState({ bedCart: { ...bedCart, ...bedCart, [key]: newState } });
    } else {
      this.setState({ bedCart: { ...bedCart, ...bedCart, [key]: newState } });
    }
  }

  /**
   * @description TODO - Save chanages upon click on the save button
   * @returns {void}
   */
  saveUheBedCart(event) {
    const { bedCart } = this.state;
    const {
      match,
      saveUheBedCartEdit,
      fetchAcknowledgedNotes,
    } = this.props;
    const isValidUrl = this.validateURL(bedCart.elert_url);

    if (isValidUrl || !bedCart.elert_url) {
      saveUheBedCartEdit({
        body: bedCart,
        id: match.params.id,
      });
      fetchAcknowledgedNotes({
        body: bedCart.notes,
        id: match.params.id,
      });
    }
  }

  /**
   * @description Validate if URL has protocol prefix and
   * if there is no blank spaces or invalid characters
   * @param {string} url
   * @return {boolean}
   */
  validateURL(url) {
    const pattern = new RegExp('^(https?:\\/\\/)'
      + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
      + '((\\d{1,3}\\.){3}\\d{1,3}))'
      + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'
      + '(\\?[;&a-z\\d%_.~+=-]*)?'
      + '(\\#[-a-z\\d_]*)?$', 'i');

    return !!pattern.test(url);
  }

  /**
   * @description Adapt data returned by the server
   * @return {Array}
   */
  modesData() {
    const { modes } = this.props;
    const adaptedData = [];
    if (modes) {
      Object.values(modes).forEach((value, index) => {
        adaptedData.push({
          label: value,
          value: index,
        });
      });
    }
    return adaptedData;
  }

  /**
   * Render title, back and save buttons
   * @returns {JSX} Header Title, Back and Save Buttons
   */
  renderHeadLine() {
    const { loading, lastLocation, history } = this.props;

    if (shouldShowEditHeader()) {
      return (
        <EditHeader
          goBack={goBackTo(`/configuration/beds-carts/uhe-units?sort=${encodeURIComponent('organization,asc')}`, lastLocation?.pathname, history)}
          save={this.saveUheBedCart}
          loading={loading}
        />
      );
    }

    return null;
  }

  /**
   * Renders error message if the URL is not valid
   * @param {string} url URL String
   * @return {JSX} Error Message
   */
  renderErrorMessage(url) {
    let message = null;
    if (!this.validateURL(url) && url) {
      message = (
        <div className="errorMessage">
          <IntlMessages id="configuration.bedsCarts.alert.invalidUrl" />
        </div>
      );
    }
    return message;
  }

  /**
   * Renders ManageUheBedCart Component
   * @returns {JSX} ManageUheBedCart Component
   */
  render() {
    const { bedCart, deviceTypes } = this.state;
    const {
      loading, latestVersion, match, customer, profile
    } = this.props;

    return (
      <div className="manage-customer-wrapper uheBedCart">
        {this.renderHeadLine()}
        <Form ref={this.formRef} {...FORM_LAYOUT} name="uheBedCart">
          <Row gutter={16}>
            <Col lg={8} md={24}>
              <Card
                loading={loading}
                className="gx-card"
                title={<IntlMessages id="configuration.bedsCarts.roomBedName" />}
              >
                <Input
                  onChange={(e) => this.onChangeInputHandler(e, 'name')}
                  value={bedCart.name}
                />
              </Card>
              <Card
                loading={loading}
                className="gx-card"
                title={<IntlMessages id="configuration.bedsCarts.deviceDetails" />}
              >
                <Form.Item
                  className="gx-align-items-center"
                  label={<IntlMessages id="configuration.bedsCarts.deviceType" />}
                >
                  <Cascader
                    expandTrigger="hover"
                    size="large"
                    key="deviceTypes"
                    options={deviceTypes || []}
                    fieldNames={{ label: 'value', value: 'value' }}
                    value={[bedCart.endpoint_type]}
                    onChange={this.onChangeDeviceType}
                  />
                </Form.Item>
                <Form.Item
                  className="gx-align-items-center"
                  label={<IntlMessages id="configuration.bedsCarts.parenPartSerialNumber" />}
                >
                  {bedCart.machine_parent_part_serial_number}
                </Form.Item>
              </Card>
              <ActionsCard
                bedCart={bedCart}
                match={match}
                onChangeAction={this.onChangeAction}
                loading={loading}
                loggedUser={profile}
              />
              <Card
                loading={loading}
                className="gx-card"
                title={
                  <IntlMessages id="configuration.bedsCarts.alert.alertUrl" />
                }
              >
                <Form.Item
                  className="gx-align-items-center"
                  label={
                    <IntlMessages id="configuration.bedsCarts.alert.alertUrl" />
                  }
                >
                  <Input
                    onChange={(e) => this.onChangeInputHandler(e, 'elert_url')}
                    value={bedCart.elert_url}
                    longdesc={this.intl.formatMessage({
                      id: 'configuration.bedsCarts.descriptions.alertUrl',
                    })}
                  />
                  {ManageUheBedCart.renderErrorMessage(bedCart.elert_url)}
                </Form.Item>
              </Card>
              <SettingOptionsCard
                bedCart={bedCart}
                onChangeOption={this.onChangeOption}
                loading={loading}
              />
              <Card
                loading={loading}
                title={<IntlMessages id="configuration.bedsCarts.acknowledged.title" />}
                className="gx-card"
              >

                <Form.Item
                  className="gx-align-items-center"
                  label={(
                    <Popover content={<IntlMessages id="configuration.bedsCarts.acknowledged.popoverAcknowledged" />} overlayStyle={{ width: '35%' }}>
                      <span className="device-attr-title">
                        <IntlMessages id="configuration.bedsCarts.acknowledged.acknowledgedSwitchTitle" />
                      </span>
                    </Popover>
                  )}

                >
                  <Switch
                    name="acknowledged_by"
                    checked={!!bedCart.acknowledged_by}
                    onChange={(e) => { this.onChangeHendleSwitch('acknowledged_by', e); }}
                  />
                </Form.Item>
                <Form.Item
                  className="gx-align-items-center"
                  label={(
                    <Popover content={<IntlMessages id="configuration.bedsCarts.acknowledged.popoverAcknowledgedBy" />}>
                      <span className="device-attr-title">
                        <IntlMessages id="configuration.bedsCarts.acknowledged.acknowledgedby" />
                      </span>
                    </Popover>
                  )}
                >
                  <span className="acknowledged-email">
                    {bedCart.acknowledged_by}
                  </span>
                </Form.Item>
                <Form.Item
                  className="gx-align-items-center"
                  label={<IntlMessages id="configuration.bedsCarts.acknowledged.supportTicket" />}
                >
                  <Input
                    name="notes"
                    value={bedCart.notes}
                    onChange={(e) => this.onChangeInputHandler(e, 'notes')}
                  />
                </Form.Item>
              </Card>
              {/* Stop */}
              <Card
                loading={loading}
                className="gx-card"
                title={
                  <IntlMessages id="configuration.bedsCarts.activeComponents.title" />
                }
              >
                <ActiveComponentsCard bedCart={bedCart} bedCartId={match.params.id} imgColSize={6} />
              </Card>
            </Col>
            <Col lg={16} md={24}>
              <Card
                loading={loading}
                className="gx-card"
                title={
                  <IntlMessages id="configuration.bedsCarts.statusReport.title" />
                }
              >
                <StatusReport
                  bedCart={bedCart}
                  latestVersion={latestVersion}
                  loading={loading}
                  timeZone={customer.time_zone}
                />
              </Card>
              <Card
                loading={loading}
                className="gx-card"
                title={<IntlMessages id="configuration.bedsCarts.deviceConfig.deviceConfigStatus" />}
              >
                <Form.Item
                  className="gx-align-items-center"
                  label={(
                    <Popover content={<IntlMessages id="configuration.bedsCarts.deviceConfig.titleDeviceConfig" />}>
                      <span className="device-attr-title">
                        <IntlMessages id="configuration.bedsCarts.deviceConfig.mode" />
                      </span>
                    </Popover>
                  )}
                >
                  <Cascader
                    className="top-filter-popup"
                    expandTrigger="hover"
                    size="large"
                    value={[bedCart.device_config_mode]}
                    options={this.modesData()}
                    onChange={(value, label) => this.onSelectMode(value, label)}
                  />
                </Form.Item>
                <Form.Item
                  className="gx-align-items-center"
                  label={(
                    <Popover content={<IntlMessages id="configuration.bedsCarts.deviceConfig.titleCaregilityMonitor" />}>
                      <span className="device-attr-title">
                        <IntlMessages id="configuration.bedsCarts.deviceConfig.caregilityMonitor" />
                      </span>
                    </Popover>
)}
                >
                  <Switch
                    name="is_live"
                    checked={!!bedCart.is_live}
                    onChange={(e) => { this.onChangeIsLiveSwitch('is_live', e); }}
                  />
                </Form.Item>
                <Form.Item
                  className="gx-align-items-center"
                  label={(
                    <Popover content={<IntlMessages id="configuration.bedsCarts.deviceConfig.titleCustomerNotification" />}>
                      <span className="device-attr-title">
                        <IntlMessages id="configuration.bedsCarts.deviceConfig.customerNotification" />
                      </span>
                    </Popover>
)}
                >
                  <Switch
                    name="customer_auto_notification"
                    checked={!!bedCart.customer_auto_notification}
                    onChange={(e) => { this.onChangeAutoNotification('customer_auto_notification', e); }}
                  />
                </Form.Item>
                <Form.Item
                  className="gx-align-items-center"
                  label={(
                    <Popover content={<IntlMessages id="configuration.bedsCarts.deviceConfig.titileCallInOut" />}>
                      <span className="device-attr-title">
                        <IntlMessages id="configuration.bedsCarts.deviceConfig.callInOut" />
                      </span>
                    </Popover>
)}
                >
                  {bedCart.under_maintenance === 1
                    ? this.intl.formatMessage({ id: 'common.callDisabled' })
                    : this.intl.formatMessage({ id: 'common.callEnabled' })}
                </Form.Item>
              </Card>
              <IpConfigurationCard
                bedCart={bedCart}
                onChangeInputHandler={this.onChangeInputHandler}
                onChangeAuth={this.onChangeAuth}
                loading={loading}
              />
              <DeviceAttrCard
                bedCart={bedCart}
                onChangeDeviceAttr={this.onChangeDeviceAttr}
                loading={loading}
              />
            </Col>
          </Row>
        </Form>
        {this.renderHeadLine()}
      </div>
    );
  }
}

ManageUheBedCart.defaultProps = {
  bedCart: {},
  deviceTypes: [],
  loading: true,
};

ManageUheBedCart.propTypes = {
  bedCart: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  deviceTypes: PropTypes.array,
  intl: PropTypes.object,
  location: PropTypes.object,
  loading: PropTypes.bool,
  latestVersion: PropTypes.number,
  fetchBedCart: PropTypes.func,
  fetchDeviceTypes: PropTypes.func,
  setSubtitle: PropTypes.func,
  systemOnFetchData: PropTypes.func,
  profile: PropTypes.object,
  fetchAcknowledged: PropTypes.func,
  fetchAcknowledgedFalse: PropTypes.func,
  fetchAcknowledgedNotes: PropTypes.func,
  fetchCareDeviceModes: PropTypes.func,
  modes: PropTypes.object,
  lastLocation: PropTypes.object.isRequired,
  customer: PropTypes.shape().isRequired,
};

const mapStateToProps = ({
  bedsCarts,
  SystemSettingsSystem,
  common,
  subtitle,
  ConfigurationUsers,
  ConfigurationCustomers,
}) => {
  const { production_version = {} } = SystemSettingsSystem.data;
  const latestVersion = parseFloat(production_version.value) || null;

  return {
    error: common.error,
    bedCart: bedsCarts.selectedBedCart,
    latestVersion,
    deviceTypes: bedsCarts.deviceTypes,
    loading: bedsCarts.loading,
    subtitle,
    profile: ConfigurationUsers.ownUser,
    modes: bedsCarts.modes,
    customer: ConfigurationCustomers.selectedCustomer,
  };
};

/**
 * Maps Actions to Props
 * @param {func} dispatch dispatch function
 * @returns {object} Actions
 */
const mapDispatchToProps = (dispatch) => ({
  clearBedCart: () => dispatch(clearBedCart()),
  fetchBedCart: (id) => dispatch(fetchBedCart(id)),
  fetchDeviceTypes: (data) => dispatch(fetchDeviceTypes(data)),
  systemOnFetchData: (page) => dispatch(systemOnFetchData(page)),
  saveUheBedCartEdit: (data) => dispatch(saveUheBedCart(data)),
  onFetchOwnPofile: () => dispatch(onFetchOwnPofile()),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  fetchAcknowledged: (id) => dispatch(fetchAcknowledged(id)),
  fetchAcknowledgedFalse: (id) => dispatch(fetchAcknowledgedFalse(id)),
  fetchAcknowledgedNotes: (id) => dispatch(fetchAcknowledgedNotes(id)),
  fetchCareDeviceModes: () => dispatch(fetchCareDeviceModes()),
  fetchCustomer: (id) => dispatch(fetchCustomer(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(injectIntl(withRouter(ManageUheBedCart))));
