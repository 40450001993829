import { BASE_URL } from '@constants/UHEEndpoints';
import RestManager from '@util/RestManager';
import {
  ACCESS_TOKEN_KEY,
  IMPERSONATE_TOKEN,
  REFRESH_TOKEN_KEY,
  CURRENT_USER_KEY,
} from '@constants/UHESettings';
import { store } from '@root/NextApp';
import { getOwnUser } from '@uhe_selectors/configuration/users/UsersSelectors';
import React, { Component } from 'react';
import momentTz from 'moment-timezone';
import { TIMEZONES } from '@constants/SystemConstants';
import { Select } from 'antd';

export { timezones as timeZoneListing } from '@assets/timezones.json';

const { Option } = Select;

const UheHelper = {
  /**
   * @param  {String} filter
   * @param  {String} sorting
   * @param  {String} endpoint
   * @description Get endpoint url for data export
   * @return {String}
   */
  getCsvUrl: (filter, sorting, endpointUrl) => {
    const token = RestManager.getToken();

    const filterQueryString = filter && filter.length ? `&${filter.join('&')}` : '';
    const sortingQueryString = sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : '';

    const tokenParam = `${filterQueryString || sortingQueryString ? `&token=${token}` : `token=${token}`}`;
    return `${BASE_URL}${endpointUrl}${filterQueryString}${sortingQueryString}${tokenParam}`;
  },

  /**
   * Clears all auth tokens
   * @returns {void}
   */
  clearAuthTokens() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(IMPERSONATE_TOKEN);
  },

  /**
   * Clears the impersonate token
   * @returns {void}
   */
  clearImpersonateAuthTokens() {
    localStorage.removeItem(IMPERSONATE_TOKEN);
  },

  /**
   * Returns the user impersonate auth token
   * @returns {string}
   */
  getImpersonateAuthToken() {
    localStorage.getItem(IMPERSONATE_TOKEN);
  },

  /**
   * Saves the current user profile in local storage
   * @params {Object} profile
   * @returns {void}
   */
  setOwnUserProfile(profile) {
    localStorage.setItem(CURRENT_USER_KEY, profile);
  },

  /**
   * Deletes the current user profile from the localstorage
   * @returns {void}
   */
  removeOwnUserProfile() {
    localStorage.removeItem(CURRENT_USER_KEY);
  },
};

/**
 * @param {string} location Fallback location to return to
 * @param history History from react-router from props
 * @param lastLocation Last location from react-router-last-location from props
 * @returns {function(): void} Action which pushes to state
 * or calls goBack whether the user has been on App page or not
 */
export const goBackTo = (location, lastLocation, history) => () => {
  if (lastLocation === location) {
    history.goBack();
  } else {
    history.push(location);
  }
};
UheHelper.goBackTo = goBackTo;

/**
 * @description Utility singleton for easy case transformation
 * @type {{
 *  fromUpperSnakeToPascalCase(string): string
 * }}
 */
export const CaseHelper = {
  /**
   * @param {string} value
   * @returns {string}
   */
  fromUpperSnakeToPascalCase(value) {
    return value.split('_')
      .map(([...y]) => y.shift().toUpperCase() + y.join('').toLowerCase())
      .join('');
  },
  fromPascalToCamelCase(value) {
    return [...value].map((char, index) => index === 0 ? char.toLowerCase() : char).join('');
  },
  fromSpaceToCamelCase(value) {
    return value
      .split(' ')
      .map(([...word], index) => index === 0
        ? word.join('').toLowerCase()
        : word.shift().toUpperCase() + word.join('').toLowerCase())
      .join('');
  },
};
UheHelper.CaseHelper = CaseHelper;


/**
 * @description Makes Type Ahead of Filter Input Case Insensitive
 * @param {string} inputValue
 * @param {Object} path
 * @returns {Object}
 */
export const cascaderSearchFilter = (inputValue, path) => path.some((option) => (option.name || option.cart_name).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
export const cascaderCustomerFiscalFilter = ((input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0);
export const cascaderDeviceSearchFilter = (inputValue, path) => path.some((option) => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
/**
 * Get the currently logged user from the global state ConfigurationUsers space
 * @returns {{}} global state ConfigurationUsers.ownUser
 */
export const getLoggedUser = () => getOwnUser(store.getState());
UheHelper.getLoggedUser = getLoggedUser;

/**
 * Returns new array without empty or falsy entries
 * @param {Array} array Items
 * @returns {Array} New array without empty or falsy entries
 */
export const withoutEmptyEntries = (array) => array.filter((item) => !!item);

/**
 * Sets all properties with empty string values to null
 * @param {object} object Object
 * @returns {object} New object empty string values
 */
export const setObjectEmptyStringPropertiesToNull = (object) => {
  for (let propName in object) {
    if (object[propName] === "") {
      object[propName] = null;
    }
  }
  return object
}

export default UheHelper;
