import React from 'react';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Upload,
  Button,
  Modal,
  Image,
  Popconfirm,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  fetchBedCart,
  deleteRoomPicture,
} from '@uhe_actions/configuration/bedsCarts/bedsCartsActions';
import RestManager from '@util/RestManager';
import { BASE_URL, ENDPOINTS } from '@constants/UHEEndpoints';

/**
 * @description Renders Active Components Cart in the ManageNonUheBedCart and Technician files
 * @param {Object} bedCart
 * @param {Object} intl
 * @returns {JSX}
 */

class ActiveComponentsCard extends React.PureComponent {
  constructor(props) {
    super(props);
    const { intl, location } = this.props;
    this.intl = intl;
    this.state = {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      bedcartComponents: {},
    };

    this.techPath = location.pathname.indexOf('/technician') !== -1;
  }

  componentDidMount() {
    this.loadDeviceImages();
  }

  componentDidUpdate(prevProps) {
    const {
      bedCart
    } = this.props;

    if (
      Object.keys(prevProps.bedCart).length === 0
      && Object.keys(bedCart).length > 0
    ) {
      this.loadDeviceImages();
    }
    if(prevProps.bedCart.components !== bedCart.components) {
      this.loadDeviceImages();
    }
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  /**
   * @description Loads Device Images
   * @returns {void}
   */
  loadDeviceImages = () => {
    const { bedCartId } = this.props;

    if (bedCartId && Object.entries(this.props.bedCart).length > 0) {
      this.setState({
        bedcartComponents: this.props.bedCart.components,
      });
    }
  }

  /**
   * @description Closes Picture preview modal
   * @returns {void}
   */
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  /**
   * @description Handles change in Upload Component
   * @returns {void}
   */
  handleChange = ({ file, fileList, event }) => {
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].status === "done") {
        fileList.splice(i, 1);
        i--;
        this.props.fetchBedCart({ id: this.props.bedCartId });
      }
    }

    this.setState({ fileList: [...fileList] });
  }

  /**
   * Render Delete Picture Button
   * @param {Object} imgList list of pictures
   * @param {Number} id bedCartd id
   * @param {Number} imgId img id
   * @returns {JSX.Element|null}
   */
  renderDeletePictureButton(imgList, id, imgId) {
    const { deleteRoomPicture } = this.props;
    return (
      <Popconfirm
        title={this.intl.formatMessage({ id: 'configuration.customer.delete_picture' })}
        onConfirm={() =>
          deleteRoomPicture(imgList, id, imgId)
        }
      >
        <Button className="delete-icon support_delete">
          <i className="icon icon-trash" />
        </Button>
      </Popconfirm>
    );
  };

  render() {
    const { bedCart, intl, bedCartId, imgColSize } = this.props;
    const { previewVisible, previewImage, previewTitle, bedcartComponents } = this.state;
    return (
      <>
        <Upload
          action={`${BASE_URL}${ENDPOINTS.monitoring.UHETable}/${bedCartId}${ENDPOINTS.bedsCarts.components}images?token=${RestManager.getAuthToken()}`}
          listType="picture"
          accept=".jpg,jpeg,.png"
          className="gx-mb-2"
          multiple
          fileList={this.state.fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          
        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
        <div className="gx-d-flex gx-justify-content-center gx-flex-column">
          <Row gutter={[16, 20]} className="form-item-row tech-cell">
            {Array.isArray(bedcartComponents) && bedCartId && bedcartComponents.length > 0 ? (
              bedcartComponents.map((component) => {
                return (
                  <Col lg={imgColSize} md={4} sm={4} xs={12} key={component.id} className="devImg">
                    <a
                      title={intl.formatMessage({
                        id: "configuration.customer.image",
                      })}
                    >
                      <Image src={`${BASE_URL}${ENDPOINTS.monitoring.UHETable}/${bedCartId}${ENDPOINTS.bedsCarts.components}${component.id}/image?token=${RestManager.getAuthToken()}`} alt="Image Not Found" loading="lazy" />
                    </a>
                    {this.techPath && <span title={intl.formatMessage({ id: 'common.delete' })} className="delPicure">{this.renderDeletePictureButton({body: bedCart, imgId: component.id, id: bedCartId})}</span>}
                  </Col>
                );
              })
            ) : (
                <p className="gx-mt-3">
                  <IntlMessages id="configuration.bedsCarts.activeComponents.component" />
                </p>
              )}
          </Row>
        </div>
      </>
    );
  }
};

ActiveComponentsCard.propTypes = {
  intl: PropTypes.object,
};

const mapStateToProps = ({
  bedsCarts,
}) => ({
  bedCart: bedsCarts.selectedBedCart,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBedCart: (id) => dispatch(fetchBedCart(id)),
  deleteRoomPicture: (id) => dispatch(deleteRoomPicture(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(ActiveComponentsCard)));
