import {
  MONITORING_UHE_FETCH_DATA,
  MONITORING_UHE_FETCH_SUCCESS,
  MONITORING_UHE_FETCH_PIE_CHART_DATA,
  MONITORING_UHE_FETCH_PIE_CHART_SUCCESS,
  MONITORING_UHE_FETCH_GMAP_DATA,
  MONITORING_UHE_FETCH_GMAP_SUCCESS,
} from "@constants/UHEActionTypes";

/**
 * @param  {number} page
 * @param  {string} sorting
 * @param  {string} filter
 * @param  {string} dashboardType
 * @return {Object}
 */
export const onFetchData = (page, sorting, filter, dashboardType) => {
  return {
    type: MONITORING_UHE_FETCH_DATA,
    page,
    sorting,
    filter,
    dashboardType,
  };
};

/**
 * @param  {Object} data
 * @return {Object}
 */
export const fetchDataSuccess = (data) => {
  return {
    type: MONITORING_UHE_FETCH_SUCCESS,
    data,
  }
};

/**
 * @param  {string} filter
 * @return {Object}
 */
export const onFetchPieChartData = (filter) => {
  return {
    type: MONITORING_UHE_FETCH_PIE_CHART_DATA,
    filter,
  };
};

/**
 * @param  {Object} data
 * @return {Object}
 */
export const fetchPieChartDataSuccess = (data) => {
  return {
    type: MONITORING_UHE_FETCH_PIE_CHART_SUCCESS,
    data,
  }
};

/**
 * @param  {string} filter
 * @return {Object}
 */
export const onFetchGmapData = (filter) => {
  return {
    type: MONITORING_UHE_FETCH_GMAP_DATA,
    filter,
  };
};

/**
 * @param  {Object} data
 * @return {Object}
 */
export const fetchGmapDataSuccess = (data) => {
  return {
    type: MONITORING_UHE_FETCH_GMAP_SUCCESS,
    data,
  }
};
